import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import DataSource from "devextreme/data/data_source";
import { Router } from "@angular/router";
import { EntityService } from "app/shared/entity.service";
import { IEntity } from "app/shared/entity";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { lastValueFrom } from "rxjs";
import { isThisTypeNode } from "typescript";
import { SessionStorageService } from "app/shared/session-storage.service";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'panel-liste-etab',
    templateUrl: './panel-liste-etab.component.html',
    styleUrls: ['./panel-liste-etab.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelListEtabComponent implements OnInit, OnDestroy, OnChanges {
    @Input() applicationName: string = '';
    @Input() space: string = '';
    @Input() version: string = '';
    @Input() variante: string = '';
    @Input() zoneName: string = '';
    @Input() moduleName: string = '';
    @Input() tabName: string = '';
    @Input() perimeter: string = '';
    @Input() idEntity: string = '';
    @Input() entityCible: string = '';
    @Input() refreshEntities: boolean = false;
    @Input() parentContext: boolean = false;
    @Input() currentProcedureAssociation: string = '';
    fullyLoaded: boolean = false;
    @Input() currentProc: IEntity;
    @Input() currentZone: any;
    @Input() currentModule: any;
    @Input() currentTab: any;
    entities:IEntity[];
    idEntityUse : string = '';
    currentProcUse : IEntity;
    modulesConfig : any ={};
    tabConfig : any ={};
    constructor(
        private router: Router,
        private entityService: EntityService,
        private applicationStructService: FrontOfficeStructService,
        private sessionStorageService: SessionStorageService
    ){}

    async ngOnChanges(changes: any) {

        if (changes.zoneName) {
            this.fullyLoaded = false;
            this.currentProcUse = null;
            this.version ='';
            this.space ='';
            this.modulesConfig ={};
            this.tabConfig ={};
        }

        if (changes.applicationName) {
            this.modulesConfig ={};
            this.fullyLoaded = false;
            this.tabConfig ={};
            this.currentProcUse = null;
        }

        if (changes.entityCible && this.fullyLoaded && this.entityCible != null && this.entityCible != '') {
            await this.loadPanel();
            $('#navigation-list-etab').dxList('instance').repaint();
        }
        else if (!this.fullyLoaded && this.idEntity.length > 0 && this.entityCible != null) {
            await this.loadPanel();
        }
        else if (changes.idEntity && this.idEntity.length > 0 && this.idEntity != this.idEntityUse && this.fullyLoaded) {
            if(this.entities.filter((th: { IdObject: number; }) => th.IdObject == parseInt(this.idEntity)).length > 0) {
                $('#navigation-list-etab').dxList('instance').repaint();
            }
            else {
                await this.loadPanel();
            }
        }

        if (changes.refreshEntities && this.refreshEntities) {
            await this.loadPanel();
        }

        if (changes.currentProc) {
            this.currentProcUse = this.currentProc
            await this.loadPanel();
        }
    }
    
    async ngOnInit(): Promise<void> {
        this.ngOnDestroy(); 
        if(this.idEntity.length > 0 && this.version.length > 0) {
            await this.loadPanel();
        }
    }

    async loadPanel()
    {
        var ctrl = this;

        if(this.version == null || this.version.length == 0 || this.space == null || this.space.length == 0 || this.currentProcUse == null) {
            return;
        }

        this.entities =[];
        if (StringUtils.isMainEntity(this.entityCible) || this.parentContext)
        {
            if (this.currentProc == null || this.currentProc.IdObject == null) {
                return;
            }

            this.entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(this.space, this.currentProc.IdObject, this.entityCible, this.applicationName));
            this.entities = this.entities.sort((a, b) => parseInt(a['Name'].match(/^[0-9]+/)[0]) > parseInt(b['Name'].match(/^[0-9]+/)[0]) ? 1 : parseInt(a['Name'].match(/^[0-9]+/)[0]) === parseInt(b['Name'].match(/^[0-9]+/)[0]) ? 0 : -1)
        }
        else
        {
            if (this.currentProc == null || this.currentProc.IdObject == null) {
                this.entities = await lastValueFrom(this.entityService.getListEntityByEntityType(this.space, this.entityCible, this.applicationName));
            }
            else {
                this.entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(this.space, this.currentProc.IdObject, this.entityCible, this.applicationName));
            }
            this.entities = this.entities.sort((a, b) => a['LongName'].split(':')[1] > b['LongName'].split(':')[1] ? 1 : a['LongName'].split(':')[1] === b['LongName'].split(':')[1] ? 0 : -1)
        }
            
        var ds = new DataSource({  
            store: this.entities,   
            paginate: false
        }); 
        
        setTimeout (()=> {
            var height = (StringUtils.isMainEntity(ctrl.entityCible)) ? '31vh' : 'calc(98vh - 76px)';
            $('#navigation-list-etab').dxList({
                dataSource: ds,
                width:'400px',
                height:height,
                useNativeScrolling: false,
                showScrollbar: 'onScroll',
                displayExpr: 'LongName',
                searchEnabled: true,
                searchExpr: ['LongName'],
                itemTemplate(itemData) {
                    return $("<div />")
                        .css("padding", "5px")
                        .addClass(itemData.IdObject == ctrl.idEntity ? "module-selected" : "module-non-selected")
                        .append($("<p />").html(StringUtils.isMainEntity(ctrl.entityCible) ? itemData.LongName : itemData.LongName.split(':')[1])
                            .css("display", "inline-block")
                        );
                },
                onItemClick: async function (data:any) {
                    let variante = await lastValueFrom(ctrl.entityService.getVarianteFromEntity(ctrl.space, ctrl.version, data.itemData.IdObject, ctrl.perimeter));
                    ctrl.sessionStorageService.remove('simuLine');
                    ctrl.sessionStorageService.remove('currentLine');
                    var selectedModule: {
                        EntityCible: any;
                        ParentEntityCible: any;
                        ModuleName: any; Name: string; 
                    } = null;
                    let moduleName =  '';

                    var i =0;

                    if(ctrl.currentZone.Modules.filter((th: { Name: string; }) => th.Name == ctrl.currentModule.Name).length > 0)
                    {
                        var procsAvailable =   ctrl.currentZone.Modules.filter((th: { Name: string; }) => th.Name == ctrl.currentModule.Name)[0].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        ctrl.currentZone.Modules.filter((th: { Name: string; }) => th.Name == ctrl.currentModule.Name)[0].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && (data.itemData.EntityType == ctrl.currentModule.EntityCible || data.itemData.EntityType == ctrl.currentModule.ParentEntityCible) && tempProcAsso.length > 0){
                                selectedModule = ctrl.currentModule;
                            }
                        });
                    }
                    if(selectedModule == null)
                    {
                        do
                        {
                            var procsAvailable =   ctrl.currentZone.Modules[i].AvailableProcedureAssociations.split('.');
                            var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                                return procsAvailable.some((f : string) => {
                                    return f === tb;
                                })
                            });
                            ctrl.currentZone.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                                if((allowVariant == "RfcCommun" || allowVariant == variante) && (data.itemData.EntityType == ctrl.currentZone.Modules[i].EntityCible || data.itemData.EntityType == ctrl.currentZone.Modules[i].ParentEntityCible) && tempProcAsso.length > 0){
                                    selectedModule = ctrl.currentZone.Modules[i];
                                }
                            });
                            i++;
                        }
                        while(selectedModule == null && i<ctrl.currentZone.Modules.length);
                    }
                    moduleName = selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;

                    let tabs = await lastValueFrom(ctrl.applicationStructService.getStructOfModule(ctrl.applicationName, ctrl.version, ctrl.zoneName, moduleName));
                    var selectedtab: { Name: string; } = null;
                    var i =0;
                    if(tabs.Tabs.filter((th: { Name: string; }) => th.Name == ctrl.currentTab.Name).length > 0)
                    {
                        var procsAvailable =  tabs.Tabs.filter((th: { Name: string; }) => th.Name == ctrl.currentTab.Name)[0].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        tabs.Tabs.filter((th: { Name: string; }) => th.Name == ctrl.currentTab.Name)[0].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && ((selectedModule.EntityCible != selectedModule.ParentEntityCible && tabs.Tabs.filter((th: { Name: string; }) => th.Name == ctrl.currentTab.Name)[0].IsGridEntity && data.itemData.EntityType == selectedModule.ParentEntityCible && selectedModule.EntityCible != selectedModule.ParentEntityCible) || (!tabs.Tabs.filter((th: { Name: string; }) => th.Name == ctrl.currentTab.Name)[0].IsGridEntity && data.itemData.EntityType == selectedModule.EntityCible)) && tempProcAsso.length > 0){
                                selectedtab = ctrl.currentTab;
                            }
                        });
                    }
                    if(selectedtab == null )
                    {
                        do
                        {
                            var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                            var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                                return procsAvailable.some((f : string) => {
                                    return f === tb;
                                })
                            });
                            tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                                if((allowVariant == "RfcCommun" || allowVariant == variante) && ((tabs.Tabs[i].IsGridEntity && data.itemData.EntityType == selectedModule.ParentEntityCible && selectedModule.EntityCible != selectedModule.ParentEntityCible) || (!tabs.Tabs[i].IsGridEntity && data.itemData.EntityType == selectedModule.EntityCible)) && tempProcAsso.length > 0){
                                selectedtab = tabs.Tabs[i];
                                }
                            });
                            i++;
                        }
                        while(selectedtab == null && i<tabs.Tabs.length);
                    }
                    ctrl.modulesConfig[variante] = moduleName;
                    ctrl.tabConfig[variante] = selectedtab

                    ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ moduleName+'/'+ data.itemData.IdObject+'/'+ selectedtab.Name]);

                    ctrl.sessionStorageService.set('currentSelectedUnitName', data.itemData.Name);
                                    
                }}).dxList('instance')
            ctrl.fullyLoaded = true;
        }, 1000);
    }

    ngOnDestroy(): void {}
}