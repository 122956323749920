import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, of } from "rxjs";
import { shareReplay, catchError, tap, retry } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class FrontOfficeGridService {
    private appUrl = './api/{version}/app/{appName}/{zone}';

    constructor(private http: HttpClient, private localStorageService: LocalStorageService)
    { }

    getGridConfigByEntityId(space: string,version: string, appName: string, idEntity: string, zone: string, module: string, tab:string, bloc:string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        if (storage != null){
            if (storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc] != null) {
                return of(JSON.parse(storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc]));
            }
        }
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + idEntity + '/' + module+ '/' + tab+ '/' + bloc + '/settings/grid.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc] = JSON.stringify(data);
                this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    updateAttributProperty(space: string, version: string, appName: string, zone: string,module:string, tab:string, bloc:string, tabName: string, attribut: any): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + module+ '/' + tab+ '/' + bloc+ '/' + tabName + '/grid_attribut.json'
       return this.http.put(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    insertAttribut(space: string, version: string, appName: string, zone: string,module:string, tab:string, bloc:string, tabName: string, attribut: any, entitiyId: string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + module+ '/' + tab+ '/' + bloc+ '/' + entitiyId+ '/' + tabName + '/grid_attribut.json'
       return this.http.post(url,attribut)
       .pipe(tap(data => JSON.stringify(data)));
    }

    insertAttributEntity(space: string, version: string, appName: string, zone: string,module:string, tab:string, bloc:string, tabName: string, attribut: any, entitiyId: string, isGridEntity : boolean): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        attribut.Id = 0;
        if(!isGridEntity)
            attribut.Id ='0';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + module+ '/' + tab+ '/' + bloc+ '/' + entitiyId+ '/' + tabName + '/grid_attribut_entity.json'
       return this.http.post(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }


    deleteAttribut(space: string, version: string, appName: string, zone: string,module:string, tab:string, bloc:string, tabName: string, idAttribut: string, entitiyId: string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + module+ '/' + tab+ '/' + bloc+ '/' + tabName+ '/' + entitiyId+ '/' + idAttribut + '/grid_attribut.json'
       return this.http.delete(url)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    getGridDataByEntityId(space: string,version: string,  appName: string, idEntity: string, stateId:string, procID: number, zone: string, module: string, tab:string, bloc:string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + idEntity + '/'+ stateId+ '/'+ procID + '/' + module+ '/' + tab+ '/' + bloc + '.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getMasterData(version: string, componentId: string,  appName: string, zone: string, module: string, tab:string, bloc:string) {
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + componentId + '/' + module+ '/' + tab+ '/' + bloc+ '/detail_master.json';
        return this.http.get<any>(url);
    }


    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}