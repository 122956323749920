// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-mobile .entity-title{
    background: transparent;
    display: flex;
    align-items: center;
}
.is-mobile .entity-title i{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    color: rgba(39, 40, 56, 1);;
    margin: 0 1rem 0 1rem;
}
.is-mobile .entity-title i::before{
    font-size: 1.25rem;
}
.is-mobile #navigation-list-etab-mobile{
    padding-left: 0;
    height: 100%;
    flex-grow: 1;
}

:host.mobile .etab-detail-list{
    font-size: 1rem;
    line-height: 1.25rem;
    margin-left: 1rem;
}

:host.mobile{
    background: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./app/front-office/panel-detail-etab/panel-detail-etab-mobile.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,aAAa;IACb,cAAc;IACd,0BAA0B;IAC1B,qBAAqB;AACzB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".is-mobile .entity-title{\r\n    background: transparent;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.is-mobile .entity-title i{\r\n    display: flex;\r\n    align-content: center;\r\n    justify-content: center;\r\n    width: 1.5rem;\r\n    height: 1.5rem;\r\n    color: rgba(39, 40, 56, 1);;\r\n    margin: 0 1rem 0 1rem;\r\n}\r\n.is-mobile .entity-title i::before{\r\n    font-size: 1.25rem;\r\n}\r\n.is-mobile #navigation-list-etab-mobile{\r\n    padding-left: 0;\r\n    height: 100%;\r\n    flex-grow: 1;\r\n}\r\n\r\n:host.mobile .etab-detail-list{\r\n    font-size: 1rem;\r\n    line-height: 1.25rem;\r\n    margin-left: 1rem;\r\n}\r\n\r\n:host.mobile{\r\n    background: rgb(255, 255, 255);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
