import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import DataSource from "devextreme/data/data_source";
import { Router } from "@angular/router";
import { EntityService } from "app/shared/entity.service";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { HomePageService } from "app/home-page/home-page.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'panel-liste-zone',
    templateUrl: './panel-liste-zone-mobile.component.html',
    styleUrls: ['./panel-liste-zone-mobile.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelListZoneMobileComponent implements OnInit, OnDestroy, OnChanges {
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() displayState: boolean = true;
    fullyLoaded: boolean = false;
    availableZones : any;
    availableApps : any = [];
    constructor(
        private router: Router,
        private spaceService: HomePageService,
        private entityService: EntityService,
        private applicationStructService: FrontOfficeStructService)
    {}

    async ngOnChanges(changes: any) {
        if (changes.zoneName && this.fullyLoaded) {
        $('#navigation-list-zone').dxList('instance').repaint();
        }
        if (changes.applicationName && this.fullyLoaded) {
        $('#navigation-list-app').dxList('instance').repaint();
        await this.loadPanel();
        }
    }
    

    async ngOnInit(): Promise<void> {
        this.ngOnDestroy(); 
        if(this.applicationName.length > 0 && this.zoneName.length > 0)
            await this.loadPanel();
    }

    async loadPanel()
    {
        var ctrl = this;
        if(this.applicationName.length == 0 || this.zoneName.length == 0)
            return;
        if(this.availableApps.length == 0)
        {
            this.availableApps = await lastValueFrom(this.spaceService.getAvailableApplications());
            
            var ds = new DataSource({  
                store: this.availableApps.filter((p: { Name: string; })=>p.Name != 'BO'),   
                paginate: false  
            }); 
            setTimeout (  ()=> {
                $('#navigation-list-app').dxList({
                    dataSource: ds,
                    width:'100%',
                    height:'40vh',
                    displayExpr: 'LongName',
                    searchEnabled: true,
                    searchExpr: ['LongName'],
                    itemTemplate(itemData, itemIndex, itemElement) {
                        if(itemData.Name == ctrl.applicationName)
                            return $("<div />").css("padding", "5px").addClass("module-selected").append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                        else
                            return $("<div />").addClass("module-non-selected").css("padding", "5px").append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                    },
                    onItemClick: async function (data:any)
                    {
                        ctrl.applicationName = data.itemData.Name;
                        ctrl.loadPanel();
                    }
                }).dxList('instance')
            }, 1000);
        }
        this.availableZones = await lastValueFrom(this.spaceService.getAvailableZonePerApplications(this.applicationName));
                   
        var dsZone = new DataSource({  
            store: this.availableZones,   
            paginate: false  
        }); 
        setTimeout (  ()=> {
            $('#navigation-list-zone').dxList({
                dataSource: dsZone,
                width:'100%',
                displayExpr: 'LongName',
                searchEnabled: true,
                height:'calc(60vh - 95px)',
                searchExpr: ['LongName'],
                itemTemplate(itemData, itemIndex, itemElement) {
                    if(itemData.Name == ctrl.zoneName)
                        return $("<div />").css("padding", "5px").css("padding-left", "2.5rem !important").addClass("module-selected").append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                    else
                        return $("<div />").addClass("module-non-selected").css("padding", "5px").css("padding-left", "2.5rem !important").append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                },
                onItemClick: async function (data:any)
                {
                    ctrl.onZoneClicked(data.itemData)
                }
            }).dxList('instance')
            ctrl.fullyLoaded = true;
        }, 1000);
    }

    async onZoneClicked(data: any): Promise<void> {
        var zone = data.Name;
        let value = await this.spaceService.getSpaceVersion(this.applicationName, zone, this.displayState);               
        let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(this.applicationName, value, zone));
        if(modules.Modules[0].IsLinkToProcedure)
        {
            let procs = await lastValueFrom(this.entityService.getAvailableProcFO(zone, value, this.applicationName, 'a'));
            let mainProc = procs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
            var currentProcedureAssociation  = await lastValueFrom(this.entityService.getProcedureAssociation(this.zoneName, mainProc.IdObject));
            let entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(zone, mainProc.IdObject, modules.Modules[0].ParentEntityCible, this.applicationName));
            let variante = await lastValueFrom(this.entityService.getVarianteFromEntity(modules.Space, modules.Version, entities[0].IdObject.toString(), modules.Perimeter));
            var selectedModule: { ModuleName: any; Name: string; } = null;
            var i =0;
            do
            {
                var procsAvailable =  modules.Modules[i].AvailableProcedureAssociations.split('.');
                var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                });
                modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                        selectedModule = modules.Modules[i];
                    }
                });
                i++;
            }
            while(selectedModule == null && i<modules.Modules.length);
            let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
            let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName, value, zone, moduleName)) 
            var selectedtab: { Name: string; } = null;
            var i =0;
            do
            {
                var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                });
                tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                    selectedtab = tabs.Tabs[i];
                    }
                });
                i++;
            }
            while(selectedtab == null && i<tabs.Tabs.length);
            this.router.navigate(['/app/' + this.applicationName+'/'+ zone+'/'+ moduleName+'/'+ entities[0].IdObject.toString()+'/'+ selectedtab.Name]);
        }
        else
        {
            let moduleName =  modules.Modules[0].ModuleName != null ? modules.Modules[0].ModuleName : modules.Modules[0].Name;
            
            let moduleConfig = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName, value, zone, moduleName));
            let entity = await lastValueFrom(this.entityService.getFirstEntityAppByEntityType(modules.Space, this.applicationName, modules.Modules[0].EntityCible));
            this.router.navigate(['/app/' + this.applicationName+'/'+ zone+'/'+ moduleName+'/'+ entity.IdObject+'/'+ moduleConfig.Tabs[0].Name]);
        }
    }
    ngOnDestroy(): void {}
}