import { Component, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActionTypeButton, IEntity, TargetButton } from 'app/shared/entity';
import { AuthService } from 'app/shared/auth.service';
import { IActionAppButton } from 'app/front-office/front-office-struct';
import { FrontOfficeStructService } from 'app/front-office/front-office-struct.service';
import { EntityService } from 'app/shared/entity.service';
import * as $ from 'jquery';
import DataSource from 'devextreme/data/data_source';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionLegacyService } from "app/shared/actions-legacy.service";
import { lastValueFrom } from 'rxjs';
import { ListUtils } from "app/utils/list-utils";
import { SessionStorageService } from "app/shared/session-storage.service";
import { FrontOfficeToolbarFactory } from '../../shared-factory/front-office-toolbar-factory';
import { StringUtils } from 'app/utils/string-utils';

@Component({
    selector: 'front-office-toolbar',
    templateUrl: './front-office-toolbar.component.html',
    styleUrls: ['./front-office-toolbar.component.css']
})

export class FrontOfficeToolbarComponent implements OnInit, OnDestroy, OnChanges {
    @Input() currentState : any;
    @Input() currentStateTab : any;
    @Input() formStates : any;
    @Input() states : any;
    @Input() listTabState : any;
    @Input() detailTab: any;
    @Input() version: string = '';
    @Input() updateDate: string ;
    @Input() updateUser: string ;
    @Input() blockedCalcul: boolean;
    @Input() identifier: string = '';
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() space: string = '';
    @Input() tabName: string = '';
    @Input() moduleName: string = '';
    @Input() tabsBlocs: any [] = [];
    @Input() variante: string = '';
    @Input() currentEntity :any | undefined;
    @Input() parentEntity :any | undefined;
    @Input() currentTab: any | undefined;
    @Input() currentModule: any | undefined;
    @Input() currentZone: any | undefined;
    @Input() currentProc: IEntity;
    @Input() currentProcedureAssociation :string;
    @Input() tabStatus: number = 0;
    name = 'Toolbar';
    errorMessage = '';
    helpTab = '';
    baseUrl: string;
    applicationActions: IActionAppButton[] = [];
    applicationTransitions: IActionAppButton[] = [];
    displayApplicationActions: IActionAppButton[] = [];
    perimeter: string = '';    
    tabs: any [] = [];
    reportsDef: any [] = [];
    exportsDef: any [] = [];
    fullExportsDef: any [] = [];
    tabRef: string = '';
    tabColor: string = ''; 
    selectedTab: any;
    simulations: IEntity[];
    multiTab : boolean = false;
    hasMultipleWorkflow : boolean = true;
    hasMainEp : boolean = true;
    fullyLoaded: boolean = false;
    idEntityUse : string = '';
    modulesConfig : any ={};
    tabConfig : any ={};
    availableProcs:IEntity[];
    constructor(private router: Router,
        private sessionStorageService: SessionStorageService,
        private authService: AuthService,
        private entitiesService : EntityService,
        public sanitizer: DomSanitizer,
        private applicationStructService: FrontOfficeStructService,
        private actionLegacyService: ActionLegacyService,
    ) 
    {
        this.baseUrl = router.url;
    }

    async ngOnInit(): Promise<void> {
        if (this.authService.hasIdToken()) {
            this.ngOnDestroy();
            await this.loadPanel();
        }
    }

    async ngOnChanges(changes: any) {
        if (changes.zoneName) {
            this.modulesConfig={};
            this.tabConfig={};
            this.availableProcs = [];
            this.fullExportsDef = [];
            this.fullyLoaded = false;
            this.helpTab ='';
            $('#buttonChangeState').parent().hide();
            $('#list-proc-main').hide();
            $('#listChangeState').hide();
        }
        if (changes.applicationName) {
            this.modulesConfig={};
            this.tabConfig={};
            this.availableProcs = [];
            this.fullExportsDef = [];
            this.fullyLoaded = false;
            this.tabs=[];
            $('#list-proc-main').empty();
            $('#buttonChangeState').empty();
            $('#listChangeState').empty();
            $('#list-proc-main').hide();
            $('#listChangeState').hide();
            $('#buttonChangeState').parent().hide();
        }
        if(changes.blockedCalcul)
        {
            var calculButton =  this.displayApplicationActions.filter((tb: { Name: string; }) => tb.Name == 'Calcul')[0];
            if(!calculButton) {
                return;
            }

            calculButton.Blocked = this.blockedCalcul;
            calculButton.LongName = this.blockedCalcul ? 'En cours de mise à jour ...' : 'A jour';
        }

        FrontOfficeToolbarFactory.handleCurrentStateChange(this, changes);

        if (changes.currentStateTab && this.fullyLoaded) {
            await this.loadPanel();
        }
        if (changes.identifier && this.identifier != this.idEntityUse) {
            this.availableProcs = [];
            this.helpTab ='';
        }

        FrontOfficeToolbarFactory.handleTabAndVarianteChanges(this, changes);
    }

    async loadPanel() {
        var ctrl = this;
        this.hasMainEp = false;
        if(this.currentZone != null)
        {
            for (var i = 0; i < this.currentZone.Modules.length; i++) {
                if(this.currentZone.Modules[i].IsLinkToProcedure)
                    this.hasMainEp = true;
            }  
        }

        this.baseUrl = this.router.url;
        if(this.version == null || this.version.length == 0 || this.currentZone == null || this.currentModule == null || (this.currentProc == null && (this.hasMainEp))) return;
        if (!this.identifier && this.identifier != '') return;
        this.ngOnDestroy();
        this.multiTab = this.currentModule.Tabs.length > 1 && this.tabName.substring(0, this.tabName.length-1) != this.currentModule.Name;
        this.space = this.currentModule.Space;
        this.perimeter = this.currentModule.Perimeter;
        this.tabs = [];
        var modules = this.currentZone.Modules;
        this.idEntityUse = this.identifier; 
            
        if(this.hasMainEp && this.identifier != '0') {
            if(this.currentZone.SySelector != 'Masquer') {
                if(this.availableProcs.length == 0 && this.idEntityUse == this.currentEntity.IdObject)  {
                    this.availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, this.currentEntity.Name))
                }
                if(this.availableProcs.length == 0 && this.parentEntity != null) {
                    this.availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, this.parentEntity.Name))
                }
                
                $('#list-proc-main').show();
                setTimeout(()=>{
                    $('#list-proc-main').dxSelectBox({
                        items: this.availableProcs,
                        placeholder: 'Sélectionner une année',
                        displayExpr:'LongName',
                        disabled : ctrl.currentZone.SySelector == 'Visible',
                        width:'250px',
                        value: this.availableProcs.filter((tb: { Name: string; }) => tb.Name == ctrl.currentProc.Name)[0],
                        showClearButton: false,
                        onItemClick(data) {
                            FrontOfficeToolbarFactory.returnToProc(ctrl, data.itemData)
                        }
                    });
                        
                    $('#list-proc').dxSelectBox('instance')
                    $('#list-proc-main').dxSelectBox('instance').repaint();
                }, 200)
            }
            var i =0;
            modules =  FrontOfficeToolbarFactory.getRetrieveModulesByProcedure(ctrl);
        }
        else {
            if(this.currentZone.SySelector != 'Masquer' && this.currentProc != null) {
                if(this.availableProcs.length == 0) {
                    this.availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, 'a'))
                }             
                $('#list-proc-main').show();
                setTimeout(()=>{
                    $('#list-proc-main').dxSelectBox({
                        items: this.availableProcs,
                        placeholder: 'Sélectionner une année',
                        displayExpr:'LongName',
                        disabled : ctrl.currentZone.SySelector == 'Visible',
                        width:'250px',
                        value: this.availableProcs.filter((tb: { Name: string; }) => tb.Name == ctrl.currentProc.Name)[0],
                        showClearButton: false,
                        onItemClick(data)
                        {
                            ctrl.actionLegacyService.changeProcEmitter.emit(data.itemData);
                        }
                    });
                    
                    $('#list-proc').dxSelectBox('instance')
                    $('#list-proc-main').dxSelectBox('instance').repaint();
                }, 200)
            }
            for (var j = 0; j < modules.length; j++) {
                for (var i = 0; i < modules[j].Tabs.length; i++) {
                    modules[j].Tabs[i].ModuleName = modules[j].Name;
                }
            }
        }
        this.idEntityUse = this.identifier; 

        FrontOfficeToolbarFactory.setTabs(ctrl);

        let fullTabs: any[] = FrontOfficeToolbarFactory.getFullTabs(ctrl, modules);
        var ctrl = this;
        
        this.displayToolbarAction();
        
      
        if(ctrl.currentTab != null) {
            ctrl.currentTab.Label = StringUtils.decodeSpecialEntitiesHtml(ctrl.currentTab.Label)
            ctrl.currentTab = fullTabs.filter((th: { Label: string; }) => th.Label == ctrl.currentTab.Label)[0];
            ctrl.selectedTab = ctrl.currentTab
        }

        fullTabs = this.getFilteredPanelsList(fullTabs)

        setTimeout (  ()=> {
        $('#listTabs').dxLookup({
            dataSource: new DataSource(FrontOfficeToolbarFactory.getListTabDatasourceObject(fullTabs)),
            dropDownOptions: {
                hideOnOutsideClick: true,
                showTitle: false,
                container: '#bodyApp',
                maxHeight:'75vh',
                position: {
                    collision: 'fit',
                    at:'left bottom',
                    my:'left top',
                    of:'#listTabs'
                },
                wrapperAttr: {
                    class: "list-tabs"
                }
            },
            width:'450px',
            displayExpr: 'Label',
            grouped: true,
            searchEnabled: true,
            searchExpr: ['Label','ModuleParent'],
            value:ctrl.selectedTab,
            groupTemplate(data){ return FrontOfficeToolbarFactory.getListTabGroupTemplate(data) },
            fieldTemplate(itemData, container) {
                if(itemData != null)
                {   
                    var valueLength = (itemData.Label.length +3)*8
                    var colorBack = "transparent";
                    if(ctrl.currentStateTab) {
                        colorBack = ctrl.currentStateTab.Color;
                    }
                    container.append($("<div/>")
                        .css("display","contents")
                        .append($("<span/>").addClass("status-chip-module")
                        .css("margin","0 .375rem 0 0rem")
                        .css("background-color", colorBack))
                        .append($("<p />").css("display","inline-block").dxTextBox({ value: itemData.Label, readOnly: true, width: valueLength+'px'}))
                    );
                }
                else
                {
                    if(ctrl.detailTab.DetailEntity)
                    {
                        var colorBack = "transparent";
                        if(ctrl.currentStateTab)
                            colorBack = ctrl.currentStateTab.Color;
                        var valueLength = ('Détail'.length +3)*8
                        container.append($("<div/>")
                        .css("display","contents")
                        .append($("<span/>").addClass("status-chip-module")
                        .css("margin","0 .375rem 0 0rem")
                        .css("background-color", colorBack))
                        .append($("<p />").css("display","inline-block").dxTextBox({ value: 'Détail', readOnly: true, width: valueLength+'px'})) );
                    }
                    else if (fullTabs != null && fullTabs.length > 0) {
                        var valeur = 'Veuillez selectionner un onglet';

                        var valueLength = (valeur.length +3)*8
                        var colorBack = "transparent";
                        if(ctrl.currentStateTab) {
                            colorBack = ctrl.currentStateTab.Color;
                        }
                        container.append($("<div/>")
                            .css("display","contents")
                            .append($("<span/>").addClass("status-chip-module")
                            .css("margin","0 .375rem 0 0rem")
                            .css("background-color", colorBack))
                            .append($("<p />").css("display","inline-block").dxTextBox({ value: valeur, readOnly: true, width: valueLength+'px'}))
                        );
                    }
                }
            },
            itemTemplate(itemData, itemIndex, itemElement) { return FrontOfficeToolbarFactory.getListTabsItemTemplate(ctrl, itemData, itemIndex); },
            onItemClick: async function (data:any) {
                if(!data.itemData.IsGridEntity) {
                    if(data.itemData.ModuleName != null) {
                        ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/'+ ctrl.identifier+'/'+ data.itemData.Name]);
                    }
                    else {
                        let module = modules.filter((tb: { Tabs: []; }) => tb.Tabs.filter((th: { Label: string; }) => th.Label == data.itemData.Label).length > 0)[0];
                        ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ module.Name+'/'+ ctrl.identifier+'/'+ data.itemData.Name]);
                    }
                }
                else {
                    if(!data.itemData.AllowFilterEntityT) {
                        if(ctrl.currentModule.IsLinkToProcedure && ctrl.currentTab.IsGridEntity) {
                            let procs = await lastValueFrom(ctrl.entitiesService.getAvailableProcFO(ctrl.zoneName, ctrl.version, ctrl.applicationName, 'a'));
                            let mainProc = procs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
                            let entities = await lastValueFrom(ctrl.entitiesService.getAvailableEntitiesFromProc(ctrl.zoneName, mainProc.IdObject, ctrl.currentModule.ParentEntityCible, ctrl.applicationName));
                            if(entities.filter((th: { IdObject: number; }) => th.IdObject.toString() == ctrl.identifier).length > 0) {
                                ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/'+ctrl.identifier+'/'+ data.itemData.Name]);
                            }
                            else {
                                ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/'+entities[0].IdObject+'/'+ data.itemData.Name]);
                            }
                        }
                        else {
                            ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/0/'+ data.itemData.Name]);
                        }
                    }
                    else {
                        ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/'+ctrl.identifier+'/'+ data.itemData.Name]);
                    }
                }
            }
        }).dxLookup('instance')
        $(".dx-lookup-field").on('mouseenter' ,function() {
            $('#listTabs').dxLookup('instance').open();
        });
        if(this.currentState != null) {
            this.tabColor = this.currentState.Color+' !important';
        }
        ctrl.fullyLoaded = true;
        }, 1000);
        
        this.tabRef  = this.zoneName+'_'+this.applicationName ;
    }

    goToHelpPage() {
        this.router.navigate([this.helpTab]);
    }

    returnToParentList(): void {
        var listModule =this.currentModule.Tabs.filter((tb: { IsGridEntity: string; }) => tb.IsGridEntity)[0];
        this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ this.moduleName+'/'+this.identifier+'/'+ listModule.Name]);
    }

    ngOnDestroy(): void {
    }

    async displayToolbarAction() {
        if((!this.currentState && this.identifier != '0') || !this.currentModule || this.currentModule.Name != this.moduleName || this.currentState == null) {
            return;
        }
         
        this.space = this.currentModule.Space;
        let tempRapport: any[] = [];
        if(this.currentState != null) {
            tempRapport = await lastValueFrom(this.applicationStructService.getReportConfig(this.applicationName, this.version, this.zoneName, this.currentState.Id, parseFloat(this.identifier)));
        }
        this.fullExportsDef = await lastValueFrom(this.applicationStructService.getExportConfig(this.applicationName, this.version, this.zoneName));
        this.exportsDef = [];

        for(var i = 0;i<this.fullExportsDef.length;i++)
        {
            var procsAvailable =  this.fullExportsDef[i].AvailableProcedureAssociations.split('.');
            var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
                return procsAvailable.some((f : string) => { return f === tb; })
            });
            this.fullExportsDef[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                if((allowVariant == "RfcCommun" || allowVariant == this.variante) && tempProcAsso.length > 0)
                {
                    if(this.exportsDef.indexOf(this.fullExportsDef[i]) == -1 && this.fullExportsDef[i].EntityCible == this.currentModule.EntityCible)
                    {
                        this.exportsDef.push(this.fullExportsDef[i]);
                    }
                }
            });
        }

        if(this.identifier == '0') {
            this.exportsDef = [];
        }
            
        var gridBlocs = this.tabsBlocs.filter((bloc: { Type: string; }) => bloc.Type == 'Grid').length;
        if(gridBlocs > 0) {
            this.exportsDef.push({
                AllowFilterEntity : false,
                AvailableVariantes: "RfcCommun",
                DetailEntity: false,
                EntityCible :"AA",
                IsGridEntity :false,
                IsTabHelp:false,
                Label: "Exporter l'onglet courant",
                Name :"GCourApp",
                Order: 99,
                Tooltip:null
            });
        }
        this.reportsDef =[];

        FrontOfficeToolbarFactory.filterAndOrganizeReports(this, tempRapport);

        let applicationActions: any[] = [];
        this.applicationTransitions = [];
        if(this.currentState != null && (this.currentStateTab != null || this.hasMainEp ))
        {
            applicationActions = await lastValueFrom(this.applicationStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.tabName.indexOf('/') == -1 ? this.tabName : this.tabName.split('/')[1], this.currentState.Id, this.currentStateTab != null ? this.currentStateTab.Id : 'draft'));
            if (this.currentState.Id)
                this.applicationTransitions  = await lastValueFrom(this.applicationStructService.getTransitions(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.currentState.Id));
        }
        else
        {
            applicationActions = await lastValueFrom(this.applicationStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.tabName.indexOf('/') == -1 ? this.tabName : this.tabName.split('/')[1], this.formStates[0].Id, 'draft'));
            if (this.currentState != null && this.currentState.Id)
                this.applicationTransitions  = await lastValueFrom(this.applicationStructService.getTransitions(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.currentState.Id));            
        }

        var tabName = this.tabName;
        if(this.multiTab && this.tabName.indexOf('/') == -1) {
            this.tabName = this.currentModule.Name+'/'+this.tabName;
        }
        else if (this.multiTab) {
            tabName = this.tabName.split('/')[1];
        }
        
        this.applicationActions = applicationActions.filter(FrontOfficeToolbarFactory.IsPartOfToolBar);
        this.displayApplicationActions = [];
        if(this.reportsDef.length == 0)
            this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name != 'Rapport');
        if(this.exportsDef.length == 0 || this.exportsDef[0].Label == 'Schéma initial')                  
            this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name != 'Export' && tb.Name != 'Import');
        else if(this.exportsDef[0].Name == 'GCourApp')   
            this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name != 'Import');
            var IsGridEntity = this.currentModule.Tabs.filter((tb: { Name: string; IsGridEntity?: boolean }) => tb.Name == tabName)[0]?.IsGridEntity ?? false;
            if(IsGridEntity) {
                this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name == 'Export');
        }
        for (var i = 0; i < this.applicationActions.length; i++) {
            if(this.applicationActions[i].Name != 'Calcul' || !(IsGridEntity))
            this.displayApplicationActions.push(this.applicationActions[i]);
        }
        var ctrl = this;
        if(this.currentZone.StateSelector != 'Masquer' && !(IsGridEntity))
        {
            let allowFormState: any[] = [];
            if(ctrl.currentState == null)
            return;
            for (var i = 0; i < this.formStates.length; i++) {
                if(this.formStates[i].Id  == ctrl.currentState.Id)
                {
                    
                    this.formStates[i].Label = StringUtils.decodeSpecialEntitiesHtml(this.formStates[i].Label)
                    allowFormState.push(this.formStates[i]);
                }
                else
                {
                    var action = <any>this.applicationTransitions.filter((th: { Name: string; }) => th.Name == this.formStates[i].Id)[0];
                    if(action != null)
                    {
                        this.formStates[i].Label = StringUtils.decodeSpecialEntitiesHtml(action.LongName)
                        allowFormState.push(this.formStates[i]);
                    }
                }
            }
            if(this.currentEntity.Status == 'V') {
                this.displayApplicationActions = this.displayApplicationActions.filter((th: { Name: string; }) => th.Name != 'Calcul'&& th.Name != 'AddSimu'&& th.Name != 'Import');
            }
            allowFormState = allowFormState.filter((th: { Id: string; }) => th.Id != ctrl.currentState.Id);
            this.hasMultipleWorkflow = allowFormState.length > 1;
            if(this.hasMultipleWorkflow && this.currentEntity.Status != 'V') {
                $('#listChangeState').show();

                await FrontOfficeToolbarFactory.setListChangeState(ctrl, allowFormState);

                $('#listChangeState').dxSelectBox('instance').repaint();
            }
            else if( allowFormState.length > 0 && allowFormState[0].id != 'Closed' && this.currentEntity.Status != 'V') {   
                $('#buttonChangeState').parent().show();
                $('#buttonChangeState').dxButton({
                    text: allowFormState[0].Label,
                    disabled : ctrl.currentZone.StateSelector == 'Visible',
                    template() {
                        return $("<div />")
                            .addClass("module-non-selected")
                            .append($("<span/>").addClass("status-chip-module").css("background-color", allowFormState[0].Color).css("margin", "0 0.375rem 0 0rem"))
                            .append($("<p />").html(allowFormState[0].Label).css("display", "inline-block").css("padding", "1px").css("margin-bottom", "0"));
                    },
                    onClick: async function (data:any) {
                        var action = ctrl.applicationTransitions.filter((th: { Name: string; }) => th.Name == allowFormState[0].Id)[0];
                        action.FormStatus = ctrl.currentState.Id;
                        if(ctrl.multiTab && ctrl.tabName.indexOf('/') == -1) {
                            ctrl.tabName = ctrl.currentModule.Name+'/'+ctrl.tabName;
                        }
                        switch (action.ActionType) {
                            case ActionTypeButton.API:
                                ctrl.actionLegacyService.parsingActionTypeApiApp(action, ctrl.space, parseFloat(ctrl.identifier), ctrl.currentModule.EntityCible, ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
                                break;
                            case ActionTypeButton.Javascript:
                                await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(action,ctrl.space, parseFloat(ctrl.identifier), ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.currentModule.EntityCible, ctrl.reportsDef, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.exportsDef, ctrl.currentZone.WorkflowId, allowFormState[0].Confirmation, ctrl.currentProcedureAssociation);
                                break;
                        }
                    }
                }).dxButton('instance')
                $('#buttonChangeState').dxButton('instance').repaint();
            }
            if(this.currentEntity.Status == 'V' || ( this.applicationTransitions != null && this.applicationTransitions.length == 0)) {
                $('#buttonChangeState').parent().hide();
                $('#listChangeState').hide();
            }
        }
        else {
            $('#listChangeState').hide();
            $('#buttonChangeState').parent().hide();
        }

        var temp =     this.displayApplicationActions.filter((th: {VisibilityCriteria: string; Name: string; }) => th.Name != 'Calcul' && th.Name != 'Rapport' && th.Name != 'Export' && th.Name != 'Import' && th.VisibilityCriteria != '')
        this.displayApplicationActions = this.displayApplicationActions.filter((th: { Name: string; }) => th.Name == 'Calcul' || th.Name == 'Rapport' || th.Name == 'Export' || th.Name == 'Import')
        var newSimu = temp.filter((th: { Name: string; }) => th.Name == 'AddSimu')[0];
        if(newSimu != null)
        this.simulations = await lastValueFrom(this.entitiesService.getListOfSimulation(this.space, this.identifier));
        else
        this.simulations = [];

        if(this.simulations.length == 0) {
            $('#list-simu').hide();
        }
        else {
            $('#list-simu').show();
            setTimeout(()=>{
                $('#list-simu').dxSelectBox({
                    items: this.simulations,
                    placeholder: 'Sélectionner une simulation',
                    displayExpr:'LongName',
                    width:'250px',
                    showClearButton: true,
                    value: this.simulations.filter((tb: { IdObject: number; }) => tb.IdObject == this.currentEntity.IdObject)[0],
                    onItemClick(data)
                    {
                        ctrl.actionLegacyService.changeSimuEmitter.emit(data.itemData);
                    },
                    onValueChanged(data)
                    {
                        if(data.value == null)
                            ctrl.actionLegacyService.changeSimuEmitter.emit(null);
                    },
                });
                
                $('#list-simu').dxSelectBox('instance').repaint();
            }, 200)
        }
        for (var i = 0; i < temp.length; i++) {
            if(this.actionLegacyService.canDisplayAppAction(temp[i], this.currentEntity, '', this.simulations.length)) {
                this.displayApplicationActions.push(temp[i]);
            }
        }
        var calculButton =  this.displayApplicationActions.filter((tb: { Name: string; }) => tb.Name == 'Calcul')[0];
        if(calculButton != null) {
            var list = ctrl.listTabState.filter((th: string ) => (th.endsWith('#draft') || th.endsWith('#with_error') || th.endsWith('#with_warning')) && th.indexOf('LIST#') == -1 && ctrl.tabs.filter((tg: string ) => tg.replace('/', '_') == th.split('#')[0]).length > 0);
            calculButton.LongName = list.length == 0 ? 'A jour' : 'Mettre à jour';
        }

        await FrontOfficeToolbarFactory.setListActionsSelectBox(ctrl);
    }

    getFilteredPanelsList(items:any[]):any {
        if (ListUtils.listHasElements(items)) {
            return items.filter((x: { IsTabHelp: boolean; }) => !x.hasOwnProperty('IsTabHelp') || x.IsTabHelp == null || x.IsTabHelp == false);
        }
        else {
            return '';
        }
    } 

    async ToolbarItemOnClick(element: IActionAppButton) {
        if(element.Blocked || element.Disabled) {
            return;
        }

        var statusTab = this.identifier == '0' ? 0 : this.tabStatus;
        element.FormStatus = this.identifier == '0' ? this.formStates[0].Id : this.currentState.Id;

        var idSimu = this.sessionStorageService.get('simuLine');
        var id = idSimu != null ? idSimu : this.sessionStorageService.get('currentLine');
        var entityType = id == null ? this.currentModule.ParentEntityCible : this.currentModule.EntityCible;

        if(id == null)
        {
            id = this.identifier;
            if(this.currentModule.ParentEntityCible != this.currentModule.EntityCible) 
            {
                element.Actions = element.Actions.replace('ChildEntityType\\\":[]', 'ChildEntityType\\\":[\\\"'+this.currentModule.EntityCible+'\\\"]')
            }
        }
        switch (element.ActionType) {
            case ActionTypeButton.API:
                this.actionLegacyService.parsingActionTypeApiApp(element, this.space, parseFloat(id), entityType, this.tabName, this.tabRef, statusTab, this.tabs, this.applicationName, this.zoneName, this.version, this.currentZone.WorkflowId);
                break;
            case ActionTypeButton.Javascript:
                await this.actionLegacyService.parsingActionTypeJavascriptApp(element,this.space, parseFloat(id), this.tabName, this.tabRef, statusTab, this.tabs, entityType, this.reportsDef, this.applicationName, this.zoneName, this.version, this.exportsDef, this.currentZone.WorkflowId, null, this.currentProcedureAssociation);
                break;
        }
    }
}
