// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-datagrid-borders > .dx-datagrid-headers{
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
}

.dx-datagrid-rowsview .dx-texteditor-input {
    padding-right: 1rem;
    padding-left: 1rem;
}
.item {
    display: flex;
    margin: 3px;
    padding: 2px;
    color: #337ab7;
    border-color: #337ab7;
    float: right;
}

    .item:hover {
        border-radius: 5px;
        color: #ffffff;
        border-color: #ffffff;
        background: #337ab7;
        cursor: pointer;
    }
    .tooltip-general
    {
        padding: 0 10px;
    }`, "",{"version":3,"sources":["webpack://./app/front-office/desktop-mobile/mobile/front-office-grid-mobile/front-office-grid-mobile.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,cAAc;IACd,qBAAqB;IACrB,YAAY;AAChB;;IAEI;QACI,kBAAkB;QAClB,cAAc;QACd,qBAAqB;QACrB,mBAAmB;QACnB,eAAe;IACnB;IACA;;QAEI,eAAe;IACnB","sourcesContent":[".dx-datagrid-borders > .dx-datagrid-headers{\r\n    border-top: none;\r\n    border-right: none;\r\n    border-bottom: none;\r\n    border-left: none;\r\n}\r\n\r\n.dx-datagrid-rowsview .dx-texteditor-input {\r\n    padding-right: 1rem;\r\n    padding-left: 1rem;\r\n}\r\n.item {\r\n    display: flex;\r\n    margin: 3px;\r\n    padding: 2px;\r\n    color: #337ab7;\r\n    border-color: #337ab7;\r\n    float: right;\r\n}\r\n\r\n    .item:hover {\r\n        border-radius: 5px;\r\n        color: #ffffff;\r\n        border-color: #ffffff;\r\n        background: #337ab7;\r\n        cursor: pointer;\r\n    }\r\n    .tooltip-general\r\n    {\r\n        padding: 0 10px;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
