import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import 'devextreme/integration/jquery';
import { Subscription, lastValueFrom } from 'rxjs';
import { BoService } from '../back-office.service';
import { LocalStorageService } from 'app/shared/local-storage.service';
import { GroupAction, IActionAPI, IActionButton, IBreadCrumbEntity, IEntity, MenuVdsashItem } from 'app/shared/entity';
import { ActionLegacyService } from 'app/shared/actions-legacy.service';
import { EntityService } from 'app/shared/entity.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { FrontOfficeStructService } from 'app/front-office/front-office-struct.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'app/back-office/navigation.service';
import { SessionStorageService } from 'app/shared/session-storage.service';
import * as numeral from 'numeral';
import { AuthService } from 'app/shared/auth.service';
import { ActionLegacyRefreshService } from 'app/shared/actions-legacy-refresh.service';
import { DownloadService } from 'app/shared/download.service';
import { DocumentService } from 'app/back-office/document.service';
import { DateUtils } from "../../utils/date-utils";
import { ListUtils } from 'app/utils/list-utils';
import { StringUtils } from 'app/utils/string-utils';

@Component({
    templateUrl: './back-office-detail.component.html',
    styleUrls: ['./back-office-detail.component.css']
})
export class BoDetailComponent implements OnInit, OnDestroy {
  errorMessage = '';
  sub!: Subscription;
  sub2!: Subscription;
  sub3!: Subscription;
  entityAnchor: IEntity;
  updateAttribut: IEntity;
  currentEntity :any;
  entityAnchors: IEntity[] = [];
  specificType: string;
  fullLoadedSpace: boolean = true;
  currentProcedureAssociation: string;
  entitesActions: IActionButton[] = [];
  menuItems: MenuVdsashItem[] = [];
  groups: GroupAction[] = [];
  editiontabs: any[] = [];
  newLinetabs: any[] = [];
  documents: any[] = [];
  dictSub: any = [];
  componentName: string = '';
  componentType: string = '';
  space: string = '';
  entityType: string = '';
  status: string = '';
  currentBlocType: string = '';
  variante: string = '';
  idEntity: number = 0;
  states: any [] = [];
  heightGrid: string = '';
  version: string = '';
  breadCrumbEntitiesReference: IBreadCrumbEntity[] = [];
  observer!: IntersectionObserver;
  gridEntities :any;
  currentState: any;
  currentCampagne: any;
  codeListName : string = '';
  userEmail : string = '';
  componentGeneralList: any [] = [];
  storage : any;
  cycleReference : any = null;
  currentActionAPI: any = null;
  refreshAction: any = null;
  refreshApp : boolean = false;
  private cutedSelectionKey = "currentCutedSelection";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private authService: AuthService,    
    private componentService: BoService,
    private localStorageService: LocalStorageService,
    private applicationStructService: FrontOfficeStructService,
    private actionLegacyService: ActionLegacyService,
    private actionLegacyRefreshService: ActionLegacyRefreshService,
    private entityService: EntityService,
    private downloadService: DownloadService,
    public sanitizer: DomSanitizer,
    private navigationService: NavigationService,
    private documentService : DocumentService,
    private sessionStorageService: SessionStorageService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.actionLegacyService.cleanEmitter();
      this.idEntity = Number(params.idParent);
      this.componentName = String(params.componentName);
      this.componentType = String(params.componentType);
      let oldSpace = this.space;
      this.space = String(params.space);
      this.entityType = String(params.entityType);
      this.userEmail = this.authService.getEmail();
      if (!this.space) {
        this.router.navigate(['']);
      }
      this.currentCampagne = null;
      this.ngOnDestroy();  
      var ctrl = this;

      this.sessionStorageService.refreshReferenceCycle.subscribe(async (params) => { await ctrl.sessionStorageServiceRefreshReferenceCycleSubscribe(); });
      this.actionLegacyRefreshService.refreshActionFromToast.subscribe(async (data) => { ctrl.actionLegacyRefreshServiceRefreshActionFromToastSubscribe(data); });
      
      if(this.entityType != 'LR'){
        this.sessionStorageService.remove('CurrentBlocType')
        this.currentBlocType = null;
      }
      else {
        this.currentBlocType = await lastValueFrom(this.componentService.getCurrentBlocType(this.idEntity));
        this.sessionStorageService.set('CurrentBlocType', this.currentBlocType);
      }

      this.currentProcedureAssociation  = await lastValueFrom(this.entityService.getProcedureAssociationfromEntity(this.space, this.idEntity));
          
      let oldIdEntity = this.entityAnchor;
      this.entityService.messageToParent.subscribe(async (message) => { ctrl.entityServiceMessageToParentSubscribe(message, oldIdEntity, oldSpace); });

      if(this.idEntity && this.idEntity != 0) {
        ctrl.currentActionAPI = null;
        this.sub = this.actionLegacyService.refreshDataGridChangeAlert.subscribe(async (actionAPI: IActionAPI) => { await ctrl.actionLegacyServiceRefreshDataGridChangeAlertSubscribe(actionAPI); })
        
        var isFirstRender = true;
        var storage = this.localStorageService.getWithExpiration(this.space);
        storage = JSON.parse(storage);
        this.storage = storage;
        if (this.storage != null && this.storage.Lists == null) {
          this.storage.Lists = {};
        }
        this.version = storage.Version;
        var storageGlobal = this.localStorageService.getWithExpiration('UserPreferences');
        storageGlobal = JSON.parse(storageGlobal);
        ctrl.fullLoadedSpace = false;
        ctrl.initializeEntityAnchors(storageGlobal);

        if(ctrl.space != oldSpace || oldIdEntity == null || this.entityAnchor.IdObject != oldIdEntity.IdObject)
          ctrl.refreshAnchorComponent();
          ctrl.fullLoadedSpace = true;
        this.sub = this.actionLegacyService.addNewLineDataGridChangeAlert.subscribe(() => {ctrl.addNewLine();})
        this.sub3 = this.actionLegacyService.executeActionChangeAlert.subscribe(async (actionAPI: IActionAPI) => { if (actionAPI) await ctrl.executeAction(actionAPI);})
        this.states = await lastValueFrom(this.applicationStructService.getListBoFormState());
        this.currentEntity = (await lastValueFrom(this.entityService.getCurrentEntity(this.space,this.idEntity.toString())));
        this.status = this.currentEntity.Status;
        ctrl.currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == (this.status == 'D' ? 'draft' : 'lock'))[0];
        let configGrid = await lastValueFrom(this.componentService.getGridConfiguration(this.space, this.entityType, this.idEntity,  this.entityType+this.componentName, this.componentType));
        
        if(configGrid.editing)
          configGrid.editing.useIcons = true;
      
        configGrid.loadPanel = {
          enabled : true,
          showIndicator : true,
          shading: true,
        }

        configGrid.onEditCanceled = (event : any)=> { ctrl.configGridOnEditCellEnded(); },
        configGrid.onSaving = async (event : any)=> { ctrl.configGridOnSaving(event); },
        configGrid.onSaved = async (event : any)=> { await ctrl.configGridOnSaved(event); }

        let gridColumns:any = [];
        let masterColumns: any[] = [];
        ctrl.editiontabs = [];
        ctrl.newLinetabs = [];
      
        await ctrl.configureGridColumns(configGrid, gridColumns, masterColumns, storage)

        ctrl.newLinetabs = ctrl.newLinetabs.filter((tb: { items: any; }) => tb.items.length > 0);
        if(ctrl.componentType != 'CS')
          configGrid.columns = gridColumns;
        var gridEvent = ctrl.preparerGridEvents(ctrl, isFirstRender, configGrid.editing.allowAdding, configGrid.editing.allowAdding && !configGrid.editing.allowDeleting);

        configGrid.editing.allowAdding = false;
      
        let specificColumns: any[] = [];
      
        for(var i = 0; i<configGrid.columns.length;i++) {
          var column = configGrid.columns[i];
          if(column.caption.indexOf('$$') != -1)
            specificColumns.push(column);

          if(ctrl.componentType != 'CS' && column.subPerimeter != '' && ctrl.space !='MDEL') {
            if(column.visible) {
              var action = 'R';
              
              if(ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] != null) {
                column.visible =ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action];
              }
              else {
                column.visible = (await ctrl.componentService.getComponentSubPerimeter(ctrl.space, ctrl.entityType, ctrl.idEntity, column.subPerimeter, action));
                ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] = column.visible;
              }
            }

            if(column.allowEditing)
            {
              var action = 'U';
              
              if(ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] != null) {
                column.allowEditing =ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action];
              }
              else {
                column.allowEditing = (await ctrl.componentService.getComponentSubPerimeter(ctrl.space, ctrl.entityType, ctrl.idEntity, column.subPerimeter, action));
                ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] = column.allowEditing;
              }
            }
          }
        }
      
        this.variante = await lastValueFrom(this.entityService.getVarianteFromEntity(ctrl.space, ctrl.version, this.idEntity.toString(), 'Space'));
        if(specificColumns.length == 0)
        {            
          await ctrl.configureAndDisplayGrid(masterColumns, configGrid, storage, gridEvent, true);
        }
        else
        {
          let columns = [];
          for(var i = 0; i<specificColumns.length;i++) {
            var column = specificColumns[i];
            columns.push(column.caption);
          }

          let changesColumns = await lastValueFrom(this.componentService.getColumnsAttributs(this.space, columns, this.idEntity));

          for(var i = 0; i<specificColumns.length;i++) {
            var column = specificColumns[i];
            column.caption = changesColumns[column.caption]
          }

          await ctrl.configureAndDisplayGrid(masterColumns, configGrid, storage, gridEvent, false);

          let grid = $('#gridComponents').dxDataGrid('instance');
          ctrl.gridEntities = grid;
        }
      }
      else {
        this.router.navigate(['']);
      }
    });
  }

  initializeEntityAnchors(storageGlobal:any) {
    if (storageGlobal && storageGlobal.EntityAnchors && storageGlobal.EntityAnchors != null && storageGlobal.EntityAnchors[this.space] != null && storageGlobal.EntityAnchors[this.space].length > 0) {
      this.entityAnchors = storageGlobal.EntityAnchors[this.space];
      let temp = this.entityAnchors.filter((th: { IdObject: number; })  => th.IdObject < 0 && th.IdObject != -999);
      if(temp.length > 0) {
        let tempEnt = this.entityAnchors.filter((th: { IdObject: number; })  => th.IdObject > 0 || th.IdObject == -999);
        this.entityAnchor = tempEnt[tempEnt.length -1];
        this.specificType = temp[0].EntityType;
      }
      else {
        this.entityAnchor = this.entityAnchors[this.entityAnchors.length -1];
        this.specificType = '';
      }
    }
    else {
      this.entityAnchors = []
      this.entityAnchor = {
        IdObject : 1,
        EntityType :'OP',
        CalculationTime : null,
        CreationTime: null,
        HasNextLevel : true,
        IsMain : false,
        IsPublished :false,
        IsSpecial : false,
        LongName:'da',
        ModificationTime : null,
        Name : ',,,',
        Owner :'dd',
        Status : 'D',
        ValidationTime:null,
        Parent:4
      }
      this.entityAnchors.push(this.entityAnchor);
    }
  }

  createCompareSeuilNumberBox() {
    var ctrl = this;
    $('#compareSeuil').dxNumberBox({
      value: this.cycleReference.ComparaisonThreshold,
      showSpinButtons: false,
      showClearButton: false,
      onValueChanged : function (e) {
        ctrl.cycleReference.ComparaisonThreshold = e.value
        ctrl.sessionStorageService.set('currentCycleRef', ctrl.cycleReference)
      },
      onKeyDown: function(e:any) {
        var preventedKeys = [38, 40];
        var keyCode = e.event.keyCode;
        var shouldPreventEvent = !e.component.option("opened") && preventedKeys.indexOf(keyCode) !== -1 && !e.event.altKey;
        if(shouldPreventEvent) {
          e.event.preventDefault();
          e.event.stopImmediatePropagation();
        }
      }
    });
  }

  actionLegacyRefreshServiceRefreshActionFromToastSubscribe(data:any) {
    var ctrl = this;
    if(data.Action == 'TransfoAvailable') {
      setTimeout(() => {
        ctrl.refreshApp = true;
        setTimeout(() => {
          ctrl.refreshApp = false;     
        }, 1000);
      }, 3000);
    }
    else if (data.Action == 'Lock' || data.Action == 'ReopenLastCycle'){
      ctrl.status = data.Action == 'Lock' ? 'V' : 'D';
      ctrl.refreshData();
    }
  }

  async actionLegacyServiceRefreshDataGridChangeAlertSubscribe(actionAPI:any) {
    var ctrl = this;
    if(actionAPI == ctrl.currentActionAPI && actionAPI != null)
      return;
    if(ctrl.currentActionAPI != null) {
      if(actionAPI && (actionAPI.Uri == ctrl.currentActionAPI.Uri && actionAPI.Parameters.ParentID
        == ctrl.currentActionAPI.Parameters.ParentID && actionAPI.Parameters.ExtraParameters
        == ctrl.currentActionAPI.Parameters.ExtraParameters && actionAPI.Parameters.EntititiesIds
        == ctrl.currentActionAPI.Parameters.EntititiesIds))
        return;
    }
    ctrl.currentActionAPI = actionAPI;
    if (actionAPI) {
      if(actionAPI.Uri.indexOf('generate_excels') == -1 && actionAPI.Uri.indexOf('delayed_documents') == -1 && actionAPI.Uri.indexOf('reports') == -1)
        await ctrl.refreshDataWithCallWebApi(actionAPI);
      else
        await this.entityService.executeAction(actionAPI).subscribe({});
    }
    else {
      await ctrl.refreshData();
      ctrl.currentActionAPI = null; 
      if (ctrl.sessionStorageService.hasKey(ctrl.cutedSelectionKey)) {
        ctrl.refreshAction = {Uri :'cut'};
      }
    }
  }

  async sessionStorageServiceRefreshReferenceCycleSubscribe() {
    var ctrl = this;
    this.cycleReference = this.sessionStorageService.get('currentCycleRef')
    if(this.cycleReference != null) {
      setTimeout(() => {
        ctrl.createCompareSeuilNumberBox();
      }, 500);
      this.breadCrumbEntitiesReference = await lastValueFrom(this.navigationService.getBreadCrumbEntity(this.space, this.cycleReference.IdObject, 'CY'));
    }
  }

  entityServiceMessageToParentSubscribe(message:any, oldIdEntity:any, oldSpace: any) {
    var ctrl = this;
    ctrl.fullLoadedSpace = false;
    if(message.Entity) {
      oldIdEntity = ctrl.entityAnchor;
      ctrl.entityAnchor = message.Entity;
      ctrl.specificType = message.Type != undefined ? message.Type.EntityType : '';
    }
    else {
      ctrl.entityAnchor = message;
      ctrl.specificType = '';
    }

    if(ctrl.space != oldSpace || oldIdEntity == null || ctrl.entityAnchor.IdObject != oldIdEntity.IdObject)
      ctrl.refreshAnchorComponent();
      setTimeout(() => {
      ctrl.fullLoadedSpace = true;
    }, 50);
  }

  async configureAndDisplayGrid(masterColumns:any, configGrid:any, storage:any, gridEvent:any, controlBool:any) {
   var ctrl = this;
    if(masterColumns.length > 0)
      await ctrl.configMaster(configGrid, masterColumns, storage, ctrl);
  
    if(ctrl.componentType == 'CS')
      configGrid.masterDetail = null;
    
    let componentList = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, this.idEntity, this.variante, this.componentName));
    if(ctrl.componentType == 'CS') {
      componentList = await ctrl.applyComponentFiltersAndProperties(componentList, controlBool);
    }
    else
    {              
      if(this.entityType == 'LR') {
        configGrid = await ctrl.applyLrGridFilters(configGrid);
      }
    }
    configGrid.dataSource = componentList
    $('#gridComponents').dxDataGrid(<string>configGrid).dxDataGrid(gridEvent).dxDataGrid('instance');
  }

  configGridOnEditCellEnded() {
    let grid = $('#gridComponents').dxDataGrid('instance');
    grid.option("editing.mode", "cell")
  }

  configGridOnSaving(event:any) {
    if(event.changes.length == 0 || !this.validateApp(event.changes[0].data, event.component))
      event.cancel = true;
  }

  async configGridOnSaved(event:any) {
    var ctrl = this;
    if(event.changes.length == 0)
    return;
    if(event.changes[0].type == 'update') {
      if(typeof(event.changes[0].data.List) !== "undefined" || event.changes[0].data.List == null) {
        event.changes[0].data.IsVisible="True";
        event.changes[0].data.IsEditable="True";
        event.changes[0].data.List =''
      }  
      
      await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, event.changes[0].data));
      if(ctrl.componentName == 'communication' || (ctrl.space == 'MDEL' && (ctrl.componentName == 'data' || ctrl.componentName == 'filter' || ctrl.componentName == 'properties'|| ctrl.componentName == 'layout')))
        ctrl.refreshData();
    }
    else  if(event.changes[0].type == 'remove') {
      ctrl.componentService.deleteAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, event.changes[0].key).subscribe({
        next: configGrid => { ctrl.refreshData();},
        error: err => console.log(err)
      });
    }
    else if(event.changes[0].type == 'insert') {
      event.changes[0].data.Id = '0';
      ctrl.componentService.insertAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, event.changes[0].data).subscribe({
        next: configGrid => {
          let grid = $('#gridComponents').dxDataGrid('instance');
          grid.option("editing.mode", "cell")
          if(ctrl.space == 'MDEL' && (ctrl.componentName == 'data' || ctrl.componentName == 'filter' || ctrl.componentName == 'properties'|| ctrl.componentName == 'layout'))
            ctrl.refreshData();
          else
          {
            var store = grid.getDataSource();
            var items = store.items();
            items[items.length-1].DisplayEnumCode= configGrid.replace(ctrl.idEntity+'_Numerotation_','')
            items[items.length-1].Id = configGrid
            var index = [];
            index.push(grid.totalCount()-1);
            grid.repaintRows(index);
          }
        },
        error: err => console.log(err)
      });
    } 
  }

  async configureGridColumns(configGrid:any, gridColumns:any, masterColumns:any, storage:any) {
    var ctrl = this;
    for (var i = 0; i < configGrid.columns.length; i++) {
      var column = configGrid.columns[i];
      if(column.dataField =='Code') {
        column.visible = false;
        ctrl.codeListName = column.format;
      }
      
      if(column.isIncludedInDataGrid || column.type =='command')
        gridColumns.push(column);

      if(column.isIncludedInMaster) {
        masterColumns.push(column);
        if(!(column.isIncludedInDataGrid || column.type =='command')) {
          column.visible = false;
          gridColumns.push(column);
        }
        var action = 'R';
        
        if(ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] != null) {
          column.visibleMaster =ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action];
        }
        else {
          column.visibleMaster = (await ctrl.componentService.getComponentSubPerimeter(ctrl.space, ctrl.entityType, ctrl.idEntity, column.subPerimeter, action));
          ctrl.dictSub[column.subPerimeter+ctrl.status+ctrl.entityType+action] = column.visibleMaster;
        }
      }
      
      if(column.isIncludedInMaster || column.isIncludedInDataGrid) {
        await ctrl.updateTabsWithItem(column, storage);
      }     

      if (column.dataType == 'number') 
        column.editorOptions = { step: 0 }

      if(column.dataType == 'bool')
        column.isRequired = false;   

      if(column.isRequired )
        column.validationRules= [{ type: "required" }]

      if(column.dataField =='Id')
        column.formItem= { visible: false } 
      
      if(column.dataType == 'DynamicList' && typeof(column.format) !== "undefined" && column.format !== null) {
        let itemsT: any = await this.getDynamicListItems(column);
        column.lookup = {
          displayExpr: 'Label',
          valueExpr: 'IdEnumerationItem',
          deferRendering : true,
          dataSource: itemsT,
        }
      }

      if(ctrl.componentName == 'communication' && column.dataField == "OpIdentifiant") {
        let itemsT: any = this.getItemsCampagne();
        column.lookup = {
          displayExpr: 'Label',
          valueExpr: 'IdEnumerationItem',
          deferRendering : true,
          dataSource: itemsT,
        }
      }

      if((ctrl.componentType == 'CS' && column.dataField == "FormattedValue"))
        ctrl.editCellTemplate(column, ctrl, storage);
    }
  }

  async updateTabsWithItem(column:any, storage:any) {
    var ctrl = this;

    let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, true)
    var currentTab:any = null;
    var currentTabNew:any = null;
    for(var j=0; j<  ctrl.editiontabs.length; j++)
      if( ctrl.editiontabs[j].title == column.tabName)
        currentTab =  ctrl.editiontabs[j];

    for(var j=0; j<  ctrl.newLinetabs.length; j++)
      if( ctrl.newLinetabs[j].title == column.tabName)
        currentTabNew =  ctrl.newLinetabs[j];

    if(currentTab == null) {
      currentTab = {};
      currentTab.items = [];
      currentTab.items.push(item);
      currentTab.colCount= 2;
      currentTab.title = column.tabName;
      ctrl.editiontabs.push(currentTab);
    }
    else {
      if(item.colSpan && item.colSpan== 2) {
        let previousItem = currentTab.items[currentTab.items.length-1];
        previousItem.colSpan = 2;
      }
      currentTab.items.push(item);
    }

    if(currentTabNew == null) {
      currentTabNew = {};
      currentTabNew.items = [];
      if(!item.editorOptions.disabled)
        currentTabNew.items.push(item);
      currentTabNew.colCount= 2;
      currentTabNew.title = column.tabName;
      ctrl.newLinetabs.push(currentTabNew);
    }
    else {
      if(item.colSpan== 2 && currentTabNew.items.length > 0) {
        let previousItem = currentTabNew.items[currentTabNew.items.length-1];
        previousItem.colSpan = 2;
      }
      if(!item.editorOptions.disabled)
        currentTabNew.items.push(item);
    }
  }

  async applyComponentFiltersAndProperties(componentList:any, controlBool: any) {
    var ctrl = this;

    if(this.entityType == 'LR') {
      let filterList = await lastValueFrom(this.componentService.getFilterLrAttribut(this.space,this.entityType, this.componentName, this.currentBlocType));
      if(filterList.length > 0) {
        componentList = componentList.filter((tb: { Name: string; }) => { return filterList.some((f) => {return f.AttributName === tb.Name;})});
        for(var i = 0; i<componentList.length;i++) {
          var associatedFilter =  filterList.filter((tb: { AttributName: string; }) => tb.AttributName == componentList[i].Name)[0];
          if(associatedFilter.IsRequired)
            componentList[i].Label += '*'
          if(associatedFilter.GrilleEntity)
            componentList[i].Label = '[Grille entité] '+componentList[i].Label
          componentList[i].Order = associatedFilter.Order;
        }
        componentList = componentList.sort((a:any, b:any) => a['Order'] > b['Order'] ? 1 : a['Order'] === b['Order'] ? 0 : -1);
      }
    }     

    await ctrl.setIsEditableIsVisibleComponentList(componentList, false, controlBool);
    componentList = componentList.filter((tb: { IsVisible: boolean; }) => tb.IsVisible);
    return componentList;
  }

  async applyLrGridFilters(configGrid: any) {
    let filterList = await lastValueFrom(this.componentService.getFilterLrAttribut(this.space,this.entityType, this.componentName, this.currentBlocType));
    if(filterList.length > 0) {
      for(var i = 0; i<configGrid.columns.length;i++) {
        var associatedFilter =  filterList.filter((tb: { AttributName: string; }) => tb.AttributName == configGrid.columns[i].dataField.replace('.IdEnumerationItem',''))[0];
        if(associatedFilter == null)
          configGrid.columns[i].visible = false;
        else  if(associatedFilter.GrilleEntity)
          configGrid.columns[i].caption = '[Grille entité] '+configGrid.columns[i].caption
      }
    }
    return configGrid;
  }

  async refreshAnchorComponent() {
    var ctrl = this;
    if(this.entityAnchor.IdObject <2) {
      this.componentGeneralList = [];
      return;
    }
    var variante = await lastValueFrom(this.entityService.getVarianteFromEntity(ctrl.space, ctrl.version, ctrl.entityAnchor.IdObject.toString(), 'Space'));
    let componentList = await lastValueFrom(this.componentService.getDetailComponent(this.space,ctrl.entityAnchor.EntityType, ctrl.entityAnchor.IdObject, variante, 'general'));
    if(this.entityType == 'LR') {
      let filterList = await lastValueFrom(this.componentService.getFilterLrAttribut(this.space,this.entityType, this.componentName, this.currentBlocType));
      if(filterList.length > 0) {
        componentList = componentList.filter((tb: { Name: string; }) => {
          return filterList.some((f) => { return f.AttributName === tb.Name;})
        });
        for(var i = 0; i<componentList.length;i++) {
          var associatedFilter =  filterList.filter((tb: { AttributName: string; }) => tb.AttributName == componentList[i].Name)[0];
          if(associatedFilter.IsRequired)
            componentList[i].Label += '*'
          if(associatedFilter.GrilleEntity)
            componentList[i].Label = '[Grille entité] '+componentList[i].Label
          componentList[i].Order = associatedFilter.Order;
        }
        componentList = componentList.sort((a, b) => a['Order'] > b['Order'] ? 1 : a['Order'] === b['Order'] ? 0 : -1);
      }
    }

    await ctrl.setIsEditableIsVisibleComponentList(componentList, true, false);

    componentList = componentList.filter((tb: { IsVisible: boolean; }) => tb.IsVisible);
    this.componentGeneralList = componentList;
  }

  async configMaster(configGrid : any, masterColumns: any[], storage: any, ctrl:any) {
    var tabs: any[]= [];
    for (var i = 0; i < masterColumns.length; i++) {
      var column = masterColumns[i];
      if(column.dataField == 'FkObject' || column.dataField == 'Id' || column.dataField == 'Code' || !column.visibleMaster)
        continue;
      let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, false)
      var currentTab:any = null;
      for(var j=0; j<  tabs.length; j++) {
        if( tabs[j].Name == StringUtils.decodeSpecialEntitiesHtml(column.tabName))
          currentTab =  tabs[j];
      }
      if(currentTab == null) {
        currentTab = {};
        currentTab.items = [];
        currentTab.items.push(item);
        currentTab.Name = StringUtils.decodeSpecialEntitiesHtml(column.tabName);
        tabs.push(currentTab);
      }
      else {
        if(item.colSpan== 2) {
          let previousItem = currentTab.items[currentTab.items.length-1];
          previousItem.colSpan = 2;
        }
        currentTab.items.push(item);
      }
    }

    if(this.space !="MDEL")
    {
      configGrid.masterDetail = ctrl.getMasterDetailConfigGrid(tabs);
    }
  }

  getMasterDetailConfigGrid(tabs: any) {
    var ctrl = this;
    return {
      enabled: true,
      template: async function(element : any, masterDetailOptions :any) {
        let grid = $('#gridComponents').dxDataGrid('instance');
        grid.option("editing.mode", "cell")
        let detailEntity = await lastValueFrom(ctrl.componentService.getMasterComponent(ctrl.space, masterDetailOptions.data.Id, ctrl.componentName, ctrl.entityType));
        ctrl.currentCampagne = detailEntity;
        ctrl.reloadDocuments();
        var items = [];
        for(var i=0; i<  tabs.length; i++)
        {
          if( tabs[i].Name == '')
          tabs[i].Name ='Infos'
          items.push({
            title:  tabs[i].Name,
            data: { item : detailEntity, items :tabs[i].items, index:i},
          })
        }

        if(ctrl.componentName == 'campagne' && ctrl.entityType == 'OP') {
          ctrl.setFileUploader();
          items.push({
            title: 'Documents',
            data:ctrl.documents,
            template:async function(itemData:any, itemIndex:any, itemElement:any) {
              ctrl.getCampagneDocumentTemplate(itemData, itemElement);
            }
          })
        }

        ctrl.setCampagneFormMaster(items, element, detailEntity, tabs);

        grid.updateDimensions();
      }
    };
  }

  getCampagneDocumentTemplate(itemData:any, itemElement:any) {
    var ctrl = this;
    var formName = 'formMasterDoc';
    var configGrid = ctrl.getCampagneConfigGrid();
    
    configGrid.dataSource = itemData.data;
    var gridEvent =
    {
      onCellPrepared: function (e: any) {
        if ( e.column.buttons && e.rowType =="header" && true)
        {
          ctrl.setCampagneButtonAddRow(e);

          const fileUploader = $('#file-uploader').dxFileUploader('instance');
          fileUploader.option({
            dialogTrigger: e.cellElement
          });
          fileUploader.repaint();
        }

        if (e.column.buttons && e.rowType =="data" )
        {
          ctrl.setCampagneButtonsColumn(e, ctrl);
        }
      },
    };

    configGrid.onSaved = async (event : any)=> {
      if(event.changes.length == 0)
        return;
      if(event.changes[0].type == 'remove')
        ctrl.deleteDocument(event.changes[0].key)
    }
    
    var info = (<any>$('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxDataGrid(configGrid)).dxDataGrid(gridEvent);
    ctrl.reloadDocuments();
    itemElement.append(info);
  }

  getCampagneConfigGrid() {
    var configGrid =<any>{
      "paging": { "pageSize": 20 },
      "remoteOperations": true,
      "columnAutoWidth": true,
      "noDataText": "Aucune données présente pour ce composant",
      "keyExpr": "ID",
      "showBorders": true,
      "columnMinWidth": 20,
      "allowColumnResizing": true,
      "allowColumnReordering": true,
      "sorting": { "mode": "multiple" },
      "rowAlternationEnabled": true,
      "filterRow": { "visible": true },
      "filterPanel": { "visible": false },
      "headerFilter": { "visible": false },
      "scrolling": { "mode": "virtual" },
      "columnChooser": { "enabled": false },
      "columnFixing": { "enabled": true },
      "columns": 
      [
        {
          "dataField": "ID",
          "caption": "ID",
          "dataType": "number",
          "cellTemplate": null,
          "format": "",
          "width": "70%",
          "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
          "visible": false,
          "visibleIndex": 2,
          "allowSorting": true,
          "isRequired": true,
          "defaultValue": " .",
          "tooltip": null,
          "allowEditing": false,
          "sortOrder": null,
          "type": null,
          "isIncludedInMaster": true,
          "isIncludedInDataGrid": true,
          "renderType": "default",
          "tabName": "",
          "style": null,
          "cssClass": null,
          "link": null,
          "calculSummary": null,
          "validationRules": [{ "type": "required" }],
          "formItem": { "visible": false },
          "name": "ID"
        },
        {
          "dataField": "Filename",
          "caption": "Nom du fichier",
          "dataType": "string",
          "cellTemplate": null,
          "format": null,
          "width": null,
          "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
          "visible": true,
          "visibleIndex": 2,
          "allowSorting": true,
          "isRequired": true,
          "defaultValue": "",
          "tooltip": null,
          "allowEditing": false,
          "sortOrder": null,
          "type": null,
          "isIncludedInMaster": false,
          "isIncludedInDataGrid": true,
          "renderType": "default",
          "tabName": "",
          "style": null,
          "cssClass": null,
          "link": null,
          "calculSummary": null,
          "name": "Filename"
        },
        {
          type: 'buttons',
          width: 110,
          buttons: []
        }
      ],
      "pager": null,
      "editing": {
        "allowUpdating": false,
        "allowDeleting": true,
        "canDelete": true,
        "allowAdding": false,
        "useIcons": true,
        "mode": "cell"
      },
      "subPerimeter": "BASE:R|/D#BASE:R|/V#ECO:R/D#ECO:R|/V#ADM:R/D#ADM:R/V#",
      "loadPanel": {
        "enabled": true,
        "showIndicator": true,
        "shading": true
      },
      "masterDetail": { "enabled": false },
    }

    return configGrid;
  }

  setCampagneButtonAddRow(e:any) {
    e.cellElement.dxButton({
      hint: 'Ajouter une ligne',
      icon: 'add',
      elementAttr: {
        id: "buttonAddDocCom",
        class: "add-document-button"
      }
    }).dxButton('instance')                    
    e.cellElement.addClass("add-grid-button")
  }

  setCampagneButtonsColumn(e:any, ctrl:any) {
    var button = e.column.buttons.filter((tb: { hint: string; }) => tb.hint == 'Télécharger')[0]
    if(button != null)
    {
      const index = e.column.buttons.indexOf(button);
      e.column.buttons.splice(index,1)
    }
    e.column.buttons.push
    ({
      hint: 'Télécharger',              
      template: function (e:any, elem:any) { ctrl.getDownloadDeleteButtonTemplate(e, true); },
      onClick(e:any) {
        ctrl.downloadDocument(e.row.data)
        e.event.preventDefault();
      },
    });

    var button = e.column.buttons.filter((tb: { hint: string; }) => tb.hint == 'Supprimer')[0]
    if(button != null)
    {
      const index = e.column.buttons.indexOf(button);
      e.column.buttons.splice(index,1)
    }
    e.column.buttons.push({
      hint: 'Supprimer',              
      template: function (e:any, elem:any) { ctrl.getDownloadDeleteButtonTemplate(e, false); },
      onClick(e:any) {
        ctrl.confirmationModalComponent.showConfirmationWindow('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer cette ligne ?', 'BO', e.row.data.ID)
        e.event.preventDefault();
      },
    });
  }

  setCampagneFormMaster(items:any, element:any, detailEntity:any, tabs:any) {
    var ctrl=this;
    if(items.length > 1)
    {
      element.append($('<div>').dxTabPanel((
        {
          items: items,
          itemTemplate: function(itemData, itemIndex, itemElement) {
            var formName = 'formMaster'+itemData.data.index;
            var info = $('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxForm({
              formData: itemData.data.item,showColonAfterLabel: true,
              labelMode: 'floating',
              onFieldDataChanged : async function(e) { ctrl.onFieldDataChanged('#'+formName, e); },
              colCount: 2,
              items: itemData.data.items
            });
            itemElement.append(info);
          }
        }
      )));
    }
    else {
      element.append($('<div id="formMaster">').addClass('component-master-form').dxForm({
        formData: detailEntity,
        showColonAfterLabel: true,
        labelMode: 'floating',
        showValidationSummary: true,
        validationGroup: 'validateForm',
        onFieldDataChanged : async function(e) { ctrl.onFieldDataChanged('#formMaster', e); },
        colCount: 2,
        items:  tabs[0].items
      }));
    }
  }

  getDownloadDeleteButtonTemplate(e:any, isDownload: any) {
    var html = '<i class="fa-solid ' + (isDownload ? 'fa-download' : 'fa-trash') + '"></i>'
    let file = $(html);
    $(e).append(file);
    return;
  }

  async onFieldDataChanged(completeFormName: any, e:any) {
    var ctrl = this;
    var form = $(completeFormName).dxForm('instance');
    var validation = form.validate();
    if(validation.isValid) {
      var componentRow = form.option('formData');
      var attribut = {
        Name:e.dataField.replace('.IdEnumerationItem',''),
        Value :e.dataField.endsWith('IdEnumerationItem') ? componentRow[e.dataField.split('.')[0]].IdEnumerationItem.toString() : componentRow[e.dataField].toString(),
      }

      await lastValueFrom(ctrl.componentService.updateAttributProperty(ctrl.space, ctrl.entityType, componentRow.Id, ctrl.componentType, ctrl.entityType+ctrl.componentName, attribut));
      if(ctrl.componentName == 'communication' || (ctrl.space == 'MDEL' && (ctrl.componentName == 'data' || ctrl.componentName == 'filter' || ctrl.componentName == 'properties'|| ctrl.componentName == 'layout')))
        ctrl.refreshData();
    } 
  }

  onSubmit() :void {
    const fileUploader = $('#file-uploader').dxFileUploader('instance');
    this.saveDocument(fileUploader.option('value'))
  }

  downloadDocument(documentA: any): void {
    var url = './api/v1/spaces/'+this.space+'/documents/'+documentA.ID;

    this.downloadService.download(url).subscribe(response => {
      var filename = documentA.Filename;
      if (response.body) {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(response.body);
        a.download = filename;
        a.click();
      }
    },()=>{});
  }

  deleteDocument(lastDocument:string): void {
    var ctrl = this;
    if(lastDocument) {
      this.documentService.deleteDocumentCampagne(ctrl.space, lastDocument);
      setTimeout(() => {
        this.reloadDocuments();   
      }, 1000);
    }
    else {
      this.documents.pop();
    }
  }

  saveDocument(uploadDocuments:any[]): void {
    var ctrl = this;
    var index = this.documents.length;
    for(var i = 0;i<uploadDocuments.length;i++) {
      index++;
      var fileA = {
        ID :'', 
        Filename : uploadDocuments[i].name,
          Extension : '.'+uploadDocuments[i].name.split('.').pop(),
          Size : uploadDocuments[i].size,
          File : <any>null,
          File64 : <any>null
      };
      
      if(fileA.Extension.toLowerCase() == '.pdf') {
        var file = uploadDocuments[i];
        let fr = new FileReader();
        fr.onload = function () {
          fileA.File64 = fr.result.toString().split(',')[1];
          ctrl.documentService.addCampagneDocument(ctrl.space,ctrl.currentCampagne.Code.IdEnumerationItem,fileA).subscribe({
            next: comments => {
              if((ctrl.documents.length+1 < 10))
                ctrl.setFileUploader();
              else
                $('#file-uploader').hide();
              ctrl.reloadDocuments();
            }
          });
        };
        fr.readAsDataURL( file );
        index++;
      }
      else {
        ctrl.setFileUploader();
      }
    }
  }

  async reloadDocuments()
  {
    this.documents = await lastValueFrom(this.documentService.getAllCampagneDocuments(this.space, this.currentCampagne.Code.IdEnumerationItem));
    let grid = $('#formMasterDoc').dxDataGrid('instance');
    if(grid == null)
      return;
    grid.option('dataSource', this.documents);
    grid.refresh();
  }

  async getItemFormPerColumn(column:any, storage: any, ctrl:any, withdefault:boolean)
  {
    let item : any = {
      dataField: column.dataField,
      label: { text: column.caption, },
      editorOptions: {
        disabled: column.allowEditing == false,
        displayFormat : '' ,       
      }
    };
    if(column.dataType == 'bool')
      column.isRequired = false;

    if(column.isRequired)
      item.validationRules= [{ type: "required" }]
    
    if(column.dataType == "decimal" || column.dataType == "number")
      ctrl.setItemFormDecimal(item, column);
    else if(column.dataType == "DynamicList" && typeof(column.format) !== "undefined" && column.format !== null)
      await ctrl.setItemFormDynamicList(item, column);
    else if(column.dataType == "DateTime" || column.dataType == "date")
      ctrl.setItemFormDatetime(item);
    else if(column.dataType == "bool")
      ctrl.setItemFormBool(item, column);        
    else if(column.dataType == "string" && column.renderType =="textarea")
      ctrl.setItemFormTextArea(item, column);
    else if(ctrl.componentName == 'communication' && column.dataField == "OpIdentifiant")
      await ctrl.setItemFormOpIdentifiant(item, column);
    else if(column.dataType == "string" && column.renderType =="html")
      ctrl.setItemFormHtmlEditor(item, column);

    if(withdefault)
    {
      if(column.defaultValue && column.defaultValue.length > 0)
      {
        if(column.dataType == 'DynamicList' && column.dataField !='Code')
        {
          var list = JSON.parse(ctrl.storage.Lists[column.format]);
          item.editorOptions.value = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
        }
        else if(column.dataType == 'boolean')
        {
          item.editorOptions.value = column.defaultValue.toLowerCase() == 'true'
          column.defaultValue = item.editorOptions.value
        }
        else if (column.dataType == 'date' || column.dataType == 'datetime')
          item.editorOptions.value = DateUtils.getDateFromStringOrToday(column.defaultValue);
        else
          item.editorOptions.value = column.defaultValue;
      }
      else if(column.dataField == 'Planification')
      {
        var date = new Date(); 
        item.editorOptions.value = moment(date).format('0 0 21 DD MM ? yyyy');
      }
      else if(column.dataType == 'number')
        item.editorOptions.value= 0
      else if(column.dataType == 'date' || column.dataType == 'datetime')
        item.editorOptions.value = new Date();
      else if(column.dataType == 'boolean')
        item.editorOptions.value= false
      else if(column.dataType == 'string')
        item.editorOptions.value= ''
      else if(column.dataType == 'DynamicList' && column.dataField !='Code')
      {
        var list = JSON.parse(ctrl.storage.Lists[column.format]);
        item.editorOptions.value= list[0]
      }
      if(column.dataField =='DisplayEnumCode'  || column.dataField =='Id')
        item.editorOptions.value= ' .'
      column.defaultValue = item.editorOptions.value
    }
    return item
  }

  setItemFormDecimal(item:any, column: any) {
    item.editorType = 'dxNumberBox';
    item.editorOptions = {
      step: 0,
      showSpinButtons: false,
      displayFormat : '' , 
      dataField : column.dataField,
      disabled: column.allowEditing == false,
      format: column.format,
      showClearButton: false,
    }
    item.editorOptions.onKeyDown = function(e:any) {
      var preventedKeys = [38, 40];
      var keyCode = e.event.keyCode;
      var shouldPreventEvent = !e.component.option("opened") && preventedKeys.indexOf(keyCode) !== -1 && !e.event.altKey;
      if(shouldPreventEvent) {
        e.event.preventDefault();
        e.event.stopImmediatePropagation();
      }
    }
    item.formatNumber = item.editorOptions.format
    item.editorOptions.format = function (value:any) {
      return numeral(value).format(item.formatNumber)
    }
  }

  async getDynamicListItems(column: any) {
    var ctrl = this;
    let itemsT: any = [];

    if (ctrl.storage.Lists[column.format.replace('#1', '')] != null) {
      itemsT = JSON.parse(ctrl.storage.Lists[column.format.replace('#1', '')]);
    }
    else
    {
      var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+column.format.replace('#1', '')+'/'+ctrl.idEntity+'.json';
      itemsT = await lastValueFrom(ctrl.httpClient.get(url));
      ctrl.storage.Lists[column.format.replace('#1', '')] = JSON.stringify(itemsT);
    }
    itemsT =  ListUtils.getOnlyActiveItems(itemsT);

    return itemsT;
  }

  async setItemFormDynamicList(item:any, column: any) {
    let itemsT: any = await this.getDynamicListItems(column);
    item.editorType = 'dxSelectBox';
    item.editorOptions = {
      dataSource: itemsT,
      displayFormat : '' , 
      searchEnabled: true,
      disabled: column.allowEditing == false,
      displayExpr: 'Label',
      valueExpr: 'IdEnumerationItem',
      deferRendering : true
    }
    if(column.format.indexOf('#1') != -1)
    {
      item.editorOptions.displayExpr = 'Code';
    }
  }

  setItemFormDatetime(item:any) {
    item.editorType ='dxDateBox'
    item.editorOptions.displayFormat= "dd.MM.yyyy";
    item.formatDate = item.editorOptions.format
    item.editorOptions.format = function (value:any) {
      return moment(value).format(item.formatDate)
    }
  }

  setItemFormBool(item:any, column:any) {
    column.dataType ='boolean';
    item.editorType = "dxCheckBox";  
  }

  setItemFormTextArea(item:any, column:any) {
    item.editorType = "dxTextArea";
    item.colSpan= 2;
    item.editorOptions = {
      height: 90,
      displayFormat : '' , 
      disabled: column.allowEditing == false,
    }
  }

  async getItemsCampagne() {
    var ctrl = this;
    let itemsT: any = [];
    if (ctrl.storage.Lists['opcampagne'] != null) {
      itemsT = JSON.parse(ctrl.storage.Lists['opcampagne']);
    }
    else
    {
      var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/opcampagne/0.json';
      itemsT = await lastValueFrom(ctrl.httpClient.get(url));
      ctrl.storage.Lists['opcampagne'] = JSON.stringify(itemsT);
    }

    itemsT = ListUtils.getOnlyActiveItems(itemsT);
    return itemsT;
  }

  async setItemFormOpIdentifiant(item: any, column:any) {
    let itemsT: any = await this.getItemsCampagne();
    item.editorType = 'dxSelectBox';
    item.editorOptions = {
      dataSource: itemsT,
      displayFormat : '' , 
      searchEnabled: true,
      disabled: column.allowEditing == false,
      displayExpr: 'Label',
      valueExpr: 'IdEnumerationItem',
      deferRendering : true
    }
  }

  setItemFormHtmlEditor(item: any, column:any) {
    item.editorType = 'dxHtmlEditor';
    item.colSpan= 2;      
    item.label.location= 'top';
    item.label.showColon=false;
    item.editorOptions = {
      height: 400,
      displayFormat : '' , 
      disabled: column.allowEditing == false,
      imageUpload: {
        tabs: ['file', 'url'],
        fileUploadMode: 'base64',
      },
      toolbar: {
        multiline:false,
        items: this.getHtmlItems(),
      },
      mediaResizing: {
        enabled: true,
      },
      allowSoftLineBreak :true,
    }
  }

  getHtmlItems() {
    return [
      'undo', 'redo', 'separator',
      {
        name: 'size',
        acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
      },
      {
        name: 'font',
        acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
      },
      'separator', 'bold', 'italic', 'strike', 'underline', 'separator', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator', 'orderedList', 'bulletList', 'separator',
      {
        name: 'header',
        acceptedValues: [false, 1, 2, 3, 4, 5],
      }, 
      'separator', 'color', 'background', 'separator', 'link', 'image', 'separator', 'clear', 'codeBlock', 'blockquote', 'separator', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
    ];
  }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
    if (this.sub2)
      this.sub2.unsubscribe();
    if (this.sub3)
      this.sub3.unsubscribe();
    this.sub = null;
  }

  async refreshDataWithCallWebApi(actionAPI: IActionAPI) {
    var ctrl = this;
    if(actionAPI.Parameters.EntityStatus == '')
      actionAPI.Parameters.EntityStatus = ctrl.currentState.Id =='draft' ?'D':'V';
      
    await this.entityService.executeAction(actionAPI).subscribe({
      next: async () => {
        ctrl.refreshAction = actionAPI;
        ctrl.status = (await lastValueFrom(this.entityService.getCurrentEntity(this.space,this.idEntity.toString()))).Status;
        if(actionAPI.Parameters.EntititiesIds[0] == this.idEntity)
        {
          await ctrl.refreshData();
        }
      },
      error: (err: any) => console.log(err)
    });
  }

  async executeAction(actionAPI: IActionAPI) {
    var ctrl = this;
    if(actionAPI.Parameters.EntityStatus == '')
      actionAPI.Parameters.EntityStatus = ctrl.currentState.Id =='draft' ?'D':'V';
    this.entityService.executeAction(actionAPI).subscribe({next: async () => {}});
  }

  async refreshData() {

    var ctrl = this;
    let componentList = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, this.idEntity, this.variante, this.componentName));
    if(ctrl.componentType == 'CS')
    {
      if(this.entityType == 'LR')
      {
        let filterList = await lastValueFrom(this.componentService.getFilterLrAttribut(this.space,this.entityType, this.componentName, this.currentBlocType));
        if(filterList.length > 0)
        {
          componentList = componentList.filter((tb: { Name: string; }) => {
            return filterList.some((f) => {
              return f.AttributName === tb.Name;
            })
          });
          for(var i = 0; i<componentList.length;i++)
          {
            var associatedFilter =  filterList.filter((tb: { AttributName: string; }) => tb.AttributName == componentList[i].Name)[0];
            if(associatedFilter.IsRequired)
              componentList[i].Label += '*'                  
            if(associatedFilter.GrilleEntity)
              componentList[i].Label = '[Grille entité] '+componentList[i].Label
            componentList[i].Order = associatedFilter.Order;
          }
          componentList = componentList.sort((a, b) => a['Order'] > b['Order'] ? 1 : a['Order'] === b['Order'] ? 0 : -1);
        }
      }

      await this.setIsEditableIsVisibleComponentList(componentList, false, false);

      componentList = componentList.filter((tb: { IsVisible: boolean; }) => tb.IsVisible);
    }

    ctrl.RefreshGrid(componentList);
  }

  async setIsEditableIsVisibleComponentList(componentList: any, isEntityAnchor: any, controlBool : any) {
    var ctrl = this;

    for(var i = 0; i<componentList.length;i++)
    {
      if (controlBool) {
        if (componentList[i].Type == 'bool'){
          componentList[i].FormattedValue = componentList[i].FormattedValue == 'True' ? 'Vrai' : 'Faux';
        }
      }

      var action = 'R';
      if(ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action] != null)
      {
        componentList[i].IsVisible =ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action];
      }
      else
      {
        if(componentList[i].IsVisible == 'True')
        {
          componentList[i].IsVisible = (await ctrl.componentService.getComponentSubPerimeter(ctrl.space, isEntityAnchor ? ctrl.entityAnchor.EntityType : ctrl.entityType, isEntityAnchor ? ctrl.entityAnchor.IdObject : ctrl.idEntity, componentList[i].SubPerimeter, action));
          ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action] = componentList[i].IsVisible;
        }
        else 
          componentList[i].IsVisible = false;
      }
      action = 'U';
      if(ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action] != null)
      {
        componentList[i].IsEditable =ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action];
      }
      else
      {
        if(componentList[i].IsEditable == 'True')
        {  
          componentList[i].IsEditable = (await ctrl.componentService.getComponentSubPerimeter(ctrl.space, isEntityAnchor ? ctrl.entityAnchor.EntityType : ctrl.entityType, isEntityAnchor ? ctrl.entityAnchor.IdObject : ctrl.idEntity, componentList[i].SubPerimeter, action));
          ctrl.dictSub[componentList[i].SubPerimeter+ctrl.status+ctrl.entityType+action] = componentList[i].IsEditable;
        }
        else 
          componentList[i].IsEditable = false;
      }
    }
  }

  addNewLine() {
    let grid = $('#gridComponents').dxDataGrid('instance');
    grid.option("editing.mode", "form")
    var tab = {
      colCount: 1,
      items: [{
        itemType: "tabbed",
        tabs: this.newLinetabs
      }]
    }
    grid.option("editing.form", tab)
    grid.addRow();  
  }

  async editCellTemplate(column:any, ctrl:any, storage : any) {
    column.editCellTemplate = await async function(element : any, info : any) {
      if(!info.data.IsEditable)
        return $(element).html(info.data.FormattedValue);
      switch(info.data.Type)
      {
        case 'decimal':
          return ctrl.getEditCellTemplateDecimal(ctrl, element, info);
        case 'DateTime':
          return ctrl.getEditCellTemplateDatetime(ctrl, element, info);
        case 'bool':
          return ctrl.getEditCellTemplateBool(ctrl, element, info);
        case 'DynamicList':
          return await ctrl.getEditCellTemplateDynamicList(ctrl, element, info, storage);
        default:
          if(info.data.RenderType =="textarea")
            return ctrl.getEditCellTemplateTextArea(ctrl, element, info);
          else if(info.data.RenderType =="html")
            return ctrl.getEditCellTemplateHtml(ctrl, element, info);
          else
            return ctrl.getEditCellTemplateTextBox(ctrl, element, info);
      }
    }
  }

  preparerGridEvents(ctrl : any, isFirstRender:boolean, canAdd:boolean, haveNoDelete: boolean) {
    var gridEvents = {
      onRowPrepared: function (event: any) { ctrl.onRowPrepared(event); },
      onFocusedCellChanging:function(event: any) { ctrl.onFocusedCellChanging(event); },
      onRowExpanding: function(event : any) { ctrl.onRowExpanding(event); },
      onRowCollapsing: function(event : any) { ctrl.onRowCollapsing(event); },
      onInitNewRow: function(event : any) { ctrl.onInitNewRow(event); },
      onContentReady: function(event:any) { ctrl.onContentReady(event, isFirstRender, canAdd, haveNoDelete); },
      onCellPrepared: function (event: any) { ctrl.onCellPrepared(event, canAdd); }
    }
    return gridEvents;
  }

  onRowPrepared(e: any) {
    if(e.data && e.data.isMain === true) {
      e.rowElement.addClass("isMain");
    } 
    else if(e.data && e.data.isPublished === true) {
      e.rowElement.addClass("isPublished");
    }
  }

  onFocusedCellChanging(e: any) {
    if(!e.columns[e.newColumnIndex].allowEditing) {  
      e.newColumnIndex+=1;  
      let colCount = e.component.columnCount();  
      for(let i = e.newColumnIndex; i < colCount; i++) {  
        if(!e.columns[i].allowEditing) 
          e.newColumnIndex += 1;  
        else   
          break;  
      }  
    }
    else if(e.cellElement.parent().hasClass('lecture-seul')) {
      e.newRowIndex += 1;
    }  
  }

  onRowCollapsing(info: any) {
    if(info.component.isRowSelected(info.key))
      info.component.deselectRows([info.key])
  }

  onRowExpanding(info: any) {
    info.component.collapseAll(-1);
  }

  onInitNewRow(info: any) {
    var ctrl = this;
    let  columnCount = info.component.columnCount()
    for(var i = 0; i < columnCount; i++) {  
      var column = info.component.columnOption(i);  
      if(column.defaultValue && column.defaultValue.length > 0)
      {
        if(column.dataType == 'DynamicList' && column.dataField !='Code')
        {
          var list = JSON.parse(ctrl.storage.Lists[column.format]);
          info.data[column.dataField.replace('.IdEnumerationItem','')] = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
        }
        else if (column.dataType == 'date' || column.dataType == 'datetime')
          info.data[column.dataField] = DateUtils.getDateFromStringOrToday(column.defaultValue);
      else
          info.data[column.dataField]=column.defaultValue;
      }
      else if (column.dataType == 'boolean' &&  column.defaultValue == true)
        info.data[column.dataField] = true;
      else if(column.dataType == 'number')
        info.data[column.dataField]= 0
      else if(column.dataType == 'date' || column.dataType == 'datetime')
        info.data[column.dataField] = new Date();
      else if(column.dataType == 'boolean')
        info.data[column.dataField]= false
      else if(column.dataType == 'string')
        info.data[column.dataField]= ''
      else if(column.dataType == 'DynamicList' && column.dataField !='Code')
      {
        var list = JSON.parse(ctrl.storage.Lists[column.format]);
        info.data[column.dataField.replace('.IdEnumerationItem','')]= list[0]
      }
      if(column.dataField =='DisplayEnumCode'  || column.dataField =='Id')
        info.data[column.dataField]= ' .'
    }
  }

  onContentReady(e: any, isFirstRender: any, canAdd: any, haveNoDelete: any) {
    var ctrl = this;
    if(ctrl.localStorageService.get("ScrollBar"))
    {
      var scrollable = e.component.getScrollable();
      var values = ctrl.localStorageService.get("ScrollBar").split('#');
      scrollable.scrollTo({ left: values[1], top: values[0]});
    }
    if(isFirstRender) {
      isFirstRender = false;
      e.component.getScrollable().on("scroll", function(e:any) {
          ctrl.localStorageService.set("ScrollBar", e.scrollOffset.top+'#'+e.scrollOffset.left);
      })
    }
    if(canAdd && haveNoDelete)
    {
      ctrl.setAddRowButton(e);
      $('.dx-datagrid-filter-row .dx-command-select').addClass("add-grid-button")
    }
  }

  onCellPrepared(e:any, canAdd: any) {
    var ctrl = this;
    if ( e.column.type == "buttons" && e.rowType =="header" && canAdd)
    {
      ctrl.setAddRowButton(e);
      e.cellElement.addClass("add-grid-button")
    }
  }

  setAddRowButton(e:any) {
    var ctrl = this;
    e.cellElement.dxButton({
      hint: 'Ajouter une ligne',
      icon: 'add',
      onClick() { ctrl.addNewLine() },
    }).dxButton('instance')
  }

  getEditCellTemplateDecimal(ctrl: any, element:any, info:any) {
    var editionFormat = info.data.EditionFormat != null && info.data.EditionFormat.length > 0 ? info.data.EditionFormat : null;
    var formatFunction = function (value:any) {
      return numeral(value).format(editionFormat)
    };
    if (editionFormat == null) {
      formatFunction = null;
    }
    return $(element).dxNumberBox({
      value: info.data.Value,
      format: formatFunction,
      step: 0,
      showSpinButtons: false,
      showClearButton: false,
      onValueChanged : async function (e) {
        info.data.FormattedValue = e.value.toString();
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = e.value.toString();
        if (info.data.Format != null) {
          info.data.FormattedValue = numeral(info.data.Value).format(info.data.Format)
          ctrl.RefreshGrid(null);
        }
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
        if(ctrl.space == 'MDEL' && (ctrl.componentName == 'data' || ctrl.componentName == 'filter' || ctrl.componentName == 'properties'|| ctrl.componentName == 'layout'))
          ctrl.refreshData();
      },
      onKeyDown: function(e:any) {
        var preventedKeys = [38, 40];
        var keyCode = e.event.keyCode;
        var shouldPreventEvent = !e.component.option("opened") &&
          preventedKeys.indexOf(keyCode) !== -1 &&
          !e.event.altKey;
        if(shouldPreventEvent) {
          e.event.preventDefault();
          e.event.stopImmediatePropagation();
        }
      }
    });
  }

  getEditCellTemplateDatetime(ctrl: any, element:any, info:any) {
    var date = moment(info.data.Value, 'DD.MM.yyyy');
    return $(element).dxDateBox({
      type: 'date',
      displayFormat: 'dd.MM.yyyy', 
      value: date.toDate(),
      onValueChanged : async function (e) {
        info.data.FormattedValue = e.value.toLocaleDateString("fr-CH");
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = e.value;
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
      }
    });
  }

  getEditCellTemplateBool(ctrl: any, element:any, info:any) {
    return  $(element).dxCheckBox({
      value: info.data.Value == 'True' ? true : false,
      onValueChanged: async function (e) {
        const newValue = e.value;
        info.data.FormattedValue = newValue ? 'True' : 'False';
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = newValue ? 'True' : 'False';
        info.data.FormattedValue= newValue ? 'Vrai' : 'Faux';
        ctrl.RefreshGrid(null);
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
      }
    });
  }

  async getEditCellTemplateDynamicList(ctrl: any, element:any, info:any, storage:any) {
    let items: any[] = [];
    var url = './api/'+storage.Version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+info.data.List.replace('#1', '')+'/'+ctrl.idEntity+'.json';
    items = await lastValueFrom(ctrl.httpClient.get(url));
    items = ListUtils.getOnlyActiveItems(items);
    var displayColonne = info.data.List.indexOf('#1') != -1 ? 'Code' : 'Label';
    return $(element).dxSelectBox({
      dataSource: items,
      displayExpr: displayColonne,
      valueExpr: 'Label',
      value: info.data.Value,
      onSelectionChanged: async function (e) {
        const newValue = e.selectedItem.IdEnumerationItem;
        if(info.data.FormattedValue == e.selectedItem.Label)
          return;
        info.data.FormattedValue = newValue;
        info.data.IsVisible="True";
        info.data.IsEditable="True";
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        ctrl.refreshData();
      }
    });
  }

  getEditCellTemplateTextArea(ctrl: any, element:any, info:any) {
    return $(element).dxTextArea({
      value: info.data.Value,
      height:90,
      onValueChanged : async function (e) {
        info.data.FormattedValue = e.value.toString();
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = info.data.FormattedValue;
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
      }
    });
  }

  getEditCellTemplateHtml(ctrl:any, element: any, info: any) {
    return $(element).dxHtmlEditor({
      height: 500,
      value: info.data.Value,
      toolbar: {
        multiline:false,
        items: ctrl.getHtmlItems(),
      },
      mediaResizing: {
        enabled: true,
      },
      allowSoftLineBreak :true,
      onValueChanged : async function (e) {
        info.data.FormattedValue = e.value.toString();
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = info.data.FormattedValue;
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
      }
    });
  }

  getEditCellTemplateTextBox(ctrl: any, element: any, info: any) {
    return $(element).dxTextBox({
      value: info.data.Value,
      onValueChanged : async function (e) {
        info.data.FormattedValue = e.value.toString();
        ctrl.resetField(info);
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, ctrl.idEntity, ctrl.componentType, ctrl.componentName, info.data));
        info.data.Value = info.data.FormattedValue;
        if(ctrl.space == 'MDEL' && (ctrl.entityType == 'AT' || ctrl.entityType == 'LR' || ctrl.entityType == 'RP') && info.data.Name == 'Label')
        {
          ctrl.updateAttribut = {
            IdObject : ctrl.idEntity,
            LongName: info.data.Value
          }
        }
        if(ctrl.space == 'MDEL' && (ctrl.entityType == 'AT' || ctrl.entityType == 'LR' || ctrl.entityType == 'RP') &&  info.data.Name == 'Name')
        {
          ctrl.updateAttribut = {
            IdObject : ctrl.idEntity,
            Name: info.data.Value
          }
        }
        if(ctrl.componentName == 'communication')
          ctrl.refreshData();
      }
    });
  }

  resetField(info: any) {
    info.data.IsVisible="True";
    info.data.IsEditable="True";
    info.data.List = "";
  }

  notify(e:any) {
    e.stopPropagation();
  }

  validateApp(data: any, component: any) {
    let isValid = true;
    let  columnCount = component.columnCount()
    for(var i = 0; i < columnCount; i++) {  
      var column = component.columnOption(i);
      if(column.isRequired)
      {
        switch(column.dataType)
        {
          case 'string':
          case 'DynamicList':
            if(data[column.dataField] == "")
              isValid = false;
            break;
          case 'decimal':
          case 'number':
            if(data[column.dataField] == 0)
              isValid = false;
            break;
          case 'DateTime':
          case 'date':
            if(data[column.dataField] == new Date())
              isValid = false;
            break;
        }
      }    
    }
    return isValid
  }

  RefreshGrid(datasource:any[]) {
    let grid = $('#gridComponents').dxDataGrid('instance');
    if (datasource != null && datasource != undefined && datasource.length > 0){
      grid.option("dataSource", datasource);
    }
    grid.option("editing.mode", "cell")
    grid.refresh();
  }

  setFileUploader() {
    var ctrl = this;

    $('#file-uploader').dxFileUploader({
      multiple: false,
      accept: '.pdf',
      value: [],
      uploadMode: 'useForm',
      visible:false,
      dialogTrigger:"#buttonAddDocCom",
      onValueChanged(e) { ctrl.onSubmit(); }
    }).dxFileUploader('instance');
  }
}