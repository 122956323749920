import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import * as $ from 'jquery';
import { FrontOfficeFormService } from "../../shared-service/front-office-form.service";
import * as numeral from 'numeral';
import * as moment from "moment";
import { FrontOfficeStructService } from "../../../front-office-struct.service";
import { LocalStorageService } from "app/shared/local-storage.service";
import { __await } from "tslib";
import { Subscription, lastValueFrom } from "rxjs";
import { StringUtils } from "app/utils/string-utils";
import { FrontOfficeFormFactory } from "../../shared-factory/front-office-form-factory";
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'front-office-form',
    templateUrl: './front-office-form.component.html',
    styleUrls: ['./front-office-form.component.css']
})
export class FrontOfficeFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formName: string = '';
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() space: string = '';
  @Input() applicationName: string = '';
  @Input() zoneName: string = '';
  @Input() moduleName: string = '';
  @Input() tabName: string = '';
  @Input() variante: string = '';
  @Input() entityIdentifier: string = '';
  @Input() multiTab: boolean;
  @Input() order: number = 0;
  @Input() contextInfo: any[];
  @Input() currentZone: any | undefined;
  @Input() currentModule: any | undefined;
  @Input() stateId: string = '';
  @Input() forceReloadData: boolean = false;
  @Input() forceReloadDataReady: boolean = false;
  @Input() entityLock: boolean = false;
  @Input() currentEntity: any;
  @Input() currentProcedureAssociation: string = '';
  @Input() entityCible: string = '';
  blocWidth: string;
  blocType: string;
  maxItems:number = 0;
  updateAvailable: boolean = true;
  fullyLoaded: boolean = false;
  dictSub: any;
  storage : any;
  groups:any[] = [];
  selectedRowIndex : string = '';
  sub!: Subscription;
  constructor(
    private applicationFormService: FrontOfficeFormService,
    private appStructService:  FrontOfficeStructService,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
  ) 
  { this.dictSub = {}; }
  
  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    var ctrl = this;

    if (changes.contextInfo && ctrl.groups.length > 0 && !ctrl.forceReloadData) {

      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
      var module = ctrl.moduleName != undefined ? ctrl.moduleName.length > 2 ? ctrl.moduleName : '' : '';
      var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName;

      for(var i = 0; i < ctrl.groups.length; i++)
      {
        let contextInfo = ctrl.contextInfo.filter((tb: { Module: String, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == ctrl.order.toString() && tb.Ligne == ctrl.groups[i].Numbering)[0];
        ctrl.groups[i].ContextInfo = contextInfo;
      }

    }
    if (changes.forceReloadDataReady && ctrl.groups.length > 0 && ctrl.forceReloadDataReady) {
      let formData = await lastValueFrom(ctrl.applicationFormService.getFormDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName))
      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
      var module = ctrl.moduleName != undefined ? ctrl.moduleName.length > 2 ? ctrl.moduleName : '' : '';
      var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName;

      for(var i = 0; i < ctrl.groups.length; i++) {
        for(var j = 0; j < ctrl.groups[i].items.length; j++) {
          var item = ctrl.groups[i].items[j];
          ctrl.refreshElement(item, formData, ctrl);
        }
        let contextInfo = ctrl.contextInfo.filter((tb: { Module: String, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == this.order.toString() && tb.Ligne == ctrl.groups[i].Numbering)[0];
        ctrl.groups[i].ContextInfo = contextInfo;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.ngOnDestroy(); 
    this.storage = this.localStorageService.getWithExpiration(this.space);
    this.storage = JSON.parse(this.storage);
    if (this.storage != null){
      if(this.storage.Lists == null )
        this.storage.Lists = {};
    }
    var ctrl = this; 
    if(this.perimeter == "Space")
    {
      var tabName = StringUtils.getTabNameWithConsiderMultiTab(this);
      
      let formData = await lastValueFrom(ctrl.applicationFormService.getFormDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName))
      let formConfig = await lastValueFrom(ctrl.applicationFormService.getFormConfigByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName, tabName));
      ctrl.blocWidth = formConfig.Width;
      ctrl.blocType = formConfig.Template;

      
      await FrontOfficeFormFactory.setGroups(ctrl, formConfig);
      FrontOfficeFormFactory.setSortGroups(ctrl);
      formConfig.items = ctrl.groups;
      
      let indexNumber = 0;

      FrontOfficeFormFactory.setItemContextInfo(ctrl, false);
      
      for(var i = 0; i<formConfig.items.length;i++) {

        var group = ctrl.groups[i];

        var numberWidth = 25;
        if(group.NumberingStyle != null && group.NumberingStyle.indexOf('width') != -1) {
          var temp = group.NumberingStyle.split('width:')[1];
          numberWidth = parseFloat(temp);
        }
        
        var labelWidth = 25;
        if(group.LabelStyle != null && group.LabelStyle.indexOf('width') != -1) {
          var temp = group.LabelStyle.split('width:')[1];
          labelWidth = parseFloat(temp);
        }

        var totalLabelNumbering = labelWidth + numberWidth;

        if (totalLabelNumbering > 100) {
          totalLabelNumbering = 100;
        }

        var rest = (100 - totalLabelNumbering) / this.maxItems;
        for(var j = 0; j < formConfig.items[i].items.length; j++) {
          var item = formConfig.items[i].items[j];
          item.id = 'item_' + this.formName + '_' + i + '_' + j;
          item.Reference = tabName.replace('/','ø')+':'+formConfig.items[i].Numbering;
          item.Onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName;
          item.Bloc = this.order;
          item.Ligne = String(formConfig.items[i].Numbering);

          if(item.cssClass == 'title') {
            item.colSpan = 5;
          }

          if(item.cssClass.indexOf('numbering') != -1 && i != 0) {
            formConfig.items[i].items[j-1].colSpan = formConfig.colCount-(i-indexNumber)+1;
            indexNumber = i;
          }

          item.colspan = 1;
          if(j == formConfig.items[i].items.length -1 && formConfig.items[i].items.length != this.maxItems && this.tabName != 'AN4'&& this.tabName != 'CDOT') {
            item.colspan = this.maxItems - formConfig.items[i].items.length +1;
          }

          if(item.style == '' || this.maxItems == 1) {
            item.style = 'width: '+rest+'%;';
          }
          else if(item.style.indexOf('width') == -1) {
            item.style += 'width: '+rest+'%;';
          }
          else {
            var currentwidth = item.style.split('width:')[1];
            currentwidth = currentwidth.split('%')[0];
            item.style = item.style.replace('width:'+currentwidth+'%', 'width: '+rest+'%')
          }

          await FrontOfficeFormFactory.setDisableVisibleItem(ctrl, item);

          if(item.RenderType =='html') {
            FrontOfficeFormFactory.setItemHtml(item);
          }
        }
      }
      setTimeout(async () => {
        for(var i = 0; i<formConfig.items.length;i++)
        {
          for(var j = 0; j<formConfig.items[i].items.length;j++)
          {
            var item = formConfig.items[i].items[j];
            await ctrl.addElement(item, formData, ctrl);
          }
        }
        ctrl.localStorageService.setWithExpiration(ctrl.space, JSON.stringify(ctrl.storage), 'CallTimout');
        ctrl.fullyLoaded = true;
        $('#loadpanel').dxLoadPanel('instance').hide();
      }, 50);
      
      ctrl.sub = ctrl.appStructService.updateParent.subscribe((item:any) => {
        let group = ctrl.groups.filter((tb: { Numbering: string; }) => tb.Numbering == item.Ligne)[0];
        if(group != null)
        {
          if(group.ContextInfo != null)
          {
            if(item.Type == 'comments')
              group.ContextInfo.HasComment = item.Value;
            else
              group.ContextInfo.HasDocument = item.Value;
          }
          else
          {
            group.ContextInfo = {
              HasComment : item.Type == 'comments' ? item.Value : false,
              HasDocument : item.Type == 'comments' ? false : item.Value
            };
          }
          
          ctrl.appStructService.updateFormData.emit('ok')
        }
      });
    }
  }

  async refreshElement(item: any, formData:any, ctrl : any){
    if(item.editorOptions.disabled || ctrl.entityLock)
    {
      if(item.dataField == null || formData[0][item.dataField.replace('.IdEnumerationItem','')] == null ) {
        return;
      }

      $($('#'+item.id).children('span')[0]).html(
        (item.editorType == 'dxNumberBox' && item.editorOptions.format != '') ? numeral(formData[0][item.dataField]).format(item.editorOptions.format) : 
        (item.editorType == 'dxDateBox' && item.editorOptions.displayformat != '') ? moment(formData[0][item.dataField]).format(item.editorOptions.displayFormat) : 
        (item.editorType == 'dxSelectBox') ? formData[0][item.dataField.replace('.IdEnumerationItem','')][item.list.indexOf('#1') != -1 ? 'Code' : 'Label']
        : formData[0][item.dataField]
      );
    }
    else {
      switch (item.editorType) {
        case 'dxNumberBox':
          $('#'+item.id).dxNumberBox('instance').option('value', formData[0][item.dataField]);
          break;
        case 'dxSelectBox':
          $('#'+item.id).dxSelectBox('instance').option('value', formData[0][item.dataField.replace('.IdEnumerationItem','')].Label);
          break;
        case 'dxTextBox':
          $('#'+item.id).dxTextBox('instance').option('value', formData[0][item.dataField]);
          break;
        case 'dxTextArea':
          $('#'+item.id).dxTextArea('instance').option('value', formData[0][item.dataField]);
          break;
        case 'dxDateBox':
          $('#'+item.id).dxDateBox('instance').option('value', formData[0][item.dataField]);
          break;
        case 'dxCheckBox':
          $('#'+item.id).dxCheckBox('instance').option('value', formData[0][item.dataField]);
          break;
      }
    }
  }

  async addElement(item: any, formData:any, ctrl : any) {

    var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

    if(item.editorOptions.disabled || ctrl.entityLock)
    {
      var datafieldName = StringUtils.getIsNameFromTObject(item.fullDataField) ? item.fullDataField.replace('.','') : item.dataField;

      if(datafieldName == null || formData[0][datafieldName.replace('.IdEnumerationItem','')] == null ) {
        return;
      }

      $('#'+item.id).empty()
      if(item.editorType == 'dxNumberBox' && item.editorOptions.format != '') {
        $('#'+item.id).append("<span>"+numeral(formData[0][datafieldName]).format(item.editorOptions.format)+"</span>");
      }
      else if(item.editorType == 'dxDateBox' && item.editorOptions.displayformat != '') {
        item.editorOptions.value = formData[0][datafieldName];
        item.editorOptions.displayFormat= "dd.MM.yyyy";
        item.formatDate = item.editorOptions.format
        item.editorOptions.format = function (value:any) {
          return moment(value).format(item.formatDate)
        }
        $('#'+item.id).dxDateBox(item.editorOptions);
      }
      else if(item.editorType == 'dxSelectBox') {
        $('#'+item.id).append(
          item.list.indexOf('#1') != -1 
            ? "<span>"+formData[0][datafieldName.replace('.IdEnumerationItem','')].Code + "</span>"
            : "<span>"+formData[0][datafieldName.replace('.IdEnumerationItem','')].Label + "</span>"
        );
      }
      else if (item.editorType == 'dxCheckBox') {
        item.editorOptions.value = formData[0][datafieldName];
        $('#'+item.id).dxCheckBox(item.editorOptions);
        $('#'+item.id).dxCheckBox('instance').option('value', Boolean(item.editorOptions.value));
        $('#'+item.id).css("float", "right");
      }
      else if(datafieldName.indexOf('.IdEnumerationItem') == -1) {
        $('#'+item.id).append("<span>"+formData[0][datafieldName]+"</span>");
      }
      else {
        $('#'+item.id).append("<span>"+formData[0][datafieldName.replace('.IdEnumerationItem','')].Label+"</span>");
      }
    }
    else if (item.dataField =='Organisation') {
      FrontOfficeFormFactory.setOrganisationItem(ctrl, item, formData);
    }
    else if (ctrl.currentModule.IsModuleUser && item.dataField =='RolePrincipal') {
      FrontOfficeFormFactory.setRolePrincipalItem(ctrl, item, formData);
    }
    else{
      FrontOfficeFormFactory.setNonDisableItemEditorOptions(ctrl, item, formData, tabName);
    }
  }

  updateContext(group : any)
  {
    var actualRow = null;
    if(group.items.length > 0)
    {
      actualRow = $('#'+group.items[0].id).parent().parent();
      if( this.selectedRowIndex != '')
      {
        let previousRow = $(this.selectedRowIndex).parent().parent();
        $(previousRow).removeClass("selected-line");
      }
      this.selectedRowIndex = '#'+group.items[0].id;
    
      $(actualRow).addClass("selected-line");
    }
    
    var tabName = StringUtils.getTabNameWithConsiderMultiTab(this);

    var item = {
      OriginName : this.formName,
      Type: 'TableForm',
      Value : group,
      Tab: tabName,
      ApplicationName : this.applicationName,
      IsSpecial : this.currentEntity != null ? this.currentEntity.IsSpecial : false,
      Space : this.space,
      Version : this.version,
      Zone : this.zoneName,
      LockEntity : this.entityLock,
      EntityId : this.entityIdentifier,
      Reference : tabName + ':' + group.Numbering,
      WorkflowId : this.currentZone.WorkflowId,
      Module: this.moduleName,
      Onglet: tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName,
      Bloc : this.order.toString(),
      Ligne: String(group.Numbering),
      ContextInfo : group.ContextInfo,
      Multi : this.multiTab,
      CommentLevel:group.CommentLevel,
      DocumentLevel:group.DocumentLevel,
      SubPerimeter:group.SubPerimeterDoc,
      StateId : this.stateId,
      RowElement : actualRow
    }
    this.appStructService.updateContextPanel.emit(item)
  }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }
}
