import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { IActionButton } from "app/shared/entity";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, of, lastValueFrom } from "rxjs";
import { shareReplay, catchError, tap, retry } from 'rxjs/operators';
import { IBoSelector } from "./back-office-selector";

@Injectable({
    providedIn: 'root'
})

export class BoService {
    private componentsUrl = './api/{version}/spaces/{space}/entities';
    //public refreshChangeBlocModuleAlert = new EventEmitter()

    constructor(private http: HttpClient, private localStorageService: LocalStorageService)
    { }

    getListComponent(space: string, entityType: string, idEntity: number): Observable<IBoSelector[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + idEntity + '/components.json'
        return this.http.get<IBoSelector[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getCurrentBlocType(idEntity: number): Observable<string> {
        var url = './api/v1/attribut/MDEL/' + idEntity + '/bloc_type.json'
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getListComponentSync(space: string, entityType: string, idEntity: number) {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + idEntity + '/components.json'
        return lastValueFrom(this.http.get<IBoSelector[]>(url));
    }

    getDetailComponentUrl(space: string, entityType: string, idEntity: number, componentName: string): string {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        return this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/' + componentName + '.json';
    }

    getDetailComponent(space: string, entityType: string, idEntity: number, variante: string, componentName: string): Observable<any[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        if(variante == '')
             variante = 'd'
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity+ '/' + variante + '/components/' + componentName + '.json'
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getFilterLrAttribut(space: string, entityType: string, componentName: string, blocType: string): Observable<any[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = './api/v1/attribut/space/' + entityType + componentName+ '/' + blocType + '/attribut_lr_filter.json'
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getMasterComponent(space: string, componentId: string, componentName: string, entityType:string) {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + componentId+ '/' + componentName+ '/detail.json';
        return this.http.get<any>(url);
    }

    getComponentActions(space: string, entityType: string, idEntity: number, componentName: string): Observable<IActionButton[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/' + entityType.toLowerCase()+componentName + '/buttons.json'
        return this.http.get<IActionButton[]>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    getComponentSubPerimeter(space: string, entityType: string, idEntity: number, subPerimeter: string, action: string) {
        if(idEntity == 0)
            return false;
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity+ '_' + action  + '/check_access.json'
        return lastValueFrom(this.http.post<boolean>(url, { Value:subPerimeter}));
    }

    getGridConfiguration(space: string, entityType: string, idEntity: number, componentName: string, componentType: string): Observable<any> {
        componentName = componentName[0].toUpperCase() + componentName.slice(1);
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        if (storage == null)
            if (storage.Components[entityType + '-' + componentName] != null) {
                return of(JSON.parse(storage.Components[entityType + '-' + componentName]));
            }
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/' + componentName + '/' + componentType + '/settings/grid.json'
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                storage.Components[entityType + '-' + componentName] = JSON.stringify(data);
                this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getColumnsAttributs(space: string, columnsAttributs: string[], idEntity: number): Observable<Record<string, string>> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = './api/v1/attribut/'+space+'/'+idEntity+'.json';
        return this.http.post<Record<string, string>>(url, columnsAttributs)
            .pipe(
                retry(1),
                shareReplay(1),
                tap(data => JSON.stringify(data)),
                catchError(this.handleError)
            );
    }

    updateAttribut(space: string, entityType: string, idEntity: number, componentType:string, componentName: string, attribut: any): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/'+componentType+'/' + componentName + '.json'
       return this.http.put(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    updateAttributProperty(space: string, entityType: string, idComponent: number, componentType:string, componentName: string, attribut: any): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idComponent + '/components/'+componentType+'/' + componentName + '/property.json'
       return this.http.put(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    insertAttribut(space: string, entityType: string, idEntity: number, componentType:string, componentName: string, attribut: any): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/'+componentType+'/' + componentName + '.json'
       return this.http.post(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    deleteAttribut(space: string, entityType: string, idEntity: number, componentType:string, componentName: string, idAttribut: string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.componentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/' + idEntity + '/components/'+componentType+'/' + componentName + '/'+idAttribut+'.json'
       return this.http.delete(url)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}
