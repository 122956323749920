import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { shareReplay, map, tap } from 'rxjs/operators';
import { IApplicationInfo, IConfigurationInfo } from "./application-model";

@Injectable({
    providedIn: 'root'
})

export class AppService {
    private spaceUrl = './api/v1/application';

    constructor(private http: HttpClient) {

    }


    getAssemblyVersion(): Observable<IApplicationInfo> {
        var url = this.spaceUrl + '/version.json';
        return this.http.get<IApplicationInfo>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data))
            );
    }

    getConfiguration(): Observable<IConfigurationInfo> {
        var url = this.spaceUrl + '/configuration.json';
        return this.http.get<IConfigurationInfo>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data))
            );
    }

   

}
