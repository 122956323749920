// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog
{
    width: 70vw;
}
.crud-communication
{
    width: 100%;
}
.document-communication
{
    width: 0%;
}

.modal-footer
{
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./app/front-office/modal-seq-error/modal-seq-error.component.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;AACf;AACA;;IAEI,WAAW;AACf;AACA;;IAEI,SAAS;AACb;;AAEA;;IAEI,uBAAuB;AAC3B","sourcesContent":[".modal-dialog\r\n{\r\n    width: 70vw;\r\n}\r\n.crud-communication\r\n{\r\n    width: 100%;\r\n}\r\n.document-communication\r\n{\r\n    width: 0%;\r\n}\r\n\r\n.modal-footer\r\n{\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
