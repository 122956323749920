import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable } from "rxjs";
import { shareReplay, retry, tap } from 'rxjs/operators';
import { IBreadCrumbEntity } from "../shared/entity";

@Injectable({
    providedIn: 'root'
})

export class NavigationService {

    private entitiesUrl = './api/{version}/spaces/{space}/Navigation';

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    getBreadCrumbEntity(space: string, id: number, entityType:string): Observable<IBreadCrumbEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/breadcrumb/'+entityType+'/' + id + '.json'
        return this.http.get<IBreadCrumbEntity[]>(url)
            .pipe(
                shareReplay(1),
                retry(1),
                tap(data => JSON.stringify(data))
            );
    }
}
