import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "app/shared/shared.module";
import { DxAccordionModule, DxAutocompleteModule, DxDataGridModule, DxHtmlEditorModule, DxPopupModule, DxTextAreaModule } from "devextreme-angular";
import { FrontOfficeFormComponent } from "./desktop-mobile/desktop/front-office-form/front-office-form.component";
import { FrontOfficeStructComponent } from "./front-office-struct/front-office-struct.component";
import { FrontOfficeGridComponent } from "./desktop-mobile/desktop/front-office-grid/front-office-grid.component";
import { ApplicationGuardService } from "app/shared/application-guard-service";
import { FrontOfficeFormMobileComponent } from "./desktop-mobile/mobile/front-office-form-mobile/front-office-form-mobile.component";
import { FrontOfficeDetailFormMobileComponent } from "./desktop-mobile/mobile/front-office-detail-form-mobile/front-office-detail-form-mobile.component";
import { FrontOfficeGridMobileComponent } from "./desktop-mobile/mobile/front-office-grid-mobile/front-office-grid-mobile.component";
import { FrontOfficeDetailGridMobileComponent } from "./desktop-mobile/mobile/front-office-detail-grid-mobile/front-office-detail-grid-mobile.component";
import { FrontOfficeToolbarItemComponent } from './front-office-toolbar-item/front-office-toolbar-item.component';
import { FrontOfficeToolbarComponent } from './desktop-mobile/desktop/front-office-toolbar/front-office-toolbar.component';
import { FrontOfficeToolbarMobileComponent } from './desktop-mobile/mobile/front-office-toolbar-mobile/front-office-toolbar-mobile.component';
import { PanelContextAppComponent } from './panel-context-app/panel-context-app.component';
import { PanelDetailEtabComponent } from './panel-detail-etab/panel-detail-etab.component';
import { PanelListAppMobileComponent } from './desktop-mobile/mobile/panel-liste-app-mobile/panel-liste-app-mobile.component';
import { PanelListEtabComponent } from './panel-liste-etab/panel-liste-etab.component';
import { PanelListZoneMobileComponent } from './desktop-mobile/mobile/panel-liste-zone-mobile/panel-liste-zone-mobile.component';
import { PanelMobileFooterComponent } from './desktop-mobile/mobile/panel-mobile-footer/panel-mobile-footer.component';
import { NgbdModalConfirmationIdpComponent } from './modal-confirmation-idp.component';
import { NgbdModalConfirmationComponent } from './modal-confirmation.component';
import { NgbdModalCommunicationComponent, NgbdModalCommunicationDetailContent } from '../front-office/modal-communication/modal-communication.component';
import { NgbdModalSeqErrorComponent, NgbdModalSeqErrorDetailContent } from "./modal-seq-error/modal-seq-error.component";

@NgModule({
    declarations: [
        FrontOfficeFormComponent,
        FrontOfficeFormMobileComponent,
        FrontOfficeDetailFormMobileComponent,
        FrontOfficeGridComponent,
        FrontOfficeGridMobileComponent,
        FrontOfficeDetailGridMobileComponent,
        FrontOfficeStructComponent,
        FrontOfficeToolbarComponent,
        FrontOfficeToolbarItemComponent,
        FrontOfficeToolbarMobileComponent,
        PanelContextAppComponent,
        PanelDetailEtabComponent,
        PanelListAppMobileComponent,
        PanelListEtabComponent,
        PanelListZoneMobileComponent,
        PanelMobileFooterComponent,
        NgbdModalConfirmationIdpComponent,
        NgbdModalConfirmationComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild([
            {
                path: 'app/:applicationName/:zoneName/:moduleName/:entityIdentifier/:tabName',
                children: [
                    {
                        path: '',
                        component: FrontOfficeStructComponent,
                        canActivate: [ApplicationGuardService]
                    }
                ]
            }
        ]),
        RouterModule,
        DxDataGridModule,
        DxPopupModule,
        DxTextAreaModule,
        DxAutocompleteModule,
        DxHtmlEditorModule,
        DxAccordionModule
    ],
    exports: [
        FrontOfficeToolbarComponent,
        FrontOfficeToolbarItemComponent,
        FrontOfficeToolbarMobileComponent,
        FrontOfficeFormComponent,
        FrontOfficeFormMobileComponent, 
        FrontOfficeDetailFormMobileComponent,
        FrontOfficeGridComponent, 
        FrontOfficeGridMobileComponent,
        FrontOfficeDetailGridMobileComponent, 
        FrontOfficeStructComponent,
        PanelContextAppComponent,
        PanelDetailEtabComponent,
        PanelListAppMobileComponent,
        PanelListEtabComponent,
        PanelListZoneMobileComponent,
        PanelMobileFooterComponent,
        NgbdModalConfirmationIdpComponent,
        NgbdModalConfirmationComponent,
        NgbdModalCommunicationComponent,
        NgbdModalCommunicationDetailContent,        
        NgbdModalSeqErrorComponent,
        NgbdModalSeqErrorDetailContent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }, 
        ApplicationGuardService,
        NgbdModalConfirmationIdpComponent,
        NgbdModalConfirmationComponent,
        NgbdModalCommunicationComponent,
        NgbdModalCommunicationDetailContent,        
        NgbdModalSeqErrorComponent,
        NgbdModalSeqErrorDetailContent,
    ],
    bootstrap: [
        FrontOfficeFormComponent,
        FrontOfficeFormMobileComponent, 
        FrontOfficeDetailFormMobileComponent,
        FrontOfficeGridComponent, 
        FrontOfficeGridMobileComponent,
        FrontOfficeDetailGridMobileComponent, 
        FrontOfficeStructComponent
    ]
})
export class FrontOfficeModule { }
