import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardWithForcedLogin } from './auth-guard-with-forced-login.service';
import { AuthService } from './auth.service';
import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { authModuleConfig } from './auth-module-config';
import { environment } from "../../environments/environment";
import { BreadCrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadCrumbMobileComponent } from '../front-office/desktop-mobile/mobile/breadcrumb-mobile/breadcrumb-mobile.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PanelComponent } from './panel/panel.component';
import { NgbdModalExportComponent, NgbdModalExportContent } from './modal-export.component';
import { NgbdModalImportComponent, NgbdModalImportContent } from './modal-import.component';
import { NgbdModalReportComponent, NgbdModalReportContent } from './modal-report.component';
import { NotificationService } from '../shared/notification.service';
import { PopupCloneComponent, PopupCloneContent } from './popup-clone.component';
import { HomePageComponent } from 'app/home-page/home-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxAutocompleteModule, DxFileManagerModule, DxHtmlEditorModule, DxListModule, DxLookupModule, DxTagBoxModule, DxTextBoxModule, DxTreeListModule } from 'devextreme-angular';
import { DxTemplateHost } from 'devextreme-angular';

export const authConfig: AuthConfig = {

    issuer: environment.idp.issuer,
    redirectUri: environment.idp.redirectUri,
    clientId: environment.idp.clientId,
    responseType: environment.idp.responseType,
    scope: environment.idp.scope,
    showDebugInformation: environment.idp.showDebugInformation,
    requireHttps: environment.idp.requireHttps
};


@NgModule({
    declarations: [
        BreadCrumbComponent,
        BreadCrumbMobileComponent,
        PanelComponent,
        NgbdModalExportComponent,
        NgbdModalExportContent,
        NgbdModalImportComponent,
        NgbdModalImportContent,
        NgbdModalReportComponent,
        NgbdModalReportContent,
        PopupCloneComponent,
        PopupCloneContent,
        HomePageComponent,
        FormsModule,
        ReactiveFormsModule,
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FontAwesomeModule,
        DxAutocompleteModule,
        DxLookupModule,
        DxListModule,
        DxTagBoxModule,
        DxTextBoxModule,
        DxTreeListModule,
        OAuthModule.forRoot(),
    ],
    exports: [
        CommonModule,
        FormsModule,
        BreadCrumbComponent,
        BreadCrumbMobileComponent,
        PanelComponent,
        NgbdModalExportComponent,
        NgbdModalExportContent,        
        NgbdModalImportComponent,
        NgbdModalImportContent,
        NgbdModalReportComponent,
        NgbdModalReportContent,
        PopupCloneComponent,
        PopupCloneContent,
        HomePageComponent,
        ReactiveFormsModule,
        DxHtmlEditorModule,
        DxTagBoxModule,
        DxFileManagerModule
    ],
    providers: [
        AuthService,
        AuthGuardWithForcedLogin,
        DxTemplateHost
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: AuthConfig, useValue: authConfig },
                { provide: OAuthModuleConfig, useValue: authModuleConfig },
                NgbdModalExportComponent,
                NgbdModalExportContent,
                NgbdModalImportComponent,
                NgbdModalImportContent,
                NgbdModalReportComponent,
                NgbdModalReportContent, 
                NotificationService,
                PopupCloneComponent,
                DxTemplateHost
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
        if (parentModule) {
            throw new Error('SharedModule is already loaded. Import it in the AppModule only');
        }
    }
}