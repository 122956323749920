import { Component, Injectable, TemplateRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {  NotificationMessage } from "app/shared/NotificationMessage";
import { ActionLegacyRefreshService } from "./actions-legacy-refresh.service";
import { NotificationService } from "./notification.service";
import { AppToastService } from "./toast.service";


@Component({
    selector: 'app-toasts',
    template: `
    <dx-toast
      *ngFor="let toast of toastService.toasts"
      [header]="toast.headertext"
      [class]="toast.classname"
      [autohide]="toast.autohide"
      [displayTime]="toast.delay || 5000"
      (hide)="toastService.remove(toast)"
    >
        <div *dxTemplate="let data of 'content'">
            <p style="background-color:green">Toast content</p>
        </div>
    </dx-toast>
  `,
    host: { '[class.ngb-toasts]': 'true', 'style': 'position: fixed;top: 50px;right: 0;margin: 0.5em;z-index: 1200;' },
})

@Injectable({ providedIn: 'root' })
export class AppToastsComponent {

    constructor(
        private notificationService: NotificationService,
        public toastService: AppToastService,
        private actionLegacyService: ActionLegacyRefreshService,
        public sanitizer: DomSanitizer
    )    
    { }

    showStandard(data: NotificationMessage) {
        let showNotif = true;
        if(data.Action != null && (data.Action.indexOf('App$CalculApp') != -1 || data.Action.indexOf('App$ImportApp') != -1)) {
            this.actionLegacyService.refreshActionAppFromToast.emit(data);
        }
        this.checkLink(data);

        if(showNotif) {
            this.showToast(NotificationType.Info, data);
        }

        this.sendNotificationInHistory(data);
    }

    showSuccess(data: NotificationMessage) {
        if(data.Action != null && data.Action != '') {
            if(data.Action != null && data.Action.indexOf('CommunicationSend') != -1) {
                this.actionLegacyService.refreshCommAppFromToast.emit(data);
            }
            if(data.Action.indexOf('App$') != -1) {
                this.actionLegacyService.refreshActionAppFromToast.emit(data);
            }
            else {
                this.actionLegacyService.refreshActionFromToast.emit(data);
            }
        }

        if(data.ShortName != '' || data.LongName != '') {
            this.checkLink(data);
            this.showToast(NotificationType.Success, data);
            this.sendNotificationInHistory(data);
        }
    }

    showError(data: NotificationMessage) {
        if(data.Action != null && data.Action.indexOf('App$') != -1) {
            this.actionLegacyService.refreshActionAppFromToast.emit(data);
        }
        
        this.checkLink(data);
        this.showToast(NotificationType.Danger, data);
        this.sendNotificationInHistory(data);
    }

    showWarning(data: NotificationMessage) {
        if(data.Action != null && data.Action.indexOf('App$') != -1) {
            this.actionLegacyService.refreshActionAppFromToast.emit(data);
        }

        this.checkLink(data);
        this.showToast(NotificationType.Warning, data);
        this.sendNotificationInHistory(data);
    }

    sendNotificationInHistory(notif: NotificationMessage):void {
        this.notificationService.addNotification(notif);
    }

    checkLink(notif : NotificationMessage)
    {
        if (notif.LongName && notif.LongName.includes("data-href")) {

            var urlLinkMatches = notif.LongName.match("data-href='([0-9a-zA-Z_\/-]*)'");
            if (urlLinkMatches && urlLinkMatches.length > 0) {
                var labelLinkMatches = notif.LongName.match("<a (.*)>(.*)</a>");
                if (labelLinkMatches && labelLinkMatches.length > 0) {
                    notif.docUrl = urlLinkMatches[1];
                    notif.docUrlLabel = labelLinkMatches[2];

                    //render color is different between toast and panel...
                    notif.LongName = notif.LongName.replace("class='text-light'", "");
                    var matches = notif.LongName.match("<a (.*)>(.*?)<\/a>");
                    if (matches && matches.length > 0) {
                        notif.LongName = notif.LongName.replace(matches[0], "");
                    }
                }
            }
        } 
    }

    isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
    isError(toast: any) { return toast && toast.level == "error"; }

    showToast(notificationType : NotificationType, data:any) {
        var options:any;
        switch (notificationType) {
            case NotificationType.Info :
                options = {
                    autohide: true,
                    headertext: data.ShortName,
                    level: 'info',
                    notif: data
                };
                break;
            case NotificationType.Warning:
                options = {
                    classname: 'bg-warning text-light',
                    autohide: true,
                    headertext: data.ShortName,
                    level: 'warning',
                    notif: data
                };
                break;
            case NotificationType.Danger:
                options = {
                    classname: 'bg-danger text-light',
                    autohide: true,
                    headertext: data.ShortName,
                    level: 'error',
                    notif: data
                }
                break;
            case NotificationType.Success:
                options = {
                    classname: 'bg-success text-light',
                    autohide: true,
                    headertext: data.ShortName,
                    level: 'success',
                    notif: data
                };
                break;
        }

        if (data.DelayToast) {
            options.delay = data.DelayToast;
        }

        this.toastService.show(data.LongName, options);
    }
}

enum NotificationType {
    Info,
    Success,
    Warning,
    Danger,
}