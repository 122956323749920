import { lastValueFrom } from "rxjs";
import CustomStore from "devextreme/data/custom_store";
import { ListUtils } from "app/utils/list-utils";
import * as $ from 'jquery';
import { DateUtils } from "app/utils/date-utils";
import { StringUtils } from "app/utils/string-utils";


export class FrontOfficeGridFactory {

    static async getItemFormPerColumn(column:any, ctrl:any) {

        let item : any = {
            dataField: column.dataField,
            label:{
                text: column.caption,
            },
            editorOptions: {
                disabled: !column.allowEditing,
                displayFormat : ''  
            }
        };

        if(column.isRequired && column.allowEditing && column.dataField != 'LongName') {
            item.validationRules = [{ type: "required" }]
        }

        item = 
            (column.dataType == "decimal" || column.dataType == "number") ? this.getItemFormNumber(item, column) : 
            (column.dataType == "DynamicList") ? this.getItemFormDynamicList(item, column, ctrl) :
            (column.dataType == "DateTime" || column.dataType == "date") ? this.getItemFormDate(item) :
            (column.dataType == "bool") ? this.getItemFormBoolean(ctrl, item, column) : 
            (column.dataType == "string" && column.renderType =="textarea") ? this.getItemFormTextarea(item, column) : 
            (column.dataType == "string" && column.renderType =="html") ? this.getItemFormHtml(item, column) : 
            this.getItemFormNoCorresponding(item, column);

        return item
    }

    static getItemFormNumber(item:any, column:any) {
        item.editorType = 'dxNumberBox';
        item.editorOptions = {
            showSpinButtons: false,
            disabled: !column.allowEditing,
            min:column.min,
            max:column.max,
            showClearButton: false,
            step: 0
        }

        if(column.format != null && column.format.toString().indexOf('#') != -1) {
            item.formatNumber = column.format;
        }

        item.editorOptions.onKeyDown = function(e:any) {
            var preventedKeys = [38, 40];
            var keyCode = e.event.keyCode;
            var shouldPreventEvent = !e.component.option("opened") && preventedKeys.indexOf(keyCode) !== -1 && !e.event.altKey;
            if(shouldPreventEvent) {
                e.event.preventDefault();
                e.event.stopImmediatePropagation();
            }
        }

        return item;
    }

    static async getItemFormDynamicList(item:any, column:any, ctrl:any) {
        var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+column.format.replace('#1', '')+'/0.json';
        let items: any = [];
        if (ctrl.storage.Lists[column.format] != null) {
            items = JSON.parse(ctrl.storage.Lists[column.format]);
        }
        else {
            var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+column.format.replace('#1', '')+'/0.json';
            items = await lastValueFrom(ctrl.httpClient.get(url));
            ctrl.storage.Lists[column.format] = JSON.stringify(items);
        }

        items = ListUtils.getOnlyActiveItems(items);

        var store = new CustomStore({
            load:()=> {return items;},
            key:'IdEnumerationItem',
            loadMode: "raw", 
        });
        store.load();
        item.editorType = 'dxSelectBox';
        item.editorOptions = {
            disabled: !column.allowEditing,
            dataSource: store,
            searchEnabled: true,
            displayExpr: 'Label',
            valueExpr: 'IdEnumerationItem',
            deferRendering : true
        }
        if(column.format.indexOf('#1') != -1) {
            item.editorOptions.displayExpr = 'Code';
        }

        return item;
    }

    static getItemFormDate(item:any) {
        item.editorType ='dxDateBox'
        item.editorOptions.displayFormat= "dd.MM.yyyy";
        return item;
    }

    static getItemFormBoolean(ctrl:any, item:any, column:any){    
        column.dataType ='boolean';
        item.editorType = "dxCheckBox";
        item.editorOptions.onValueChanged = async function (e:any) {
            var attribut = {
                Name:item.fullDataField.replace('.IdEnumerationItem',''),
                Value :e.value.toString(),
                Format :item.subPerimeter
            }
            await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName + 'ø' + ctrl.tabName, attribut));
            ctrl.appStructService.updateFormData.emit('ok')
        };

        return item;
    }

    static getItemFormTextarea(item:any, column:any) {    
        item.editorType = "dxTextArea";
        item.colSpan= 2;
        item.editorOptions= {
            height: 90,
            mask:column.mask,
        }

        return item;
    }

    static getItemFormHtml(item:any, column:any) {   
        item.editorType = 'dxHtmlEditor';
        item.colSpan= 2;
        item.editorOptions = {
            disabled: !column.allowEditing,
            height: 500,
            mask:column.mask,
            imageUpload: {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
            },
            toolbar: {
                multiline:false,
                items: 
                [
                    'undo', 'redo', 'separator',
                    {
                    name: 'size',
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                    },
                    {
                    name: 'font',
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                    },
                    'separator', 'bold', 'italic', 'strike', 'underline', 'separator',
                    'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator',
                    'orderedList', 'bulletList', 'separator',
                    {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                    }, 'separator',
                    'color', 'background', 'separator',
                    'link', 'image', 'separator',
                    'clear', 'codeBlock', 'blockquote', 'separator',
                    'insertTable', 'deleteTable',
                    'insertRowAbove', 'insertRowBelow', 'deleteRow',
                    'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
                ],
            },
            mediaResizing: { enabled: true, },
            allowSoftLineBreak :true,
        }
        item.label.location= 'top';
        item.label.showColon=false;

        return item;
    }

    static getItemFormNoCorresponding(item:any, column:any) {
        item.editorOptions.mask=column.mask;
        return item;
    }

    static async refreshData(ctrl:any) {
        if(ctrl.stateId == '') {
          return;
        }
        $('#loadpanel').dxLoadPanel('instance').show();
        var procID = (ctrl.isGridEntity && ctrl.currentProc.IdObject != null) ? ctrl.currentProc.IdObject : 0;
        let gridData = await lastValueFrom(ctrl.applicationGridService.getGridDataByEntityId(ctrl.zoneName, ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName));
        gridData = ctrl.getGridDataWithHasErrorUpdated(gridData);
        FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
    
        var grid = $('#grid-' + ctrl.gridName).dxDataGrid('instance');
        ctrl.gridDataSource = gridData
        if(!ctrl.filterErrorsRows) {
          grid.option("dataSource", gridData);
        }
        else {
            ctrl.filterDataSource =  ctrl.gridDataSource.filter((tb: { HasError: boolean; }) => tb.HasError == true);
          grid.option("dataSource", ctrl.filterDataSource);
        }
    
        grid.refresh();
    
        $('#loadpanel').dxLoadPanel('instance').hide();
    }

    static setGridContextInfo(ctrl:any, gridData:any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl, '/');
    
        for(var i = 0; i<gridData.length; i++) {
          if(gridData[i].ContextInfo == null) {
    
            var module = ctrl.moduleName != undefined ? ctrl.moduleName.length > 2 ? ctrl.moduleName : '' : '';
            var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : ctrl.tabName;
            var bloc = ctrl.order;
            var ligne:string = String(ctrl.isGridEntity ? gridData[i].Id : gridData[i].Id.replace(ctrl.entityIdentifier+'_', ''));
    
            let contextInfo = ctrl.contextInfo.filter((tb: { Module: string, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == bloc && tb.Ligne == ligne).sort((a:any, b:any) => a.Level - b.Level);;
            if(contextInfo.length > 0) {
              gridData[i].ContextInfo = contextInfo[0];
            }   
          }
          else
          {
            var module = ctrl.moduleName != undefined ? ctrl.moduleName.length > 2 ? ctrl.moduleName : '' : '';
            var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : ctrl.tabName;
            var bloc = ctrl.order.toString();
            var ligne:string = String(ctrl.isGridEntity ? gridData[i].Id : gridData[i].Id.replace(ctrl.entityIdentifier+'_', ''));
    
            let contextInfo = ctrl.contextInfo.filter((tb: { Module: string, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == bloc && tb.Ligne == ligne).sort((a:any, b:any) => a.Level - b.Level);;
            if(contextInfo.length == 0) {
              gridData[i].ContextInfo = null;
            }   
          }
        }
    }

    static deleteRow(ctrl:any, rowKey:any, entityId:any, refreshData:boolean, emitMessage: string) {
        var tabName6 = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        ctrl.applicationGridService.deleteAttribut(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName6, rowKey, entityId).subscribe({
          next: async () => {
            setTimeout(async () => {
              if (refreshData) {
                await FrontOfficeGridFactory.refreshData(ctrl);
              }
              ctrl.appStructService.updateFormData.emit(emitMessage)
            }, 1000);
          },
        error: (err: any) => console.log(err)});
    }

    static gridInitNewRow(ctrl:any, info:any) {
        let  columnCount = info.component.columnCount()
        for(var i = 0; i < columnCount; i++) {  
            var column = info.component.columnOption(i);
            if(column.defaultValue && column.defaultValue.length > 0) {
            if(column.dataType == 'DynamicList' && column.dataField.indexOf('Code.') == -1) {
                var list = JSON.parse(ctrl.storage.Lists[column.format]);
                info.data[column.dataField.replace('.IdEnumerationItem','')] = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
            }
            else if(column.dataType == 'date' || column.dataType == 'datetime') {
                info.data[column.dataField] = DateUtils.getDateFromStringOrToday(column.defaultValue);
            }
            else {
                info.data[column.dataField]=column.defaultValue;
            }
            }
            else if (column.dataType == 'boolean' &&  column.defaultValue == true){
            info.data[column.dataField] = true;
            }
            else if(column.dataType == 'number') {
            info.data[column.dataField]= 0
            if(column.min != null)
                info.data[column.dataField]= column.min
            }
            else if(column.dataType == 'date' || column.dataType == 'datetime') {
            info.data[column.dataField] = new Date();
            }
            else if(column.dataType == 'boolean') {
            info.data[column.dataField]= false
            }
            else if(column.dataType == 'string') {
            info.data[column.dataField]= ''
            }
            else if(column.dataType == 'DynamicList' && column.dataField.indexOf('Code.') == -1) {
            var list = JSON.parse(ctrl.storage.Lists[column.format]);
            info.data[column.dataField.replace('.IdEnumerationItem','')]= list[0]
            }
            if(column.dataField == 'Parent') {
            info.data[column.dataField] = null
            }
        }
    }

    static gridFocusedCellChanging(e:any) {
        if(!e.columns[e.newColumnIndex].allowEditing) {  
            e.newColumnIndex+=1;  
            let colCount = e.component.columnCount();  
            for(let i = e.newColumnIndex; i < colCount; i++) {  
                if(e.columns[i] != null && !e.columns[i].allowEditing) {  
                    e.newColumnIndex += 1;  
                } else {  
                    break;  
                }  
            }  
        }
        else if(e.cellElement.parent().hasClass('lecture-seul')) {
            e.newRowIndex += 1;
        }  
    }

    static generateUUID() {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;
            if(d > 0){
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    static async gridSaved(ctrl:any, event:any, refreshData:any){
        if(event.changes.length == 0) {
          return;
        }
    
        switch (event.changes[0].type) {
          case 'update' :
            await FrontOfficeGridFactory.gridOnUpdate(ctrl, event);
            break;
          case 'remove':
            FrontOfficeGridFactory.deleteRow(ctrl, event.changes[0].key, ctrl.entityIdentifier, true, 'refresh');
            break;
          case 'insert':
            await FrontOfficeGridFactory.gridOnInsert(ctrl, event, refreshData);
            break;
        }
    }

    static async gridOnInsert(ctrl: any, event:any, refreshData:any) {
        if(ctrl.isGridEntity) 
            await FrontOfficeGridFactory.gridOnInsertEntityGrid(ctrl, event);
        else 
            await FrontOfficeGridFactory.gridOnInsertNormalGrid(ctrl, event, refreshData);
    }

    static async gridOnInsertNormalGrid(ctrl:any, event: any, refreshData:any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        event.changes[0].data.Id = FrontOfficeGridFactory.generateUUID();
        ctrl.applicationGridService.insertAttribut(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, event.changes[0].data, ctrl.entityIdentifier).subscribe({
            next: async () => {
              //Set the created id of the last inserted values
              await FrontOfficeGridFactory.refreshData(ctrl);
              ctrl.appStructService.updateFormData.emit('refresh')
            },
            error: async (err:any) => {
                console.log(err);
                //Set the created id of the last inserted values
                await FrontOfficeGridFactory.refreshData(ctrl);
                ctrl.appStructService.updateFormData.emit('refresh')
              }
        });
    }

    static async gridOnInsertEntityGrid(ctrl:any, event: any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        
        if(event.changes[0].data.Disabled) {
          event.changes[0].data.Disabled = false;
        }
        ctrl.applicationGridService.insertAttributEntity(ctrl.space,ctrl.version, ctrl.applicationName,ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, event.changes[0].data, ctrl.entityIdentifier, ctrl.isGridEntity).subscribe({
          next: async () => {
            //Set the created id of the last inserted values
            await FrontOfficeGridFactory.refreshData(ctrl);
            ctrl.appStructService.updateFormData.emit('refresh')
          },
          error: (err:any) => console.log(err)
        });
    }

    static async gridOnUpdate(ctrl:any, event: any) {
      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
      await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, event.changes[0].data));
      ctrl.appStructService.updateFormData.emit('ok')
    }

    static setPopupMouseLeave() {
        $("#popup").dxPopover({
            width: 400,
            hideEvent: "mouseleave",
            height: 150   
        }).dxPopover("instance");
    }

    static setStorage(ctrl:any) {
        ctrl.storage = ctrl.localStorageService.getWithExpiration(ctrl.space);
        ctrl.storage = JSON.parse(ctrl.storage);
    
        if (ctrl.storage != null && ctrl.storage.Lists == null){
            ctrl.storage.Lists = {};
        }
    }

    static async onItemClickOrganisationChoice(ctrl:any, data:any, options:any) {
        if(data.itemData.Key == options.value) { return; }
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
        let rows = grid.getVisibleRows();  
        let currentRowGrid = rows[options.rowIndex];
        currentRowGrid.data['Organisation'] = data.itemData.Key;
        await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, currentRowGrid.data));
        ctrl.appStructService.updateFormData.emit('ok')
    }

    static async setDictSub(ctrl:any, item:any) {
        if(item.subPerimeter != '') {
            if(item.visible) {
                var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'RSub' : 'R';

                if(ctrl.dictSub[item.subPerimeter+action] != null) {
                    item.visible = ctrl.dictSub[item.subPerimeter+action];
                }
                else {
                    ctrl.dictSub[item.subPerimeter+action] = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, item.subPerimeter, ctrl.stateId, action);
                    item.visible = ctrl.dictSub[item.subPerimeter + action];
                }
            }
                
            if(item.allowEditing) {
                var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'USub' : 'U';
                if(item.subPerimeter == null)
                    item.subPerimeter = ''
                if(ctrl.dictSub[item.subPerimeter + action] != null) {
                    item.allowEditing = ctrl.dictSub[item.subPerimeter+action];
                }
                else {
                    ctrl.dictSub[item.subPerimeter + action] = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, item.subPerimeter, ctrl.stateId, action);
                    item.allowEditing = ctrl.dictSub[item.subPerimeter+action];
                }
            }
        }
    }

    static async setTabsAndItemsConfigMaster(ctrl:any, masterColumns: any[], tabs:any) {
        for (var i = 0; i < masterColumns.length; i++) {
            var column = masterColumns[i];
            if(column.dataField == 'FkObject' || column.dataField == 'Id' || column.dataField == 'Code')
                continue;
            let item = await FrontOfficeGridFactory.getItemFormPerColumn(column, ctrl)
            var currentTab:any = null;
        
            for(var j=0; j<  tabs.length; j++) {
                if( tabs[j].Name == column.tabName)
                currentTab =  tabs[j];
            }
        
            if(currentTab == null) {
                currentTab = {};
                currentTab.items = [];
                currentTab.items.push(item);
                currentTab.Name = column.tabName;
                tabs.push(currentTab);
            }
            else {
                if(item.colSpan== 2) {
                    let previousItem = currentTab.items[currentTab.items.length-1];
                    previousItem.colSpan = 2;
                }
                currentTab.items.push(item);
            }
        } 
    }

    static configureGridColumnsWidthFromGridConfigColumnWidth(gridConfiguration:any) {
        var visibleColumns = gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
        for(var i = 0; i<visibleColumns.length;i++) {
            var style = visibleColumns[i].style;
            if(style) {
                style = style.substring(style.indexOf('width'))
                style = style.split(';')[0]
                visibleColumns[i].width = style.replace('width:','');
            }
        }
    }

    static configureGridColumnsWidthWithSpecificColumns(gridConfiguration:any) {
        if((gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') == -1))
        {
          var visibleColumns = gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
          for(var i = 0; i<visibleColumns.length;i++){
            visibleColumns[i].width = 100/visibleColumns.length+'%';
          }
        }
        else if((gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfiguration.columns.filter((tb: {style: string; visible: boolean; }) => tb.visible && (tb.style == '' ||   tb.style.indexOf('width') == -1)).length > 0))
        {
          var visibleColumns = gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
          for(var i = 0; i<visibleColumns.length;i++)
          {
            var style = visibleColumns[i].style;
            if(style)
            {
              style = style.substring(style.indexOf('width'))
              let width = style.split(';')[0];
              visibleColumns[i].style = style.replace(width+';','');
            }
            
            visibleColumns[i].width = 100/visibleColumns.length+'%';
          }
        }
        else if(gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') != -1)
        {
          FrontOfficeGridFactory.configureGridColumnsWidthFromGridConfigColumnWidth(gridConfiguration);
        }
    }

    static async setCellEnumerationItem(ctrl:any, item:any) {    
        item.dataField = item.dataField.replace('.IdEnumerationItem', '');
        item.dataField = item.dataField.split('.')[item.dataField.split('.').length-1];
        
        let items: any = [];
        if (ctrl.storage.Lists[item.format] != null) {
          items = JSON.parse(ctrl.storage.Lists[item.format]);
        }
        else {
          var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+item.format.replace('#1', '')+'/0.json';
          items = await lastValueFrom(ctrl.httpClient.get(url));
          ctrl.storage.Lists[item.format] = JSON.stringify(items);
        }
    
        items = ListUtils.getOnlyActiveItems(items);
    
        var store = new CustomStore({
          load:()=> {return items;},
          key:'IdEnumerationItem',
          loadMode: "raw", 
        });
        store.load();
        if(item.dataField != 'Code') {
          item.dataField = item.dataField+'.IdEnumerationItem';
          item.lookup = {
            displayExpr: 'Label',
            valueExpr: 'IdEnumerationItem',
            deferRendering : true,
            dataSource: store,
          }
        }
        else {
          item.dataField = item.dataField+'.Label';
        }
    }

    static async setColumnIsIncludedInDatagridOrMaster(ctrl:any, column:any) {
        let item = await FrontOfficeGridFactory.getItemFormPerColumn(column, ctrl)
        var currentTab:any = null;
        for(var j=0; j < ctrl.editiontabs.length; j++) {
          if( ctrl.editiontabs[j].title == column.tabName) {
            currentTab =  ctrl.editiontabs[j];
          }
        }

        if(currentTab == null) {
          currentTab = {};
          currentTab.items = [];
          currentTab.items.push(item);
          currentTab.title = column.tabName;
          ctrl.editiontabs.push(currentTab);
        }
        else {
          if(item.colSpan== 2) {
            let previousItem = currentTab.items[currentTab.items.length-1];
            previousItem.colSpan = 2;
          }
          currentTab.items.push(item);
        }
    }

    static async setColumnsCaptions(ctrl:any, specificColumns:any) {
        let columns = [];

        for(var i = 0; i<specificColumns.length;i++) {
            var column = specificColumns[i];
            columns.push(column.caption);
        }

        let changesColumns:any = await lastValueFrom(ctrl.componentService.getColumnsAttributs(ctrl.space, columns, parseInt(ctrl.entityIdentifier)));
        
        for(var i = 0; i<specificColumns.length;i++) {
            var column = specificColumns[i];
            column.caption = changesColumns[column.caption]
        }
    }
}