import {  Injectable, OnDestroy } from "@angular/core";
import * as $ from 'jquery';
import { NotificationMessage } from "app/shared/NotificationMessage";
import { AnnotationType } from "devextreme/common/charts";

@Injectable({ providedIn: 'root' })
export class PopupService implements OnDestroy {
    constructor() {}
    popup: any;

    ngOnDestroy(): void {
        throw new Error("Method not implemented.");
    }

    openDetailOfNotification(notification: NotificationMessage) {
        this.showPopup(notification.NameInModal, notification.Description, 'fit-content');
    }

    showPopup(title: string, htmlContent: any, width: any = 500) {
        this.popup = $("#popup-action").dxPopup({
            height: "auto",
            width: width,
            showTitle: true,
            title: title,
            visible: true,
            resizeEnabled: true,
            deferRendering: false,
            contentTemplate: htmlContent,
        }).dxPopup("instance"); 

        this.popup.show();

        return this.popup;
    }

    closePopup() {
        this.popup.hide();
    }
}