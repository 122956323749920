import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import * as $ from 'jquery';
import DataSource from "devextreme/data/data_source";
import { EntityService } from "app/shared/entity.service";
import { IEntity } from "app/shared/entity";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { lastValueFrom } from "rxjs";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'panel-selecteur-view',
    templateUrl: './panel-selecteur-view.component.html',
    styleUrls: ['./panel-selecteur-view.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelSelecteurViewComponent implements OnInit, OnDestroy, OnChanges {
    @Input() space: string = '';
    
    @Output() specificType: string;
    @Input() version: string = '';
    fullyLoaded: boolean = false;
    @Input() entityAnchorsBase: IEntity[] = [];
    entityAnchors: IEntity[] = [];
    entities : any[];
    fullEntities : any[];
    constructor(
      private entityService: EntityService,
      private applicationStructService: FrontOfficeStructService,)
    {}

    async ngOnChanges(changes: any) {
        if (changes.space && this.fullyLoaded) {
            this.fullyLoaded = false;
            this.entities =null
            this.fullEntities = null
          }
        
        if (changes.entityAnchorsBase && !this.fullyLoaded) {
            await this.loadPanel();
        }
    }

    async ngOnInit(): Promise<void> {
        this.ngOnDestroy(); 
    }

    async loadPanel() {
        var ctrl = this;

        if(this.version == null || this.version.length == 0 || this.space == null || this.space.length == 0) {
            return;
        }

        this.fillEntityAnchors();
        
        await this.fillEntities();

        var ds = new DataSource({  
            store: ctrl.entities,   
            paginate: false  
        });

        let tempAnchor: number[] =[];
        this.entityAnchors.forEach((e) => {
            tempAnchor.push(ctrl.entities.filter((tb: { IdObject: number; }) => e.IdObject == tb.IdObject)[0]);
        });

        let firstLoad = true;

        var configTree = this.getConfigTree(firstLoad);

        this.setPanelSelectorTree(ds, configTree, tempAnchor);
    }

    async fillEntities() {
        let tempE: IEntity = null;

        var ctrl = this;
        if(this.entityAnchors.length == 0 || this.entityAnchors[0].IdObject == 0)
        {
            this.fullEntities = await lastValueFrom(this.entityService.getEntitiesAnchorTree(this.space, 1));
            this.entities = this.fullEntities;
        }
        else
        {
            let ids: any[] = [];
            let typesUse: any[] = [];
            let tempEntities: IEntity[] = [];
            let lastEntityId = 0;
            for(var i = 0; i<ctrl.entityAnchors.length; i++) {
                var e = ctrl.entityAnchors[i];
                ids.push(e.IdObject);
                if(e.IdObject> 0) {
                    lastEntityId = e.IdObject;
                    if(this.entities == null) {
                        tempE = await lastValueFrom(this.entityService.getCurrentEntity(this.space, e.IdObject.toString()));
                    }

                    if(tempE != null) {
                        tempEntities.push(tempE)
                    }
                }
            };

            this.fullEntities = await lastValueFrom(this.entityService.getEntitiesAnchorTree(this.space, lastEntityId));
            this.entities = this.fullEntities;
            tempEntities.forEach(async (e) => {
                ctrl.entities.push(e)
            });

            var types = ctrl.entities.filter((th: { IdObject: number; })  => th.IdObject < 0 );
            let idsTemp: number[] = [];
            idsTemp.push(lastEntityId)

            do
            {
                ctrl.entities.filter((th: { Parent: number; IdObject: number; })  => idsTemp.findIndex(v => v == th.Parent) > -1 ).forEach((e) => {
                    ids.push(e.IdObject);
                    idsTemp.push(e.IdObject)
                });
                idsTemp = [];
            }while(idsTemp.length > 0)

            types.forEach((e) => {
                typesUse.push(e.IdObject);
            });
            ctrl.entities = ctrl.entities.filter((th: { IdObject: number; })  => ids.findIndex(v => v == th.IdObject) > -1 ||  typesUse.findIndex(v => v == th.IdObject) > -1);
        }

        if(ctrl.entityAnchors.findIndex(v => v.IdObject == -999) > -1)
        {
            let fostates = await lastValueFrom(this.applicationStructService.getListFormState('vdsash_legacy'));
            if(ctrl.entityAnchors.findIndex(v => v.IdObject <=-10000 && v.EntityType.indexOf('Fo#')> -1) != -1)
            {
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => ctrl.entityAnchors.findIndex(v => v.IdObject == th.IdObject)  > -1);

                var foStatus =  ctrl.entityAnchors.filter(v => v.IdObject <=-10000 && v.EntityType.indexOf('Fo#')> -1)[0];
                var i = -10000;
                fostates.forEach((e: { Id: string;Label: string; }) => {
                    if(foStatus.IdObject == i)  {
                        ctrl.entities.push(this.getEntityObjectFromFrontOfficeState(i, e));
                    }
                    i--;
                });
            }
            else
            {
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => ctrl.entityAnchors.findIndex(v => v.IdObject == th.IdObject)  > -1 || th.IdObject < 0);

                var i = -10000;
                fostates.forEach((e: { Id: string;Label: string; }) => {
                    ctrl.entities.push(this.getEntityObjectFromFrontOfficeState(i, e));
                    i--;
                });
            }               
        }

        if(ctrl.entityAnchors.findIndex(v => v.EntityType.indexOf('Bo#')> -1) != -1)
        {
            var boStatus = ctrl.entityAnchors.filter(v => v.EntityType.indexOf('Bo#')> -1)[0];
            ctrl.entities = ctrl.entities.filter((th: {
                EntityType: any; IdObject: number; 
            })  => th.EntityType.indexOf('Bo#') == -1 || th.IdObject == boStatus.IdObject);
        }

        if(ctrl.entityAnchors.findIndex(v => v.EntityType.indexOf('Tr#')> -1) != -1)
        {
            var boStatus = ctrl.entityAnchors.filter(v => v.EntityType.indexOf('Tr#')> -1)[0];
            ctrl.entities = ctrl.entities.filter((th: {
                EntityType: any; IdObject: number; 
            })  => th.EntityType.indexOf('Tr#') == -1 || th.IdObject == boStatus.IdObject);
        }

        if(ctrl.entityAnchors.findIndex(v => v.IdObject < 0 && v.IdObject != -999 && v.EntityType.indexOf('#')== -1) != -1)
        {
            var specStatus = ctrl.entityAnchors.filter(v => v.IdObject < 0 && v.IdObject != -999 && v.EntityType.indexOf('#')== -1)[0];
            ctrl.entities = ctrl.entities.filter((v: {
                EntityType: any; IdObject: number; 
            })  => v.IdObject > 0 || v.IdObject == -999  || v.EntityType.indexOf('#')> -1 || v.IdObject == specStatus.IdObject);
        }
    }

    getConfigTree(firstLoad:any) {
        var ctrl=this;
        var configTree = {
            dataSource: ctrl.entities,
            width:'100%',
            selectionMode: 'multiple',
            displayExpr: 'LongName',
            dataStructure: 'plain',
            parentIdExpr: 'Parent',
            searchEnabled: true,
            multiline: false,
            acceptCustomValue :true,
            rootValue : 0,
            searchExpr: ['LongName'],
            keyExpr: 'IdObject',
            createChildren : <Function>null,
            placeholder :'Périmètre de travail',
            onValueChanged: async function (data:any)
            {
                if (data.value.length == data.previousValue.length) {
                    if (data.event != undefined && data.event.currentTarget != undefined && data.event.currentTarget.className != undefined && data.event.currentTarget.className == 'dx-tag-remove-button') {
                        var elementToRemoveName = data.event.currentTarget.parentElement.innerText;

                        data.value = data.value.filter((x:any) => x.LongName !== elementToRemoveName);

                        var tag = $('#panel-selector-tree').dxTagBox(<string><unknown>configTree).dxTagBox('instance')
                        tag.option('value', data.value)
                        tag.repaint();
                    }
                }

                await ctrl.configTreeOnValueChanged(firstLoad, data, configTree)
            }
        };

        return configTree;
    }

    async configTreeOnValueChanged(firstLoad:any, data:any, configTree:any) {
        var ctrl = this;
        // if(firstLoad)
        // {
        //     firstLoad = false;
        //     return;
        // }
        var previous = data.previousValue;
        var actualValue = data.value;
        if(previous.length < actualValue.length)
        {
            var newAdd = actualValue.filter((th: { IdObject: number; })  => previous.findIndex((pr: { IdObject: number; }) =>pr.IdObject == th.IdObject) == -1 )[0];
            //Normal hierachie
            if(newAdd.IdObject > 0)
            {
                let ids: any[] = [];
                let typesUse: any[] = [];
                ctrl.entityAnchors.forEach((e) => {
                    ids.push(e.IdObject);
                });

                var types = ctrl.entities.filter((th: { IdObject: number; })  => th.IdObject < 0 && th.IdObject != -999 );
                let idsTemp: number[] = [];
                idsTemp.push(ctrl.entityAnchors[ctrl.entityAnchors.length -1].IdObject)
                do
                {
                    ctrl.entities.filter((th: { Parent: number; IdObject: number; })  => idsTemp.findIndex(v => v == th.Parent) > -1 ).forEach((e) => {
                    ids.push(e.IdObject);
                    idsTemp.push(e.IdObject)
                    });
                    idsTemp = [];
                }while(idsTemp.length > 0)

                types.forEach((e) => {
                    typesUse.push(e.IdObject);
                });
                let tempEntities: IEntity[] = [];
                ctrl.fullEntities = await lastValueFrom(ctrl.entityService.getEntitiesAnchorTree(ctrl.space, newAdd.IdObject));

                for(var i = 0; i<ctrl.entityAnchors.length; i++)
                {
                    var e = ctrl.entityAnchors[i];
                    ids.push(e.IdObject);
                    if(e.IdObject> 0)
                    {
                        var tempE = await lastValueFrom(ctrl.entityService.getCurrentEntity(ctrl.space, e.IdObject.toString()));
                        tempEntities.push(tempE)
                    }
                };
                ctrl.entities = ctrl.fullEntities;
                tempEntities.forEach(async (e) => {
                    ctrl.entities.push(e)
                });
                ctrl.entityAnchors = actualValue;
            }
            else if(newAdd.IdObject == -999)
            {
                //Current context
                actualValue = actualValue.filter((th: { IdObject: number; })  => th.IdObject == -999 || th.IdObject == 1 );
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => ctrl.entityAnchors.findIndex(v => v.IdObject == th.IdObject)  > -1);

                let fostates = await lastValueFrom(ctrl.applicationStructService.getListFormState('vdsash_legacy'));
                var i = -10000;
                fostates.forEach((e: { Id: string;Label: string; }) => {
                    ctrl.entities.push(ctrl.getEntityObjectFromFrontOfficeState(i, e));
                    i--;
                });
                var tag = $('#panel-selector-tree').dxTagBox(<string><unknown>configTree).dxTagBox('instance');
                var originalOnValueChanged = tag.option('onValueChanged');
                tag.option('onValueChanged', null);
                tag.option('value', ctrl.entityAnchors);
                tag.option('onValueChanged', originalOnValueChanged);
                tag.repaint();
            }
            else if(newAdd.EntityType.indexOf('Fo#') > -1)
            {
                //Status Fo
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => ctrl.entityAnchors.findIndex(v => v.IdObject == th.IdObject)  > -1);
            }
            else if(newAdd.EntityType.indexOf('Bo#') > -1)
            {
                //Status Bo
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => th.EntityType.indexOf('Bo#') == -1 || newAdd.IdObject == th.IdObject);
            }
            else if(newAdd.EntityType.indexOf('Tr#') > -1)
            {
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((th: {
                    EntityType: any; IdObject: number; 
                })  => th.EntityType.indexOf('Tr#') == -1 || newAdd.IdObject == th.IdObject);
            }
            else if(newAdd.IdObject < 0 && newAdd.EntityType.indexOf('#')== -1)
            {
                //entity type
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((v: {
                    EntityType: any; IdObject: number; 
                })  => v.IdObject > 0 || v.IdObject == -999  || v.EntityType.indexOf('#')> -1 || v.IdObject == newAdd.IdObject);
            }
        }
        else
        {
            var newRemove = previous.filter((th: { IdObject: number; })  => actualValue.findIndex((pr: { IdObject: number; }) =>pr.IdObject == th.IdObject) == -1 )[0];
            if(newRemove != null && newRemove.Parent == null) {
                newRemove = ctrl.entityAnchorsBase.filter((th: { IdObject: number; })  => th.IdObject == newRemove.IdObject)[0];
            }
            if(newRemove != null && newRemove.IdObject > 0)
            {
                let ids: any[] = [];
                let typesUse: any[] = [];
                ctrl.entityAnchors.forEach((e) => {
                    ids.push(e.IdObject);
                });

                var types = ctrl.entities.filter((th: { IdObject: number; })  => th.IdObject < 0 && th.IdObject != -999 );
                let idsTemp: number[] = [];
                idsTemp.push(ctrl.entityAnchors[ctrl.entityAnchors.length -1].IdObject)
                do
                {
                    ctrl.entities.filter((th: { Parent: number; IdObject: number; })  => idsTemp.findIndex(v => v == th.Parent) > -1 ).forEach((e) => {
                    ids.push(e.IdObject);
                    idsTemp.push(e.IdObject)
                    });
                    idsTemp = [];
                }while(idsTemp.length > 0)

                types.forEach((e) => {
                    typesUse.push(e.IdObject);
                });

                let tempEntities: IEntity[] = [];
                ctrl.fullEntities = await lastValueFrom(ctrl.entityService.getEntitiesAnchorTree(ctrl.space, newRemove.Parent));

                for(var i = 0; i<ctrl.entityAnchors.length; i++) {
                    var e = ctrl.entityAnchors[i];
                    ids.push(e.IdObject);
                    if(e.IdObject> 0) {
                        tempE = await lastValueFrom(ctrl.entityService.getCurrentEntity(ctrl.space, e.IdObject.toString()));
                        tempEntities.push(tempE)
                    }
                };
                ctrl.entities = ctrl.fullEntities;
                tempEntities.forEach(async (e) => {
                    ctrl.entities.push(e)
                });
                ctrl.entityAnchors = actualValue;
            }
            else if(newRemove != null && newRemove.EntityType.indexOf('Fo#') > -1)
            {
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.entities.filter((v: {
                    EntityType: any; IdObject: number; 
                })  => v.IdObject != newRemove.IdObject);
                
                let fostates = await lastValueFrom(ctrl.applicationStructService.getListFormState('vdsash_legacy'));
                var i = -10000;
                fostates.forEach((e: { Id: string;Label: string; }) => {
                    ctrl.entities.push(ctrl.getEntityObjectFromFrontOfficeState(i, e));
                    i--;
                });
            }
            else if(newRemove != null && newRemove.EntityType.indexOf('Bo#') > -1)
            {
                //Status Bo
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.fullEntities;
            }
            else if(newRemove != null && newRemove.EntityType.indexOf('Tr#') > -1)
            {
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.fullEntities;
            }
            else if(newRemove != null && newRemove.IdObject < 0 && newRemove.EntityType.indexOf('#')== -1)
            {
                ctrl.entityAnchors = actualValue;
                ctrl.entities = ctrl.fullEntities;
            }
        }
        if(ctrl.entityAnchors.length > 0) {
            if(ctrl.entityAnchors[ctrl.entityAnchors.length -1].IdObject > 0 || ctrl.entityAnchors[ctrl.entityAnchors.length -1].IdObject == -999) {
                ctrl.entityService.setEntityAnchor(ctrl.space, ctrl.entityAnchors[ctrl.entityAnchors.length -1], ctrl.entityAnchors);
            }
            else if(ctrl.entityAnchors.findIndex(v => v.IdObject <=0 && v.EntityType.indexOf('#')== -1))
            {
                let temp = ctrl.entityAnchors.filter((th: { IdObject: number; })  => th.IdObject > 0 || th.IdObject == -999);
                if(temp.length > 0) {
                    ctrl.entityService.setEntityAnchor(ctrl.space, temp[temp.length -1], ctrl.entityAnchors);
                }
            }
            ctrl.entityAnchors = data.value;
        }
        else
        {
            ctrl.fullEntities = await lastValueFrom(ctrl.entityService.getEntitiesAnchorTree(ctrl.space, 1));
            ctrl.entities = ctrl.fullEntities;
            ctrl.entityService.setEntityAnchor(ctrl.space, {
                IdObject : 0,
                EntityType :'OP',
                CalculationTime : null,
                CreationTime: null,
                HasNextLevel : true,
                IsMain : false,
                IsPublished :false,
                IsSpecial : false,
                longName:'da',
                ModificationTime : null,
                Name : ',,,',
                Owner :'dd',
                Status : 'D',
                ValidationTime:null,
                Parent:4
            }, ctrl.entityAnchors);
        }
        var dsr = new DataSource({  
            store: ctrl.entities,   
            paginate: false  
        }); 
        $('#panel-selector-tree').dxTagBox('instance').option('dataSource', dsr);
    }

    fillEntityAnchors() {
        this.entityAnchors = [];
        this.entityAnchorsBase.filter((th: { IdObject: number; })  => th!= null && th.IdObject > 0 ).sort((a, b) => a['IdObject'] > b['IdObject'] ? 1 : a['IdObject'] === b['IdObject'] ? 0 : -1).forEach((e) => {
            if( e != null && (typeof e !== 'undefined')) {
                this.entityAnchors.push(e);
            }
        });
        
        this.entityAnchorsBase.filter((th: { IdObject: number; })  => th!= null && th.IdObject == -999 ).sort((a, b) => a['IdObject'] > b['IdObject'] ? 1 : a['IdObject'] === b['IdObject'] ? 0 : -1).forEach((e) => {
            if( e != null && (typeof e !== 'undefined')) {
                this.entityAnchors.push(e);
            }
        });
        
        this.entityAnchorsBase.filter((th: { IdObject: number; })  => th!= null && th.IdObject < 0 && th.IdObject != -999 ).forEach((e) => {
            if( e != null && (typeof e !== 'undefined')) {
                this.entityAnchors.push(e);
            }
        });
    }

    ngOnDestroy(): void {}

    getEntityObjectFromFrontOfficeState(i:any, e:any) {
        var entity : any = {
            IdObject : i,
            EntityType : 'Fo#' + e.Id,
            CalculationTime : null,
            CreationTime : null,
            HasNextLevel : true,
            IsMain : false,
            IsPublished : false,
            IsSpecial : false,
            LongName : '#' + StringUtils.decodeSpecialEntitiesHtml(e.Label) +" - FO",
            ModificationTime : null,
            Name : ',,,',
            Owner :'dd',
            Status : 'D',
            ValidationTime : null,
            Parent : -1
        };

        return entity;
    }

    setPanelSelectorTree(ds:any, configTree:any, tempAnchor:any) {
        $('#panel-selector-tree').dxTagBox({
            dataSource: ds,
            width:'500px',
            displayExpr: 'LongName',
            searchEnabled: true,
            searchExpr: ['LongName']
        }).dxTagBox('instance')


        this.fullyLoaded = true;
        setTimeout(()=> {
            var tag = $('#panel-selector-tree').dxTagBox(<string><unknown>configTree).dxTagBox('instance')
            
            tag.option('value', tempAnchor)
            tag.repaint();
            this.fullyLoaded = true;
        }, 1000);
    }
}