// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-mobile.navbar{
    background-color: rgb(0, 173, 230);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
    z-index: 2;
}
.is-mobile.navbar::before{
    content: none;
}

.mobile{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 6rem;
    background: rgb(255, 255, 255);
}

.button-calcul-mobile.dx-button-mode-contained,
.button-calcul-mobile.dx-button-mode-contained .dx-button-content .dx-icon{
    color: #fff !important;
}

:host{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
}

#appStructPanelMobile{
    overflow-y: auto;
    text-align: center;
    flex-grow: 1;
    padding-bottom: 4rem;
    height: 100vh;
}`, "",{"version":3,"sources":["webpack://./app/front-office/front-office-struct-mobile.component.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,UAAU;AACd;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,oBAAoB;IACpB,8BAA8B;AAClC;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".is-mobile.navbar{\r\n    background-color: rgb(0, 173, 230);\r\n    position: fixed;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    height: 4rem;\r\n    z-index: 2;\r\n}\r\n.is-mobile.navbar::before{\r\n    content: none;\r\n}\r\n\r\n.mobile{\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex-grow: 1;\r\n    padding-bottom: 6rem;\r\n    background: rgb(255, 255, 255);\r\n}\r\n\r\n.button-calcul-mobile.dx-button-mode-contained,\r\n.button-calcul-mobile.dx-button-mode-contained .dx-button-content .dx-icon{\r\n    color: #fff !important;\r\n}\r\n\r\n:host{\r\n    display: flex;\r\n    flex-direction: column;\r\n    position: relative;\r\n    flex-grow: 1;\r\n}\r\n\r\n#appStructPanelMobile{\r\n    overflow-y: auto;\r\n    text-align: center;\r\n    flex-grow: 1;\r\n    padding-bottom: 4rem;\r\n    height: 100vh;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
