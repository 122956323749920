import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {
    private localStorage: Storage;

    private changes$ = new Subject();

    constructor() {
        this.localStorage = window.localStorage;
    }

    getWithExpiration(key: string): any {
        if (this.isLocalStorageSupported) {
            var itemStr = this.localStorage.getItem(key);
            const item = JSON.parse(<string>itemStr)
            if (!item)
                return null;
            const now = new Date()
            if (!item.expiry || now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null;
            }
            if (item.value)
                return JSON.parse(<string>item.value);
            else
                return null;
        }

        return null;
    }

    get(key: string): any {
        if (this.isLocalStorageSupported) {
            var itemStr = this.localStorage.getItem(key);
            if(itemStr){
                const item = JSON.parse(<string>itemStr)
                if (!item)
                    return null;
                else
                    return item;
            }
            else{
                return null;
            }
        }

        console.warn('localstorage not supported');
        return null;
    }

    setWithExpiration(key: string, value: any, cachePolicy: string): boolean {
        
        if (this.isLocalStorageSupported) {
            var now = new Date();
            var itemStr = this.localStorage.getItem('SPAConfiguration');
            var configuration = JSON.parse(<string>itemStr);
            var timeout = 0;
            if (cachePolicy == 'CallTimout')
                timeout = configuration.CachePoliciesCallTimeout;
            else if (cachePolicy == 'TestTimout')
                timeout = configuration.CachePoliciesTestTimeout;
            
                var item = {
                value: JSON.stringify(value),
                expiry: now.getTime() + timeout,
            }
            
            this.localStorage.setItem(key, JSON.stringify(item));
            this.changes$.next({
                type: 'set',
                key,
                value
            });
            return true;
        }
        return false;
    }

    hasKey(key: string)
    {
        if (this.isLocalStorageSupported) {
            var itemStr = this.localStorage.getItem(key);
            return itemStr != null;
        }
        return false;
    }

    set(key: string, value: any): boolean {
        if (this.isLocalStorageSupported) {
            var now = new Date();

            this.localStorage.setItem(key, JSON.stringify(value));
            this.changes$.next({
                type: 'set',
                key,
                value
            });
            return true;
        }

        return false;
    }

    remove(key: string): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.removeItem(key);
            this.changes$.next({
                type: 'remove',
                key
            });
            return true;
        }

        return false;
    }

    get isLocalStorageSupported(): boolean {
        return !!this.localStorage
    }
}