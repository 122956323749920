import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadService {

    constructor(private http: HttpClient) { }

    download(url: string):Observable<any>
    {
        return this.http.get(url, {
            responseType: 'blob',
            observe: 'events',
            reportProgress: true
        }).pipe(
            map(event => this.getEventMessage(event)),
            catchError(this.handleError)
        );
    }

    downloadWithObject(url: string, object:any):Observable<any>
    {
        return this.http.post(url, object, {
            responseType: 'blob',
            observe: 'events',
            reportProgress: true            
        })
    }

    private getEventMessage(event: any): any {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            return { progress: Math.round((100 * event.loaded) / event.total) };
    
          case HttpEventType.Response:
            return { body: event.body, headers: event.headers };
    
          default:
            return `Unhandled event: ${event.type}`;
        }
    }

    private handleError(error: any): Observable<any> {
        console.error('Download error:', error);
        return of({ error: true, message: error.message });
    }
}