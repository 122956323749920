import { Component, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import {  faClose } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from "@angular/platform-browser";
import { DownloadService } from "../../shared/download.service";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { AuthService } from "app/shared/auth.service";
import * as moment from "moment";
import { lastValueFrom } from "rxjs";
import { NgbdModalConfirmationComponent } from "app/front-office/modal-confirmation.component";
import { Subscription } from "rxjs-compat";

@Component({
    selector: 'panel-context-app',
    templateUrl: './panel-context-app.component.html',
    styleUrls: ['./panel-context-app.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelContextAppComponent implements OnInit, OnDestroy {

    @Input() isMobile: boolean = false;
    faClose = faClose;
    isOpened:boolean = false;
    information: string = '';
    heightCssPropertyInfo: string = '';
    heightCssPropertyComment: string = '';
    heightCssPropertyDocument: string = '';
    heightCssPropertyControl: string = '';
    sub!: Subscription;
    me: string = '';
    item: any;
    currentComment: any;
    activePanel: string = '';
    isMultiRowManagementMode: boolean = false;
    showInformation: boolean = false;
    infoActiv: boolean = false;
    firstCom: boolean = false;
    showComment: boolean = false;
    commentActiv: boolean = false;
    docActiv: boolean = false;
    controlActiv: boolean = false;
    showFile: boolean = false;
    deleteFile: boolean = false;
    addFile: boolean = false;
    deleteComments: boolean = false;
    deleteDocuments: boolean = false;
    updateComments: boolean = false;
    commentIsAdding: boolean = false;
    documentIsAdding: boolean = false;
    comments: any[] = [];
    documents: any[] = [];
    title: string = '';
    formats: string[] = [];
    tabs: any[] =[];
    showControl: boolean = false;
    controleType: string = '';
    constructor(
        private downloadService: DownloadService,
        private appStructService:  FrontOfficeStructService,
        private authService: AuthService,
        private confirmationModalComponent: NgbdModalConfirmationComponent,
        public sanitizer: DomSanitizer) {
        $("#panelContextInfo").hide();
        
    }

    isUpdate(comment :any){
        this.currentComment = comment;
      }

    ngOnInit(): void {
        var ctrl = this;
        this.appStructService.updateContextPanel.subscribe(async (item:any) => {

            this.isMultiRowManagementMode = item.IsMultiRowManagement !== undefined && item.IsMultiRowManagement;
            if (this.isMultiRowManagementMode) {
                var isNormalOpening = item.MultiRowNormalOpening !== undefined && item.MultiRowNormalOpening;
                if (!isNormalOpening) {
                    if (this.isOpened){
                        if (item.SelectedObjects.length <= 1) {
                            this.closePanel();
                            return;
                        }
                    }
                    else{
                        return;
                    }
                }
            }


            if( ctrl.item != null && this.currentComment != null ) {
                this.saveCommentDirectly(this.currentComment);
            }
            this.ngOnDestroy();
            this.tabs =[];
            ctrl.showControl = false;
            this.heightCssPropertyComment = '';
            this.heightCssPropertyDocument = '';
            this.heightCssPropertyInfo = '';
            this.heightCssPropertyControl ='';
            ctrl.item = item;
            this.documents = [];
            this.comments = [];
            this.formats = [];
            this.activePanel = '';
            ctrl.activePanel = '';

            if (this.isMultiRowManagementMode) {
                this.title = 'Ajouter à la sélection (' + this.item.SelectedObjects.length + ' lignes)';
                document.getElementById('divPanelTitle').style.backgroundColor = '#00ade6';
                document.getElementById('divPanelTitle').style.color = 'white';
            }
            else {
                document.getElementById('divPanelTitle').style.backgroundColor = '';
                document.getElementById('divPanelTitle').style.color = '';
                if(item.Type =='TableForm') {
                    this.title = item.Tab +' : ' + item.Value.Numbering;
                }
                if(item.Type =='Grid') {

                    if (item.Value.Code != null && item.Value.Code.Label != null) {
                        this.title = item.Tab.replace('ø','/') + ' : ' + item.Value.Code.Label;
                    }
                    else {
                        this.title = item.Tab.replace('ø','/') + ' : <br> ' + item.Value.LongName;
                    }
                }
            }

            ctrl.information = $("<p/>").html(item.Value.Tooltip).text();
            ctrl.showInformation = item.Value.TooltipEnabled;
            ctrl.showComment = item.Value.AllowComment;
            ctrl.showFile = item.Value.AllowFile;
            if(item.ContextInfo != null) {
                ctrl.showControl = item.ContextInfo.Description && item.ContextInfo.Description != '';
            }

            ctrl.manageDisplaying();

            if (this.isMultiRowManagementMode) {
                await ctrl.initMultiRowManagement(item);
            }
            else {
                await ctrl.initNormalMode(item);
            }

        })
    }

    manageDisplaying() {
        var ctrl = this;

        if(ctrl.showComment && !ctrl.showFile && !ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(100vh - 210px)'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyControl ='0px';
        }
        else if(!ctrl.showComment && !ctrl.showFile && ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyInfo = 'calc(100vh - 210px)'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyControl ='0px';
        }
        else if(!ctrl.showComment && ctrl.showFile && !ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyDocument = 'calc(100vh - 210px)'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyControl ='0px';
        }
        else if(!ctrl.showComment && !ctrl.showFile && !ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyControl = 'calc(100vh - 210px)'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyDocument ='0px';
        }
        else if(ctrl.showComment && ctrl.showFile && ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(60vh - 210px)'
            this.heightCssPropertyDocument = '20vh'
            this.heightCssPropertyInfo = '12vh'
            this.heightCssPropertyControl ='8vh';
        }
        else if(ctrl.showComment && ctrl.showFile && ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(60vh - 210px)'
            this.heightCssPropertyDocument = '25vh'
            this.heightCssPropertyInfo = '15vh'
            this.heightCssPropertyControl ='0px';
        }
        else if(!ctrl.showComment && ctrl.showFile && ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyDocument = 'calc(80vh - 210px)'
            this.heightCssPropertyInfo = '20vh'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyControl ='0px';
        }
        else if(ctrl.showComment && !ctrl.showFile && ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(80vh - 210px)'
            this.heightCssPropertyInfo = '20vh'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyControl ='0px';
        }
        else if(!ctrl.showComment && ctrl.showFile && ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyDocument = 'calc(70vh - 210px)'
            this.heightCssPropertyInfo = '20vh'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyControl ='10vh';
        }
        else if(ctrl.showComment && !ctrl.showFile && ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(70vh - 210px)'
            this.heightCssPropertyInfo = '20vh'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyControl ='10vh';
        }
        else if(!ctrl.showComment && ctrl.showFile && ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyDocument = 'calc(70vh - 210px)'
            this.heightCssPropertyInfo = '20vh'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyControl ='10vh';
        }
        else if(ctrl.showComment && !ctrl.showFile && !ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(80vh - 210px)'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyControl ='20vh';
        }
        else if(!ctrl.showComment && ctrl.showFile && !ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyDocument = 'calc(80vh - 210px)'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyControl ='20vh';
        }
        else if(!ctrl.showComment && !ctrl.showFile && ctrl.showInformation && ctrl.showControl)
        {
            this.heightCssPropertyInfo = 'calc(70vh - 210px)'
            this.heightCssPropertyDocument = '0px'
            this.heightCssPropertyComment = '0px'
            this.heightCssPropertyControl ='30vh';
        }
        else if(ctrl.showComment && ctrl.showFile && !ctrl.showInformation && !ctrl.showControl)
        {
            this.heightCssPropertyComment = 'calc(70vh - 210px)'
            this.heightCssPropertyInfo = '0px'
            this.heightCssPropertyDocument = '30vh'
            this.heightCssPropertyControl ='0vh';
        }
        if(this.isMobile)
        {
            this.heightCssPropertyInfo = 'calc(60vh - 53px)'
            this.heightCssPropertyDocument = 'calc(60vh - 53px)'
            this.heightCssPropertyComment = 'calc(60vh - 53px)'
            this.heightCssPropertyControl ='calc(60vh - 53px)';
        }
    }

    async initNormalMode(item:any) {
        var ctrl = this;
        if(ctrl.showFile && item.SubPerimeter != '') {
            ctrl.showFile = await ctrl.appStructService.getSubPerimeter(item.Space, item.Version,item.ApplicationName, item.Zone, item.SubPerimeter, item.StateId, item.IsSpecial ? 'RSub' : 'R');    
        }
       
        if(ctrl.showInformation) {
            this.tabs.push({
                id: 'panelInfos',
                icon: 'info',
              })
            ctrl.activePanel = 'panelInfos';
            ctrl.infoActiv = true;
        }
        ctrl.me = ctrl.authService.getEmail();
        if(ctrl.showComment) {
            if(ctrl.activePanel == '') {
                ctrl.activePanel = 'panelComments';
                this.commentActiv = true;
            }
            if(!this.isMobile) {
                this.commentActiv = true;
            }
            
            var nbComment = item.ContextInfo && item.ContextInfo.NbComment ? item.ContextInfo.NbComment : 0
            this.tabs.push({
                id: 'panelComments',
                icon: 'comment',
                text: nbComment
            })
            ctrl.comments = await lastValueFrom(ctrl.appStructService.getAllComments(item.ApplicationName, item.Version, item.Zone, item.EntityId, item.Module, item.Onglet, item.Bloc, item.Ligne, item.Tab, this.item.SubPerimeter));
            for(var i = 0; i<ctrl.comments.length; i++) {
                ctrl.comments[i].DateCreationString = moment(ctrl.comments[i].DateCreation).format('DD.MM.yyyy')
                ctrl.comments[i].DateCreation = new Date(ctrl.comments[i].DateCreation)
                if(ctrl.comments[i].DateModification) {
                    ctrl.comments[i].DateModificationString = moment(ctrl.comments[i].DateModification).format('DD.MM.yyyy')
                }
            }
            ctrl.deleteComments = !item.LockEntity;
            ctrl.updateComments = !item.LockEntity;
            ctrl.refreshControleStatus();
            if(!item.LockEntity) {
                ctrl.addNewComment();
            }
            this.comments = this.comments.sort((a, b) => a['DateCreation'] > b['DateCreation'] ? -1 : a['DateCreation'] === b['DateCreation'] ? 0 : 1);
        }
        if(ctrl.showFile)
        {
            var nbDoc = item.ContextInfo && item.ContextInfo.NbDocument ? item.ContextInfo.NbDocument : 0
            this.tabs.push({
                id: 'panelDocs',
                icon: 'textdocument',
                text: nbDoc
            })
            if(ctrl.activePanel == '')
            {
                ctrl.activePanel = 'panelDocs';
                this.docActiv = true;
            }
            if(!this.isMobile) {
                this.docActiv = true;
            }

            ctrl.deleteFile = true;
            ctrl.addFile = true;
            if(item.SubPerimeter != '') {
                ctrl.deleteFile =  await ctrl.appStructService.getSubPerimeter(item.Space, item.Version,item.ApplicationName, item.Zone, item.SubPerimeter, item.StateId, item.IsSpecial ? 'DSub' : 'D'); 
                ctrl.addFile =  await ctrl.appStructService.getSubPerimeter(item.Space, item.Version,item.ApplicationName, item.Zone, item.SubPerimeter, item.StateId, item.IsSpecial ? 'CSub' : 'C');         
            }
            if(item.LockEntity) {
                ctrl.deleteFile = false;
                ctrl.addFile = false;
            }
            ctrl.documents = await lastValueFrom(ctrl.appStructService.getAllDocuments(item.ApplicationName,item.Version,item.Zone, item.EntityId, item.Module, item.Onglet, item.Bloc, item.Ligne, item.Tab,this.item.SubPerimeter));
            ctrl.refreshControleStatus();
            this.openTabDoc();
        }
        
        if(ctrl.showControl) {
            this.tabs.push({
                id: 'panelControle',
                icon: 'clear',
            })
            if(ctrl.activePanel == '') {
                ctrl.activePanel = 'panelControle';
                this.controlActiv = true;
            }
            if(!this.isMobile) {
                this.controlActiv = true;
            }
        }
        if(this.isMobile) {
            const tabsInstance = $('#tabs').dxTabs({
                dataSource: this.tabs,
                selectedIndex: 0,
                onItemClick(e) {
                    ctrl.infoActiv = e.itemData.id == 'panelInfos';
                    ctrl.commentActiv = e.itemData.id == 'panelComments';
                    ctrl.docActiv = e.itemData.id == 'panelDocs';
                    if(e.itemData.id == 'panelDocs') {
                        ctrl.openTabDoc();
                    }
                    ctrl.controlActiv = e.itemData.id == 'panelControle';
                },
            }).dxTabs('instance');
        }
        ctrl.openPanel();

        this.confirmationModalComponent.clean();
        this.sub = this.confirmationModalComponent.confirmDeleteDocChange.subscribe((documentId : any) => {
            if (documentId != null) {
                this.deleteDocuments = true;
                this.appStructService.deleteDocument(this.item.ApplicationName, this.item.Version, this.item.Zone, documentId, this.item.Tab).subscribe({
                    next: comments => {
                        var blocref = ctrl.item.Zone+'_'+ctrl.item.ApplicationName;
                        ctrl.appStructService.saveDocumentContext(ctrl.item.Zone, blocref, ctrl.item.EntityId, ctrl.item.Module, ctrl.item.Onglet, ctrl.item.Bloc, ctrl.item.Ligne, ctrl.documents.length > 1, ctrl.documents.length-1, ctrl.item.WorkflowId).subscribe({
                        next: async comments => {
                            var temp = 
                            {
                                OriginName : ctrl.item.OriginName, 
                                Module : ctrl.item.Module,
                                Onglet : ctrl.item.Onglet,
                                Bloc : ctrl.item.Bloc,
                                Ligne : ctrl.item.Ligne,
                                Type: 'documents',
                                Value: false,
                                Nb : ctrl.documents.length - 1
                            }
                            ctrl.appStructService.updateParent.emit(temp);
                            ctrl.deleteDocuments = false;
                            await ctrl.reloadDocuments();
                            },
                            error(err) {
                                ctrl.deleteDocuments = false;
                            },
                        });
                            
                        
                    },
                    error(err) {
                        ctrl.deleteDocuments = false;
                    },
                });
            }
        });
    }

    async initMultiRowManagement(item:any) {
        var ctrl = this;
        ctrl.addFile = true;

        if(ctrl.showComment) {
            if(ctrl.activePanel == '') {
                ctrl.activePanel = 'panelComments';
                this.commentActiv = true;
            }
            if(!this.isMobile) {
                this.commentActiv = true;
            }
            
            var nbComment = item.ContextInfo && item.ContextInfo.NbComment ? item.ContextInfo.NbComment : 0;
            this.tabs.push({
                id: 'panelComments',
                icon: 'comment',
                text: nbComment
            })
            
            ctrl.updateComments = !item.LockEntity;
            ctrl.refreshControleStatus();
            if(!item.LockEntity) {
                ctrl.addNewComment();
            }
        }

        if(ctrl.showFile)
        {
            var nbDoc = item.ContextInfo && item.ContextInfo.NbDocument ? item.ContextInfo.NbDocument : 0
            this.tabs.push({
                id: 'panelDocs',
                icon: 'textdocument',
                text: nbDoc
            })
            if(ctrl.activePanel == '')
            {
                ctrl.activePanel = 'panelDocs';
                this.docActiv = true;
            }
            if(!this.isMobile) {
                this.docActiv = true;
            }

            ctrl.deleteFile = false;
            ctrl.addFile = !item.LockEntity;

            ctrl.documents = await lastValueFrom(ctrl.appStructService.getAllDocuments(item.ApplicationName,item.Version,item.Zone, item.EntityId, item.Module, item.Onglet, item.Bloc, item.Ligne, item.Tab,this.item.SubPerimeter));
            
            ctrl.refreshControleStatus();
            this.openTabDoc();
        }

        ctrl.openPanel();

        this.confirmationModalComponent.clean();
        this.sub = this.confirmationModalComponent.confirmAddingDocChange.subscribe(async (objectsDocument : any) => {
            if (objectsDocument != null) {
                await this.addDocumentsFromList(objectsDocument);
            }
            else {
                const fileUploader = $('#file-uploader').dxFileUploader('instance');
                fileUploader.option('value', []);
            }
        });

        this.sub = this.confirmationModalComponent.confirmAddingCommentChange.subscribe(async (lastComment : any) => {
            if (lastComment != null) {
                await this.saveCommentMultiRowManagementMode(lastComment);
                this.currentComment = null;
                const textarea = document.getElementById('commentBody') as HTMLTextAreaElement;
                textarea.value = '';
            }
        });
    }

    downloadDocument(documentA: any): void {
        var url = ''
        if(documentA.DocumentGuid == ''){
            url = './api/'+this.item.Version+'/app/'+this.item.ApplicationName+'/'+this.item.Zone+'/'+this.item.EntityId+'/old_documents.json';
            this.downloadService.downloadWithObject(url, documentA).subscribe(response => {
                var filename = documentA.DocCode == '' ? documentA.SourceFileName : documentA.DocNom;
                if (response.body) {
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(response.body);
                    a.download = filename;
                    a.click();
                }
            });
        }
        else
        {
            url = './api/v1/spaces/'+this.item.Zone+'/documents/'+documentA.DocumentGuid;
            
            this.downloadService.download(url).subscribe(response => {
                var filename = documentA.DocCode == '' ? documentA.SourceFileName : documentA.DocNom;
                if (response.body) {
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(response.body);
                    a.download = filename;
                    a.click();
                }
            });
        }
    }

    openTabDoc()
    {
        var ctrl = this;

        if(ctrl.item.Value.MaxSizePerDoc == null || ctrl.item.Value.MaxSizePerDoc == 0) {
            ctrl.item.Value.MaxSizePerDoc = 10000000;
        }

        if(ctrl.item.Value.Extension == null || ctrl.item.Value.Extension == ''){
            ctrl.item.Value.Extension = 'pdf';
        }
    
        if (ctrl.item.Value.Extension != null){
            ctrl.formats = ctrl.item.Value.Extension.split(',');
            var finalFormatList:string[] = [];
            ctrl.formats.forEach(format => {
                var finalFormat = (format != null && format.length > 0 && format[0] != '.') ?  '.' + format : format;
                finalFormatList.push(finalFormat);
            });

            ctrl.formats = finalFormatList;
            ctrl.item.Value.Extension = ctrl.formats.join(',');
        }
        
        this.actualiseFileUploaderVisibility();
    }

    onSubmit() :void {
        const fileUploader = $('#file-uploader').dxFileUploader('instance');
        this.saveDocument(fileUploader.option('value'))
    }

    closePanel(): void {
        this.isOpened = false;
        if (this.item != null && this.isMultiRowManagementMode && this.item.CurrentCell && this.item.CurrentCell.classList) {
            this.item.CurrentCell.classList.remove("multiRowManagementSelected");
        }

        if(this.item != null && this.currentComment != null ) {
           this.saveCommentDirectly(this.currentComment);
        }
        if(this.item && this.item.RowElement) {
            $(this.item.RowElement).removeClass("selected-line");
        }
        $("#panelContextInfo").hide();
        if(!this.isMobile) {
            $("#appStructPanel").css({
                width: 'calc(100%)',
                'border-right':''
            });
        }
        else {
            $("#appStructPanelMobile").css({
                height: '100vh'
            });
        }
    }

    openPanel(): void {
        this.isOpened = true;
        $("#panelContextInfo").show();
        if($("#panelExtraInfo").is(':visible')) {
            $("#panelContextInfo").css({
                right: 'calc(240px)'
            })
            ;
        }
        else if(!this.isMobile) {
            $("#panelContextInfo").css({
                right: '0rem'
            });
        }

        if(!this.isMobile) {
            $("#appStructPanel").css({
                width: 'calc(100% - 375px)',
                'border-right':'none'
            });
        }
        else {
            $("#appStructPanelMobile").css({
                height: '50vh'
            });
        }
    }

    async reloadComment()
    {
        if (!this.isMultiRowManagementMode) {
            await this.reloadCommentsNormalMode();
        }
    }

    async reloadCommentsNormalMode() {
        this.comments = await lastValueFrom(this.appStructService.getAllComments(this.item.ApplicationName,this.item.Version,this.item.Zone, this.item.EntityId, this.item.Module, this.item.Onglet, this.item.Bloc, this.item.Ligne, this.item.Tab,this.item.SubPerimeter));
        for(var i = 0; i<this.comments.length; i++)
        {
            this.comments[i].DateCreationString = moment(this.comments[i].DateCreation).format('DD.MM.yyyy')
            this.comments[i].DateCreation = new Date(this.comments[i].DateCreation)
            if(this.comments[i].DateModification)
            {
                this.comments[i].DateModificationString = moment(this.comments[i].DateModification).format('DD.MM.yyyy')
            }
        }
        this.refreshControleStatus();
        var tab = this.tabs.filter((tb: any) => tb.id == 'panelComments')[0];
        tab.text = this.comments.length;
        if(this.isMobile)
        {
            var tabs = $('#tabs').dxTabs('instance');
            tabs.repaint();
        }
        this.addNewComment();
        this.currentComment = null;
        this.comments = this.comments.sort((a, b) => a['DateCreation'] > b['DateCreation'] ? -1 : a['DateCreation'] === b['DateCreation'] ? 0 : 1);
    }

    refreshControleStatus()
    {
        if(this.item.ContextInfo != null){
        if(this.documents.length == 0 && this.comments.length == 0)
        {
            if(this.item.ContextInfo.Level == 2 || this.item.DocumentLevel == 3 || this.item.CommentLevel == 3)
                this.controleType = 'danger';
            else if(this.item.ContextInfo.Level == 1)
                this.controleType = 'warning';
        }
        else if(this.documents.length == 0 && this.item.DocumentLevel == 3)
            this.controleType = 'danger';
        else if(this.comments.length == 0 && this.item.CommentLevel == 3)
            this.controleType = 'danger';
        else if(this.item.ContextInfo.Level != 0 && this.documents.length > 0 && this.item.DocumentLevel > 1 && this.item.CommentLevel < 2)
           { this.controleType = '';   }
        else if(this.item.ContextInfo.Level != 0 && this.comments.length > 0 && this.item.CommentLevel > 1 && this.item.DocumentLevel < 2)
        {
        this.controleType = '';
        }
        else if(this.item.ContextInfo.Level != 0 && this.documents.length > 0 && this.item.DocumentLevel > 1 && this.comments.length > 0 && this.item.CommentLevel > 1)
            this.controleType = '';
        else if(this.item.ContextInfo.Level == 2)
            this.controleType = 'danger';
        else if(this.item.ContextInfo.Level == 1)
        {
            this.controleType = 'warning';
        }
    }
    }

    addNewComment(): void {
        this.firstCom = this.comments.length == 0;
        this.comments.push(
            {
                OriginName : this.item.OriginName, 
                DateCreation : new Date(),
                DateCreationString : moment(new Date()).format('DD.MM.yyyy'),
                Owner:this.me,
                ErrorMessage:'',
                Module : this.item.Module,
                Onglet : this.item.Onglet,
                Bloc : this.item.Bloc,
                Ligne : this.item.Ligne,
            }
        )
    }

    saveComment(lastComment:any): void {
        if (this.isMultiRowManagementMode) {
            this.confirmationModalComponent.showSimpleConfirmation('Demande de confirmation', 'Etes-vous sûr(e) de vouloir ajouter ce commentaire à ces lignes ?', lastComment, 2);
        }
        else {
            this.saveCommentNormalMode(lastComment);
        }
    }

    async saveCommentMultiRowManagementMode(lastComment:any) {
        await this.AddCommentOneByOne(lastComment, this.item.SelectedObjects, 0);
    }

    async AddCommentOneByOne(lastComment:any, objects:any, index:number) {
        var ctrl = this;

        lastComment.Module = objects[index].Module;
        lastComment.Onglet = objects[index].Onglet;
        lastComment.Bloc = objects[index].Bloc;
        lastComment.Ligne = objects[index].Ligne;

        await this.addComment(lastComment, objects[index].Module, objects[index].Onglet, objects[index].Bloc, objects[index].Ligne, objects[index].EntityId, objects[index].NbComment + 1, ctrl.item.OriginName); 

        if (index < objects.length - 1){
            index+=1;
            ctrl.AddCommentOneByOne(lastComment, objects, index);
        }

        this.currentComment = null;
    }

    async saveCommentNormalMode(lastComment:any) {
        var ctrl = this;
        this.currentComment = null;
        if(lastComment.Id) {
            this.appStructService.updateComment(this.item.ApplicationName,this.item.Version,this.item.Zone, lastComment.Id, lastComment.Commentaire, this.item.Tab,this.item.SubPerimeter).subscribe({
                next: () => {
                    ctrl.reloadComment();
                }
            });
        }
        else {
            var nbCom = ctrl.firstCom ? 1 : ctrl.comments.length;
            if (ctrl.commentIsAdding == false) {
                ctrl.commentIsAdding = true;
                await this.addComment(lastComment, ctrl.item.Module, ctrl.item.Onglet, ctrl.item.Bloc, ctrl.item.Ligne, ctrl.item.EntityId, nbCom, ctrl.item.OriginName);
                ctrl.commentIsAdding = false;
            }
        }
    }

    async addComment(comment:any, module:any, onglet:any, bloc:any, ligne:any, entityId:any, nbCom:any, originName: any) {
        var ctrl = this;

        await lastValueFrom(this.appStructService.addComment(this.item.ApplicationName, this.item.Version, this.item.Zone, entityId, comment, this.item.Tab, this.item.SubPerimeter));

        var blocref = ctrl.item.Zone+'_'+ctrl.item.ApplicationName;

        await lastValueFrom(ctrl.appStructService.saveCommentContext(ctrl.item.Zone, blocref, entityId, module, onglet, bloc, ligne, true, nbCom, ctrl.item.WorkflowId));

        var temp = {
            OriginName : originName,
            Module : module,
            Onglet : onglet,
            Bloc : bloc,
            Ligne : ligne,
            Type:'comments',
            Value:true,
            Nb : nbCom
        }
        ctrl.appStructService.updateParent.emit(temp)

        if (!this.isMultiRowManagementMode) {
            ctrl.reloadComment();
        }
    }

    saveCommentDirectly(lastComment:any): void {
        var ctrl = this;
        var blocref = ctrl.item.Zone+'_'+ctrl.item.ApplicationName;
        this.currentComment = null;
        var nbComment = ctrl.comments.length
        if(lastComment.Id) {
            this.appStructService.updateComment(this.item.ApplicationName,this.item.Version,this.item.Zone, lastComment.Id,lastComment.Commentaire, this.item.Tab,this.item.SubPerimeter).subscribe({
                next: comments => {
                    ctrl.reloadComment();
                }
            });
        }
        else {
            var nbCom = ctrl.firstCom ? 1 : nbComment;
            this.appStructService.addComment(this.item.ApplicationName,this.item.Version,this.item.Zone, this.item.EntityId,lastComment, this.item.Tab,this.item.SubPerimeter).subscribe({
                next: comments => {
                    ctrl.appStructService.saveCommentContext(ctrl.item.Zone, blocref, ctrl.item.EntityId, ctrl.item.Module, ctrl.item.Onglet, ctrl.item.Bloc, ctrl.item.Ligne, true, nbComment, ctrl.item.WorkflowId).subscribe({
                    next: comments => {
                        var temp = 
                        {
                            OriginName : ctrl.item.OriginName,
                            Module : ctrl.item.Module,
                            Onglet : ctrl.item.Onglet,
                            Bloc : ctrl.item.Bloc,
                            Ligne : ctrl.item.Ligne,
                            Type:'comments',
                            Value:true,
                            Nb : nbCom
                        }
                        ctrl.appStructService.updateParent.emit(temp)
                    }});
                }
            });
        }
    }

    deleteComment(lastComment:any): void {
        var ctrl = this;
        this.currentComment = null;
        if(lastComment.Id)
        {
            var nbCom = ctrl.comments.length;
            this.appStructService.deleteComment(this.item.ApplicationName,this.item.Version,this.item.Zone, lastComment.Id, this.item.Tab).subscribe({
                next: comments => {
                    var blocref = ctrl.item.Zone+'_'+ctrl.item.ApplicationName;
                    ctrl.appStructService.saveCommentContext(ctrl.item.Zone, blocref, ctrl.item.EntityId, ctrl.item.Module, ctrl.item.Onglet, ctrl.item.Bloc, ctrl.item.Ligne, ctrl.comments.length > 2, ctrl.comments.length-2, ctrl.item.WorkflowId).subscribe({
                    next: comments => {
                        var temp = 
                        {
                            OriginName : ctrl.item.OriginName,
                            Module : ctrl.item.Module,
                            Onglet : ctrl.item.Onglet,
                            Bloc : ctrl.item.Bloc,
                            Ligne : ctrl.item.Ligne,
                            Type:'comments',
                            Value:false,
                            Nb : nbCom -2
                        }
                        ctrl.appStructService.updateParent.emit(temp)
                    }});
                    ctrl.reloadComment();
                }
            });
        }
        else{
            this.comments.pop();
        }
    }

    async reloadDocuments()
    {
        this.documents = await lastValueFrom(this.appStructService.getAllDocuments(this.item.ApplicationName,this.item.Version,this.item.Zone, this.item.EntityId, this.item.Module, this.item.Onglet, this.item.Bloc, this.item.Ligne, this.item.Tab,this.item.SubPerimeter));
        var tab = this.tabs.filter((tb: any) => tb.id == 'panelDocs')[0];
        tab.text = this.documents.length;
        if(this.isMobile)
        {
            var tabs = $('#tabs').dxTabs('instance');
            tabs.repaint();
        }
        this.refreshControleStatus();
        this.actualiseFileUploaderVisibility();
    }

    createFileUploader() {
        var ctrl = this;
        $('#file-uploader').dxFileUploader({
            multiple : true,
            accept : ctrl.item.Value.Extension,
            maxFileSize : ctrl.item.Value.MaxSizePerDoc,
            value : [],
            uploadMode : 'instantly',
            onValueChanged(e) {
                ctrl.onSubmit();
            }
        }).dxFileUploader('instance');

        $('#file-uploader').show();
    }

    actualiseFileUploaderVisibility() {
        var ctrl = this;
        if(ctrl.item.Value.MaxDoc == 0 || ctrl.documents.length < ctrl.item.Value.MaxDoc){
            ctrl.createFileUploader();
        }
        else{
            $('#file-uploader').hide();
        }
    }
    
    async saveDocument(uploadDocuments:any[]) {
        var blockNextImport = false;
        var objectsDocument = this.getObjectsDocumentFromUploadDocuments(uploadDocuments);

        if (blockNextImport) {
            $('#file-uploader').hide();
        }

        if (objectsDocument.length > 0) {

            var message = objectsDocument.length == 1 
                ? 'Etes-vous sûr(e) de vouloir ajouter ce document à ces lignes ?'
                : 'Etes-vous sûr(e) de vouloir ajouter ces documents à ces lignes ?';

            if (this.isMultiRowManagementMode) {
                this.confirmationModalComponent.showSimpleConfirmation('Demande de confirmation', message, objectsDocument, 1);
            }
            else {
                this.addDocumentsFromList(objectsDocument);
            }
        }
    }

    getObjectsDocumentFromUploadDocuments(uploadDocuments:any) {
        var ctrl = this;
        var index = this.documents.length;
        var objectsDocument = [];
        var nbDocuments = ctrl.documents.length;

        for(var i = 0; i < uploadDocuments.length; i++) {
            index++;

            var documentName = this.getRightDocumentName(uploadDocuments[i].name);

            var documentObject =  {
                OriginName: this.item.OriginName,
                DocCode : this.item.Value.DocCode,
                DocIndex : index,
                DocNom : this.item.Value.DocCode + '_' + index + '.' + documentName.split('.').pop(),
                DocLoadDate : new Date(),
                Module : this.item.Module,
                Onglet : this.item.Onglet,
                Bloc : this.item.Bloc,
                Ligne : this.item.Ligne,
                SourceFileName : documentName
            };

            var fileObject =  {
                Filename : documentName,
                Extension : '.' + documentName.split('.').pop(),
                Size : uploadDocuments[i].size,
                CreationDate : new Date(),
                File64 : <any>null
            };
            
            var hasRightExtension = ctrl.formats.filter((tb: string) => tb == fileObject.Extension).length != 0;
            var hasRightNumber =  (ctrl.item.Value.MaxDoc == 0 || nbDocuments + 1 <= ctrl.item.Value.MaxDoc);

            if(hasRightExtension && hasRightNumber) {
                var finalObjectDocument = {
                    DocumentObject : documentObject,
                    FileObject : fileObject,
                    File : uploadDocuments[i]
                };

                objectsDocument.push(finalObjectDocument);
                nbDocuments++;
            }
        }

        return objectsDocument;
    }

    getRightDocumentName(originName : String) {
        var beforeExtension: string = originName.split('.').slice(0, -1).pop();
        var extension: string = originName.split('.').pop().toLowerCase();

        return beforeExtension + "." + extension;
    }

    async addDocumentsFromList(objectsDocument:any) {
        var ctrl = this;
        var nbDocumentsToAdd = objectsDocument.length;
        await ctrl.AddDocumentOneByOne(objectsDocument, nbDocumentsToAdd, 0);
    }

    async AddDocumentOneByOne(objectsDocument:any, nbDocumentsToAdd:any, index:number) {
        var ctrl = this;
        let fileReader = new FileReader();
        fileReader.onload = async function () {
            await ctrl.fileReaderOnload(fileReader, objectsDocument[index], nbDocumentsToAdd, index+1 == objectsDocument.length);
            if (index < objectsDocument.length - 1){
                index+=1;
                await ctrl.AddDocumentOneByOne(objectsDocument, nbDocumentsToAdd, index);
            }
        };

        fileReader.readAsDataURL(objectsDocument[index].File);
    }

    async fileReaderOnload(fileReader:any, objectDocument:any, nbDocumentsToAdd:any, last : boolean)  {
        var finalDocumentObject = {
            Document: objectDocument.DocumentObject,
            File: objectDocument.FileObject
        }

        objectDocument.FileObject.File64 = fileReader.result.toString().split(',')[1];

        if (this.isMultiRowManagementMode) {
            await this.addDocumentMultiRowManagementMode(finalDocumentObject);
        }
        else {
            
            if (this.documentIsAdding == false) {
                this.documentIsAdding = true;
                await this.addDocumentNormalMode(finalDocumentObject, nbDocumentsToAdd, last);
                this.documentIsAdding = false;
            }
        }
    }

    async addDocumentMultiRowManagementMode(finalDocumentObject:any) {
        var ctrl = this;
        var index = 0;

        for(var i = 0; i < this.item.SelectedObjects.length; i++)
        {
            var object = this.item.SelectedObjects[i];

            object.NbDocument ++;
            index = object.NbDocument;
            finalDocumentObject.Document.DocIndex = index;
            finalDocumentObject.Document.DocNom = finalDocumentObject.Document.DocCode + '_' + index + '.' + finalDocumentObject.Document.DocNom.split('.').pop();

            finalDocumentObject.Document.Module = object.Module;
            finalDocumentObject.Document.Onglet = object.Onglet;
            finalDocumentObject.Document.Bloc = object.Bloc;
            finalDocumentObject.Document.Ligne = object.Ligne;

            await lastValueFrom(ctrl.appStructService.addDocument(ctrl.item.ApplicationName, ctrl.item.Version, ctrl.item.Zone, object.EntityId, finalDocumentObject, ctrl.item.Tab, ctrl.item.SubPerimeter));

            ctrl.createFileUploader();
            var blocref = ctrl.item.Zone + '_' + ctrl.item.ApplicationName;
            
            object.NbDocument = (ctrl.item.Value.MaxDoc > 0) 
                ? (object.NbDocument >= ctrl.item.Value.MaxDoc ? ctrl.item.Value.MaxDoc : object.NbDocument)
                : object.NbDocument;

            await lastValueFrom(ctrl.appStructService.saveDocumentContext(ctrl.item.Zone, blocref, object.EntityId, object.Module, object.Onglet, object.Bloc, object.Ligne, true, object.NbDocument, ctrl.item.WorkflowId));

            var temp = 
            {
                OriginName : object.OriginName,
                Module : object.Module,
                Onglet : object.Onglet,
                Bloc : object.Bloc,
                Ligne : object.Ligne,
                Type : 'documents',
                Value : true,
                Nb : object.NbDocument
            }
            ctrl.appStructService.updateParent.emit(temp)
        }
    }

    async addDocumentNormalMode(finalDocumentObject:any, nbDocumentsToAdd:any, last :boolean) {
        var ctrl = this;
        await lastValueFrom(ctrl.appStructService.addDocument(ctrl.item.ApplicationName, ctrl.item.Version, ctrl.item.Zone, ctrl.item.EntityId, finalDocumentObject, ctrl.item.Tab, ctrl.item.SubPerimeter));
        
        ctrl.createFileUploader();
        if(last)
            await ctrl.saveAddDocumentContext(finalDocumentObject, nbDocumentsToAdd);
    }
    async saveAddDocumentContext(finalDocumentObject:any, nbDocumentsToAdd:any) {
        var ctrl = this;
        var blocref = ctrl.item.Zone + '_' + ctrl.item.ApplicationName;
        var finalNbDocs = (ctrl.item.Value.MaxDoc > 0) 
        ? (nbDocumentsToAdd + ctrl.documents.length >= ctrl.item.Value.MaxDoc ? ctrl.item.Value.MaxDoc : nbDocumentsToAdd + ctrl.documents.length)
        : nbDocumentsToAdd + ctrl.documents.length;
        await lastValueFrom(ctrl.appStructService.saveDocumentContext(ctrl.item.Zone, blocref, ctrl.item.EntityId, ctrl.item.Module, ctrl.item.Onglet, ctrl.item.Bloc, ctrl.item.Ligne, true, finalNbDocs, ctrl.item.WorkflowId));
        
        var temp = 
        {
            OriginName : ctrl.item.OriginName,
            Module : ctrl.item.Module,
            Onglet : ctrl.item.Onglet,
            Bloc : ctrl.item.Bloc,
            Ligne : ctrl.item.Ligne,
            Type : 'documents',
            Value : true,
            Nb : finalNbDocs
        }
        ctrl.appStructService.updateParent.emit(temp)
        await ctrl.reloadDocuments();
    }

    deleteDocument(lastDocument:any): void {
        if(lastDocument.Id) {
            if(this.deleteDocuments == false)
            {
            this.confirmationModalComponent.showSimpleConfirmation('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer ce document ?', lastDocument.Id, 0);
            }
        }
        else {
            this.documents.pop();
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}