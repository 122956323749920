import { IActionAppButton } from "app/front-office/front-office-struct";
import { ActionTypeButton, IEntity, TargetButton } from "app/shared/entity";
import { StringUtils } from "app/utils/string-utils";
import { lastValueFrom } from 'rxjs';
import * as $ from 'jquery';

export class FrontOfficeToolbarFactory {
    static async setListActionsSelectBox(ctrl:any) {
        $('#listActions').dxSelectBox({
            dataSource: ctrl.displayApplicationActions,
            width:'250px',
            placeholder:'Actions disponibles',
            itemTemplate(itemData, itemIndex, itemElement) {
                return $("<div />")
                    .css("padding", "5px")
                    .append($("<i/>")
                    .addClass("fa-solid")
                    .addClass(itemData.IconClass))
                    .append($("<p />")
                    .html(itemData.LongName).css("display", "inline-block"));
            },
            onItemClick: async function (data:any) {
                var action = data.itemData;
                action.FormStatus = ctrl.currentState.Id;
                $('#listActions').dxSelectBox('instance').reset();
                switch (action.ActionType) {
                    case ActionTypeButton.API:
                        ctrl.actionLegacyService.parsingActionTypeApiApp(action, ctrl.space, parseFloat(ctrl.identifier), ctrl.currentModule.EntityCible, ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
                        break;
                    case ActionTypeButton.Javascript:
                        await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(action,ctrl.space, parseFloat(ctrl.identifier), ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.currentModule.EntityCible, ctrl.reportsDef, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.exportsDef, ctrl.currentZone.WorkflowId, null, ctrl.currentProcedureAssociation);
                        break;
                }
            }
        }).dxSelectBox('instance')
    }

    static async setListChangeState(ctrl:any, allowFormState:any) {
        $('#listChangeState').dxSelectBox({
            dataSource: allowFormState,
            width:'250px',
            displayExpr: 'Label',
            disabled : ctrl.currentZone.StateSelector == 'Visible',
            fieldTemplate(itemData, container) {
                container.append($("<div/>").css("display","contents").append($("<p />").dxTextBox({ value: itemData != null ? itemData.Label : '', readOnly: true })));
            },
            itemTemplate(itemData, itemIndex, itemElement) {
                if(itemData.Id == ctrl.currentState.Id) {
                    return $("<div />")
                        .css("padding", "5px")
                        .addClass("module-selected")
                        .append($("<span/>").addClass("status-chip-module").css("background-color", itemData.Color))
                        .append($("<p />").html(itemData.Label).css("display", "inline-block").css("font-weight","600"));
                }
                else {
                    return $("<div />")
                        .addClass("module-non-selected")
                        .css("padding", "5px")
                        .append($("<span/>").addClass("status-chip-module").css("background-color", itemData.Color))
                        .append($("<p />").html(itemData.Label).css("display", "inline-block"));
                }
            },
            onItemClick: async function (data:any) {
                if(data.itemData.Id != ctrl.currentState.Id) {
                    var action = ctrl.applicationTransitions.filter((th: { Name: string; }) => th.Name == data.itemData.Id)[0];
                    action.FormStatus = ctrl.currentState.Id;
                    if(ctrl.multiTab && ctrl.tabName.indexOf('/') == -1) {
                        ctrl.tabName = ctrl.currentModule.Name+'/'+ctrl.tabName;
                    }
                    switch (action.ActionType) {
                        case ActionTypeButton.API:
                            ctrl.actionLegacyService.parsingActionTypeApiApp(action, ctrl.space, parseFloat(ctrl.identifier), ctrl.currentModule.EntityCible, ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
                            break;
                        case ActionTypeButton.Javascript:
                            await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(action,ctrl.space, parseFloat(ctrl.identifier), ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.currentModule.EntityCible, ctrl.reportsDef, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.exportsDef, ctrl.currentZone.WorkflowId, data.itemData.Confirmation, ctrl.currentProcedureAssociation);
                            break;
                    }
                }
                $('#listChangeState').dxSelectBox('instance').reset();
            }
        }).dxSelectBox('instance')
    }

    static IsPartOfToolBar(element: IActionAppButton) {
        return (element.Target == TargetButton.Toolbar);
    }

    static getListTabsItemTemplate(ctrl:any, itemData:any, itemIndex:any) {
        if(ctrl.identifier != '0') {
            var list = ctrl.listTabState.filter((th: string ) => th.indexOf(itemData.Name+'#') > -1);
            if(itemData.ModuleLabel != '') {
                list = ctrl.listTabState.filter((th: string ) => th.indexOf(itemData.ModuleName+'_'+itemData.Name+'#') > -1);
            }
            if(list.length != 0) {
                let currentTabStateStatus = list[0];
                var currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == currentTabStateStatus.split('#')[1])[0];
                
                if(ctrl.selectedTab != null && itemData.Name == ctrl.selectedTab.Name && itemData.ModuleLabel == ctrl.selectedTab.ModuleLabel) {
                    return $("<div />")
                    .css("padding", "5px")
                    .attr('title', StringUtils.decodeSpecialEntitiesHtml(itemData.ToolTip))
                    .addClass("module-selected")
                    .append($("<span/>").addClass("status-chip-module")
                    .css("background-color", currentState.Color))
                    .append(
                        $("<p />").html(itemData.Label).css("display", "inline-block").css("font-weight","600")
                    );
                }
                else {
                    var temp = $("<div />")
                        .attr('title', StringUtils.decodeSpecialEntitiesHtml(itemData.ToolTip))
                        .addClass("module-non-selected")
                        .css("padding", "5px")
                        .css("width", "fit-content")
                        .append($("<span/>").addClass("status-chip-module").css("background-color", currentState.Color))
                        .append($("<p />").html(itemData.Label).css("display", "inline-block"));
                    if(itemIndex % 2 == 1) {
                        temp.addClass("alternative-row")
                    }
                    return temp;
                }

            }
            else {
                return $("<div />")
                    .addClass("module-non-selected")
                    .css("padding", "5px")
                    .css("width", "fit-content")
                    .append($("<p />").html(itemData.Label).css("display", "inline-block"));
            }
        }
        else {
            return $("<div />")
                .addClass("module-non-selected")
                .css("padding", "5px")
                .append($("<p />").html(itemData.Label).css("display", "inline-block"));
        }
    }

    static async returnToProc(ctrl:any, proc?: IEntity): Promise<void> {
        let moduleName = '';
        if (!proc) {
            var selectedModule: { ModuleName: any; Name: string; ParentEntityCible:string; } = null;
            if(ctrl.modulesConfig[ctrl.variante] == null) {
                var i =0;
                do {
                    var procsAvailable =  ctrl.currentZone.Modules[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    ctrl.currentZone.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tempProcAsso.length > 0){
                            selectedModule = ctrl.currentZone.Modules[i];
                        }
                    });
                    i++;
                }
                while(selectedModule == null && i<ctrl.currentZone.Modules.length);
                moduleName = selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;

                let tabs:any = await lastValueFrom(ctrl.applicationStructService.getStructOfModule(ctrl.applicationName, ctrl.version, ctrl.zoneName, moduleName));
                var selectedtab: { Name: string; } = null;
                var i =0;
                do
                {
                    var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tempProcAsso.length > 0) {
                            selectedtab = tabs.Tabs[i];
                        }
                    });
                    i++;
                }
                while(selectedtab == null && i<tabs.Tabs.length);
                ctrl.modulesConfig[ctrl.variante] = moduleName;
                ctrl.tabConfig[ctrl.variante] = selectedtab
            }
            else {
                moduleName = ctrl.modulesConfig[ctrl.variante]
                selectedtab = ctrl.tabConfig[ctrl.variante]
            }
            let entities:any = await lastValueFrom(ctrl.entitiesService.getAvailableEntitiesFromProc(ctrl.zoneName, ctrl.currentProc.IdObject, selectedModule.ParentEntityCible, ctrl.applicationName));
            ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);      
        }
        else {
            if(ctrl.currentModule.ParentEntityCible == '') {
                ctrl.currentModule.ParentEntityCible = ctrl.currentEntity.EntityType;
            }
            let entities:any = await lastValueFrom(ctrl.entitiesService.getAvailableEntitiesFromProc(ctrl.zoneName, proc.IdObject, ctrl.currentModule.ParentEntityCible, ctrl.applicationName));
            var tabName = ctrl.tabName;
            if(tabName.indexOf('/') > -1) {
                tabName = tabName.split('/')[1];
            }
            var selectEntities = entities.filter((tb: { IdObject: number; }) => tb.IdObject == ctrl.currentEntity.IdObject);
            var currentProcedureAssociation:any  = await lastValueFrom(ctrl.entitiesService.getProcedureAssociation(ctrl.space, proc.IdObject));
            if(ctrl.currentEntity && selectEntities.length > 0 &&  ctrl.currentProcedureAssociation == currentProcedureAssociation) {
                ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ ctrl.moduleName+'/'+ ctrl.currentEntity.IdObject+'/'+ tabName]);
            }
            else if(ctrl.currentEntity && selectEntities.length == 0) {
                let selectEntity = entities.filter((tb: { Name: string; }) => tb.Name == ctrl.currentEntity.Name);
                if(selectEntity.length > 0 &&  ctrl.currentProcedureAssociation == currentProcedureAssociation) {
                    ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ ctrl.moduleName+'/'+ selectEntity[0].IdObject+'/'+ tabName]);
                }
                else {
                    let variante:any = await lastValueFrom(ctrl.entitiesService.getVarianteFromEntity(ctrl.currentZone.Space, ctrl.currentZone.Version, entities[0].IdObject.toString(), ctrl.currentZone.Perimeter));
                    if(ctrl.modulesConfig[variante] == null || ctrl.currentProcedureAssociation != currentProcedureAssociation) {
                        var i =0;
                        do {
                            var procsAvailable =  ctrl.currentZone.Modules[i].AvailableProcedureAssociations.split('.');
                            var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                                return procsAvailable.some((f : string) => {
                                    return f === tb;
                                })
                            });
                            ctrl.currentZone.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                                if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                                    selectedModule = ctrl.currentZone.Modules[i];
                                }
                            });
                            i++;
                        }
                        while(selectedModule == null && i<ctrl.currentZone.Modules.length);
                        moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                        let tabs:any = await lastValueFrom(ctrl.applicationStructService.getStructOfModule(ctrl.applicationName, ctrl.version, ctrl.zoneName, moduleName));
                        var selectedtab: { Name: string; } = null;
                        var i =0;
                        do {
                            var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                            var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                                return procsAvailable.some((f : string) => {
                                    return f === tb;
                                })
                            });
                            tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                                if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0) {
                                    selectedtab = tabs.Tabs[i];
                                }
                            });
                            i++;
                        }
                        while(selectedtab == null && i<tabs.Tabs.length);
                        ctrl.modulesConfig[ctrl.variante] = moduleName;
                        ctrl.tabConfig[ctrl.variante] = selectedtab
                    }
                    else {
                        moduleName = ctrl.modulesConfig[ctrl.variante]
                        selectedtab = ctrl.tabConfig[ctrl.variante]
                    }
                    ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);
                }
            }
            else {   
                let variante:any = await lastValueFrom(ctrl.entitiesService.getVarianteFromEntity(ctrl.currentZone.Space, ctrl.currentZone.Version, entities[0].IdObject.toString(), ctrl.currentZone.Perimeter));
                if(ctrl.modulesConfig[variante] == null)
                {
                    var i =0;
                    do
                    {
                        var procsAvailable =  ctrl.currentZone.Modules[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        ctrl.currentZone.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                                selectedModule = ctrl.currentZone.Modules[i];
                            }
                        });
                        i++;
                    }
                    while(selectedModule == null && i<ctrl.currentZone.Modules.length);
                
                    moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                
                    let tabs:any = await lastValueFrom(ctrl.applicationStructService.getStructOfModule(ctrl.applicationName, ctrl.version, ctrl.zoneName, moduleName));
                    var selectedtab: { Name: string; } = null;
                    var i =0;
                    do
                    {
                        var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                            selectedtab = tabs.Tabs[i];
                            }
                        });
                        i++;
                    }
                    while(selectedtab == null && i<tabs.Tabs.length);
                
                    ctrl.modulesConfig[variante] = moduleName;
                    ctrl.tabConfig[variante] = selectedtab
                }
                else {
                    moduleName = ctrl.modulesConfig[variante]
                    selectedtab = ctrl.tabConfig[variante]
                }
                ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);
            }
        }
    }

    static getCurrentModule(ctrl:any, i:any, tempProcAsso:any) {
        let currentModule = <any>{};
        currentModule = null;

        ctrl.currentZone.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
            if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante)&& tempProcAsso.length > 0){
                if(ctrl.currentZone.Modules[i].IsTabHelp ) {
                    ctrl.helpTab = '/app/'+ctrl.applicationName+'/'+ctrl.zoneName+'/'+ctrl.currentZone.Modules[i].ModuleName+'/'+ctrl.idEntityUse+'/'+ctrl.currentZone.Modules[i].Name
                }
                if(ctrl.currentZone.Modules[i].AllowFilterEntity == null) {
                    ctrl.currentZone.Modules[i].AllowFilterEntity = false;
                }
                currentModule = {
                    Name : ctrl.currentZone.Modules[i].Name,
                    Label : ctrl.currentZone.Modules[i].Label,
                    ToolTip : ctrl.currentZone.Modules[i].Tooltip,
                    DisplayOrder : parseInt(ctrl.currentZone.Modules[i].Order),
                    EntityCible : ctrl.currentZone.Modules[i].EntityCible,
                    ModuleName : ctrl.currentZone.Modules[i].ModuleName,
                    AllowFilterEntityT:  ctrl.currentZone.Modules[i].AllowFilterEntity,
                    Tabs : [],
                    IsTabHelp : ctrl.currentZone.Modules[i].IsTabHelp
                };
            }
        });

        if(currentModule != null) {     
            for (var j = 0; j < ctrl.currentZone.Modules[i].Tabs.length; j++) {
                var procsAvailable =  ctrl.currentZone.Modules[i].Tabs[j].AvailableProcedureAssociations.split('.');
                var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                });
                ctrl.currentZone.Modules[i].Tabs[j].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante)&& tempProcAsso.length > 0){
                        if(ctrl.currentZone.Modules[i].Tabs[j].IsTabHelp) {
                            ctrl.helpTab = '/app/'+ctrl.applicationName+'/'+ctrl.zoneName+'/'+ctrl.currentZone.Modules[i].Name+'/'+ctrl.idEntityUse+'/'+ctrl.currentZone.Modules[i].Tabs[j].Name
                        }

                        if(ctrl.currentZone.Modules[i].Tabs[j].AllowFilterEntity == null) {
                            ctrl.currentZone.Modules[i].Tabs[j].AllowFilterEntity = false;
                        }

                        if(currentModule.Tabs.filter((tb: { Name: string; DisplayOrder : number, Label :string}) => tb.Name == ctrl.currentZone.Modules[i].Tabs[j].Name && tb.DisplayOrder == parseInt(ctrl.currentZone.Modules[i].Tabs[j].Order)&& tb.Label == ctrl.currentZone.Modules[i].Tabs[j].Label).length == 0) {
                            currentModule.Tabs.push({
                                Name : ctrl.currentZone.Modules[i].Tabs[j].Name,
                                Label : ctrl.currentZone.Modules[i].Tabs[j].Label,
                                ToolTip : ctrl.currentZone.Modules[i].Tabs[j].Tooltip,
                                DisplayOrder : parseInt(ctrl.currentZone.Modules[i].Tabs[j].Order),
                                EntityCible : ctrl.currentZone.Modules[i].Tabs[j].EntityCible,
                                AllowFilterEntityT:ctrl.currentZone.Modules[i].Tabs[j].AllowFilterEntity,
                                ModuleName : currentModule.Name,
                                IsGridEntity : ctrl.currentZone.Modules[i].Tabs[j].IsGridEntity,
                                DetailEntity : ctrl.currentZone.Modules[i].Tabs[j].DetailEntity,
                            });
                        }
                    }
                });
            }
        }

        return currentModule;
    }

    static getListTabGroupTemplate(data:any) {
        if(data.items[0].ModuleLabel.length > 2)
            return $('<span>'+data.items[0].ModuleLabel+'</span>')
        else
            return $('')
    }

    static getFullTabs(ctrl:any, modules:any) {
        let fullTabs: any[] = [];
        for (var i = 0; i < modules.length; i++) {
            modules[i].Label = StringUtils.decodeSpecialEntitiesHtml(modules[i].Label)
            for (var j = 0; j < modules[i].Tabs.length; j++) {
                modules[i].Tabs[j].Label = StringUtils.decodeSpecialEntitiesHtml(modules[i].Tabs[j].Label)
                modules[i].Tabs[j].ToolTip = StringUtils.decodeSpecialEntitiesHtml(modules[i].Tabs[j].ToolTip)
                modules[i].Tabs[j].ModuleParentOrder =  modules[i].DisplayOrder;
                if(modules[i].Tabs[j].ModuleParentOrder == null) {
                    modules[i].Tabs[j].ModuleParentOrder =  modules[i].Order;
                }
                modules[i].Tabs[j].ModuleLabel =  modules[i].Label;
                modules[i].Tabs[j].ModuleName =  modules[i].Name;
                if((ctrl.identifier != '0' || modules[i].Tabs[j].IsGridEntity) && !modules[i].Tabs[j].DetailEntity && !(modules[i].Tabs[j].Name =='DET2' && modules[i].ParentEntityCible != modules[i].EntityCible)) {
                    fullTabs.push(modules[i].Tabs[j]);
                }
            }
            if(modules[i].Tabs.length == 0) {
                modules[i].ModuleParent = 0;
                modules[i].ModuleParentOrder = 0;
                modules[i].ModuleLabel =  '';
                fullTabs.push(modules[i]);
            }

        }

        return fullTabs;
    } 

    static setTabs(ctrl:any) {
        for (var j = 0; j < ctrl.currentZone.Modules.length; j++) {
            if(ctrl.currentModule.EntityCible == ctrl.currentZone.Modules[j].EntityCible && ctrl.currentModule.ParentEntityCible == ctrl.currentZone.Modules[j].ParentEntityCible)
            for(var i = 0; i < ctrl.currentZone.Modules[j].Tabs.length; i++) {
                if(!ctrl.currentZone.Modules[j].Tabs[i].IsGridEntity && ctrl.currentEntity && ctrl.currentEntity.EntityType == ctrl.currentZone.Modules[j].EntityCible) {
                    var procsAvailable =  ctrl.currentZone.Modules[j].Tabs[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => { return f === tb; })
                    });
                    ctrl.currentZone.Modules[j].Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if(((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tempProcAsso.length > 0)) {
                            ctrl.tabs.push(
                                (ctrl.currentZone.Modules[j].Tabs.length > 1 || ctrl.currentZone.Modules[j].Tabs[i].Name.substring(0, ctrl.currentZone.Modules[j].Tabs[i].Name.length-1) != ctrl.currentZone.Modules[j].Name) 
                                ? ctrl.currentZone.Modules[j].Name+'/'+ctrl.currentZone.Modules[j].Tabs[i].Name
                                : ctrl.currentZone.Modules[j].Tabs[i].Name);
                        }
                    });
                }
            }

            if(ctrl.currentZone.Modules[j].Tabs.length == 0) {
                var procsAvailable =  ctrl.currentZone.Modules[j].AvailableProcedureAssociations.split('.');
                var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                });
                ctrl.currentZone.Modules[j].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if (((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tempProcAsso.length > 0)) {
                        ctrl.tabs.push(ctrl.currentZone.Modules[j].Name);
                    }
                });
            }
        }
    }

    static getListTabDatasourceObject(fullTabs:any) {
        return {
            store: fullTabs,
            key: 'Label',
            group: 'ModuleParentOrder',
            sort: 'DisplayOrder'
        };
    }

    static async handleTabAndVarianteChanges (ctrl:any, changes:any) {
        if (ctrl.fullyLoaded && ((changes.currentTab && !ctrl.fullyLoaded && ctrl.variante.length > 0) || (changes.variante && !ctrl.fullyLoaded && ctrl.currentTab != null))) {
            await ctrl.loadPanel();
        }

        if (ctrl.fullyLoaded && (changes.currentTab && ctrl.variante.length > 0 && changes.currentTab && ctrl.currentTab !== undefined && (typeof ctrl.currentTab !== 'undefined') && (typeof changes.currentTab.currentValue !== 'undefined') && ctrl.identifier.length > 0 && ctrl.identifier != ctrl.idEntityUse)) {
            await ctrl.loadPanel();
        }
        else if (changes.currentTab && changes.currentTab != null && (typeof changes.currentTab !== 'undefined') && ctrl.selectedTab != null && ctrl.currentTab.Name != ctrl.selectedTab.Name) {
            ctrl.selectedTab = ctrl.currentTab;
            ctrl.multiTab = ctrl.currentModule.Tabs.length > 1 && ctrl.tabName.substring(0, ctrl.tabName.length-1) != ctrl.currentModule.Name;
            ctrl.tabRef  = ctrl.zoneName+'_'+ctrl.applicationName ;
            var instance = $('#listTabs').dxLookup('instance');
            instance.repaint();
        }
    }

    static async handleCurrentStateChange(ctrl:any, changes:any) {
        if (changes.currentState) {
            if (ctrl.currentState != null) {
                ctrl.currentState.Label = StringUtils.decodeSpecialEntitiesHtml(ctrl.currentState.Label);
                await ctrl.displayToolbarAction();
                await ctrl.loadPanel();
            }
            else if (!ctrl.fullyLoaded) {
                await ctrl.displayToolbarAction();
                await ctrl.loadPanel();
            }
        }
    }

    static filterAndOrganizeReports(ctrl:any, tempRapport:any) {
        let tabs: any[] = [];
        for(var i = 0;i<tempRapport.length;i++) {
            var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                return tempRapport[i].AvailableProcedureAssociations.some((f : string) => { return f === tb;})
            });
            tempRapport[i].AllowedVariantes.forEach(async (allowVariant: string) => {
                if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tempProcAsso.length > 0) {
                    tabs = [];
                    for(var j = 0;j<tempRapport[i].Tabs.length;j++) {
                        tempRapport[i].Tabs[j].AllowedVariantes.forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && tabs.indexOf(tempRapport[i].Tabs[j]) == -1) {
                                tabs.push(tempRapport[i].Tabs[j]);
                            }
                        });
                    }
                    tempRapport[i].Tabs = tabs;
                    if(ctrl.reportsDef.indexOf(tempRapport[i]) == -1) {
                        ctrl.reportsDef.push(tempRapport[i]);
                    }
                }
            });
        }
    }

    static getRetrieveModulesByProcedure (ctrl:any) {
        var modules =  [];

        for (var i = 0; i < ctrl.currentZone.Modules.length; i++) {
            var procsAvailable =  ctrl.currentZone.Modules[i].AvailableProcedureAssociations.split('.');
            var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                return procsAvailable.some((f : string) => {
                    return f === tb;
                })
            });

            var currentModule = FrontOfficeToolbarFactory.getCurrentModule(ctrl, i, tempProcAsso);
         
            if (currentModule != null) {
                modules.push(currentModule)
            }
            
        }

        return modules;
    }
}