import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, of, lastValueFrom } from "rxjs";
import { shareReplay, catchError, tap, retry } from 'rxjs/operators';
@Injectable()
export class DocumentService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}
    private documentsUrl = './api/{version}/spaces/{space}/documents';
    downloadDocument(space: string, identifier : string) {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.documentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + identifier + '';
            this.http.get(url,{observe: 'response', responseType: 'blob' as 'json'}).subscribe(
                (response: any) =>{
                    var contentDisposition = response.headers.get('content-disposition');
                    var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    filename = decodeURIComponent(filename);
                    var blob = new Blob([response.body], {type: response.body.type});

                    var downloadURL = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = downloadURL;
                    link.download = filename;
                    link.click();
                }
            )
        }
        deleteDocument(space: string, identifier : string) {
            var storage = this.localStorageService.getWithExpiration(space);
            storage = JSON.parse(storage);
            var url = this.documentsUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + identifier + '.json';
                this.http.delete(url,{responseType: 'blob' as 'json'}).subscribe(
                    (response: any) =>{
                        console.log('delete ok')
                    }
                )
            }
            deleteDocumentCampagne(space: string, identifier : string) {
                var storage = this.localStorageService.getWithExpiration(space);
                storage = JSON.parse(storage);
                var url = './api/v1/spaces/'+space+ '/documents/' + identifier + '.json';
                    this.http.delete(url,{responseType: 'blob' as 'json'}).subscribe(
                        (response: any) =>{
                            console.log('delete ok')
                        }
                    )
                }
                addCampagneDocument(space: string, identifier : string, document:any): Observable<any> {
                    var url = './api/v1/spaces/'+space+ '/documents/' + identifier + '/campagnes_documents.json';
                    return this.http.post<string>(url, document).pipe(
                        retry(1),
                        shareReplay(1),
                        tap(data => {
                            JSON.stringify(data)
                        }),
                        catchError(this.handleError)
                    )
                }
            getAllCampagneDocuments(space: string, identifier : string): Observable<any[]> {
                var storage = this.localStorageService.getWithExpiration(space);
                storage = JSON.parse(storage);
                var url = './api/v1/spaces/'+space+ '/documents/' + identifier + '/campagnes_documents.json';
                return this.http.get<any[]>(url)
                .pipe(
                    retry(1),
                    shareReplay(1),
                    tap(data => JSON.stringify(data)),
                    catchError(this.handleError)
                );
                }

                
    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}