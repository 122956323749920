import { PlatformLocation } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AppToastsComponent } from 'app/shared/toast.component';
import { environment } from "../../environments/environment";

export const authConfig: AuthConfig = {
    issuer: environment.idp.issuer,
    redirectUri: environment.idp.redirectUri,
    tokenEndpoint: environment.idp.tokenEndpoint,
    silentRefreshRedirectUri: environment.idp.silentRefreshRedirectUri,
    useSilentRefresh: environment.idp.useRefreshToken,
    clientId: environment.idp.clientId,
    responseType: environment.idp.responseType,
    scope: environment.idp.scope,
    showDebugInformation: environment.idp.showDebugInformation,
    requireHttps: environment.idp.requireHttps
};

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    isLoaded: boolean = false;
    public afterLogin = new EventEmitter();
    storage: Storage;
    hubConnection?: signalR.HubConnection;
    timerId : any;
    socket : WebSocket;
    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private plateformLocation: PlatformLocation,
        private toastService: AppToastsComponent
    ) {

        this.storage = window.sessionStorage;
        authConfig.strictDiscoveryDocumentValidation = false;	
        authConfig.postLogoutRedirectUri = environment.idp.postLogoutRedirectUri;
        this.oauthService.configure(authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            if (this.hasTokenValid()) {
                //this.oauthService.setupAutomaticSilentRefresh();
                if (this.oauthService.state) {
                    this.router.navigate([decodeURIComponent(this.oauthService.state)]);
                } else {

                    this.router.navigate([this.plateformLocation.hash.substring(1)]);
                }

                this.connectWS();
                this.afterLogin.emit('ok')
                this.isLoaded = true;
            } else {
                //this.oauthService.setupAutomaticSilentRefresh();
                this.oauthService.silentRefresh();
                this.oauthService.initCodeFlow(this.plateformLocation.hash.substring(1), {'acr_values' : 'tenant:'+authConfig.clientId, 'TenantId' : authConfig.clientId});
            }
        });
    }

checkTokenValid()
{
    if (this.hasTokenValid()) {
        
        return;
    } else {
        
        //this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.silentRefresh();
        this.oauthService.initCodeFlow(this.plateformLocation.hash.substring(1), {'acr_values' : 'tenant:'+authConfig.clientId, 'TenantId' : authConfig.clientId});
    }
}

connectWS()
{
    var url = 'wss://'+this.plateformLocation.hostname+':'+this.plateformLocation.port +'/ws';
    let token = '';
    if(this.hasAccessToken())
    token = this.accessToken;
else
token = this.accessToken
clearInterval(this.timerId);
try
{
              this.socket = new WebSocket(url+'?access_token='+token);
}catch
{
    return;
}
              this.socket.addEventListener('message', async (event) => {
                  let data = JSON.parse(event.data);
                   console.log(data)
                  switch (data.Level)
                  {
                      case "Success":
                          this.toastService.showSuccess(data);
                          break;
                      case "Info":
                          this.toastService.showStandard(data);
                          break;
                      case "Warning":
                          this.toastService.showWarning(data);
                          break;
                      case "Danger":
                          this.toastService.showError(data);
                          break;
                      default:
                          this.toastService.sendNotificationInHistory(data);
                          break;
                  }
              });
              var ctrl = this;
              let  tm = setTimeout(function () {

                  /// ---connection closed ///
       
       
           }, 50000);
           this.socket.onopen = function (event) {
              setInterval(()=>ctrl.ping(tm), 90000);
              }
              this.socket.onclose = function(e) {  
                console.log('socket closed try again'); 
                ctrl.timerId = setInterval(() => {
                    ctrl.connectWS();
                }, 10000);
              }
              
              this.socket.onerror = function(err) {
                console.error(err)
                ctrl.socket.close();
              }
}
ping(tm:any) {
    try
    {
    this.socket.send('__ping__');
    tm = setTimeout(function () {

        /// ---connection closed ///


 }, 5000);
}
 catch
{
    return;
}
}

pong( tm:any) {
    clearTimeout(tm);
}

    hasTokenValid() {
        if (this.hasAccessToken() && this.hasIdToken()) {
            var dt = new Date().getTime();
            return dt < this.getIdTokenExpiration() && dt < this.getAccessTokenExpiration();
        }
        return false;
    }

    hasAccessToken() {
        return this.oauthService.hasValidAccessToken();
    }

    hasIdToken() {
        return this.oauthService.hasValidIdToken();
    }

    getIdTokenExpiration(){
        return this.oauthService.getIdTokenExpiration();
    }

    getAccessTokenExpiration() {
        return this.oauthService.getAccessTokenExpiration();
    }

    getAccessToken() {
        return this.oauthService.getAccessToken();
    }

    getIdToken() {
        return this.oauthService.getIdToken();
    }

    getEmail() {
        var claims: any = this.oauthService.getIdentityClaims();
        if (!claims) {
            return null;
        }
        return claims['name'];
    }

    getIdp() {
        var claims: any = this.oauthService.getIdentityClaims();
        if (!claims) {
            return null;
        }
        return claims['provider'];
    }

    public login(){
        this.oauthService.tryLoginCodeFlow();
    }
    public forceRefresh() {
        this.logout();
        this.login();
    }

    public logout() {
        this.oauthService.logOut();
        window.location.href = this.logoutUrl;
        return false;
    }
    

    public get accessToken() { return this.oauthService.getAccessToken(); }
    public get refreshToken() { return this.oauthService.getRefreshToken(); }
    public get identityClaims() { return this.oauthService.getIdentityClaims(); }
    public get idToken() { return this.oauthService.getIdToken(); }
    public get logoutUrl() { return this.oauthService.postLogoutRedirectUri; }
}