import { ApplicationRef, Component,  EventEmitter,  Input, OnInit, Output, createComponent } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import * as $ from 'jquery';
import { LocalStorageService } from "app/shared/local-storage.service";
import { BoService } from "app/back-office/back-office.service";
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import * as numeral from "numeral";
import { AuthService } from "app/shared/auth.service";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { DownloadService } from "../../shared/download.service";
import { DateUtils } from "../../utils/date-utils";
import { ListUtils } from "app/utils/list-utils";
import { PopupService } from "../../shared/popup.service";
import { StringUtils } from "app/utils/string-utils";

@Component({
  selector:'ngbd-modal-communication-content',
  template: `
    <div class="modal-body">
      <div class="crud-communication">
          <div id="gridCommunication"></div>
      </div>
      <div id="file-uploader"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
        Annuler
      </button>
      <button type="button" class="btn btn-outline-dark" id="popupSendMeButton" disabled> 
        M'envoyer pour contrôle
      </button>
      <button type="button" class="btn btn-primary modalOkButton" id="popupSendButton" disabled >          
        Envoyer maintenant
      </button>
    </div>
  `,
  styleUrls: ['./modal-communication.component.css']
})

export class NgbdModalCommunicationDetailContent implements OnInit {
  @Input() title: string = "default";

  @Output() messageToParent :EventEmitter<any> = new EventEmitter();

  public entityIdentifier : string;
  public applicationName : string;
  public communication : any;
  public version :string;
  public space : string;
  public storage : any;
  public entityType : string;
  public userMail : string;
  userEmail : string = '';
  newLinetabs: any[] = [];
  messages: any[] = [];
  documents: any[] = [];
  communications: any[] = [];
  constructor(
    public httpClient: HttpClient,
    private authService: AuthService,   
    private downloadService: DownloadService,
    private localStorageService: LocalStorageService,    
    private appStructService:  FrontOfficeStructService,
    private componentService: BoService,
    public sanitizer: DomSanitizer){}

    async ngOnInit(): Promise<void> {
      var storage = this.localStorageService.getWithExpiration(this.space);
      storage = JSON.parse(storage);
      this.storage = storage
      this.version = storage.Version;
      if(!storage.Lists) {
        storage.Lists = [];
      }
      let masterColumns: any[] = [];
      this.userEmail = this.authService.getEmail();
      var configGrid = <any>{
        "paging": { "pageSize": 20 },
        "remoteOperations": true,
        "columnAutoWidth": true,
        "noDataText": "Aucune données présente pour ce composant",
        "keyExpr": "Id",
        "width":"100%",
        "showBorders": true,
        "columnMinWidth": 20,
        "allowColumnResizing": true,
        "allowColumnReordering": true,
        "sorting": { "mode": "multiple" },
        "rowAlternationEnabled": true,
        "filterRow": { "visible": true },
        "filterPanel": { "visible": false },
        "headerFilter": { "visible": false },
        "selection": {
          "mode": "multiple",
          "showCheckBoxesMode":"always"
        },
        "scrolling": { "mode": "virtual" },
        "columnChooser": { "enabled": false },
        "columnFixing": { "enabled": true },
        "columns": [
          this.getIdColumn(),
          {
            "dataField": "OpIdentifiant",
            "caption": "Message",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
            "visible": false,
            "visibleIndex": 3,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": false,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Message",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "OpIdentifiant"
          },  
          this.getExpediteurColumn(8, true, "Message", true),
          {
              "dataField": "SentToEpUser",
              "caption": "Envoi aux utilisateur de l'établissement",
              "dataType": "boolean",
              "cellTemplate": null,
              "format": null,
              "width": null,
              "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
              "visible": false,
              "visibleIndex": 8,
              "allowSorting": true,
              "isRequired": false,
              "defaultValue": "",
              "tooltip": null,
              "allowEditing": true,
              "sortOrder": null,
              "type": null,
              "isIncludedInMaster": true,
              "isIncludedInDataGrid": false,
              "renderType": "default",
              "tabName": "Message",
              "style": null,
              "cssClass": null,
              "link": null,
              "calculSummary": null,
              "name": "SentToEpUser"
          },
          this.getCcColumn(),
          this.getCccColumn(10, false, true, "Message"),
          {
              "dataField": "ApplicationName",
              "caption": "Nom de l'application associé",
              "dataType": "string",
              "cellTemplate": null,
              "format": null,
              "width": 100,
              "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
              "visible": false,
              "visibleIndex": 11,
              "allowSorting": true,
              "isRequired": true,
              "defaultValue": "",
              "tooltip": null,
              "allowEditing": true,
              "sortOrder": null,
              "type": null,
              "isIncludedInMaster": true,
              "isIncludedInDataGrid": false,
              "renderType": "default",
              "tabName": "Message",
              "style": null,
              "cssClass": null,
              "link": null,
              "calculSummary": null,
              "validationRules": [{ "type": "required" }],
              "name": "ApplicationName"
          },
          this.getTitleColumn(11, true, "Message", true),
          this.getMessageColumn(12, true, "Message"),
          this.getDateCreationColumn("Date de création", false, 5, true, false, "default", "Message", true),
          {
            "dataField": "DateDerniereModification",
            "caption": "Date de MAJ",
            "dataType": "date",
            "cellTemplate": null,
            "format": "dd.MM.yyyy",
            "width": null,
            "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
            "visible": false,
            "visibleIndex": 6,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "1899-12-31T23:00:00.000Z",
            "tooltip": null,
            "allowEditing": false,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Message",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "DateDerniereModification"
          },
          {
            "dataField": "DateEnvoi",
            "caption": "Date de l'envoi",
            "dataType": "date",
            "cellTemplate": null,
            "format": "dd.MM.yyyy",
            "width": null,
            "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
            "visible": true,
            "visibleIndex": 7,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "1899-12-31T23:00:00.000Z",
            "tooltip": null,
            "allowEditing": false,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": true,
            "renderType": "default",
            "tabName": "Message",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "DateEnvoi"
          },
          {
            "dataField": "Excel",
            "caption": "Export excel",
            "dataType": "string",
            "cellTemplate": null,
            "format": "",
            "width": null,
            "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
            "visible": false,
            "visibleIndex": 8,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Message",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Excel"
          },
          {
            type: 'buttons',
            width: 110,
            buttons: ['delete']
          }
        ],
        "pager": null,
        "editing": {
            "allowUpdating": true,
            "allowDeleting": true,
            "canDelete": true,
            "allowAdding": false,
            "useIcons": true,
            "mode": "cell"
        },
        "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
        "loadPanel": {
            "enabled": true,
            "showIndicator": true,
            "shading": true
        },
        "masterDetail": { "enabled": true },
        "dataSource": []
    }
    var ctrl = this;
    
    ctrl.newLinetabs = [];
    for (var i = 0; i < configGrid.columns.length; i++) {
      var column = configGrid.columns[i];
      if(column.isIncludedInMaster) {
        masterColumns.push(column);
      }
      if(column.isIncludedInMaster || column.isIncludedInDataGrid) {
        let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, true)
        var currentTab:any = null;
        var currentTabNew:any = null;
        for(var j=0; j<  ctrl.newLinetabs.length; j++) {
          if( ctrl.newLinetabs[j].title == column.tabName)
            currentTabNew =  ctrl.newLinetabs[j];
        }
        if(currentTab == null) {
          currentTab = {};
          currentTab.items = [];
          currentTab.items.push(item);
          currentTab.colCount= 1;
          currentTab.title = column.tabName;
        }
        else {
          if(item.colSpan && item.colSpan== 2) {
            let previousItem = currentTab.items[currentTab.items.length-1];
            previousItem.colSpan = 2;
          }
          currentTab.items.push(item);
        }
        if(currentTabNew == null) {
          currentTabNew = {};
          currentTabNew.items = [];
          if(!item.editorOptions.disabled)
            currentTabNew.items.push(item);
          currentTabNew.colCount= 1;
          currentTabNew.title = column.tabName;
          ctrl.newLinetabs.push(currentTabNew);

        }
        else {
          if(item.colSpan== 2 && currentTabNew.items.length > 0) {
            let previousItem = currentTabNew.items[currentTabNew.items.length-1];
            previousItem.colSpan = 2;
          }
          if(!item.editorOptions.disabled)
            currentTabNew.items.push(item);
        }
      }
    }
    
    configGrid.onSaving = async (event : any)=> {
      if(event.changes.length == 0)
      event.cancel = true;;
      if(!ctrl.validateApp(event.changes[0].data, event.component))
      event.cancel = true;;
    },
    configGrid.onSaved = async (event : any)=> {
      if(event.changes.length == 0)
      return;
      if(event.changes[0].type == 'update') {
        if(typeof(event.changes[0].data.List) !== "undefined" || event.changes[0].data.List == null) {
          event.changes[0].data.IsVisible="True";
          event.changes[0].data.IsEditable="True";
          event.changes[0].data.List =''
                
        }  
        await lastValueFrom(ctrl.componentService.updateAttribut(ctrl.space, ctrl.entityType, parseFloat(ctrl.entityIdentifier), 'CD', 'communication', event.changes[0].data));
      }
      else  if(event.changes[0].type == 'remove') {
          ctrl.componentService.deleteAttribut(ctrl.space, ctrl.entityType, parseFloat(ctrl.entityIdentifier), 'CD', 'communication', event.changes[0].key).subscribe({
            next: async configGrid => {
              await ctrl.refreshData();
          },
          error: err => console.log(err)
        });
      }
      else if(event.changes[0].type == 'insert') {
        event.changes[0].data.Id = 0;
          ctrl.componentService.insertAttribut(ctrl.space, ctrl.entityType, parseFloat(ctrl.entityIdentifier), 'CD', 'communication', event.changes[0].data).subscribe({
            next: configGrid => {
            let grid = $('#gridCommunication').dxDataGrid('instance');
            grid.option("editing.mode", "cell")
            var store = grid.getDataSource();
            var items = store.items();
            items[items.length-1].DisplayEnumCode= configGrid.replace(parseFloat(ctrl.entityIdentifier)+'_Numerotation_','')
            items[items.length-1].Id = configGrid
            var index = [];
            index.push(grid.totalCount()-1);
            grid.repaintRows(index);
          },
          error: err => console.log(err)
        });
      }
    }
    
    ctrl.newLinetabs = ctrl.newLinetabs.filter((tb: { items: any; }) => tb.items.length > 0);
    var ctrl = this;
    var gridEvent = {
      onCellPrepared: function (e: any) {
        if (e.column.style && e.column.style !== "") {
          var styles = e.column.style.split(';')
          styles.forEach((style: string) => {
            var attributs = style.split(':');
          e.cellElement.css(attributs[0], attributs[1]);
          });
        }
        if ( e.column.buttons && e.rowType =="header" && true) {
          e.cellElement.dxButton({
            hint: 'Ajouter une ligne',
            icon: 'add',
            onClick() { ctrl.addNewLine();},
          }).dxButton('instance')
          e.cellElement.addClass("add-grid-button")
        }
      },
      onSelectionChanged: function (selectedItems: any) { 
        ctrl.communication = selectedItems.selectedRowsData.length == 1 ? selectedItems.selectedRowsData[0] : null;
        $('#popupSendButton').prop('disabled', selectedItems.selectedRowsData.length != 1);
        $('#popupSendMeButton').prop('disabled', selectedItems.selectedRowsData.length != 1);
      },
      onRowExpanding: function(info : any) {
        if(!info.component.isRowSelected(info.key)) {
          var selectRows = info.component.getSelectedRowKeys();
          info.component.collapseRow(selectRows);
          info.component.selectRows([info.key])
        }
      },
      onRowCollapsing: function(info : any) {
        if(info.component.isRowSelected(info.key)) {
          info.component.deselectRows([info.key])
        }
      },
      onInitNewRow: function(info : any) {
        let  columnCount = info.component.columnCount()
        for(var i = 0; i < columnCount; i++) {  
          var column = info.component.columnOption(i);
          if(column.defaultValue && column.defaultValue.length > 0) {
            if(column.dataType == 'DynamicList' && column.dataField.indexOf('Code.') == -1) {
              var list = JSON.parse(storage.Lists[column.format]);
              info.data[column.dataField.replace('.IdEnumerationItem','')] = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
            }
            else if(column.dataType == 'date' || column.dataType == 'datetime') {
              info.data[column.dataField] = DateUtils.getDateFromStringOrToday(column.defaultValue);
            }
            else
              info.data[column.dataField]=column.defaultValue;
          }
          else if(column.dataType == 'number') {
            info.data[column.dataField]= 0
          }
          else if(column.dataType == 'date' || column.dataType == 'datetime') {
            info.data[column.dataField] = new Date();
          }
          else if(column.dataType == 'boolean') {
            info.data[column.dataField]= false
          }
          else if(column.dataType == 'string') {
            info.data[column.dataField]= ''
          }
          else if(column.dataType == 'DynamicList' && column.dataField.indexOf('Code.') == -1) {
            var list = JSON.parse(storage.Lists[column.format]);
            info.data[column.dataField.replace('.IdEnumerationItem','')]= list[0]
          }
          if(column.dataField == 'Parent')
            info.data[column.dataField] = null
          if(column.dataField == 'ApplicationName'){
            info.data[column.dataField] = ctrl.applicationName;
            column.visible = false;
          }
          if(column.dataField == 'Expediteur'){
            info.data[column.dataField] = ctrl.userEmail;
          }
        }
      },
    };
    if(masterColumns.length > 0) {
      await ctrl.configMaster(configGrid, masterColumns, storage, ctrl, true, true, 'communication');
    }
    this.communications = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, parseFloat(ctrl.entityIdentifier), '', 'communication'));
    this.communications = this.communications.filter((tb: { ApplicationName: string; }) => tb.ApplicationName == this.applicationName);
    configGrid.dataSource = this.communications;
    $('#gridCommunication').dxDataGrid(<string>configGrid).dxDataGrid(gridEvent).dxDataGrid('instance')
    this.createFileUploader();
  }

  async reloadDocuments() {
    const colIndex = this.communication.Id.toString().indexOf(':');
    var onglet = this.communication.Id.toString().substring(0, colIndex).trim();
    var ligne = this.communication.Id.toString().substring(colIndex + 1).trim()

    this.documents = await lastValueFrom(this.appStructService.getAllDocuments(this.applicationName,this.version,this.space, this.entityIdentifier, 'Feature', 'Communication', '1', ligne, 'communicationSpe',''));
    let grid = $('#formMasterDoc').dxDataGrid('instance');
    grid.option('dataSource', this.documents);
    grid.refresh();
  }

  async reloadMessages() {
    this.messages = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, parseFloat(this.entityIdentifier), '', 'envoicomm'));
    
    this.messages = this.messages.filter((tb: {
      CommunicationId: any; ApplicationName: string; 
    }) => tb.ApplicationName == this.applicationName && tb.CommunicationId == this.communication.Id);
    let grid = $('#formMasterMess').dxDataGrid('instance');
    grid.option('dataSource', this.messages);
    grid.refresh();
  }

  async refreshData() {
    var ctrl = this;

    this.communications = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, parseFloat(ctrl.entityIdentifier), '', 'communication'));
    this.communications = this.communications.filter((tb: { ApplicationName: string; }) => tb.ApplicationName == this.applicationName);
    let grid = $('#gridCommunication').dxDataGrid('instance');
    grid.option("dataSource", this.communications);
    grid.option("editing.mode", "cell")
    grid.refresh();
  }
  async configMaster(configGrid : any, masterColumns: any[], storage: any, ctrl:NgbdModalCommunicationDetailContent, addMessage : boolean, addDocument : boolean, nomcomposant : string) {
    var tabs: any[]= [];
    for (var i = 0; i < masterColumns.length; i++) {
      var column = masterColumns[i];
      if(column.dataField == 'FkObject' || column.dataField == 'Id' || column.dataField == 'Code')
        continue;
      let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, false)
      var currentTab:any = null;
      for(var j=0; j<  tabs.length; j++) {
        if( tabs[j].Name == StringUtils.decodeSpecialEntitiesHtml(column.tabName))
          currentTab =  tabs[j];
      }
      if(currentTab == null) {
        currentTab = {};
        currentTab.items = [];
        currentTab.items.push(item);
        currentTab.Name = StringUtils.decodeSpecialEntitiesHtml(column.tabName);
        tabs.push(currentTab);
      }
      else {
        if(item.colSpan== 2) {
          let previousItem = currentTab.items[currentTab.items.length-1];
          previousItem.colSpan = 2;
        }
        currentTab.items.push(item);
      }
    } 

    if(masterColumns.filter((tb: { dataField: string; }) => tb.dataField == 'DateDerniereModification').length > 0) {
      var tabModel = tabs.filter((tb: { Name: string; }) => tb.Name == 'Message')[0];
      tabModel.items = this.getItems(tabModel);
    } 
    else if(masterColumns.filter((tb: { dataField: string; }) => tb.dataField == 'Destinataires').length > 0) {
      var tabModel = tabs[0];
      tabModel.items = this.getItems(tabModel);
    }

    if(this.space !="MDEL") {
      configGrid.masterDetail = {
        enabled: true,
        template: async function(element : any, masterDetailOptions :any) {
          let grid = $('#gridCommunication').dxDataGrid('instance');
          grid.option("editing.mode", "cell")
          let detailEntity = await lastValueFrom(ctrl.componentService.getMasterComponent(ctrl.space, masterDetailOptions.data.Id, nomcomposant, ctrl.entityType));
          var items = [];
          for(var i=0; i<  tabs.length; i++) {
            if( tabs[i].Name == '')
            tabs[i].Name ='Infos'
            items.push({
              title:  tabs[i].Name,
              data: { item : detailEntity, items :tabs[i].items, index:i},
              template: function(itemData:any, itemIndex:any, itemElement:any) {
                var formName = 'formMaster'+itemData.data.index;
                var info = $('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxForm({
                  formData: itemData.data.item,                    
                  labelMode: 'floating',
                  scrollingEnabled:true,
                  showColonAfterLabel: true,
                  onFieldDataChanged : async function(e) {
                    if(e.dataField == 'Message')
                      return;
                    var form = $('#'+formName).dxForm('instance');
                    var validation = form.validate();
                    if(validation.isValid) {
                      var componentRow = form.option('formData');
                      var attribut = {
                        Name:e.dataField.replace('.IdEnumerationItem',''),
                        Value :e.dataField.endsWith('IdEnumerationItem') ? componentRow[e.dataField.split('.')[0]].IdEnumerationItem.toString() : componentRow[e.dataField].toString(),
                      }
                      await lastValueFrom(ctrl.componentService.updateAttributProperty(ctrl.space, ctrl.entityType, componentRow.Id, 'CD', nomcomposant, attribut));
                    }
                  },
                  colCount: 2,
                  items: itemData.data.items
                });
                itemElement.append(info);
              }
            })
          }
          if(addDocument) {
            items.push({
              title: 'Documents',
              data:ctrl.documents,
              template:async function(itemData:any, itemIndex:any, itemElement:any) {
                var formName = 'formMasterDoc';
                var configGrid =<any>{
                  "paging": { "pageSize": 20 },
                  "remoteOperations": true,
                  "columnAutoWidth": true,
                  "noDataText": "Aucune données présente pour ce composant",
                  "keyExpr": "Id",
                  "showBorders": true,
                  "columnMinWidth": 20,
                  "allowColumnResizing": true,
                  "allowColumnReordering": true,
                  "sorting": { "mode": "multiple" },
                  "rowAlternationEnabled": true,
                  "filterRow": { "visible": true },
                  "filterPanel": { "visible": false },
                  "headerFilter": { "visible": false },
                  "scrolling": { "mode": "virtual" },
                  "columnChooser": { "enabled": false },
                  "columnFixing": { "enabled": true },
                  "columns": [
                    ctrl.getIdColumn(),
                    {
                        "dataField": "DocLoadDate",
                        "caption": "Date d'ajout",
                        "dataType": "date",
                        "cellTemplate": null,
                        "format": "dd.MM.yyyy",
                        "width": null,
                        "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
                        "visible": true,
                        "visibleIndex": 1,
                        "allowSorting": true,
                        "isRequired": true,
                        "defaultValue": "1899-12-31T23:00:00.000Z",
                        "tooltip": null,
                        "allowEditing": false,
                        "sortOrder": null,
                        "type": null,
                        "isIncludedInMaster": false,
                        "isIncludedInDataGrid": true,
                        "renderType": "default",
                        "tabName": "",
                        "style": null,
                        "cssClass": null,
                        "link": null,
                        "calculSummary": null,
                        "name": "DocLoadDate"
                    },
                    {
                        "dataField": "SourceFileName",
                        "caption": "Nom du fichier",
                        "dataType": "string",
                        "cellTemplate": null,
                        "format": null,
                        "width": null,
                        "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
                        "visible": true,
                        "visibleIndex": 2,
                        "allowSorting": true,
                        "isRequired": true,
                        "defaultValue": "",
                        "tooltip": null,
                        "allowEditing": false,
                        "sortOrder": null,
                        "type": null,
                        "isIncludedInMaster": false,
                        "isIncludedInDataGrid": true,
                        "renderType": "default",
                        "tabName": "",
                        "style": null,
                        "cssClass": null,
                        "link": null,
                        "calculSummary": null,
                        "name": "SourceFileName"
                    },
                    {
                      type: 'buttons',
                      width: 110,
                      buttons: []
                    }
                  ],
                  "pager": null,
                  "editing": {
                      "allowUpdating": false,
                      "allowDeleting": true,
                      "canDelete": true,
                      "allowAdding": false,
                      "useIcons": true,
                      "mode": "cell"
                  },
                  "subPerimeter": "BASE:R|/D#BASE:R|/V#ECO:R/D#ECO:R|/V#ADM:R/D#ADM:R/V#",
                  "loadPanel": {
                      "enabled": true,
                      "showIndicator": true,
                      "shading": true
                  },
                  "masterDetail": { "enabled": false },
              }
              configGrid.dataSource = itemData.data;
              var gridEvent = {
                  onCellPrepared: function (e: any) {
                    if ( e.column.buttons && e.rowType =="header" && true) {
                      e.cellElement.dxButton({
                        hint: 'Ajouter une ligne',
                        icon: 'add',
                        elementAttr: {
                          id: "buttonAddDocCom",
                          class: "add-document-button"
                      }
                      }).dxButton('instance')                    
                      e.cellElement.addClass("add-grid-button")
                      const fileUploader = $('#file-uploader').dxFileUploader('instance');
                      fileUploader.option({
                        dialogTrigger: e.cellElement
                      });
                      fileUploader.repaint();
                    }
                    if (e.column.buttons && e.rowType =="data" ) {
                      var button = e.column.buttons.filter((tb: { hint: string; }) => tb.hint == 'Télécharger')[0]
                      if(button != null) {
                        const index = e.column.buttons.indexOf(button);
                        e.column.buttons.splice(index,1)
                      }

                      e.column.buttons.push({
                        hint: 'Télécharger',              
                        template: function (e:any, elem:any) {
                          let file = null
                          var html = '<i class="fa-solid fa-download"></i>'
                          file = $(html);
                          $(e).append(file);
                          return;
                        },
                        onClick(e:any) {
                          ctrl.downloadDocument(e.row.data)
                          e.event.preventDefault();
                        },
                      });

                      var button = e.column.buttons.filter((tb: { hint: string; }) => tb.hint == 'Supprimer')[0]
                      if(button != null) {
                        const index = e.column.buttons.indexOf(button);
                        e.column.buttons.splice(index,1)
                      }

                      e.column.buttons.push({
                        hint: 'Supprimer',              
                        template: function (e:any, elem:any) {
                          let file = null
                          var html = '<i class="fa-solid fa-trash"></i>'
                          file = $(html);
                          $(e).append(file);
                          return;
                        },
                        onClick(e:any) {
                          ctrl.deleteDocument(e.row.data.Id)
                          e.event.preventDefault();
                        },
                      });
                    }
                  },
                };
                configGrid.onSaved = async (event : any)=> {
                  if(event.changes.length == 0)
                    return;

                  if(event.changes[0].type == 'remove') {
                    ctrl.deleteDocument(event.changes[0].key)
                  }
                }

                var info = (<any>$('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxDataGrid(configGrid)).dxDataGrid(gridEvent);
                ctrl.reloadDocuments();
                itemElement.append(info);
              }
            })
          }
          if(addMessage) {
            var messages = ctrl.messages.filter((tb: { CommunicationId: number; }) => tb.CommunicationId == masterDetailOptions.data.Id);
            messages = messages.sort((a, b) => a['DateCreation'] > b['DateCreation'] ? -1 : a['DateCreation'] === b['DateCreation'] ? 0 : 1)
              
            items.push( {
              title: 'Historique',
              data:messages,
              template:async function(itemData:any, itemIndex:any, itemElement:any) {
                var formName = 'formMasterMess';
                var configGrid =<any>{
                  "paging": { "pageSize": 20 },
                  "remoteOperations": true,
                  "columnAutoWidth": true,
                  "noDataText": "Aucune données présente pour ce composant",
                  "keyExpr": "Id",
                  "showBorders": true,
                  "columnMinWidth": 20,
                  "allowColumnResizing": true,
                  "allowColumnReordering": true,
                  "sorting": { "mode": "multiple" },
                  "rowAlternationEnabled": true,
                  "filterRow": { "visible": true },
                  "filterPanel": { "visible": false },
                  "headerFilter": { "visible": false },
                  "scrolling": { "mode": "virtual" },
                  "columnChooser": { "enabled": false },
                  "columnFixing": { "enabled": true },
                  "columns": [
                    ctrl.getIdColumn(),
                    {
                      "dataField": "CommunicationId",
                      "caption": "CommunicationId",
                      "dataType": "number",
                      "cellTemplate": null,
                      "format": "",
                      "width": null,
                      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
                      "visible": false,
                      "visibleIndex": 2,
                      "allowSorting": true,
                      "isRequired": true,
                      "defaultValue": " .",
                      "tooltip": null,
                      "allowEditing": false,
                      "sortOrder": null,
                      "type": null,
                      "isIncludedInMaster": false,
                      "isIncludedInDataGrid": false,
                      "renderType": "default",
                      "tabName": "",
                      "style": null,
                      "cssClass": null,
                      "link": null,
                      "calculSummary": null,
                      "formItem": { "visible": false },
                      "name": "CommunicationId"
                    },
                    ctrl.getDateCreationColumn("Date d'envoi", true, 4, false, true, "default", "", false),
                    {
                        "dataField": "FkEtat.IdEnumerationItem",
                        "caption": "Etat de l'envoie",
                        "dataType": "DynamicList",
                        "cellTemplate": null,
                        "format": "Etat_Envoi_Campagne",
                        "width": null,
                        "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
                        "visible": false,
                        "visibleIndex": 5,
                        "allowSorting": true,
                        "isRequired": true,
                        "defaultValue": {
                          "IdEnumerationItem": "Etat_Envoi_Campagne_Envoye",
                          "ListName": null,
                          "Code": "Envoye",
                          "Label": "Envoyé",
                          "IsActive": true,
                          "Ordre": 1
                        },
                        "tooltip": null,
                        "allowEditing": false,
                        "sortOrder": null,
                        "type": null,
                        "isIncludedInMaster": true,
                        "isIncludedInDataGrid": false,
                        "renderType": "default",
                        "tabName": "",
                        "style": null,
                        "cssClass": null,
                        "link": null,
                        "calculSummary": null,
                        "name": "FkEtat.IdEnumerationItem"
                      },
                      ctrl.getExpediteurColumn(6, false, "", false),
                      {
                          "dataField": "Destinataires",
                          "caption": "Destinataires EP",
                          "dataType": "string",
                          "cellTemplate": null,
                          "format": null,
                          "width": null,
                          "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
                          "visible": false,
                          "visibleIndex": 7,
                          "allowSorting": true,
                          "isRequired": true,
                          "defaultValue": "",
                          "tooltip": null,
                          "allowEditing": false,
                          "sortOrder": null,
                          "type": null,
                          "isIncludedInMaster": true,
                          "isIncludedInDataGrid": false,
                          "renderType": "default",
                          "tabName": "",
                          "style": null,
                          "cssClass": null,
                          "link": null,
                          "calculSummary": null,
                          "name": "Destinataires"
                      },
                      ctrl.getCcColumn(),
                      ctrl.getCccColumn(9, true, false, ""),
                      ctrl.getTitleColumn(10, false, "", false),
                      ctrl.getMessageColumn(11, false, ""),
                  ],
                  "pager": null,
                  "editing": {
                      "allowUpdating": false,
                      "allowDeleting": false,
                      "canDelete": false,
                      "allowAdding": false,
                      "useIcons": true,
                      "mode": "cell"
                  },
                  "subPerimeter": "BASE:R|/D#BASE:R|/V#ECO:R/D#ECO:R|/V#ADM:R/D#ADM:R/V#",
                  "loadPanel": {
                      "enabled": true,
                      "showIndicator": true,
                      "shading": true
                  },
                  "masterDetail": { "enabled": true },
                }
                configGrid.dataSource = itemData.data;
                let masterColumns: any[] = [];
                for (var i = 0; i < configGrid.columns.length; i++) {
                  var column = configGrid.columns[i];
                  if(column.isIncludedInMaster) {
                      masterColumns.push(column);
                  }

                  if(column.dataField == "OpIdentifiant") {
                    let itemsT: any[] = await this.getOpCampagneItems();
                    itemsT = ListUtils.getOnlyActiveItems(itemsT);
                    column.lookup = {
                      displayExpr: 'Label',
                      valueExpr: 'IdEnumerationItem',
                      deferRendering : true,
                      dataSource: itemsT,
                    }
                  }
                }
                if(masterColumns.length > 0) {
                  await ctrl.configMaster(configGrid, masterColumns, ctrl.storage, ctrl, false, false, 'envoicomm');
                }
                var info = $('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxDataGrid(configGrid);
                ctrl.reloadMessages();
                itemElement.append(info);
              }
            })
          }

          if(items.length > 1) {
            element.append($('<div>').dxTabPanel(({ items: items,
              onSelectionChanged: async function(e) {
                if(e.addedItems[0].title == 'Historique')
                {
                  ctrl.reloadMessages();
                }
              },})));
          }
          else {
            element.append($('<div id="formMaster">').addClass('component-master-form').dxForm({
              formData: detailEntity,
              labelMode: 'floating',
              showColonAfterLabel: true,
              showValidationSummary: true,
              validationGroup: 'validateForm',
              onFieldDataChanged : async function(e) {
                var form = $('#formMaster').dxForm('instance');
                var validation = form.validate();
                if(validation.isValid) {
                  var componentRow = form.option('formData');
                  var attribut = {
                    Name:e.dataField.replace('.IdEnumerationItem',''),
                    Value :e.dataField.endsWith('IdEnumerationItem') ? componentRow[e.dataField.split('.')[0]].IdEnumerationItem.toString() : componentRow[e.dataField].toString(),
                  }
                  await lastValueFrom(ctrl.componentService.updateAttributProperty(ctrl.space, ctrl.entityType, componentRow.Id, 'CD', 'communication', attribut));
                }
              },
              colCount: 2,
              items:  tabs[0].items
            }));
          }
          grid.updateDimensions();
        }
      };
    }
  }

  getItems(tabModel:any) {
    var items = tabModel.items;
    var groupsItems = 
    [
      {
        itemType: 'group',
        items: items.filter((tb: { dataField: string; }) => tb.dataField == 'Title' || tb.dataField == 'Message'),
      },
      {
        itemType: 'group',
        items: items.filter((tb: { dataField: string; }) => tb.dataField != 'Title' && tb.dataField != 'Message'),
      }
    ];
    return groupsItems;
  }

  getIdColumn() {
    return {
      "dataField": "Id",
      "caption": "Id",
      "dataType": "number",
      "cellTemplate": null as any,
      "format": "",
      "width": null as null,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": false,
      "visibleIndex": 2,
      "allowSorting": true,
      "isRequired": true,
      "defaultValue": " .",
      "tooltip": null as any,
      "allowEditing": false,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": true,
      "renderType": "default",
      "tabName": "",
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "validationRules": [{"type": "required"}],
      "formItem": {"visible": false},
      "name": "Id"
    }
  }

  getDateCreationColumn(caption: any, visible: any, visibleIndex:any, isIncludedInMaster: any, isIncludedInDatagrid: any, renderType: any, tabName: any, isTypeRequired: any) {
    var column = {
      "dataField": "DateCreation",
      "caption": caption,
      "dataType": "date",
      "cellTemplate": null as any,
      "format": "dd.MM.yyyy",
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": visible,
      "visibleIndex": visibleIndex,
      "allowSorting": true,
      "isRequired": true,
      "defaultValue": "1899-12-31T23:00:00.000Z",
      "tooltip": null as any,
      "allowEditing": false,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": isIncludedInMaster,
      "isIncludedInDataGrid": isIncludedInDatagrid,
      "renderType": renderType,
      "tabName": tabName,
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "name": "DateCreation",
      "validationRules": null as any
    };

    if (isTypeRequired) {
      column['validationRules'] = [{"type": "required"}];
    }

    return column;
  }

  getExpediteurColumn(visibleIndex: any, allowEditing: any, tabName: any, isTypeRequired: any) {
    var column = {
      "dataField": "Expediteur",
      "caption": "Expediteur",
      "dataType": "string",
      "cellTemplate": null as any,
      "format": null as any,
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": false,
      "visibleIndex": visibleIndex,
      "allowSorting": true,
      "isRequired": true,
      "defaultValue": "",
      "tooltip": null as any,
      "allowEditing": allowEditing,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": false,
      "renderType": "default",
      "tabName": tabName,
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "validationRules": null as any,
      "name": "Expediteur"
    };
    
    if (isTypeRequired) {
      column['validationRules'] = [{"type": "required"}];
    }

    return column;
  }

  getCcColumn() {
    var column = {
      "dataField": "Cc",
      "caption": "Copie",
      "dataType": "string",
      "cellTemplate": null as any,
      "format": null as any,
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": false,
      "visibleIndex": 9,
      "allowSorting": true,
      "isRequired": false,
      "defaultValue": "",
      "tooltip": null as any,
      "allowEditing": true,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": false,
      "renderType": "default",
      "tabName": "Message",
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "validationRules": null as any,
      "name": "Cc"
    };
    
      return column;

  }

  getCccColumn(visibleIndex: any, isRequired: any, allowEditing: any, tabName: any) {
    return {
      "dataField": "Ccc",
      "caption": "Copie cachée",
      "dataType": "string",
      "cellTemplate": null as any,
      "format": null as any,
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": false,
      "visibleIndex": visibleIndex,
      "allowSorting": true,
      "isRequired": isRequired,
      "defaultValue": "",
      "tooltip": null as any,
      "allowEditing": allowEditing,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": false,
      "renderType": "default",
      "tabName": tabName,
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "name": "Ccc"
    };
  }

  getTitleColumn(visibleIndex: any, allowEditing: any, tabName: any, isTypeRequired: any) {
    var column = {
      "dataField": "Title",
      "caption": "Titre de la communication",
      "dataType": "string",
      "cellTemplate": null as any,
      "format": null as any,
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": true,
      "visibleIndex": visibleIndex,
      "allowSorting": true,
      "isRequired": true,
      "defaultValue": "",
      "tooltip": null as any,
      "allowEditing": allowEditing,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": true,
      "renderType": "default",
      "tabName": tabName,
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "name": "Title",
      "validationRules": null as any
    };
    
    if (isTypeRequired) {
      column['validationRules'] = [{"type": "required"}];
    }

    return column;
  }

  getMessageColumn(visibleIndex: any, allowEditing: any, tabName: any) {
    return {
      "dataField": "Message",
      "caption": "Contenu de la communication",
      "dataType": "string",
      "cellTemplate": null as any,
      "format": null as any,
      "width": null as any,
      "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
      "visible": false,
      "visibleIndex": visibleIndex,
      "allowSorting": true,
      "isRequired": false,
      "defaultValue": "",
      "tooltip": null as any,
      "allowEditing": allowEditing,
      "sortOrder": null as any,
      "type": null as any,
      "isIncludedInMaster": true,
      "isIncludedInDataGrid": false,
      "renderType": "html",
      "tabName": tabName,
      "style": null as any,
      "cssClass": null as any,
      "link": null as any,
      "calculSummary": null as any,
      "name": "Message"
   };
  }
    
  downloadDocument(documentA: any): void {
    var url = ''
    if(documentA.DocumentGuid == ''){
        url = './api/'+this.version+'/app/'+this.applicationName+'/'+this.space+'/'+this.entityIdentifier+'/old_documents.json';
        
        this.downloadService.downloadWithObject(url, documentA).subscribe(response => {
            var filename = documentA.SourceFileName;
            if (response.body) {
                const a = document.createElement('a')
                a.href = window.URL.createObjectURL(response.body);
                a.download = filename;
                a.click();
            }
        },error=>{
            var createdDt=new Date();
        });
    }
    else
    {
        url = './api/v1/spaces/'+this.space+'/documents/'+documentA.DocumentGuid;
        
        this.downloadService.download(url).subscribe(response => {
            var filename = documentA.SourceFileName;
            if (response.body) {
                const a = document.createElement('a')
                a.href = window.URL.createObjectURL(response.body);
                a.download = filename;
                a.click();
            }
        },error=>{
            var createdDt=new Date();
        });
    }
  }

  deleteDocument(lastDocument:string): void {
    var ctrl = this;
    if(lastDocument) {
      this.appStructService.deleteDocument(this.applicationName,this.version,this.space, lastDocument, 'communicationSpe').subscribe({
        next: comments => {
            ctrl.reloadDocuments();
        }
      });
    }
    else {
        this.documents.pop();
    }
  }
    
  validateApp(data: any, component: any) {
    let isValid = true;
    let  columnCount = component.columnCount()
    for(var i = 0; i < columnCount; i++) {  
      var column = component.columnOption(i);
      if(column.isRequired) {
        switch(column.dataType) {
          case 'string':
          case 'DynamicList':
            if(data[column.dataField] == "")
              isValid = false;
              break;
          case 'decimal':
          case 'number':
            if(data[column.dataField] == 0)
              isValid = false;
              break;
          case 'DateTime':
          case 'date':
              if(data[column.dataField] == new Date())
                isValid = false;
                break;
        }
      }    
    }
    return isValid
  }

  async getItemFormPerColumn(column:any, storage: any, ctrl:NgbdModalCommunicationDetailContent, withdefault:boolean) {
    let item : any = {
      dataField: column.dataField,
      label:{
        text: column.caption,
      },
      editorOptions: {
        
        disabled: column.allowEditing == false,
        displayFormat : '' ,       
      }
    };
    if(column.dataType == 'bool')
      column.isRequired = false;
    if(column.isRequired)
    {
      item.validationRules= [{ type: "required" }]
    }
    if(column.dataField == 'Cc' || column.dataField == 'Ccc')
    {
      item.validationRules= [{
        type: 'custom',
        message: 'Erreur de formatage au niveau des emails',
        validationCallback: ctrl.validateEmail
      }];
    }
    if(column.dataField == 'Expediteur')
    {
      item.validationRules= [{
        type: 'custom',
        message: 'Erreur de formatage au niveau de l\'expediteur',
        validationCallback: ctrl.validateExpediteur
      }];
    }
    if(column.dataType == "decimal" || column.dataType == "number"){
      item.editorType = 'dxNumberBox';
      item.editorOptions = {
        showSpinButtons: false,
        displayFormat : '' , 
        dataField : column.dataField,
        disabled: column.allowEditing == false,
        format: column.format,
        showClearButton: false,
      }
      item.editorOptions.onKeyDown = function(e:any) {
        var preventedKeys = [38, 40];
        var keyCode = e.event.keyCode;
        var shouldPreventEvent = !e.component.option("opened") &&
            preventedKeys.indexOf(keyCode) !== -1 &&
            !e.event.altKey;
        if(shouldPreventEvent) {
            e.event.preventDefault();
            e.event.stopImmediatePropagation();
        }
      }
      item.formatNumber = item.editorOptions.format
      item.editorOptions.format = function (value:any) {
        return numeral(value).format(item.formatNumber)
      }
    }
    else if(column.dataType == "DynamicList" && typeof(column.format) !== "undefined" && column.format !== null) {
      let itemsT: any[] = [];
      if (ctrl.storage.Lists[column.format.replace('#1', '')] != null) {
        itemsT = JSON.parse(ctrl.storage.Lists[column.format.replace('#1', '')]);
      }
      else {
        var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+column.format.replace('#1', '')+'/0.json';
        itemsT = await lastValueFrom(<any>ctrl.httpClient.get(url));
        ctrl.storage.Lists[column.format.replace('#1', '')] = JSON.stringify(itemsT);
      }
      
      this.setListItem(itemsT, item, column);

      if(column.format.indexOf('#1') != -1) {
        item.editorOptions.displayExpr = 'Code';
      }
    }
    else if(column.dataType == "DateTime" || column.dataType == "date") {
      item.editorType ='dxDateBox'
      item.editorOptions.displayFormat= "dd.MM.yyyy";
      item.formatDate = item.editorOptions.format
      item.editorOptions.format = function (value:any) {
        return moment(value).format(item.formatDate)
      }
    }
    else if(column.dataType == "bool") {
      column.dataType ='boolean';
      item.editorType = "dxCheckBox";      
    }
    else if(column.dataType == "string" && column.renderType =="textarea") {
      item.editorType = "dxTextArea";
      item.colSpan= 2;
      item.editorOptions= {
        height: 90,
        displayFormat : '' , 
        disabled: column.allowEditing == false,
      }
    }
    else if(column.dataField == "OpIdentifiant") {
      let itemsT: any[] = await this.getOpCampagneItems();
      this.setListItem(itemsT, item, column);
    }
    else if(column.dataType == "string" && column.renderType =="html") {
      item.editorType = 'dxHtmlEditor';
      item.colSpan= 1;
      item.editorOptions = {
        height: 300,
        disabled: column.allowEditing == false,
        imageUpload: {
          tabs: ['file', 'url'],
          fileUploadMode: 'base64',
        },
        toolbar: {
          multiline:false,
          items: [
            'undo', 'redo', 'separator',
            {
              name: 'size',
              acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
            },
            {
              name: 'font',
              acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
            },
            'separator', 'bold', 'italic', 'strike', 'underline', 'separator', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator', 'orderedList', 'bulletList', 'separator',
            {
              name: 'header',
              acceptedValues: [false, 1, 2, 3, 4, 5],
            }, 
            'separator', 'color', 'background', 'separator', 'link', 'image', 'separator', 'clear', 'codeBlock', 'blockquote', 'separator', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
          ],
        },
        mediaResizing: {
          enabled: true,
        },
        allowSoftLineBreak :true,
        onFocusOut : async function (e:any) {
          var attribut = {
            Name:'Message',
            Value :e.component.option('value'),
          }
          let grid = $('#gridCommunication').dxDataGrid('instance');
          var selectRows = grid.getSelectedRowsData();
          await lastValueFrom(ctrl.componentService.updateAttributProperty(ctrl.space, ctrl.entityType, selectRows[0].Id, 'CD', 'communication', attribut));
        }
      }
      item.label.location= 'top';
      item.label.showColon=false;
    }
    
    if(withdefault) {
      if(column.defaultValue && column.defaultValue.length > 0) {
        if(column.dataType == 'DynamicList' && column.dataField !='Code') {
          var list = JSON.parse(ctrl.storage.Lists[column.format]);
          item.editorOptions.value = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
        }
        else if(column.dataType == 'boolean') {
          item.editorOptions.value = column.defaultValue.toLowerCase() == 'true'
          column.defaultValue = item.editorOptions.value
        }
        else if (column.dataType == 'date' || column.dataType == 'datetime'){
          item.editorOptions.value = DateUtils.getDateFromStringOrToday(column.defaultValue);
        }
        else {
          item.editorOptions.value=column.defaultValue;
        }
      }
      else if(column.dataType == 'number') {
        item.editorOptions.value= 0
      }
      else if(column.dataType == 'date' || column.dataType == 'datetime') {
        item.editorOptions.value = new Date();
      }
      else if(column.dataType == 'boolean') {
        item.editorOptions.value= false
      }
      else if(column.dataType == 'string') {
        item.editorOptions.value= ''
      }
      else if(column.dataType == 'DynamicList' && column.dataField !='Code') {
        var list = JSON.parse(ctrl.storage.Lists[column.format]);
        item.editorOptions.value = list[0]
      }
      if(column.dataField =='DisplayEnumCode'  || column.dataField =='Id') {
        item.editorOptions.value= ' .'
      }
      column.defaultValue = item.editorOptions.value
    }
    if(column.dataField == "ApplicationName")
      item.visible = false

    return item
  }

  async getOpCampagneItems() {
    var ctrl = this;
    let itemsT: any[] = [];
          
    if (ctrl.storage.Lists['opcampagne'] != null) {
      itemsT = JSON.parse(ctrl.storage.Lists['opcampagne']);
    }
    else {
      var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/opcampagne/0.json';
      itemsT = <any[]>await lastValueFrom((<any>ctrl.httpClient).get(url));
      ctrl.storage.Lists['opcampagne'] = JSON.stringify(itemsT);
    }

    return itemsT;
  }

  setListItem(itemsT : any, item : any, column : any) {
    itemsT = ListUtils.getOnlyActiveItems(itemsT);
    item.editorType = 'dxSelectBox';
    item.editorOptions = {
      dataSource: itemsT,
      displayFormat : '' , 
      searchEnabled: true,
      disabled: column.allowEditing == false,
      displayExpr: 'Label',
      valueExpr: 'IdEnumerationItem',
      deferRendering : true
    }
  }

validateEmail (e: any) {
  if(e.value == '')
  return true;
    var emails = e.value.split(';');

    var isOk = true;
    emails.forEach((email: string) => {
      if(email.length == 0)
        isOk = false;
      else if(!String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        isOk = false;
    });
    return isOk;
}

validateExpediteur (e: any) {
  if(e.value == '')
  return false;
    var emails = e.value.split(';');
  if(emails.length > 1)
    return false;
  if(!String(emails).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
    return false;
  if(emails.indexOf('@fhvi.ch') != -1)
    return false;
    return true;
}
  
  addNewLine() {
    var newComm = {
      "Id": 0,
      "OpIdentifiant": "",
      "Expediteur": this.userEmail,
      "Cc": "",
      "Ccc": "",
      "ApplicationName": this.applicationName,
      "Title": "",
      "Message": "",
      "DateCreation": "1899-12-31T23:00:00.000Z",
      "DateDerniereModification": "1899-12-31T23:00:00.000Z",
      "DateEnvoi": "1899-12-31T23:00:00.000Z",
      "SentToEpUser" : true
    }
    
    this.componentService.insertAttribut(this.space, this.entityType, parseFloat(this.entityIdentifier), 'CD', 'communication', newComm).subscribe({
      next: async configGrid => {
        await this.refreshData();
        setTimeout(async () => {
          let grid = $('#gridCommunication').dxDataGrid('instance');
          const visibleRows = grid.getVisibleRows();
          let current = visibleRows.filter((tb: { data: any; }) => tb.data.Id  == configGrid)[0];
          grid.expandRow(current.key);
          grid.repaint();
      }, 500);
      }
    });
  }

  onSubmit() :void {
    const fileUploader = $('#file-uploader').dxFileUploader('instance');
    this.saveDocument(fileUploader.option('value'))
  }

  saveDocument(uploadDocuments:any[]): void {
    var ctrl = this;
    var index = this.documents.length;

    const colIndex = this.communication.Id.toString().indexOf(':');
    var onglet = this.communication.Id.toString().substring(0, colIndex).trim();
    var ligne = this.communication.Id.toString().substring(colIndex + 1).trim()

    for(var i = 0;i<uploadDocuments.length;i++) {
      index++;
      
      var documentA = {
          DocCode : 'Communication',
          DocIndex : index,
          DocNom : 'Communication'+'_'+index+'.'+uploadDocuments[i].name.split('.').pop(),
          DocLoadDate : new Date(),
          Module : 'Feature',
          Onglet : 'Communication',
          Bloc : '1',
          Ligne: ligne,
          SourceFileName : uploadDocuments[i].name
      };

      var fileA = {
          Filename : uploadDocuments[i].name,
          Extension : '.'+uploadDocuments[i].name.split('.').pop(),
          Size : uploadDocuments[i].size,
          CreationDate : new Date(),
          File64 : <any>null,
      };

      if(fileA.Extension == '.pdf') {
        var file = uploadDocuments[i];
        let fr = new FileReader();
        fr.onload = function () {
          fileA.File64 = fr.result.toString().split(',')[1];
          ctrl.appStructService.addDocument(ctrl.applicationName,ctrl.version,ctrl.space, ctrl.entityIdentifier,docFull, 'communicationSpe','').subscribe({
            next: comments => {
              if((ctrl.documents.length+1 < 10)) {
                ctrl.createFileUploader();
              }
              else {
                $('#file-uploader').hide();
              }

              ctrl.reloadDocuments();
            }
          });
        };
        fr.readAsDataURL( file );
        var docFull = {
          Document : documentA,
          File : fileA,
        }
        
        index++;
      }
      else {
        this.createFileUploader();
      }
    }
  }

  createFileUploader() {
    var ctrl = this;

    $('#file-uploader').dxFileUploader({
      multiple: false,
      accept: '.pdf',
      value: [],
      uploadMode: 'useForm',
      dialogTrigger:"#buttonAddDocCom",
      visible: false,
      onValueChanged(e) {ctrl.onSubmit();}
    }).dxFileUploader('instance');
  }

  async sendForm(action : string) {
    if(this.communication == null) {
      return;
    }

    let temp = await lastValueFrom(this.componentService.getDetailComponent(this.space,this.entityType, parseFloat(this.entityIdentifier), '', 'communication'));
    this.communication = temp.filter((tb: { Id: any; }) => tb.Id  == this.communication.Id)[0];
    this.communication.Action = action;
    this.communication.Documents = '';

    const colIndex = this.communication.Id.toString().indexOf(':');
    var onglet = this.communication.Id.toString().substring(0, colIndex).trim();
    var ligne = this.communication.Id.toString().substring(colIndex + 1).trim()

    this.documents = await lastValueFrom(this.appStructService.getAllDocuments(this.applicationName,this.version,this.space, this.entityIdentifier, 'Feature', 'Communication', '1', ligne, 'communicationSpe',''));
      
    for(var i = 0; i<this.documents.length;i++)
    {
      this.communication.Documents+=this.documents[i].DocumentGuid+'|';
    }

    this.messageToParent.emit(this.communication);
  }
}

@Component({
  selector: "ngbd-modal-communication-component",
})

export class NgbdModalCommunicationComponent{
  @Output() refreshCommunicationChange: EventEmitter<any> = new EventEmitter();
  modalRef : any;
  insidePopup: any;
  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }
  
  show(actionAPI: IActionAPI, entityId: string, space: string, applicationName: string){
    this.popupService.showPopup('Gestion des communications', this.getContentTemplate(actionAPI, entityId, space, applicationName), 1600);

    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
    document.getElementById('popupSendMeButton').addEventListener("click", () => { this.sendCommunication('SendMe'); });
    document.getElementById('popupSendButton').addEventListener("click", () => { this.sendCommunication('RealSend'); });
  }

  getContentTemplate(actionAPI: IActionAPI, entityId: string, space: string, applicationName: string) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalCommunicationDetailContent, {
        environmentInjector,
    });

    this.insidePopup.instance.entityIdentifier = entityId;
    this.insidePopup.instance.space = space;
    this.insidePopup.instance.applicationName = applicationName;
    this.insidePopup.instance.entityType = actionAPI.Parameters.EntityType;
    this.insidePopup.instance.messageToParent.subscribe((communication:any)=>{
      actionAPI.Parameters.ExtraParameters = JSON.stringify(this.getParameterFromCommunication(communication, communication.Action=='SendMe'));
      this.refreshCommunicationChange.emit(actionAPI);
    })

    this.insidePopup.hostView.detectChanges();
    return this.insidePopup.location.nativeElement;
  }

  getParameterFromCommunication(communication: any, demoMode: any) {
    return {
      Identifier : communication.OpIdentifiant,
      Id : communication.Id,
      Body : communication.Message,
      Subject : communication.Title,
      SendToEP : communication.SentToEpUser,
      Excel : communication.Excel,
      DocumentsId : communication.Documents,
      DemoMode : demoMode
    }
  }

  sendCommunication(sendType:string) {
    this.insidePopup.instance.sendForm(sendType)
  }
}

