import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, lastValueFrom } from "rxjs";
import { shareReplay, catchError, tap, retry } from 'rxjs/operators';
import { IActionAppButton } from "./front-office-struct";


@Injectable({
    providedIn: 'root'
})

export class FrontOfficeStructService {
    private appUrl = '';
    public updateContextPanel = new EventEmitter()
    public updateDetailMobileLinePanel = new EventEmitter()
    public updateParent = new EventEmitter()
    public updateFormData = new EventEmitter()
    public changeMenu = new EventEmitter()
    constructor(private http: HttpClient,
        private localStorageService: LocalStorageService)
    { }

    getStructOfTab(version:string, appName: string, zone: string, module: string, tab:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + module+ '/' + tab+'/settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    checkDisplayError (version:string, appName: string, zone: string, tab:string, entity:string, entityCible:string, workflow:String): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + tab+ '/' + entity +'/' + entityCible+'/' + workflow+'/display_error_first.json';
        return this.http.put<any>(url, '').pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    changeMobileMenu(menuName : string)
    {
        this.changeMenu.emit(menuName);
    }

    getStructOfBloc(version:string, appName: string, zone: string, module: string, tab:string, blocName:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + module+ '/' + tab+ '/' + blocName+'/settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    
    async getSubPerimeter(space: string,version: string, appName: string, zone: string, subPerimeter: string, stateId: string, actionType:string) {
        if(subPerimeter == null)
            subPerimeter = '';
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        let storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
            if (storage != null){
                if(storage.Access == null )
                storage.Access = {};
            }
            var key = appName+'_'+zone+'_'+stateId+'_'+subPerimeter;
            if(storage.Access[key] != null)
                return storage.Access[key];
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + 0 + '/' + stateId+ '/' + actionType + '/check_access.json'

        var value = lastValueFrom(this.http.post<boolean>(url, { Value:subPerimeter}));
        storage.Access[key] = await value;
        return value;
    }

    getTabStatus(version:string, space: string, zone:string, application:string, identifier: string, tab:string): Observable<string> {
        this.appUrl = './api/v1/application/{space}';
        var url = this.appUrl.replace('{version}', version).replace('{space}', space)+ '/' +application+ '_' +zone + '/' + identifier+ '/' + tab+'/tab_status.json';
        return this.http.get<string>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    isDirector(appName:string, zone: string, version: string): Observable<boolean> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/is_director.json'

        return this.http.get<boolean>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        );
     }

    getFormAppStatus(version:string, space: string, zone:string, application:string, identifier: string, tab:string,workflow_id:string): Observable<string> {
        this.appUrl = './api/v1/application/{space}';
        var url = this.appUrl.replace('{version}', version).replace('{space}', space)+ '/' +application+ '_' +zone + '/' + identifier+ '/' + tab+ '/' + workflow_id+'/form_status.json';
        return this.http.get<string>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }
    getListAppStatus(version:string, space: string, zone:string, application:string, identifier: string): Observable<string[]> {
        this.appUrl = './api/v1/application/{space}';
        var url = this.appUrl.replace('{version}', version).replace('{space}', space)+ '/' +application+ '_' +zone + '/' + identifier+'/list_tab_status.json';
        return this.http.get<string[]>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getFormStatus(version:string, space: string, identifier: string, zone:string): Observable<number> {
        this.appUrl = './api/{version}/spaces/{space}/Entities';
        var url = this.appUrl.replace('{version}', version).replace('{space}', space) + '/' + identifier+ '/' + zone+'/form_status.json';
        return this.http.get<number>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getActionButton(appName: string,version:string, space: string, module:string, tab:string, currentState: string, currentStateTab:string): Observable<IActionAppButton[]> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{appName}', appName).replace('{version}', version).replace('{zone}', space) + '/' + module+ '/' + tab+ '/' + 
        currentState+ '/' + currentStateTab+'/buttons.json';
        return this.http.get<IActionAppButton[]>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getTransitions(appName: string,version:string, space: string, module:string, currentState: string): Observable<any[]> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{appName}', appName).replace('{version}', version).replace('{zone}', space) + '/' + module+ '/' + currentState+'/transitions.json';
        return this.http.get<any[]>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }


    getListState(workflow_id: string): Observable<any> {
        var url = '/api/workflow/'+workflow_id+'/states_settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getListBoFormState(): Observable<any> {
        var url = '/api/workflow/vdsash_legacy_bo/form_states_settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getListFormState(workflow_id: string): Observable<any> {
        var url = '/api/workflow/'+workflow_id+'/form_states_settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getStructOfModule(appName: string,version:string,  zone: string, module: string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{appName}', appName).replace('{zone}', zone).replace('{version}', version) + '/' + module+ '/settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getStructOfZone(appName: string,version:string, zone: string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{appName}', appName).replace('{zone}', zone).replace('{version}', version) + '/settings.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getAllComments(appName: string, version:string, zone: string, entityId:string, module:string, onglet:string, bloc:string, ligne:string, tabName:string, subperimeter:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+entityId+'/'+module+'/'+onglet+'/'+bloc+'/'+ligne+'/'+tabName+'/comments.json';
        return this.http.post<any>(url, { Value:subperimeter.replace('/', '*')}).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getContextInfoPerBloc(space: string, blocRef:string, entityId:string): Observable<any> {
        this.appUrl = './api/v1/application/{space}/{blocRef}';
        var url = this.appUrl.replace('{space}', space).replace('{blocRef}', blocRef) +'/'+entityId+'.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getContextInfoPerBlocEntityGrid(space: string, blocRef:string, entityId:string, entityType: string): Observable<any> {
        this.appUrl = './api/v1/application/{space}/{blocRef}/{entityId}';
        var url = this.appUrl.replace('{space}', space).replace('{blocRef}', blocRef).replace('{entityId}', entityId) +'/'+entityType+'/list_context_info.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    saveCommentContext(space: string, blocRef:string, entityId:string, module:string, onglet:string, bloc:string, ligne:string, hasComm : boolean, nbCom:number, WorkflowId : string): Observable<any> {
        this.appUrl = './api/v1/application/{space}/{blocRef}';
        var url = this.appUrl.replace('{space}', space).replace('{blocRef}', blocRef) +'/'+entityId+'/'+module+'/'+onglet+'/'+bloc+'/'+ligne+'/'+hasComm+'/'+nbCom+'/'+WorkflowId+'/comments.json';
        return this.http.put<any>(url, hasComm).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    sendUserIntegration(): Observable<any> {
        var url =  './api/v1/idp/aaa/user_intergration_all.json';
        return this.http.put<any>(url, '').pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    saveDocumentContext(space: string, blocRef:string, entityId:string, module:string, onglet:string, bloc:string, ligne:string, hasDoc : boolean, nbDoc:number, WorkflowId : string): Observable<any> {
        this.appUrl = './api/v1/application/{space}/{blocRef}';
        var url = this.appUrl.replace('{space}', space).replace('{blocRef}', blocRef) +'/'+entityId+'/'+module+'/'+onglet+'/'+bloc+'/'+ligne+'/'+hasDoc+'/'+nbDoc+'/'+WorkflowId+'/documents.json';
        return this.http.put<any>(url, hasDoc).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    updateComment(appName: string, version:string, zone: string, comId:string, comText:string, tabName:string, subperimeter:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var obj = {
            Value : comText,
            SubPerimeter:subperimeter
        }
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+comId+'/'+tabName+'/comments.json';
        return this.http.put(url, obj).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    addComment(appName: string, version:string, zone: string, entityId:string, comment:any, tabName:string, subperimeter:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+entityId+'/'+tabName+'/comments.json';
        comment.SubPerimeter=subperimeter;
        return this.http.post<string>(url, comment).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    calcul(appName: string, task : any): Observable<any> {
        this.appUrl = './api/v1/{appName}/actions/calcul_app.json';
        var url = this.appUrl.replace('{appName}', appName);
        return this.http.post<string>(url, task).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getExportConfig(appName: string, version:string, zone: string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{appName}', appName).replace('{zone}', zone).replace('{version}', version)+'/schema_exports.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getReportConfig(appName: string, version:string, zone: string, currentState: string, identifier :number): Observable<any> {
        this.appUrl = './api/{version}/spaces/{zone}/Entities/{appName}';
        var url = this.appUrl.replace('{appName}', appName).replace('{zone}', zone).replace('{version}', version)+'/'+currentState+'/'+identifier+'/reports_definition.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    deleteComment(appName: string, version:string, zone: string, comId:string, tabName:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+comId+'/'+tabName+'/comments.json';
        return this.http.delete(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getAllDocuments(appName: string, version:string, zone: string, entityId:string, module:string, onglet:string, bloc:string, ligne:string, tabName:string, subperimeter:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+entityId+'/'+module+'/'+onglet+'/'+bloc+'/'+ligne+'/'+tabName+'/documents.json';
        return this.http.post<any>(url, { Value:subperimeter}).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    addDocument(appName: string, version:string, zone: string, entityId:string, document:any, tabName:string, subperimeter:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+entityId+'/'+tabName+'/documents.json';
        document.SubPerimeter=subperimeter;
        return this.http.post<string>(url, document).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    deleteDocument(appName: string, version:string, zone: string, docId:string, tabName:string): Observable<any> {
        this.appUrl = './api/{version}/app/{appName}/{zone}';
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) +'/'+docId+'/'+tabName+'/documents.json';
        return this.http.delete(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getSeqLog(traceId: string): Observable<any> {
        var url = './api/v1/monitoring/log/seq/'+traceId+'.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}