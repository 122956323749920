// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#fo_table_contacts {
    color: #fff !important;
    margin-bottom: 0;
    width: 100%;
}
.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.small-cell-footer
{
    width: 16.66666667%;
    vertical-align: top;
}
.big-cell-footer
{
    width: 66.66666667%;
    vertical-align: top;
}
.main-body
{
    background-color: rgb(228 233 238);
    overflow: hidden;
    height: 100vh;
    position: relative;
}
.main-body.mobile
{
    background-color: rgb(228 233 238);
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
html *{
    font-family: Nunito, sans-serif;
}
#modeValidation
{
    color: white;
    font-size: 1.35rem;
    padding-left: 15px;
    margin-top: -5px;
}`, "",{"version":3,"sources":["webpack://./app/app.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,oCAAoC;IACpC,wBAAwB;IACxB,wDAAwD;AAC5D;AACA;;IAEI,mBAAmB;IACnB,mBAAmB;AACvB;AACA;;IAEI,mBAAmB;IACnB,mBAAmB;AACvB;AACA;;IAEI,kCAAkC;IAClC,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;AACA;;IAEI,kCAAkC;IAClC,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,+BAA+B;AACnC;AACA;;IAEI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["#fo_table_contacts {\r\n    color: #fff !important;\r\n    margin-bottom: 0;\r\n    width: 100%;\r\n}\r\n.table>:not(caption)>*>* {\r\n    padding: 0.5rem 0.5rem;\r\n    background-color: var(--bs-table-bg);\r\n    border-bottom-width: 1px;\r\n    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);\r\n}\r\n.small-cell-footer\r\n{\r\n    width: 16.66666667%;\r\n    vertical-align: top;\r\n}\r\n.big-cell-footer\r\n{\r\n    width: 66.66666667%;\r\n    vertical-align: top;\r\n}\r\n.main-body\r\n{\r\n    background-color: rgb(228 233 238);\r\n    overflow: hidden;\r\n    height: 100vh;\r\n    position: relative;\r\n}\r\n.main-body.mobile\r\n{\r\n    background-color: rgb(228 233 238);\r\n    overflow: hidden;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    position: relative;\r\n}\r\nhtml *{\r\n    font-family: Nunito, sans-serif;\r\n}\r\n#modeValidation\r\n{\r\n    color: white;\r\n    font-size: 1.35rem;\r\n    padding-left: 15px;\r\n    margin-top: -5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
