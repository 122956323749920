export const environment = {
    production: true,
    urlBackend: 'https://legacy-phms.fhv.ch',
    idp: {
      clientId: 'DE20EB5DAACA7563',
      issuer: 'https://idp-phms.fhv.ch',
      redirectUri: window.location.origin,
      tokenEndpoint: 'https://idp-phms.fhv.ch/connect/token',
      postLogoutRedirectUri: 'https://idp-phms.fhv.ch/account/logout?redirectUrl=https%3A%2F%2Fphms.fhv.ch',
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      scope: 'openid profile email roles apilocal.manage security',
      showDebugInformation: false,
      responseType: 'code',
      skipIssuerCheck: false,
      strictDiscoveryDocumentValidation: false,
      requestAccessToken: true,
      requestIdToken: true,
      oidc: true,
      useRefreshToken: true,
      silentRenew: true,
      requireHttps: false
    }
};