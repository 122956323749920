import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { BoService } from "app/back-office/back-office.service";
import { ActionTypeButton, IEntity } from "app/shared/entity";
import { LocalStorageService } from "app/shared/local-storage.service";
import { SessionStorageService } from "app/shared/session-storage.service";
import * as $ from 'jquery';
import { Subscription, lastValueFrom } from "rxjs";
import { FrontOfficeGridService } from "../../../front-office-grid.service";
import { FrontOfficeStructService } from "../../../front-office-struct.service";
import { IDPService } from "../../../idp.service";
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from "exceljs";
import { saveAs } from 'file-saver';
import { ActionLegacyService } from "app/shared/actions-legacy.service";
import { NgbdModalConfirmationComponent } from "app/front-office/modal-confirmation.component";
import { NgbdModalConfirmationIdpComponent } from "app/front-office/modal-confirmation-idp.component";
import { FrontOfficeGridFactory } from "../../shared-factory/front-office-grid-factory";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'front-office-grid',
    templateUrl: './front-office-grid.component.html',
    styleUrls: ['./front-office-grid.component.css']
})
export class FrontOfficeGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() gridName: string = '';
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() space: string = '';
  @Input() currentState : any;    
  @Input() tabStatus: number = 0;
  @Input() currentStateTab : any;
  @Input() entityCible: string = '';
  @Input() applicationName: string = '';
  @Input() currentProc: IEntity;
  @Input() zoneName: string = '';
  @Input() moduleName: string = '';
  @Input() tabName: string = '';
  @Input() entityIdentifier: string = '';
  @Input() currentZone: any | undefined;
  @Input() multiTab: boolean;
  @Input() information: string = '';
  @Input() showInformation: boolean = false;
  @Input() isGridEntity: boolean = false;    
  @Input() exportCurrentTab: boolean = false; 
  @Input() currentModule: any;
  @Input() currentEntity: any;
  @Input() allowFilterEntity: boolean = false;
  @Input() autoCreationEntity:boolean = false;
  @Input() canDelete: boolean = false;
  @Input() showComment: boolean = false;
  @Input() showFile: boolean = false;
  @Input() extension: string = '';
  @Input() parentEntityCible: string = '';
  @Input() docCode: string = '';
  @Input() generalToolTip: string = '';
  @Input() displayTooltip: boolean = false;
  @Input() maxDoc: number = 0;
  @Input() maxSizePerDoc: number = 0;
  @Input() order: number = 0;
  @Input() contextInfo: any[];
  @Input() commentLevel: number = 0;
  @Input() documentLevel: number = 0;
  @Input() state: any;
  stateId: string = '';
  @Input() subPerimeter: string = '';
  @Input() subPerimeterDoc: string = '';
  @Input() isDirector: boolean = false;
  @Input() forceReloadData: boolean = false;
  @Input() forceReloadDataReady: boolean = false;
  @Input() entityLock: boolean = false;
  gridConfiguration : any;
  lastSelectedRow: any;
  lastCellDiv :any;
  subPattern : string = '';
  selectedRowIndex : string = '';
  storage : any;
  filterErrorsRows: boolean = false;
  showLineOnError: boolean = false;
  sub!: Subscription;
  sub2!: Subscription;
  sub3!: Subscription;
  editiontabs: any[] = [];
  gridDataSource: any[] = [];
  filterDataSource: any[] = [];
  dictSub: any;
  parents : any[];
  organisations : any[];
  nbLineSelected : number = 0;
  codeListName : string = '';
  selectedRowId : string = '';
  multiRowManagement : boolean = true;
  headerGotFilterErrorRows  : boolean = false;
  constructor(
    private httpClient: HttpClient,
    private confirmationModalComponent: NgbdModalConfirmationComponent,
    private router: Router,
    private componentService: BoService,   
    private idpModalComponent: NgbdModalConfirmationIdpComponent,     
    private appStructService:  FrontOfficeStructService,
    private applicationGridService: FrontOfficeGridService,
    private localStorageService: LocalStorageService,
    private idpService : IDPService,        
    private actionLegacyService: ActionLegacyService,
    private sessionStorageService: SessionStorageService) 
    {
      this.dictSub = {}; 
    }

  async ngOnChanges(changes: SimpleChanges): Promise<void> 
  {
    var ctrl = this;

    if(changes.exportCurrentTab && ctrl.exportCurrentTab) {
      var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
      ctrl.exportDataGrid(grid);
    }
    
    if(changes.currentProc && ctrl.currentProc.IdObject != null && ctrl.isGridEntity) {
      FrontOfficeGridFactory.refreshData(ctrl);
    }
    
    if (changes.contextInfo && !ctrl.isGridEntity && ctrl.gridName != null && $('#grid-'+ctrl.gridName)!= null) {
      var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
      if (grid != null) {
        if (changes.contextInfo && !ctrl.forceReloadData) {

          var oldRow = null;
          if (this.selectedRowId != null && this.selectedRowId.length > 0){
            var oldDatasourceRow = ctrl.gridDataSource.filter((tb: { Id: string; }) => tb.Id == this.selectedRowId)[0];

            oldRow = {
              Id: oldDatasourceRow.Id,
              HasError: oldDatasourceRow.HasError
            }

          }

          var gridData = ctrl.gridDataSource;    
          FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
          gridData = ctrl.getGridDataWithHasErrorUpdated(gridData);
  
          if (ctrl.filterErrorsRows) {
            ctrl.filterDataSource =  ctrl.gridDataSource.filter((tb: { HasError: boolean; }) => tb.HasError == true);
            grid.option("dataSource", ctrl.filterErrorsRows ? ctrl.filterDataSource : gridData);
            grid.refresh();
          }
          else {

            var newRow = null;
            if (this.selectedRowId != null && this.selectedRowId.length > 0){
              newRow = gridData.filter((tb: { Id: string; }) => tb.Id == this.selectedRowId)[0];
            }

            var forceReloadRow = false;
            if (oldRow != null && newRow != null) {
              if (oldRow.Id == newRow.Id) {
                if (oldRow.HasError != newRow.HasError) {
                  forceReloadRow = true;
                }
              }
            }

            ctrl.gridChangeOnlyRowsChanged(gridData, forceReloadRow);
          }
        }
      }
    }
    
        if (changes.forceReloadDataReady && ctrl.forceReloadDataReady && ctrl.gridName != null && $('#grid-'+ctrl.gridName)!= null) {
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          if (grid != null) {
            var procID = ctrl.currentProc.IdObject != null ?  ctrl.currentProc.IdObject : 0;
            let gridData = await lastValueFrom(ctrl.applicationGridService.getGridDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName));
            
            FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
            gridData = ctrl.getGridDataWithHasErrorUpdated(gridData);
  
            ctrl.gridDataSource = gridData;
            if (ctrl.filterErrorsRows) {
              ctrl.filterDataSource =  ctrl.gridDataSource.filter((tb: { HasError: boolean; }) => tb.HasError == true);
            }
            grid.option("dataSource", ctrl.filterErrorsRows ? ctrl.filterDataSource : gridData);
            grid.refresh();
        }
    }
  }

  gridChangeOnlyRowsChanged(newDatasource:any, forceReloadRow:any) {
    var grid = $('#grid-'+this.gridName).dxDataGrid('instance');

    newDatasource.forEach(async (row:any) => {
      if (row != null && (row.Id != this.selectedRowId || forceReloadRow)) { 
        var index = grid.getRowIndexByKey(row.Id);  
        var visibleRow = grid.getVisibleRows()[index];

        if (visibleRow != undefined) {
          var gridRow = visibleRow.data;  

          Object.assign(gridRow, row);
          grid.repaintRows([index]); 
        }
      }
    });

    this.gridDataSource = newDatasource;

    if (forceReloadRow) {
      grid.refresh();
    }
  }

  async ngOnInit(): Promise<void> {
    this.ngOnDestroy(); 
    FrontOfficeGridFactory.setPopupMouseLeave();
    FrontOfficeGridFactory.setStorage(this);

    var ctrl = this; 
    this.stateId = this.state != null ? this.state.Id : 'ready';

    if(this.perimeter == "Space") {
      this.sub = ctrl.appStructService.updateParent.subscribe((item:any) => {
        var grid = $('#grid-' + item.OriginName).dxDataGrid('instance');

        var index = 0;

        if (ctrl.isGridEntity) {
          index = grid.getRowIndexByKey(Number(item.Ligne));
        }
        else {
          index = grid.getRowIndexByKey(ctrl.entityIdentifier + '_' + item.Ligne);
        }

        if(index != -1) {
          let rows = grid.getVisibleRows();  
          let row = rows[index];

          if ((<any>row.data).ContextInfo == null) {
            (<any>row.data).ContextInfo = {
              HasComment : false,
              HasDocument : false
            };
          }

          if(item.Type == 'comments') {
            (<any>row.data).ContextInfo.HasComment = item.Nb > 0;
            (<any>row.data).ContextInfo.NbComment = item.Nb;
          }
          else {
            (<any>row.data).ContextInfo.HasDocument = item.Nb > 0;
            (<any>row.data).ContextInfo.NbDocument = item.Nb;
          }

          ctrl.appStructService.updateFormData.emit('ok')
          grid.refresh();
        }
      });

      if(this.currentModule.IsModuleUser) {
        this.sub2 = this.idpModalComponent.refreshIdpRequestChange.subscribe(async (idpRequest) => {
          if(idpRequest == null) {
            return;
          }
          var selectedRows = this.getGridSelectedRows();
          if(selectedRows != null && selectedRows.length > 0) {
            var value = '';
            selectedRows.forEach((row: any) => {
              value+=row.LongName+',';
            });
            idpRequest.Value = value;
          }
          switch(idpRequest.Action) {
            case 'sentAccess' : 
              await lastValueFrom(ctrl.idpService.sentEmailAccess(ctrl.space, idpRequest));
              break;
            case 'resetPass' : 
              await lastValueFrom(ctrl.idpService.resetPassEmailAccess(ctrl.space, idpRequest));
              break;
            case 'sentGlobalAccess' : 
              await lastValueFrom(ctrl.idpService.sentGlobalEmailAccess(ctrl.space, idpRequest));
              break;
            case 'sentOnboarding' : 
              await lastValueFrom(ctrl.idpService.sentOnboarding(ctrl.space, idpRequest));
              break;
          }
        });
      }
      
      
      this.confirmationModalComponent.clean();
      this.sub = this.confirmationModalComponent.confirmDeleteChange.subscribe((deleteSetting : any) => {

        if (deleteSetting.RowId == 'multiRowDeleting') {
          this.confirmDeleteSelectedRows();
          return;
        }

        if(deleteSetting.GridId != this.gridName || !deleteSetting.Retour) {
          return;
        }

        FrontOfficeGridFactory.deleteRow(ctrl, deleteSetting.RowId, deleteSetting.RowId, true, 'refresh');
      });


      this.gridConfiguration = await lastValueFrom(this.applicationGridService.getGridConfigByEntityId(this.space,this.version, this.applicationName, this.entityIdentifier, this.zoneName, this.moduleName, this.tabName, this.gridName));
      this.multiRowManagement = this.gridConfiguration.multiRowManagement;

      if(this.entityLock) {
        this.gridConfiguration.editing.allowAdding = false;
        this.gridConfiguration.editing.allowUpdating = false;
        this.gridConfiguration.editing.allowDeleting = false;
        this.gridConfiguration.editing.canDelete = false;
      }

      this.canDelete = this.gridConfiguration.editing.canDelete;
      let canAdd = this.gridConfiguration.editing.allowAdding;

      if(this.gridConfiguration.editing.allowUpdating) {
        this.gridConfiguration.editing.selectTextOnEditStart= true;
      }
      this.gridConfiguration.width='100%';
      this.gridConfiguration.editing.allowAdding = false;
      this.gridConfiguration.columnAutoWidth = false;

      var fileName = this.multiTab 
        ? "Grille_"+this.applicationName+'_'+this.zoneName+'_'+this.entityIdentifier+'_'+this.moduleName+'_'+this.tabName+'_'+this.gridName+'.xlsx'
        : "Grille_"+this.applicationName+'_'+this.zoneName+'_'+this.entityIdentifier+'_'+this.tabName+'_'+this.gridName+'.xlsx';

      this.gridConfiguration.export = {
        fileName: fileName
      }

      let specificColumns: any[] = [];
      let gridColumns = [];
      let masterColumns: any[] = [];
      ctrl.editiontabs = []; 
      this.gridConfiguration.selection.mode = 'multiple'
      this.gridConfiguration.selection.showCheckBoxesMode = 'always'

      let hasSummary = false;
      for (var i = 0; i < this.gridConfiguration.columns.length; i++) {
        var column = this.gridConfiguration.columns[i];
        if (column.calculSummary && column.calculSummary.length > 0) {
          hasSummary = true;
        }

        if (column.caption.indexOf('$$') != -1) {
          specificColumns.push(column);
        }

        if (column.isIncludedInDataGrid || column.type =='command') {
          gridColumns.push(column);
        }

        if(column.dataField.indexOf('Code')>-1) {
          ctrl.codeListName = column.format;
        }

        if(column.isIncludedInMaster) {
            masterColumns.push(column);
            if(!(column.isIncludedInDataGrid || column.type =='command')) {
              column.visible = false;
              gridColumns.push(column);
            }
        }
        
        if(column.isIncludedInMaster || column.isIncludedInDataGrid) {
          FrontOfficeGridFactory.setColumnIsIncludedInDatagridOrMaster(ctrl, column);
        }
      }

      if(hasSummary) {
        this.gridConfiguration.summary = {};
        this.gridConfiguration.summary.totalItems = [];
        this.gridConfiguration.remoteOperations = false;
      }
      
      for(var i = 0; i < this.gridConfiguration.columns.length; i++) {
        let item = this.gridConfiguration.columns[i];
        if(hasSummary && item.calculSummary && item.calculSummary.length > 0) {
          var summaryKind = item.calculSummary.split('|');
          for(var j = 0; j < summaryKind.length; j++) {
            if (summaryKind[j] == "Sum" || summaryKind[j] == "Average" || summaryKind[j] == "Min" || summaryKind[j] == "Max" || summaryKind[j] == "Sum" || summaryKind[j] == "Count") {
              this.gridConfiguration.summary.totalItems.push({
                column: item.dataField,
                summaryType: summaryKind[j].toLowerCase()
              });
            }
          }
        }

        if (item.dataField == 'Name') {
          item.caption = 'Identifiant court'
        }
        else if(item.dataField == 'LongName') {
          item.caption = 'Identifiant long'
        }
        else if(item.dataField == 'Parent') {
          item.caption = 'Etablissement'
        }

        if (item.dataType == "text "){
          item.dataType ='string';
        }
        
        if(this.entityLock) {
          item.allowEditing = false;
        }

        if(item.allowEditing) {
          var action = this.currentEntity != null && this.currentEntity.IsSpecial ? 'USub' : 'U';

          if(item.subPerimeter == null) {
            item.subPerimeter = '';
          }

          if(this.dictSub[item.subPerimeter+action] != null) {
            item.allowEditing = this.dictSub[item.subPerimeter+action];
          }
          else {
            var stateId = ctrl.stateId;
            this.dictSub[item.subPerimeter+action] = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, item.subPerimeter, stateId, action);
            item.allowEditing = this.dictSub[item.subPerimeter+action];
          }
        }

        await this.SetItemCell(item);

        await FrontOfficeGridFactory.setDictSub(ctrl, item);

        if(this.isGridEntity && item.visible && (item.dataField == 'LongName' || item.dataField == 'Nom' || item.dataField == 'Prenom' || item.dataField == 'DateSelonActe')) {
          item.allowEditing = true;
        }

        if(item.link && item.link.length > 0) {
          ctrl.subPattern = item.link;
        }

        if(item.dataField == '' || item.dataField == 'Disabled') {
          item.visible = false;
        }

        if(item.isRequired && item.allowEditing && item.visible ) {
          item.validationRules= [{ type: "required" }];
        }
      }

      if(this.gridConfiguration.editing.canDelete && ctrl.subPerimeter != '' && (this.currentEntity == null  || !this.currentEntity.IsSpecial)) {
        this.gridConfiguration.editing.canDelete = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'D');
      }

      if(canAdd && ctrl.subPerimeter != '' && (this.currentEntity == null || !this.currentEntity.IsSpecial)) {
        canAdd = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'C');
      }

      if(this.gridConfiguration.editing.canDelete && ctrl.subPerimeter != '' && this.currentEntity != null &&this.currentEntity.IsSpecial) {
        this.gridConfiguration.editing.canDelete = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'DSub');
      }

      if(canAdd && ctrl.subPerimeter != '' &&this.currentEntity != null && this.currentEntity.IsSpecial) {
        canAdd = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'CSub');
      }
        
      if(this.isGridEntity) {
        await this.setEntityGridConfiguationColumns();
      }
      else {
        await this.setNormalGridConfiguationColumns();
      }

      this.gridConfiguration.onSaved = async (event : any)=> { await FrontOfficeGridFactory.gridSaved(ctrl, event, true); }
      var gridEvent = {
        onCellPrepared: function (e: any) { ctrl.gridCellPrepared(e, canAdd); },
        onRowPrepared: function (e: any) {ctrl.gridRowPrepared(e); },
        onInitNewRow: function(info : any) { FrontOfficeGridFactory.gridInitNewRow(ctrl, info); },
        onEditingStart: function (e: any) { ctrl.gridEditingStart(e); },
        onExporting: function(e: any) { ctrl.gridExporting(e); },
        onSelectionChanged: function (e: any) { ctrl.gridSelectionChanged(e); },
        onFocusedCellChanging: function(e: any){ FrontOfficeGridFactory.gridFocusedCellChanging(e); },
      };

      let gridData:any;

      if(specificColumns.length == 0) {
        if(masterColumns.length > 0) {
          await ctrl.configMaster(this.gridConfiguration, masterColumns, ctrl);
        }
        var procID = this.currentProc.IdObject == null ? 0 : this.currentProc.IdObject;
        gridData = await lastValueFrom(this.applicationGridService.getGridDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName));
        
        FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
        if(!this.isGridEntity)
        {
          gridData = this.getGridDataWithHasErrorUpdated(gridData);
        }
        
        this.configureGridColumnsWidthWithNormalColumns();
      }
      else
      {
        await FrontOfficeGridFactory.setColumnsCaptions(ctrl, specificColumns);

        if(masterColumns.length > 0) {
          ctrl.configMaster(this.gridConfiguration, masterColumns, ctrl);
        }

        var procID = this.currentProc.IdObject != null ? this.currentProc.IdObject : 0
        gridData = await lastValueFrom(this.applicationGridService.getGridDataByEntityId(ctrl.zoneName,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName))
        
        FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
        gridData = this.getGridDataWithHasErrorUpdated(gridData);

        FrontOfficeGridFactory.configureGridColumnsWidthWithSpecificColumns(ctrl.gridConfiguration);
      }

      var sumColumn =  0;
      this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).forEach((column: any) => { 
        sumColumn += parseFloat(column.width.replace('%', ''))
      });

      if(sumColumn < 85) {
        var visibleColumns = this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
        let toAdd = (85-sumColumn)/visibleColumns.length;
        this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).forEach((column: any) => { 
          column.width=(parseFloat(column.width.replace('%', ''))+toAdd)+'%'
        });
      } 

      this.gridConfiguration.columnMinWidth = '';
      let grid = $('#grid-'+ctrl.gridName).dxDataGrid(<string>this.gridConfiguration).dxDataGrid(gridEvent).dxDataGrid('instance');
      ctrl.gridDataSource = gridData

      if(!ctrl.filterErrorsRows) {
        grid.option("dataSource", gridData);
      }
      else {
        ctrl.filterDataSource =  ctrl.gridDataSource.filter((tb: { HasError: boolean; }) => tb.HasError == true);
        grid.option("dataSource", ctrl.filterDataSource);
      }

      if(ctrl.isGridEntity) {
        //grid.option("editing.mode", "row")
      }
      
      grid.refresh();
      $('#loadpanel').dxLoadPanel('instance').hide();      
    }
  }

  async setEntityGridConfiguationColumns() {
    var ctrl = this;

    if(this.tabStatus == null) {
      this.tabStatus = 0
    }

    var applicationActions : any[] = await lastValueFrom(this.appStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.tabName.indexOf('/') == -1 ? this.tabName : this.tabName.split('/')[1], this.currentState.Id, this.currentStateTab.Id));;
    applicationActions = applicationActions.filter((tb: { VisibilityCriteria: string; }) => tb.VisibilityCriteria.startsWith('Features@'))
    var canEditGrid = true;
    var action = this.currentEntity != null &&this.currentEntity.IsSpecial ? 'USub' : 'U';

    if(ctrl.subPerimeter != '' && ctrl.subPerimeter != null) {
      canEditGrid = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, action);
    }

    var columnButon =
    {
      type: 'buttons',
      width: '15%',
      style: 'text-align: left',
      allowEditing:false,
      buttons: 
      [
        ctrl.getGridEntityButtonContext(),
        ctrl.getGridEntityButtonDelete(this.gridConfiguration.editing.canDelete),
        ctrl.getGridEntityButtonActivate(canEditGrid),
        ctrl.getGridEntityButtonDesactivate(canEditGrid),
        ctrl.getGridEntityButtonAccessInformation(canEditGrid),
        ctrl.getGridEntityButtonAccessOrganizationInformation(),
        ctrl.getGridEntityButtonResetPassword(canEditGrid),
        ctrl.getGridEntityButtonEmailActivation(canEditGrid)
      ],
    };
    applicationActions.forEach(element => {
      columnButon.buttons.push({
        hint: element.Tooltip,
        template: '<i class="fa-solid '+element.IconClass+'"></i>',
        async onClick(e:any) {
          var action = element;
          var tabRef  = ctrl.zoneName+'_'+ctrl.applicationName ;
          action.FormStatus = ctrl.currentState.Id;
          switch (action.ActionType) {
            case ActionTypeButton.API:
              ctrl.actionLegacyService.parsingActionTypeApiApp(action, ctrl.space, parseFloat(e.row.data.Id), ctrl.currentModule.EntityCible, ctrl.tabName, tabRef, ctrl.tabStatus, [], ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
              break;
            case ActionTypeButton.Javascript:
              await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(action,ctrl.space, parseFloat(e.row.data.Id), ctrl.tabName, tabRef, ctrl.tabStatus, [], ctrl.currentModule.EntityCible, [], ctrl.applicationName, ctrl.zoneName, ctrl.version, [], ctrl.currentZone.WorkflowId, null, null);
              break;
          }
              
            e.event.preventDefault();
          },
        })
    });
    
    this.gridConfiguration.columns.push(columnButon);
  }

  async setNormalGridConfiguationColumns() {
    var ctrl = this;
    var applicationActions : any[] = await lastValueFrom(this.appStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.tabName.indexOf('/') == -1 ? this.tabName : this.tabName.split('/')[1], this.currentState.Id, this.currentStateTab.Id));;
    var calc = applicationActions.filter((tb: { Name: string; }) => tb.Name == 'Calcul')[0];
    var calc = <any>applicationActions.filter((tb: { Name: string; }) => tb.Name == 'Calcul')[0];
    this.showLineOnError = calc == null ? false : calc.DisplayErrorTab;
    this.headerGotFilterErrorRows = this.showLineOnError && this.gridConfiguration.filterRow.visible;

    if(this.gridConfiguration.editing.canDelete) {
      this.gridConfiguration.columns.push({
        type: 'buttons',
        width: 80,
        allowEditing:false,
        buttons: [{
            hint: 'Supprimer',
            template: '<i class="fa-solid fa-trash"></i>',
            onClick(e:any) {
              FrontOfficeGridFactory.deleteRow(ctrl, e.row.key, ctrl.entityIdentifier, true, 'ok');
            },
          }
        ],
      });
    }
    else
    {
      this.gridConfiguration.columns.push({
        type: 'buttons',
        width: 40,
        allowEditing:false,
        buttons: [],
      });
    }
    var columnButton = this.gridConfiguration.columns[this.gridConfiguration.columns.length-1];
    if(ctrl.showComment) {
      columnButton.width += 40;
    }
    if(ctrl.showFile) {
      columnButton.width += 40;
    }
  }

  async SetItemCell(item:any) {
    if(item.dataField.indexOf('IdEnumerationItem') > 0) {
      await FrontOfficeGridFactory.setCellEnumerationItem(this, item);
    }
    else if (item.dataField == 'Organisation') {
      await this.setCellOrganisation(item);
    }
    else if (item.dataField =='RolePrincipal' || item.dataField =='Role') {
      await this.setCellRole(item);      
    }
    else if (item.dataField == 'Provider' ) {
      await this.setCellProvider(item);
    }
    else if (item.dataField == 'Parent')
    {
      await this.setCellParent(item);        
    }
    else if (item.dataField != '') {
      await this.setCellNormal(item);
    }
  }

  async setCellProvider(item: any) {
    var ctrl = this;

    let items : any =  await lastValueFrom(ctrl.idpService.getAvailableProviders());
    item.allowSorting= false;  
    
    var canEdit = item.allowEditing;
    item.cellTemplate= function (container: any, options: any) {
      container.dxAutocomplete({
        dataSource: items,
        disabled : !canEdit || options.value != '',
        placeholder: 'Veuillez choisir un idp',
        valueExpr: 'Name',
        value: options.value,
        showClearButton: false,
        onItemClick: async function (data:any)
        {
          if(data.itemData.Key == options.value) {
            return;
          }

          var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          currentRowGrid.data['Provider'] = data.itemData.Name;
          await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, currentRowGrid.data));
          container.dxAutocomplete('instance').option('disabled', true);
          ctrl.appStructService.updateFormData.emit('ok')
        }
      });
    }
    item.editCellTemplate= function (container: any, options: any) {
      container.dxAutocomplete({
        dataSource: items,
        placeholder: 'Veuillez choisir un idp',
        valueExpr: 'Name',
        disabled : !canEdit || options.value != '',
        value: options.value,
        showClearButton: false,
        onItemClick: async function (data:any)
        {
          if(data.itemData.Key == options.value) {
            return;
          }
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          currentRowGrid.data['Provider'] = data.itemData.Name;
          (<any>currentRowGrid).cells[6].setValue(data.itemData.Name);
        }
      });
    } 
  }

  async setCellParent(item : any) {
    var ctrl = this;

    var url = './api/v1/application/'+ctrl.applicationName+'/'+ctrl.space+'/'+ctrl.parentEntityCible+'/'+ctrl.currentProc.IdObject+'/list_entity.json';
    let items : any =  await lastValueFrom(ctrl.httpClient.get(url));
    ctrl.parents = items
    item.allowSorting= false;
    item.cellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Id: string; }) => tb.Id == options.value)[0]
      container.dxAutocomplete({
        dataSource: items,
        placeholder: 'Veuillez choisir une entité parente',
        valueExpr: 'Id',
        searchExpr:'Name',
        disabled : true,
        value: temp != null ? temp.Name : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Name}</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any)
        {
          if(data.itemData.Id == options.value) {
            return;
          }
          var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          currentRowGrid.data['Parent'] = data.itemData.Id;
          await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName,
            ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, currentRowGrid.data));
            ctrl.appStructService.updateFormData.emit('ok')
        }
      });
    }  
    item.editCellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Id: string; }) => tb.Id == options.value)[0]
      container.dxAutocomplete({
        dataSource: items,
        placeholder: 'Veuillez choisir une entité parente',
        valueExpr: 'Id',
        searchExpr:'Name',
        value: temp != null ? temp.Name : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Name}</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any) {
          if(data.itemData.Id == options.value) {
            return;
          }
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          currentRowGrid.data['Parent'] = data.itemData.Id;   
          var index = (grid.getVisibleColumns() as any[]).filter((tb: { dataField: string; }) => tb.dataField == 'Parent')[0].index;   
          (<any>currentRowGrid).cells[index-1] = data.itemData.Id;
        }
      });
    } 
  }

  async setCellRole(item : any) {
    var ctrl = this;

    var url = './api/v1/application/'+ctrl.space+'/availableRole/vue.json';
    let param = { Parameters : '' }
    let items : any =  await lastValueFrom(ctrl.httpClient.post(url, param));
    item.allowSorting= false;
    var canEdit = item.allowEditing;
    item.cellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Role: string; }) => tb.Role == options.value)[0]
      container.dxAutocomplete({
        dataSource: items,
        disabled : !canEdit,
        placeholder: 'Veuillez choisir un rôle',
        valueExpr: 'Role',
        value: temp != null ? temp.Role : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Label}</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any)
        {
          if(data.itemData.Role == options.value) {
            return;
          }

          var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          var associateCell = (<any>currentRowGrid).cells.filter((tb: { column: any; }) => tb.column.dataField == 'RolePrincipal')[0];
          if(associateCell != null)
            currentRowGrid.data['RolePrincipal'] = data.itemData.Role;
          else
          {
            associateCell = (<any>currentRowGrid).cells.filter((tb: { column: any; }) => tb.column.dataField == 'Role')[0];
            if(associateCell != null)
              currentRowGrid.data['Role'] = data.itemData.Role;
          } 

          await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName,
            ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, currentRowGrid.data));
            ctrl.appStructService.updateFormData.emit('ok')
        }
      });
    }
    item.editCellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Role: string; }) => tb.Role == options.value)[0]
      container.dxAutocomplete({
        disabled : !canEdit,
        dataSource: items,
        placeholder: 'Veuillez choisir un rôle',
        valueExpr: 'Role',
        value: temp != null ? temp.Role : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Label}</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any) {
          if(data.itemData.Role == options.value) {
            return;
          }
          
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];

          var associateCell = (<any>currentRowGrid).cells.filter((tb: { column: any; }) => tb.column.dataField == 'RolePrincipal')[0];
          if(associateCell != null)
          {
            associateCell.setValue(data.itemData.Role);
          }
          else
          {
            associateCell = (<any>currentRowGrid).cells.filter((tb: { column: any; }) => tb.column.dataField == 'Role')[0];
            if(associateCell != null)
            {
              associateCell.setValue(data.itemData.Role);  
            }
          }              
        }
      });
    }     
  }

  async setCellOrganisation(item: any) {
    var ctrl = this;

    var url = './api/v1/application/'+ctrl.space+'/availableOrganisation/vue.json';
    let param = { Parameters : '' }
    let items : any =  await lastValueFrom(ctrl.httpClient.post(url, param));
    ctrl.organisations = items;
    item.allowSorting= false;
    var canEdit = item.allowEditing;
    item.cellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Key: string; }) => tb.Key == options.value)[0]
      container.dxAutocomplete({
        dataSource: items,
        disabled : !canEdit,
        placeholder: 'Veuillez choisir une organisation',
        valueExpr: 'Val',
        value: temp != null ? temp.Val : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Val} (${data.Type})</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any) {
          await FrontOfficeGridFactory.onItemClickOrganisationChoice(ctrl, data, options);
        }
      });
    }
    item.editCellTemplate= function (container: any, options: any) {
      var temp = items.filter((tb: { Key: string; }) => tb.Key == options.value)[0];
      container.dxAutocomplete({
        dataSource: items,
        placeholder: 'Veuillez choisir une organisation',
        valueExpr: 'Val',
        value: temp != null ? temp.Val : null,
        itemTemplate(data:any) {
          return $(`<div>${data.Val} (${data.Type})</div>`);
        },
        showClearButton: false,
        onItemClick: async function (data:any) {
          if(data.itemData.Key == options.value) {
            return;
          }
        
          var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          let rows = grid.getVisibleRows();  
          let currentRowGrid = rows[options.rowIndex];
          currentRowGrid.data['Organisation'] = data.itemData.Key;           
          var associateCell = (<any>currentRowGrid).cells.filter((tb: { column: any; }) => tb.column.dataField == 'Organisation')[0];
          associateCell.setValue(data.itemData.Key);
        }
      });
    }   
  }

  async setCellNormal(item: any) {
    if (item.dataType == 'number') {
      item.editorOptions = {  
        step: 0  
      }  
    }

    item.dataField = item.dataField.split('.')[item.dataField.split('.').length-1];
  }

  configureGridColumnsWidthWithNormalColumns() {
    if((this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') == -1))
    {
      var visibleColumns = this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
      var commandColumn = this.gridConfiguration.columns.filter((tb: { type: string; }) => tb.type == "buttons")[0];
      var width = 100/visibleColumns.length+'%';
      if(commandColumn != null)
      {
        if(commandColumn.width == '15%') {
          width = 85/visibleColumns.length+'%'
        }
      }
      for(var i = 0; i<visibleColumns.length;i++) {
        visibleColumns[i].width = width;
      }
    }
    else if((this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && this.gridConfiguration.columns.filter((tb: {style: string; visible: boolean; }) => tb.visible && (tb.style == '' ||   tb.style.indexOf('width') == -1)).length > 0))
    {
      var visibleColumns = this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible);
      var commandColumn = this.gridConfiguration.columns.filter((tb: { type: string; }) => tb.type == "buttons")[0];
      var width = 100/visibleColumns.length+'%';
      if(commandColumn != null)
      {
        if(commandColumn.width == '15%') {
          width = 85/visibleColumns.length+'%'
        }
      }
      for(var i = 0; i<visibleColumns.length;i++) {
        visibleColumns[i].width = width;
      }
      for(var i = 0; i<visibleColumns.length;i++) {
        var style = visibleColumns[i].style;
        if(style) {
          style = style.substring(style.indexOf('width'))
          let widthT = style.split(';')[0];
          visibleColumns[i].style = style.replace(widthT+';','');
        }
        visibleColumns[i].width = width;
      }
    }
    else if(this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible).length > 0 && this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && this.gridConfiguration.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') != -1)
    {
      FrontOfficeGridFactory.configureGridColumnsWidthFromGridConfigColumnWidth(this.gridConfiguration);
    }
  }

  getGridDataWithHasErrorUpdated(gridData:any) {

    var ctrl = this;

    for(var i = 0; i<gridData.length; i++) {
      gridData[i].HasError = 
        ((gridData[i].ContextInfo != null && gridData[i].ContextInfo.Level == 2) && 
          (
               (ctrl.commentLevel == 2 || ctrl.documentLevel == 2) 
            || (ctrl.commentLevel == 0 && ctrl.documentLevel == 0) 
            || (ctrl.commentLevel == 1 && ctrl.documentLevel == 0) 
            || (ctrl.commentLevel == 0 && ctrl.documentLevel == 1) 
            || (ctrl.commentLevel == 1 && ctrl.documentLevel == 1) 
            || (ctrl.commentLevel == 3 && (gridData[i].ContextInfo == null || (!gridData[i].ContextInfo.HasComment))) 
            || (ctrl.documentLevel == 3 && (gridData[i].ContextInfo == null || (!gridData[i].ContextInfo.HasDocument)))
          )
        )
        ||
        ((gridData[i].ContextInfo != null && gridData[i].ContextInfo.Level == 1 && ((ctrl.commentLevel == 2 && !gridData[i].ContextInfo.HasComment) || (ctrl.documentLevel == 2 && !gridData[i].ContextInfo.HasDocument))));   
      }

    return gridData;
  }

  getGridEntityButtonContext():any{
    var ctrl = this;
    return {
      hint: 'Context',
      template: '<i class="fa-solid reverseColorAdd fa-arrow-right"></i>',
      visible(e:any) {
        return ctrl.subPattern != '';
      },
      onClick(e:any) {
        if(ctrl.subPattern != '') {
          let detailTab = ctrl.currentModule.Tabs.filter((tb: { Name: string; }) => tb.Name == ctrl.subPattern)[0]
          if(detailTab != null) {
            if(detailTab.DetailEntity) {
              ctrl.sessionStorageService.set('currentLine', e.row.key);
              ctrl.router.navigate(['/app/' + ctrl.applicationName + '/' + ctrl.zoneName+ '/' + ctrl.moduleName+ '/' + ctrl.entityIdentifier + '/'+ctrl.subPattern]);
            }
            else {
              ctrl.router.navigate(['/app/' + ctrl.applicationName + '/' + ctrl.zoneName+ '/' + ctrl.moduleName+ '/' + e.row.key + '/'+ctrl.subPattern]);
            }
          }
        }
        e.event.preventDefault();
      },
    };
  }

  getGridEntityButtonDelete(canDelete:any):any{
    var ctrl = this;
    return {
      hint: 'Supprimer',
      template: '<i class="fa-solid fa-trash"></i>',
      visible(e:any) {
        return !ctrl.isDirector && canDelete;
      },
      onClick(e:any) {
        ctrl.confirmationModalComponent.showConfirmationWindow('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer cette ligne ?', ctrl.gridName, e.row.data.Id)
        e.event.preventDefault();
      },
    };
  }

  getGridEntityButtonActivate(canEditGrid:any):any{
    var ctrl = this;
    return {
      hint: 'Activer',
      template: '<i class="fa-solid fa-unlock"></i>',
      visible(e:any) {
        return e.row.data.Disabled != null && e.row.data.Disabled == true && ctrl.nbLineSelected < 2 && canEditGrid;
      },
      onClick(e:any) {
        var tabName6 = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        e.row.data.Disabled = false;
        ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName,ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName6, e.row.data).subscribe
        ({
            next: async config => {
              if(ctrl.currentModule.IsModuleUser) {
                var idpRequest = { 
                  Value:e.row.data.LongName, 
                  SubPerimeter : ctrl.subPerimeter,
                }
                if(e.row.data.Provider == 'Local') {
                  await lastValueFrom(ctrl.idpService.enableUser(ctrl.space, idpRequest));
                }
              }
              setTimeout(async ()=>
              await FrontOfficeGridFactory.refreshData(ctrl), 200);
            },
            error: err => console.log(err)
        });
        e.event.preventDefault();
      },
    };
  }

  getGridEntityButtonDesactivate(canEditGrid:any):any{
    var ctrl = this;
    return {
      hint: 'Désactiver',
      template: '<i class="fa-solid fa-ban"></i>',
      visible(e:any) {
        return e.row.data.Disabled != null && e.row.data.Disabled == false && ctrl.nbLineSelected < 2 && canEditGrid;
      },
      onClick(e:any) {
        var tabName6 = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        e.row.data.Disabled = true;
        ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName6, e.row.data).subscribe({
          next: async config => {
            if(ctrl.currentModule.IsModuleUser) {
              var idpRequest = { 
                Value:e.row.data.LongName, 
                SubPerimeter : ctrl.subPerimeter,
              }
              if(e.row.data.Provider == 'Local') {
                await lastValueFrom(ctrl.idpService.disableUser(ctrl.space, idpRequest));
              }
            }
            setTimeout(async ()=>
            await FrontOfficeGridFactory.refreshData(ctrl), 200);
        },
        error: err => console.log(err)
      });
        e.event.preventDefault();
      },
    };
  }

  getGridEntityButtonAccessInformation(canEditGrid:any):any{
    var ctrl = this;
    return {
      hint: 'Information accès',
      template: '<i class="fa-solid fa-paper-plane"></i>',
      visible(e:any) {
        return e.row.data.Id != null && ctrl.currentModule.IsModuleUser && canEditGrid;
      },
      async onClick(e:any) {
        var idpRequest = { 
          Value:e.row.data.LongName, 
          SubPerimeter : ctrl.subPerimeter,
          Action : 'sentAccess'
        }
        ctrl.idpModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir renvoyer les accès par email pour les utilisateurs sélectionées ?', idpRequest, true);
        e.event.preventDefault();
      },
    };
  }

  getGridEntityButtonAccessOrganizationInformation():any {
    var ctrl = this;
    return {
      hint: 'Information accès organisation',
      template: '<i class="fa-solid fa-passport"></i>',
      visible(e:any) {
        return e.row.data.Id != null && ctrl.currentModule.IsModuleUser && ctrl.nbLineSelected < 2 && ctrl.isDirector;
      },
      async onClick(e:any) {
        var idpRequest = { 
          Value:e.row.data.LongName, 
          SubPerimeter : ctrl.subPerimeter,
          Action : 'sentGlobalAccess'
        }
        ctrl.idpModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir recevoir les accès des utilisateurs de votre organisation par email ?', idpRequest, false);
        e.event.preventDefault();
      },
    }
  }

  getGridEntityButtonResetPassword(canEditGrid:any):any{
    var ctrl = this;
    return {
      hint: 'Rénitialisé mot de passe',
      template: '<i class="fa-solid fa-key"></i>',
      visible(e:any) {
        return e.row.data.Id != null && ctrl.currentModule.IsModuleUser && ctrl.nbLineSelected < 2 && e.row.data.Provider == 'Local' && canEditGrid;
      },
      async onClick(e:any) {
        var idpRequest = { 
          Value:e.row.data.LongName, 
          SubPerimeter : ctrl.subPerimeter,
          Action : 'resetPass'
        }
        ctrl.idpModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) faire un reset du mot de pass ?', idpRequest, true);
        
        e.event.preventDefault();
      },
    }
  }

  getGridEntityButtonEmailActivation(canEditGrid:any):any{
    var ctrl = this;
    return  {
      hint: 'Envoi email activation',
      template: '<i class="fa-solid fa-handshake"></i>',
      visible(e:any) {
        return e.row.data.Id != null && ctrl.currentModule.IsModuleUser && ctrl.nbLineSelected < 2 && e.row.data.Provider == 'Local' && canEditGrid && (e.row.data.DateOnboarding == '1899-12-31T23:00:00' || e.row.data.DateOnboarding == '1900-01-01T00:00:00');
      },
      async onClick(e:any) {
        var idpRequest = { 
          Value:e.row.data.LongName, 
          SubPerimeter : ctrl.subPerimeter,
          Action : 'sentOnboarding'
        }
        ctrl.idpModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir renvoyer l\'email d\'activation', idpRequest, true);
        
        e.event.preventDefault();
      },
    }
  }

  updateContext(row : any, tab: string, rowElement:any)
  {
    row.Tooltip = this.information;
    row.TooltipEnabled = this.showInformation;
    row.AllowComment = this.showComment;
    row.AllowFile = this.showFile;
    row.MaxDoc = this.maxDoc;
    row.MaxSizePerDoc = this.maxSizePerDoc;
    row.Extension = this.extension;
    row.DocCode = this.docCode;
    var tabName = tab + ':' + (this.isGridEntity ? row.Id : row.Code);
    if(row.Code && row.Code.IdEnumerationItem) {
      tabName = tab + ':' + row.Code.IdEnumerationItem;
    }
    console.log('enter')
    var item = {
      OriginName : this.gridName,
      Type: 'Grid',
      Value : row,
      Tab: tab,
      ApplicationName : this.applicationName,
      IsSpecial : this.currentEntity != null  ? this.currentEntity.IsSpecial : false,
      Space : this.space,
      Version : this.version,
      Zone : this.zoneName,
      LockEntity : this.entityLock,
      EntityId : this.isGridEntity ? row.Id : this.entityIdentifier,
      Module: this.moduleName,
      Onglet: this.tabName.includes("/") ? this.tabName.split("/").pop() : this.tabName.includes("ø") ? this.tabName.split("ø").pop() : this.tabName,
      Bloc : this.order.toString(),
      Ligne: String((row.Code && row.Code.IdEnumerationItem) ? row.Code.IdEnumerationItem : (this.isGridEntity ? row.Id : row.Code)),
      ContextInfo : row.ContextInfo,
      Multi : this.multiTab,
      CommentLevel:this.commentLevel,
      DocumentLevel:this.documentLevel,
      SubPerimeter:this.subPerimeterDoc,
      StateId : this.stateId,
      WorkflowId : this.currentZone.WorkflowId,
      RowElement : rowElement
    }
    this.appStructService.updateContextPanel.emit(item)
  }

  async configMaster(config : any, masterColumns: any[], ctrl:any)
  {
    var tabs: any[]= [];
    FrontOfficeGridFactory.setTabsAndItemsConfigMaster(ctrl, masterColumns, tabs);

    config.masterDetail = {
      enabled: true,
      template: async function(element : any, masterDetailOptions :any) {
        let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
        if(ctrl.isGridEntity) {
          //grid.option("editing.mode", "row")
        }
        let detailEntity = await lastValueFrom(ctrl.applicationGridService.getMasterData(ctrl.version, masterDetailOptions.data.Id, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName));
        var items: { data: { index: number, item : any, items : any }, title : string; }[] = [];
        for(var i=0; i<  tabs.length; i++) {
          if( tabs[i].Name == '') {
            tabs[i].Name ='Infos'
          }
          items.push({
            title:  StringUtils.decodeSpecialEntitiesHtml(tabs[i].Name),
            data: { item : detailEntity, items :tabs[i].items, index:i},
          })
        }
        if(items.length > 1) {
          element.append($('<div>').dxTabPanel((
            {
              items: items,
              itemTemplate: function(itemData, itemIndex, itemElement) {
                var formName = 'formMaster'+itemData.data.index;
                var info = $('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxForm({
                  formData: itemData.data.item,
                  showColonAfterLabel: true,
                  labelMode: 'floating',
                  onFieldDataChanged : async function(e) { await ctrl.dxFormFieldDataChanged(e, itemData.data.item.Id, '#'+formName); },
                  colCount: 2,
                  items: itemData.data.items
                });
                itemElement.append(info)
              }
            }
          )));
        }
        else
        {
          element.append($('<div id="formMaster">').addClass('component-master-form').dxForm({
            formData: detailEntity,
            showValidationSummary: true,
            showColonAfterLabel: true,
            labelMode: 'floating',
            validationGroup: 'validateForm',
            onFieldDataChanged : async function(e) { await ctrl.dxFormFieldDataChanged(e, items[0].data.item.Id, '#formMaster'); },
            colCount: 2,
            items:  tabs[0].items
          }));
        }
        grid.updateDimensions();
      }
    };
  }

  gridCellPrepared(e:any, canAdd:any){
    var ctrl = this;

    if (e.column.style && e.column.style !== "") {
      var styles = e.column.style.split(';')
      styles.forEach((style: string) => {
        var attributs = style.split(':');
        e.cellElement.css(attributs[0], attributs[1]);
      });
    }
    if (e.column.buttons && e.rowType =="header" && canAdd) {
      ctrl.addHeaderRowAddButton(e);
      if (ctrl.multiRowManagement) {
        if (ctrl.headerGotFilterErrorRows) {
          ctrl.addHeaderErrorRowFilter(e);
        }
        else if (!ctrl.editiontabs || ctrl.editiontabs.length <= 0) {
          ctrl.addMultiRowManagement(e);
        }
      }
    }

    if (e.column.buttons && e.rowType =="filter") {
      if (ctrl.multiRowManagement) {
        ctrl.addMultiRowManagement(e);
      }
      else if (ctrl.showLineOnError && !ctrl.isGridEntity) {
        ctrl.addHeaderErrorRowFilter(e);
      }
    }

    if (e.column.buttons && e.rowType =="data" )
    {
      this.createContextButton(e);
    }
  }

  addHeaderRowAddButton(e : any) {
    var ctrl = this;
    
    e.cellElement.dxButton({
      hint: 'Ajouter une ligne',
      icon: 'add',
      onClick(param:any) {
        if(ctrl.autoCreationEntity) {
          var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
          var data = {
            Name :'',
            LongName :'',
            LectureSeul :false,
            ResidNom:'',
            ResidPrenom:'',
            Const6:''
          }
          ctrl.applicationGridService.insertAttributEntity(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, data, ctrl.entityIdentifier, ctrl.isGridEntity).subscribe({
            next: config => {
              if(ctrl.subPattern != '') {
                let detailTab = ctrl.currentModule.Tabs.filter((tb: { Name: string; }) => tb.Name == ctrl.subPattern)[0]
                if(detailTab != null) {
                  if(detailTab.DetailEntity) { ctrl.sessionStorageService.set('currentLine', config); }
                  ctrl.router.navigate(['/app/' + ctrl.applicationName + '/' + ctrl.zoneName+ '/' + ctrl.moduleName+ '/' + (detailTab.DetailEntity ?  ctrl.entityIdentifier : config) + '/'+ctrl.subPattern]);
                }
              }
            }
          });
        }
        else if (param.event.target.className.includes('dx-icon-add') || param.event.target.className.includes('dx-button-content')) {
          let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
          grid.addRow();
          grid.deselectAll();
        }
      },
    }).dxButton('instance')
    e.cellElement.addClass("add-grid-button")
    e.cellElement.addClass("headerRowAddButton")
  }

  addHeaderErrorRowFilter(e : any){
    var ctrl = this;
    e.cellElement.append($("<div>").addClass("headerErrorRowFilter").dxCheckBox({
      text: 'Erreurs',
      value:ctrl.filterErrorsRows,
      onValueChanged(e :any) {
        const newValue = e.value;
        let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
        ctrl.filterErrorsRows = newValue == false;
        if(newValue != false) {ctrl.filterDataSource =  ctrl.gridDataSource.filter((tb: { HasError: boolean; }) => tb.HasError == true);}
        grid.option("dataSource", newValue == false ? ctrl.gridDataSource : ctrl.filterDataSource);
        grid.refresh();
      },
    }))
  }

  gridRowPrepared(e:any){
    var ctrl=this;

    if (e.data) {


      if (e.data.LectureSeul === true) {
        e.rowElement.addClass("lecture-seul");
      }

      if (e.data.HasError === true) {
        var row = ctrl.gridDataSource.filter((tb: { Id: string; }) => tb.Id == e.data.Id)[0];
        if (row.ContextInfo.Level == 2) {
          e.rowElement.addClass("error");
        }
      }
      else{
        e.rowElement.removeClass("error");
      }

      if ((e.data.ContextInfo != null && e.data.ContextInfo.Level == 1 && ((ctrl.commentLevel == 2 && !e.data.ContextInfo.HasComment) || (ctrl.documentLevel == 2 && !e.data.ContextInfo.HasDocument)))){
        e.rowElement.addClass("warning");
      }
    }
  }

  gridEditingStart(e:any){
    if(e.data && e.data.LectureSeul === true){
      e.cancel=true;
    }

    this.selectedRowId = e.data.Id;
  }

  gridExporting(e:any){
    exportDataGrid(e.component);
  }

  exportDataGrid(grid:any) {
    var ctrl = this;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');
    exportDataGrid({
      component: grid,
      worksheet,
      customizeCell(options) {
        const { gridCell } = options;
        const { excelCell } = options;

        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'Parent') {
            var filteredList = ctrl.parents.filter((tb: { Id: number; }) => tb.Id == gridCell.data['Parent']);
            if (filteredList.length > 0){
              excelCell.value = filteredList[0].Name;
            }
          }
          if (gridCell.column.dataField === 'Organisation') 
          {
            var filteredList = ctrl.organisations.filter((tb: { Key: number; }) => tb.Key == gridCell.data['Organisation']);
            if (filteredList.length > 0){
              excelCell.value = filteredList[0].Val;
            }
          } 
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
        var fileName = "Grille_"+ctrl.applicationName+'_'+ctrl.zoneName+'_'+ctrl.entityIdentifier+'_'+ctrl.tabName+'_'+ctrl.gridName+'.xlsx';
        if(ctrl.multiTab)
          fileName = "Grille_"+ctrl.applicationName+'_'+ctrl.zoneName+'_'+ctrl.entityIdentifier+'_'+ctrl.moduleName+'_'+ctrl.tabName+'_'+ctrl.gridName+'.xlsx';
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
      });
    })
  }

  gridSelectionChanged(e:any){
    var ctrl = this;

    if(ctrl.currentModule.IsModuleUser)
    {
      ctrl.nbLineSelected = e.selectedRowKeys.length;
      let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
      grid.repaint()
    }

    if (ctrl.multiRowManagement) {
      var gridId = e.element[0].id;
      ctrl.actualiseGridMultiRowManagementDisabled(gridId);
    }
  }

  actualiseGridMultiRowManagementDisabled(gridId : any) {
    var divId = this.getMultiRowManagementDivId(gridId);

    var selectedRows = this.getGridSelectedRows();
    if (selectedRows != null) {
      if (selectedRows.length > 1) {
        document.getElementById(divId).classList.remove('multiRowManagementDivDisabled');
      }
      else {
        document.getElementById(divId).classList.add('multiRowManagementDivDisabled');
      }
    }
    else {
      document.getElementById(divId).classList.add('multiRowManagementDivDisabled');
    }

    this.justUpdateMultipleRowManagementContext();

  }



  async dxFormFieldDataChanged(e:any, currentRowId:any, formName:any) {
    var ctrl = this;
    var form = $(formName).dxForm('instance');
    var validation = form.validate();
    if(validation.isValid) {
      var componentRowForm = form.option('formData');
      var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
      let rows = grid.getVisibleRows();  
      let currentRowGrid = rows.filter((tb: { key: any; }) => tb.key == currentRowId)[0]
      currentRowGrid.data[e.dataField.replace('.IdEnumerationItem', '')] = componentRowForm[e.dataField.replace('.IdEnumerationItem', '')];
      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

      await lastValueFrom(ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName,
          ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, currentRowGrid.data));
          ctrl.appStructService.updateFormData.emit('ok')
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async OnButtonsCellClick(e : any){

    if (this.lastCellDiv != null){
      this.lastCellDiv.classList.remove("multiRowManagementSelected");
    }
    var tabName = StringUtils.getTabNameWithConsiderMultiTab(this);

    let actualRow = e.component.getRowElement(e.row.rowIndex);
    this.updateContext(e.row.data, tabName, actualRow)
    if( this.selectedRowIndex != '')
    {
      let previousRow = e.component.getRowElement(this.selectedRowIndex);
      $(previousRow).removeClass("selected-line");
    }
    this.selectedRowIndex = e.row.rowIndex;
    $(actualRow).addClass("selected-line");
    if (e.event != undefined){
      e.event.preventDefault();
    }

    this.lastSelectedRow = actualRow;
  }

  getMultiRowManagementDivId(gridId:any) {
    var divId = gridId + 'multiRowManagement';
    return divId;
  }

  get GetMultiRowManagementClass() {
    return 'multiRowManagementDivDisabled';
  }

  addMultiRowManagement(e : any) {
    var gridId = e.element[0].id;
    var divId = this.getMultiRowManagementDivId(gridId);

    e.cellElement.addClass("multiRowManagementCell")

    var html = '<div class="multiRowManagementDiv multiRowManagementDivDisabled" id="' + divId + '">';

    if (this.canDelete) {
      html += '<span id="delete"><i class="fa-solid fa-trash iconMultiRowManagement" title="Supprimer la sélection"></i></span>'
    }

    html += '<div id="contextButton" class="contextButton" title="Afficher le contexte">';

    if(this.showComment) {
      html += '<span><i class="fa-solid fa-comment contextIcon iconMultiRowManagement" title="Nombre de commentaires"></i></span>'
    }

    if (this.showFile) {
      html += '<span><i class="fa-solid fa-file contextIcon iconMultiRowManagement" title="Nombre de documents"></i></span>'
    }

    html += '</div>';
    
    html += '</div>';
    e.cellElement.append(html);

    e.cellElement[e.cellElement.length-1].onclick = async (e:any)=> {
      this.multiRowManagementClick(e);
    }
  }

  multiRowManagementClick(e:any) {

    var selectedRows = this.getGridSelectedRows();
    if (selectedRows == null || selectedRows.length <= 1) {
      return;
    }

    if (this.lastSelectedRow != null) {
      $(this.lastSelectedRow).removeClass("selected-line");
    }

    if (e.srcElement.parentElement.localName == 'span') {
      if (e.srcElement.parentElement.id == 'delete') {
        this.deleteSelectedRowsClick();
        return;
      }
      e.srcElement.parentElement.parentElement.parentElement.classList.add("multiRowManagementSelected");
      this.lastCellDiv = e.srcElement.parentElement.parentElement.parentElement;
    }
    else if (e.srcElement.parentElement.localName == 'div') {
      e.srcElement.parentElement.parentElement.classList.add("multiRowManagementSelected");
      this.lastCellDiv = e.srcElement.parentElement.parentElement;
    }
    else if (e.srcElement.parentElement.parentElement.localName == 'div') {
      e.srcElement.parentElement.parentElement.parentElement.classList.add("multiRowManagementSelected");
      this.lastCellDiv = e.srcElement.parentElement.parentElement.parentElement;
    }
    else {
      e.srcElement.parentElement.classList.add("multiRowManagementSelected");
      this.lastCellDiv = e.srcElement.parentElement;
    }

    if (e.srcElement.parentElement.id == 'contextButton' || e.srcElement.id == 'contextButton' || e.srcElement.parentElement.parentElement.id == 'contextButton')
    {
      var selectedObjects:any[] = this.getSelectedObjectsFromSelectedRows(selectedRows);

      this.callMultipleRowManagementOpenPanel(selectedObjects, true);
    }
  }

  getSelectedObjectsFromSelectedRows(selectedRows:any) {
    var selectedObjects:any[] = [];

    selectedRows.forEach((selectedRow:any) => {
      if (selectedRow != null) { 
        var selectedObject = {
          Module: this.moduleName,
          Onglet: this.tabName.includes("/") ? this.tabName.split("/").pop() : this.tabName.includes("ø") ? this.tabName.split("ø").pop() : this.tabName,
          Bloc : this.order.toString(),
          Ligne: String(this.isGridEntity ? selectedRow.Id : selectedRow.Code == null ? selectedRow.Id : selectedRow.Code.IdEnumerationItem ? selectedRow.Code.IdEnumerationItem : selectedRow.Code),
          NbComment: selectedRow.ContextInfo != null ? selectedRow.ContextInfo.NbComment : 0,
          NbDocument: selectedRow.ContextInfo != null ? selectedRow.ContextInfo.NbDocument : 0,
          EntityId: this.isGridEntity ? selectedRow.Id : this.entityIdentifier
        }

        selectedObjects.push(selectedObject);
      }
    });

    return selectedObjects;
  }

  justUpdateMultipleRowManagementContext() {
    var selectedRows = this.getGridSelectedRows();

    var selectedObjects:any[] = this.getSelectedObjectsFromSelectedRows(selectedRows);
    this.callMultipleRowManagementOpenPanel(selectedObjects, false);
  }

  callMultipleRowManagementOpenPanel(selectedObjects: any[], multiRowNormalOpening:boolean){

    var tabName = StringUtils.getTabNameWithConsiderMultiTab(this);

    var row = {
      Tooltip: false,
      TooltipEnabled: false,
      AllowComment: this.showComment,
      AllowFile: this.showFile,
      MaxDoc: this.maxDoc,
      MaxSizePerDoc: this.maxSizePerDoc,
      Extension: this.extension,
      DocCode: this.docCode
    };
    var item = {
      OriginName : this.gridName,
      MultiRowNormalOpening : multiRowNormalOpening,
      CurrentCell : this.lastCellDiv,
      SelectedObjects: selectedObjects,
      IsMultiRowManagement: true,
      Type: 'Grid',
      Value : row,
      Tab: tabName,
      ApplicationName : this.applicationName,
      IsSpecial : this.currentEntity != null  ? this.currentEntity.IsSpecial : false,
      Space : this.space,
      Version : this.version,
      Zone : this.zoneName,
      LockEntity : this.entityLock,
      EntityId : this.entityIdentifier,
      Module: this.moduleName,
      Onglet: this.tabName.includes("/") ? this.tabName.split("/").pop() : this.tabName.includes("ø") ? this.tabName.split("ø").pop() : this.tabName,
      Bloc : this.order.toString(),
      Multi : this.multiTab,
      CommentLevel:this.commentLevel,
      DocumentLevel:this.documentLevel,
      SubPerimeter:this.subPerimeterDoc,
      StateId : this.stateId,
      WorkflowId : this.currentZone.WorkflowId,
    }
    this.appStructService.updateContextPanel.emit(item)
  }

  deleteSelectedRowsClick() {
    var grid = $('#grid-' + this.gridName).dxDataGrid('instance');
    var selectedRows = grid.getSelectedRowsData();
    if(selectedRows != null && selectedRows.length > 1) {
      this.confirmationModalComponent.showConfirmationWindow('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer les lignes sélectionnées ?', this.gridName, 'multiRowDeleting')
    }
  }

  confirmDeleteSelectedRows() {
    var ctrl = this;
    var selectedRows = this.getGridSelectedRows();
    selectedRows.forEach((selectedRow:any) => {
      if (selectedRow != null) { 
        FrontOfficeGridFactory.deleteRow(ctrl, selectedRow.Id, ctrl.currentModule.IsModuleUser ? selectedRow.Id : ctrl.entityIdentifier, selectedRow == selectedRows[selectedRows.length-1], 'ok');
      }
    });
  }

  getGridSelectedRows() {
    var grid = $('#grid-' + this.gridName).dxDataGrid('instance');
    return grid.getSelectedRowsData();
  }

  createContextButton(e:any) {
    var ctrl = this;

    var button = e.column.buttons.filter((tb: { hint: string; }) => tb.hint == 'Afficher le contexte')[0]
    if(button != null) {
      const index = e.column.buttons.indexOf(button);
      e.column.buttons.splice(index,1)
    }

    if (ctrl.showComment || ctrl.showFile || ctrl.showInformation) {

      e.column.buttons.push({
        hint: 'Afficher le contexte',
        onClick(e:any) {
          ctrl.OnButtonsCellClick(e)
        },
        visible(e: any) {
            return e.row.data.Id != null;
        },
        template: function (e: any, elem: any) {

          
          if(elem.data && elem.data.ContextInfo != null && elem.data.ContextInfo.Level ==1 && ((ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level != 0 && ctrl.commentLevel > 1 && elem.data.ContextInfo.HasComment) || (ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level != 0 && ctrl.documentLevel > 1 && elem.data.ContextInfo.HasDocument))) {
            $(e).parent().addClass('warning')
          }
          else if(elem.data && elem.data.ContextInfo != null && elem.data.ContextInfo.Level ==2 && ((ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level != 0 && ctrl.commentLevel > 1 && elem.data.ContextInfo.HasComment) || (ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level != 0 && ctrl.documentLevel > 1 && elem.data.ContextInfo.HasDocument))) {
            $(e).parent().addClass('error')
          }
                    
          var validatedByComment = (elem.data && (ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level == 1 && ctrl.commentLevel > 1 && elem.data.ContextInfo.HasComment));
          var validatedByDocument = (elem.data && (ctrl.showInformation || ctrl.showComment || ctrl.showFile) && (elem.data.ContextInfo != null && elem.data.ContextInfo.Level != null && elem.data.ContextInfo.Level == 1 && ctrl.documentLevel > 1 && elem.data.ContextInfo.HasDocument)); 

          var title = "Afficher le contexte";

          if (validatedByComment && validatedByDocument) {
            title += " (La ligne a été validée par commentaire et document)";
          }
          else {
            if (validatedByComment) {
              title += " (la ligne a été validée par commentaire)";
            }
  
            if (validatedByDocument) {
              title += " (la ligne a été validée par document)";
            }
          }

          let container = $('<div class="contextButton" title="' + title + '"></div>');
  
          if (ctrl.showInformation) {
              let infoIcon = $('<i class="fa-solid fa-circle-info contextIcon"></i>');
              container.append(infoIcon);
          }
  
          if (ctrl.showComment) {
              let commentIcon = $('<i class="fa-solid fa-comment contextIcon"></i>');
              container.append(commentIcon);
              if (elem.data.ContextInfo != null) {
                  const style = `style="${ctrl.isGridEntity ? 'padding-top: 19px; margin-left: -2px;' : 'padding-top: 15px;'}"`;
                  let badge = $(`<span class="button_badge_grid" ${style}></span>`);
                  badge.html(elem.data.ContextInfo.NbComment);
                  container.append(badge);
              }
          }
  
          if (ctrl.showFile) {
              let fileIcon = $('<i class="fa-solid fa-file contextIcon"></i>');
              container.append(fileIcon);
              if (elem.data.ContextInfo != null) {
                  const style = `style="${ctrl.isGridEntity ? 'padding-top: 19px; margin-left: -2px;' : 'padding-top: 15px;'}"`;
                  let badge = $(`<span class="button_badge_grid" ${style}></span>`);
                  badge.html(elem.data.ContextInfo.NbDocument);
                  container.append(badge);
              }
          }
  
          $(e).append(container);
        }
    });
    }
  }
}