import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { LocalStorageService } from 'app/shared/local-storage.service';
import { AuthService } from '../shared/auth.service';

@Component({
    templateUrl: './home-page.component.html'
})

export class HomePageComponent implements OnInit {
    errorMessage = '';
    email: string='';
    onHome:boolean=true;
    constructor(private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.email = this.authService.getEmail();
        if(this.localStorageService.hasKey('ActiveRoute'))
        {
            var url = this.localStorageService.get('ActiveRoute');
            this.router.navigate([url]);
        }
    }
}
