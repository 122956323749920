import {  Injectable, OnDestroy, TemplateRef } from "@angular/core";
import { Subscription } from "rxjs";
import * as $ from 'jquery';
import { NotificationService } from "./notification.service";
import notify from 'devextreme/ui/notify';
import { NotificationMessage } from "app/shared/NotificationMessage";
import { DownloadService } from "./download.service";
import { PopupService } from "./popup.service";

export enum Categories {
    error,
    warning,
    info,
    success
}

@Injectable({ providedIn: 'root' })
export class AppToastService implements OnDestroy {

    toasts: any[] = [];
    isPanelOpened: boolean = false;
    fileDls: any = {};
    sub!: Subscription;

    constructor(private notificationService:NotificationService, private downloadService: DownloadService, private popupService: PopupService) {
        this.sub = this.notificationService.onIsOpenedChange.subscribe((isOpened) => {
            this.isPanelOpened = isOpened;
            this.fileDls = {};
        });
    }

    ngOnDestroy(): void {
        if (this.sub != null) {
            this.sub.unsubscribe();
        }
    }

    show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
        let isFound = false;
        let date = new Date(options.notif.creationDt)
        date.setMilliseconds(date.getMilliseconds() -2);
        var ctrl = this;
        this.toasts.forEach(function (item) {
            if (item.notif.identifier == options.notif.identifier && new Date(item.notif.creationDt) > date) {
                isFound = true;
            }
            else if (item.notif.identifier == options.notif.identifier && item.level != 'success')
            {
                ctrl.remove(item)
            }
        });
        if (!isFound) {
            if (!this.isPanelOpened) {
                notify({
                    contentTemplate: (element : any,) => {
                        element.append(this.getToastContentTemplate(options, element));
                    },
                    width: 350,
                    minWidth: 150,
                    type: options.level,
                    displayTime: options.notif.DelayToast || 5000,
                    animation: {
                      show: {
                        type: 'fade', duration: 400, from: 0, to: 1,
                      },
                      hide: { type: 'fade', duration: 40, to: 0 },
                    },
                  },
                  {
                     position : 'top right',
                     direction :'down-push',
                  });
                this.toasts.push({ textOrTpl, ...options });
            }

            if (options.notif.docUrl !== undefined && options.notif.docUrl.length > 0) {
                document.getElementById('downloadButton').addEventListener("click", () => { this.download(options.notif); });
                document.getElementById('downloadButton').removeAttribute('id')
            }
            
            if (options.notif.labelLinkForDescription) {
                document.getElementById('openModalButton').addEventListener("click", () => { this.openModal(options.notif); });
            } 
        }
    }

    getToastContentTemplate(options: any, element: any) {
        console.log(options);
        var closeButton = $('<button type="button" aria-label="Close" class="btn-close"></button>');
        closeButton.on('click', {toast: options, element: element, ctrl: this}, this.hideToast)
        var temp = $('<div></div>');

        temp.append(`<div class="toast-header"><strong class="me-auto">${options.notif.ShortName}</strong></div>`);
        temp.children('.toast-header').append(closeButton);
        if(options.notif.LongName != "")
            temp.append(`<div class="toast-body">${options.notif.LongName}</div>`);


        if (options.notif.docUrl !== undefined && options.notif.docUrl.length > 0) {
            var div = $('<div class="divToastContent"></div>');
            div.append(`<button id="downloadButton" class="btn btn-outline-secondary"><i class="fa-solid fa-download me-2"></i>` + options.notif.docUrlLabel + `</button>`);
            temp.append(div);

            var div = $('<div class="divToastContent"></div>');
            div.append(`<div id="divProgressBar` + options.notif.Identifier + `" style="display:none" class="progress"></div>`);
            temp.append(div);
        }

        if (options.notif.labelLinkForDescription) {
            var div = $('<div class="divToastContent"></div>');
            div.append(`<button type="button" id="openModalButton" class='btn btn-outline-secondary' (click)="openModal('default',toast.notif)"> ` + options.notif.labelLinkForDescription + ` </button>`);
            temp.append(div);
        }   

        return temp;
    }

    openModal(notif: NotificationMessage):void{
        this.popupService.openDetailOfNotification(notif);
    }

    hideToast(item:any) {
        item.data.element.hide();
        item.data.ctrl.toasts = item.data.ctrl.toasts.filter((t: any) => t !== item.data.toast);
    }
    remove(toast:any) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    clear() {
        this.toasts.splice(0, this.toasts.length);
    }

    download(notif: NotificationMessage): void {
        if(this.fileDls[notif.docUrl] != null)
        return;
        this.fileDls[notif.docUrl] = true;

        this.showProgressBar(notif);

        var progressBar = this.getCreateProgressBar(notif);

        this.downloadService.download(notif.docUrl).subscribe(response => {
            this.subscribeDownloadSuccess(response, progressBar, notif);
        },error=>{
            this.subscribeDownloadError(notif);
        });
    }

    getCreateProgressBar(notif:any) {
        return  $("#divProgressBar" + notif.Identifier).dxProgressBar({
            min: 0,
            max: 100,
            value: 0,
            statusFormat(ratio) {
                return `Chargement : ${ratio * 100}%`;
            },
            onComplete(e) {
                e.element.addClass('complete');
            },
        }).dxProgressBar('instance');
    };

    subscribeDownloadSuccess(response:any, progressBar:any, notif:any) {
        if (response.progress !== undefined) {
            progressBar.option('value', Math.ceil(response.progress));
        } 
        else if (response.body) {                
            progressBar.option('value', 100);
            var filename = "download-VDSASH";
            let f= response.headers.get('content-disposition')?.split(';')[1];
            if (f && f.includes('filename=')) {
                filename = f.substring(10);
                filename = filename.replace("\"", "");
                filename = filename.replace("\"", "");
                filename = decodeURIComponent(filename);
            }
            this.fileDls[notif.docUrl] = null;
            this.hideProgressBar(notif);
            notif.progress = 0;
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(response.body);
            a.download = filename;
            console.debug('filename: ' + filename);
            a.click();
        }
    }

    subscribeDownloadError(notif:any) {
        this.fileDls[notif.docUrl] = null;
        this.hideProgressBar(notif);
        var createdDt=new Date();
        var expirationDt=new Date(createdDt.getFullYear(),createdDt.getMonth(),createdDt.getDate(),createdDt.getHours()+1,createdDt.getMinutes());
        this.notificationService.addNotification(new NotificationMessage("Downloads",new Date(),"Danger","Une erreur s'est produite","Impossible de récupérer le document","spa",5,undefined,undefined,undefined,expirationDt,undefined,undefined));
    }

    showProgressBar(notif:any) {
        $("#divProgressBar" + notif.Identifier).css("display", "block");
    }

    hideProgressBar(notif:any) {
        $("#divProgressBar" + notif.Identifier).css("display", "none");
    }
}