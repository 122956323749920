// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-datagrid-borders > .dx-datagrid-headers{
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
}


.dx-datagrid-rowsview .dx-texteditor-input {
    padding-right: 1rem;
    padding-left: 1rem;
}

.tooltip-general
{
    padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./app/front-office/desktop-mobile/desktop/front-office-grid/front-office-grid.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;;AAGA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":[".dx-datagrid-borders > .dx-datagrid-headers{\r\n    border-top: none;\r\n    border-right: none;\r\n    border-bottom: none;\r\n    border-left: none;\r\n}\r\n\r\n\r\n.dx-datagrid-rowsview .dx-texteditor-input {\r\n    padding-right: 1rem;\r\n    padding-left: 1rem;\r\n}\r\n\r\n.tooltip-general\r\n{\r\n    padding: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
