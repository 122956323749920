import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/shared/auth.service";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, of } from "rxjs";
import { shareReplay, retry, tap } from 'rxjs/operators';
import { IActionAPI, IActionButton, IEntity } from "./entity";

@Injectable({
    providedIn: 'root'
})

export class EntityService {
    private entitiesUrl = './api/{version}/spaces/{space}/Entities';
    public messageToParent = new EventEmitter();
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private authService: AuthService,
        private router: Router) { }

    getEntities(space: string, idParent: number, entityType: string, allSubElement: boolean = false): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/' + entityType + '/' + idParent + '/null/' + allSubElement + '/children.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getListExport(version: string, space: string, entityType: string): Observable<any[]> {
        var url = this.entitiesUrl.replace('{version}',version).replace('{space}', space)+ '/entities/schema_exports.json';
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    setEntityAnchor (space: string, entity : any, entities : any[])
    {
        var storage = this.localStorageService.getWithExpiration('UserPreferences');
        storage = JSON.parse(storage);
        if (storage == null){
            storage = 
            {
                EntityAnchors :{},
                Filters :{},
                ExpandedRows:{}
            };
        }
        if(storage.Filters == null)
            storage.Filters = {};
        if(storage.ExpandedRows == null)
            storage.ExpandedRows = {};
        storage.EntityAnchors[space] = entities;
        this.localStorageService.setWithExpiration('UserPreferences', JSON.stringify(storage), 'CallTimout');
        let temp = entities.filter((th: { IdObject: number; })  => th.IdObject < 0 && th.IdObject != -999);
        if(temp.length > 0)
        {
            this.messageToParent.emit(
                {
                    Entity : entity,
                    Type : temp[0]
                });
        }
        else
            this.messageToParent.emit(entity);
    }

    setFilterPreference (space: string, filters : any[])
    {
        var storage = this.localStorageService.getWithExpiration('UserPreferences');
        storage = JSON.parse(storage);
        if (storage == null){
            storage = 
            {
                EntityAnchors :{},
                Filters :{},
                ExpandedRows:{}
            };
        }
        if(storage.Filters == null)
            storage.Filters = {};
        if(storage.ExpandedRows == null)
            storage.ExpandedRows = {};
        storage.Filters[space] = filters;
        this.localStorageService.setWithExpiration('UserPreferences', JSON.stringify(storage), 'CallTimout');
    }

    setExpandPreference (space: string, expandedRows : any[])
    {
        var storage = this.localStorageService.getWithExpiration('UserPreferences');
        storage = JSON.parse(storage);
        if (storage == null){
            storage = 
            {
                EntityAnchors :{},
                Filters :{},
                ExpandedRows:{}
            };
        }
        if(storage.Filters == null)
            storage.Filters = {};
        if(storage.ExpandedRows == null)
            storage.ExpandedRows = {};
        storage.ExpandedRows[space] = expandedRows;
        this.localStorageService.setWithExpiration('UserPreferences', JSON.stringify(storage), 'CallTimout');
    }

    getFirstEntityByEntityType(space: string, entityType: string): Observable<IEntity> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/entity.json';
        return this.http.get<IEntity>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getFirstEntityAppByEntityType(space: string, applicationName:string,entityType: string): Observable<IEntity> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + applicationName + '/' + entityType + '/entity_app.json';
        return this.http.get<IEntity>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    GetMainEntityType(space: string, entityType: string): Observable<string> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/main_entity.json';
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    GetNamePattern(space: string, entityType: string): Observable<string> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/name_pattern.json';
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getListEntityByEntityType(space: string, entityType: string, applicationName: string): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + applicationName + '/entities.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getEntitiesTree(space: string, parentID:number, childEntity : string): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)  +'/'+parentID+'/'+childEntity+ '/entities_tree.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getEntitiesAnchorTree(space: string, parent:number): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)  + '/'+parent+ '/entities_anchor_tree.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }


    getAvailableProcFO(space: string, version: string, applicationName:string, epName : string): Observable<IEntity[]> {
        var url = this.entitiesUrl.replace('{version}', version).replace('{space}', space) + '/' + applicationName+ '/' + epName+ '/published_sys.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getProcOfEntity(space: string, entityId: string, applicationName:string): Observable<IEntity> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/' + applicationName+ '/' + entityId+ '/published_sy.json';
        return this.http.get<IEntity>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getCurrentEntity(space: string, entityId: string): Observable<IEntity> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/entities/' + entityId+ '/current.json';
        return this.http.get<IEntity>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }
    
    getAvailableEntitiesFromProc(space: string, idProc: number, childType: string, applicationName:string): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/' + applicationName+ '/' + idProc + '/' + childType+ '/sub_children.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getListOfSimulation(space: string, entityId: string): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/' + entityId+ '/user_simulations.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getSimpleComponents(space: string): Observable<any[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/simple_components.json';
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getListComponents(space: string): Observable<any[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/list_components.json';
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getVarianteFromEntity(space: string,version: string, idEntity: string, perimeter: string): Observable<string> {
        
        var url = this.entitiesUrl.replace('{version}', version).replace('{space}', space) + '/entities/' + idEntity + '/' + perimeter + '/variante.json';
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getMasterEntity(space: string, entityId: number, entityType: string) {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + entityId+ '/detail.json';
        return this.http.get<IEntity>(url);
    }

    getBIComponents(space: string): Observable<any[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space)+ '/bi_components.json';
        return this.http.get<any[]>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space, error)
                })
            );
    }

    getRootEntities(space: string): Observable<IEntity[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/entities.json';
        return this.http.get<IEntity[]>(url)
            .pipe(
                shareReplay(1),
                retry(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space,error)
                })
            );
    }

    handleEntitiesNotFound(space:string,error:HttpErrorResponse) {
        if (error.status === 0) {
            console.error('An error occurred:', error.error);
        } else {
            console.error(`Backend returned code ${error.status}, body was: `, error.error);
            var storage = this.localStorageService.getWithExpiration(space);
            if (storage != null) {
                this.localStorageService.remove(space);
            }

            this.router.navigate(['']);
        }
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getEntitiesAction(space: string, idParent: number, entityType: string, childType: string): Observable<IActionButton[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType+ '/' + idParent+ '/' + childType + '/buttons.json';
        return this.http.get<IActionButton[]>(url)
            .pipe(
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space,error)
                })
            );
    }

    getProcedureAssociation(space: string, idSy: number): Observable<string> {
        var url = './api/v1/attribut/'+space+'/' + idSy + '/proc_assoc.json'
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space,error)
                })
            );
    }

    getProcedureAssociationfromEntity(space: string, idEntity: number): Observable<string> {
        var url = './api/v1/attribut/'+space+'/' + idEntity + '/proc_assoc_entity.json'
        return this.http.get<string>(url)
            .pipe(
                retry(1),
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space,error)
                })
            );
    }

    getFullEntitiesAction(space: string): Observable<IActionButton[]> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/entities/full_buttons.json';
        return this.http.get<IActionButton[]>(url)
            .pipe(
                shareReplay(1),
                tap({
                    next: (data) => JSON.stringify(data),
                    error: (error) => this.handleEntitiesNotFound(space,error)
                })
            );
    }

    getGridConfiguration(space: string, entityType: string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.entitiesUrl.replace('{version}', storage.Version).replace('{space}', space) + '/' + entityType + '/settings/grid';
        if (storage.Entities[entityType.toString()] != null) {
            return of(JSON.parse(storage.Entities[entityType]));
        }
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                storage.Entities[entityType] = JSON.stringify(data);
                this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
                JSON.stringify(data)
            })
        )
    }

    executeAction(action: IActionAPI): any {
        if(action.Parameters.Space)
        {
            var storage = this.localStorageService.getWithExpiration(action.Parameters.Space);
            storage = JSON.parse(storage);
            action.Parameters.Version = storage.Version;
        }
        switch (action.Type) {
            case "GET":
                return this.http.get<any[]>(action.Uri)
                        .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
            case "POST":
                if (action.File) {
                    console.debug('file: ',action.File);
                    const formData = new FormData();
                    
                    formData.append('InputFile',action.File,action.File.name);
                    if(action.Parameters.EntititiesIds)
                    {
                        formData.append('EntititiesIds', action.Parameters.EntititiesIds);
                        formData.append('FocusEntityType', action.Parameters.FocusEntityType);
                        formData.append('CurrentEntityType', action.Parameters.CurrentEntityType);
                        formData.append('SubPerimeter', action.Parameters.SubPerimeter);
                        formData.append('EntityStatus', action.Parameters.EntityStatus);
                        formData.append('SubPerimeterImport', action.Parameters.SubPerimeterImport);
                        formData.append('FormStatus', action.Parameters.FormStatus);
                        formData.append('ExtraParameters', action.Parameters.ExtraParameters);
                    }
                    else
                    {
                        formData.append('EntityId', action.Parameters.EntityId);
                        formData.append('ApplicationName', action.Parameters.ApplicationName);
                        formData.append('ZoneName', action.Parameters.ZoneName);
                        formData.append('WorkflowId', action.Parameters.WorkflowId);
                        formData.append('FormStatus', action.Parameters.FormStatus);
                        formData.append('ExtraParameters', action.Parameters.ExtraParameters);
                          
                    }
                    formData.append('Version', action.Parameters.Version);
                    
                    
                    let headers = new HttpHeaders();
                    headers = headers.append('enctype', 'multipart/form-data');
                    headers = headers.append('Authorization', 'Bearer ' + this.authService.getIdToken());
                    return this.http.post<any[]>(action.Uri, formData).pipe(shareReplay(1),tap(data => JSON.stringify(data)));
                }
                else
                    return this.http.post<any[]>(action.Uri, action.Parameters)
                        .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
            case "PUT":
                if(action.Parameters)
                    return this.http.put<any[]>(action.Uri, action.Parameters)
                            .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
                else return this.http.put<any[]>(action.Uri, {})
                .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
            case "DELETE":
                if (!action.Parameters)
                    return this.http.delete<any[]>(action.Uri)
                            .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
                else {
                    const options = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                        }),
                        body: action.Parameters,
                    };
                    return this.http.delete<any[]>(action.Uri, options)
                            .pipe(shareReplay(1),tap(data => JSON.stringify(data)));
                }
        }

        return undefined;
    }
}
