import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FrontOfficeStructService } from 'app/front-office/front-office-struct.service';
import { LocalStorageService } from 'app/shared/local-storage.service';

@Component({
    selector: 'breadcrumb-mobile',
    templateUrl: './breadcrumb-mobile.component.html',
    styleUrls: ['./breadcrumb-mobile.component.css']
})
@Injectable({ providedIn: 'root' })
export class BreadCrumbMobileComponent implements OnInit {
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() currentEntity: any;
    @Input() currentWindowMobile: string = '';
    footColor = '';
    constructor(private applicationStructService: FrontOfficeStructService, private localStorageService: LocalStorageService) 
    {}

    async ngOnInit(): Promise<void> {
        var configuration = this.localStorageService.get('SPAConfiguration');
        this.footColor = configuration.FootBackGroundColor
    }
   
    changeMenu(menu:string): void {
        this.applicationStructService.changeMobileMenu(menu)
    }
}