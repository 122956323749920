export class StringUtils {
    static getIsNameFromTObject(name:String):Boolean{
        if (name != null && name.length > 0 && name.includes('.')) {
            var extractedString = name.substring(0, name.indexOf('.'));
            return extractedString.length == 2;
        }
        return false;
    }

    static isMainEntity(name:String):boolean{
        return name == 'UT' || name == 'UR';
    }

    static getTabNameWithConsiderMultiTab(ctrl:any, separator:string = 'ø') {
        return ctrl.multiTab 
            ? ctrl.moduleName + separator + ctrl.tabName 
            : ctrl.tabName;
    }

    static decodeSpecialEntitiesHtml(htmlentities: string) {
        const str = document.createElement("textarea");
        str.innerHTML = htmlentities;
        return str.value;
    }
}