import { ListUtils } from "app/utils/list-utils";
import { StringUtils } from "app/utils/string-utils";
import { lastValueFrom } from "rxjs";
import * as moment from "moment";
import * as $ from 'jquery';

export class FrontOfficeFormFactory {
    static async UpdateValue(ctrl:any, item: any, name:any, value: any, tabName: any) { 
        var attribut =
        {
            Name: item.fullDataField.replace('.IdEnumerationItem',''),
            Value: value,
            Format: item.subPerimeter
        }
        await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut, item.Reference));
        ctrl.appStructService.updateFormData.emit('ok')
    }

    static setItemHtml(item:any) {
        item.editorOptions.imageUpload= {
            tabs: ['file', 'url'],
            fileUploadMode: 'base64',
        };
        item.editorOptions.toolbar= {
            items: [
                'undo', 'redo', 'separator',
                {
                    name: 'size',
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                },
                {
                    name: 'font',
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                },
                'separator', 'bold', 'italic', 'strike', 'underline', 'separator',
                'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator',
                'orderedList', 'bulletList', 'separator',
                {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                }, 'separator',
                'color', 'background', 'separator',
                'link', 'image', 'separator',
                'clear', 'codeBlock', 'blockquote', 'separator',
                'insertTable', 'deleteTable',
                'insertRowAbove', 'insertRowBelow', 'deleteRow',
                'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
            ],
        };
        item.editorOptions.mediaResizing= {
            enabled: true,
        };
    }

    static async setOrganisationItem(ctrl:any, item:any, formData:any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        var url = './api/v1/application/'+ctrl.space+'/availableOrganisation/vue.json';
        let param = {
            Parameters : ''
        }
        var items = await lastValueFrom(ctrl.httpClient.post(url, param));
        $('#'+item.id).dxAutocomplete(
        {
            dataSource: items,
            placeholder: 'Veuillez choisir une organisation',
            valueExpr: 'Key',
            value: formData[0][item.dataField],
            itemTemplate(data) {
                return $(`<div>${data.Val} (${data.Type})</div>`);
            },
            showClearButton: false,
            async onItemClick(data) {
                if(data.itemData.Key == item.editorOptions.value)
                    return;
                await FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField, data.itemData.Key, tabName);
            },
        });
    }

    static async setRolePrincipalItem(ctrl:any, item:any, formData:any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
        var url = './api/v1/application/'+ctrl.space+'/availableRole/vue.json';
        let param = {
            Parameters : ''
        }
        var items = await lastValueFrom(ctrl.httpClient.post(url, param));   
        $('#'+item.id).dxAutocomplete({
            dataSource: items,
            placeholder: 'Veuillez choisir un rôle',
            valueExpr: 'Role',
            value: formData[0][item.dataField],
            itemTemplate(data) {
                return $(`<div>${data.Label}</div>`);
            },
            showClearButton: false,
            async onItemClick(data) {
                if(data.itemData.Role == item.editorOptions.value) { return; }
                await FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField, data.itemData.Role, tabName);
            },
        });
    }

    static async setDxSelectBoxItem(ctrl:any, item:any, formData:any) {
        var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

        let items: any[] = [];
        if (ctrl.storage.Lists[item.list.replace('#1', '')] != null) {
          items = JSON.parse(ctrl.storage.Lists[item.list.replace('#1', '')]);
        }
        else
        {
          var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+item.list.replace('#1', '')+'/0.json';
          items = await lastValueFrom(ctrl.httpClient.get(url));
          ctrl.storage.Lists[item.list.replace('#1', '')] = JSON.stringify(items);
        }
        item.editorOptions.dataSource = ListUtils.getOnlyActiveItems(items);
        if(item.list.indexOf('#1') != -1)
        {
          item.editorOptions.displayExpr = 'Code';
        }

        item.editorOptions.value = formData[0][item.dataField.replace('.IdEnumerationItem','')] != null
          ?  formData[0][item.dataField.replace('.IdEnumerationItem','')].Label
          : 'Sélectionner une valeur';
        item.editorOptions.onSelectionChanged = async function (e:any) { 
            if(e.selectedItem.Label == item.editorOptions.value)
                return;
            FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), e.selectedItem.IdEnumerationItem, tabName);
        }
        $('#'+item.id).dxSelectBox(item.editorOptions);
    }

    static async setDisableVisibleItem(ctrl:any, item:any) {
        item.Visible = true;
        if(item.subPerimeter != '' && ctrl.space !='MDEL') {
            var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'RSub' : 'R';
            if(ctrl.dictSub[item.subPerimeter+action] != null) {
                item.Visible = ctrl.dictSub[item.subPerimeter+action];
            }
            else {
                ctrl.dictSub[item.subPerimeter+action] = await ctrl.appStructService.getSubPerimeter(ctrl.space,ctrl.version,ctrl.applicationName, ctrl.zoneName, item.subPerimeter, ctrl.stateId, action);
                item.Visible = ctrl.dictSub[item.subPerimeter+action];
            }
            if(!item.editorOptions.disabled){
                var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'USub' : 'U';
                if(ctrl.dictSub[item.subPerimeter+action] != null) {
                    item.editorOptions.disabled = !ctrl.dictSub[item.subPerimeter+action];
                }
                else {
                    ctrl.dictSub[item.subPerimeter+action] = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, item.subPerimeter, ctrl.stateId, action);
                    item.editorOptions.disabled = !ctrl.dictSub[item.subPerimeter+action];
                }
            }
        }
    }

    static setSortGroups(ctrl:any) {
        ctrl.groups = ctrl.groups.sort(function (a:any, b:any) {
            var aSize = a['Bloc'];
            var bSize = b['Bloc'];
            var aLow = a['Name'].replace('_' + ctrl.space, '').replace('_','');
            var bLow = b['Name'].replace('_' + ctrl.space, '').replace('_','');
            if(aSize == bSize) {
                return aLow.split('_')[0] < bLow.split('_')[0] ? -1 
                    : (aLow.split('_')[0] > bLow.split('_')[0]) ? 1 
                    : (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0
            }
            else {
                return (aSize < bSize) ? -1 : 1;
            }
        });
    }

    static async setGroups(ctrl:any, formConfig:any) {
        ctrl.groups = [];
        for(var i = 0; i<formConfig.items.length; i++) {
            let bloc = formConfig.items[i];
            var procsAvailable = bloc.AvailableProcedureAssociations.split('|');
            var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                return procsAvailable.some((f : string) => { return f === tb; })
            });

            if((bloc.Variante == "RfcCommun" || bloc.Variante == ctrl.variante) && bloc.items.length > 0 && (tempProcAsso.length > 0)) {
                if(bloc.SubPerimeter != '' && ctrl.space !='MDEL') {
                    var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'RSub' : 'R';

                    if(ctrl.dictSub[bloc.SubPerimeter+action] != null) {
                        if(ctrl.dictSub[bloc.SubPerimeter+action]) {
                            ctrl.groups.push(bloc);
                        }
                    }
                    else {
                        var value = await ctrl.appStructService.getSubPerimeter(ctrl.space,ctrl.version,ctrl.applicationName, ctrl.zoneName, bloc.SubPerimeter, ctrl.stateId, action);
                        ctrl.dictSub[bloc.SubPerimeter+action] = value;
                        if(value) {
                            ctrl.groups.push(bloc);
                        }
                    }
                }
                else {
                    ctrl.groups.push(bloc);
                }
            }
        }
    }

    static setItemContextInfo(ctrl:any, isMobile:any) {
        var thisTabHasLabels : boolean = this.thisTabHasLabels(ctrl.groups);
        var thisTabHasNumberings : boolean = this.thisTabHasNumberings(ctrl.groups);

        for(var i = 0; i < ctrl.groups.length; i++) {

            var item = ctrl.groups[i];

            if (isMobile) {
                item.NumberingStyle = 'display:none'
            }

            if (item.HideLabel && !thisTabHasLabels){
                item.LabelStyle = 'display:none'
            }

            if (item.HideNumbering && !thisTabHasNumberings){
                item.NumberingStyle = 'display:none'
            }

            var module = ctrl.moduleName != undefined ? ctrl.moduleName.length > 2 ? ctrl.moduleName : '' : '';
            var tabName:any = ctrl.multiTab && ctrl.tabName.indexOf('ø') == -1 ? ctrl.moduleName + '/' + ctrl.tabName : ctrl.tabName;
            var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName;
            let contextInfo = ctrl.contextInfo.filter((tb: { Module:String, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == ctrl.order.toString() && tb.Ligne == item.Numbering)[0];

            if (item.items.length > ctrl.maxItems) {
                ctrl.maxItems = item.items.length;
            }

            if (contextInfo != null) {
                item.ContextInfo = contextInfo;
            }

            item.Index = i;
        }
    }

    static thisTabHasNumberings(groups:any):boolean{
        if (groups != null){
            for (var group of groups) {
                if (group.Numbering != null && group.Numbering.length > 0 && !group.HideNumbering) {
                    return true;
                };
            }
        }  

        return false;
    }

    static thisTabHasLabels(groups:any):boolean{  
        if (groups != null){
            for (var group of groups) {
                if (group.Label != null && group.Label.length > 0 && !group.HideLabel) {
                    return true;
                };
            }
        }  

        return false;
    }

    static dxNumberBoxKeyDown(e:any){
        var preventedKeys = [38, 40];
        var keyCode = e.event.keyCode;
        var shouldPreventEvent = !e.component.option("opened") && preventedKeys.indexOf(keyCode) !== -1 && !e.event.altKey;
        if(shouldPreventEvent) {
            e.event.preventDefault();
            e.event.stopImmediatePropagation();
        }
    }

    static setNonDisableItemEditorOptions(ctrl:any, item:any, formData:any, tabName:any) {
        switch (item.editorType) {
            case 'dxNumberBox':
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) { 
                    FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), e.value.toString(), tabName); 
                };
                item.editorOptions.onKeyDown = function(e:any) { 
                FrontOfficeFormFactory.dxNumberBoxKeyDown(e); 
                };

                item.format = item.editorOptions.format;
                $('#' + item.id).dxNumberBox(item.editorOptions);
                break;

            case 'dxSelectBox':
                FrontOfficeFormFactory.setDxSelectBoxItem(ctrl, item, formData);
                break;

            case 'dxTextBox':
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) { 
                    FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), e.value.toString(), tabName); 
                };
                $('#' + item.id).dxTextBox(item.editorOptions);
                break;

            case 'dxTextArea':
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.height = 90;
                item.editorOptions.onValueChanged = async function (e:any) { 
                    FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), e.value.toString(), tabName); 
                };
                $('#' + item.id).dxTextArea(item.editorOptions);
                break;

            case 'dxDateBox':
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                    FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), moment(e.value.toString()).format('DD.MM.yyyy'), tabName);
                };
                item.editorOptions.displayFormat = "dd.MM.yyyy";
                $('#' + item.id).dxDateBox(item.editorOptions);
                break;

            case 'dxCheckBox':
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                    FrontOfficeFormFactory.UpdateValue(ctrl, item, item.fullDataField.replace('.IdEnumerationItem',''), e.value.toString(), tabName);
                };
                $('#' + item.id).dxCheckBox(item.editorOptions);
                break;
        }
    }
}