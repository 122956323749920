import { ApplicationRef, Component,  EventEmitter,  Input, Output, createComponent } from "@angular/core";
import {  FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import * as $ from 'jquery';
import { PopupService } from "./popup.service";

@Component({
  selector:'ngbd-modal-import-content',
  template: `
    <form [formGroup]="fg" class="form-import" novalidate>
      <div class="modal-body">
        <div class="form-group">
          <input type="file" class="inputFileCustomStyle" id="inputFile">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
          Annuler
        </button>
        <button type="button" class="btn btn-primary modalOkButton" id="popupOkButton" disabled>          
          Importer
        </button>
      </div>
    </form>
  `
})

export class NgbdModalImportContent {
  @Input() title: string = "default";
  @Output() messageToParent :EventEmitter<any> = new EventEmitter();

  public fg!: FormGroup;
  
  constructor(
    public fb: FormBuilder,
    public sanitizer: DomSanitizer){

    this.fg=this.fb.group({
      InputFile: ['',[Validators.required]]
    });
    var form = $('form-import');
    $(document.body).append(form);
  }
}

@Component({
    selector: "ngbd-modal-import-component",
})

export class NgbdModalImportComponent{
  public onSelectedFile = new EventEmitter();
  insidePopup: any;

  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }

  show(title:string, actionAPI: IActionAPI){
    this.popupService.showPopup(title, this.getContentTemplate(actionAPI));

    document.getElementById('inputFile').addEventListener('change', (event) => { this.onFileSelect(event); });
    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
    document.getElementById('popupOkButton').addEventListener("click", () => { this.validImport(); });
  }
  
  getContentTemplate(actionAPI: IActionAPI) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalImportContent, {
        environmentInjector,
    });
        
    this.insidePopup.instance.messageToParent.subscribe((receivedEntry:File)=>{
      actionAPI.File = receivedEntry;
      this.onSelectedFile.emit(actionAPI);
    });

    this.insidePopup.hostView.detectChanges();
    
    return this.insidePopup.location.nativeElement;
  }

  onFileSelect($event:any){
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      if(this.insidePopup.instance.fg) {
        this.insidePopup.instance.fg.get('InputFile').setValue(file);
        this.actualiseOkButton();
      }
    }
  }
  
  validImport() {
    this.insidePopup.instance.messageToParent.emit(this.insidePopup.instance.fg.get('InputFile').value);
    this.popupService.closePopup();
  }

  actualiseOkButton() {
    $('#popupOkButton').prop('disabled', this.insidePopup.instance.fg == undefined || this.insidePopup.instance.fg == '');
  }
}

