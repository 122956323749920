import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, lastValueFrom } from "rxjs";
import { shareReplay, tap } from 'rxjs/operators';
import { IApp, IHomePage } from "./home-page";

@Injectable({
    providedIn: 'root'
})

export class HomePageService {
    private spaceUrl = './api/v1/';
    public changeModeAlert = new EventEmitter()
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

    cleanEmitter()
    {
        this.changeModeAlert = new EventEmitter();
    }

    getAvailableApplications(): Observable<IApp[]> {
        var url = this.spaceUrl + 'applications.json';
        return this.http.get<IApp[]>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data))
            );
    }

    getAvailableZonePerApplications(applicationName: string): Observable<IHomePage[]> {
        var url = this.spaceUrl + 'applications/'+applicationName+'/zones.json';
        return this.http.get<IHomePage[]>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data))
            );
    }

    hasValidationMode(): Observable<boolean> {
        var url = this.spaceUrl + 'applications/can_display_validation.json';
        return this.http.get<boolean>(url)
            .pipe(
                shareReplay(1),
                tap(data => JSON.stringify(data))
            );
    }

    async getSpaceVersion(applicationName: string, zone: string, displayState: boolean): Promise<string> {
        var url = this.spaceUrl + 'applications/' + applicationName +'/'+ zone + '/version.json';
        var version = await lastValueFrom(this.http.get<string>(url));
        if (version) {
            this.setSpaceVersion(zone, version ,applicationName, displayState);
        }
        return version;
    }

    private setSpaceVersion(space: string, version: string, applicationName: string, displayState: boolean) {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        if (storage == null) {
            storage = {
                Version: version,
                DisplayState:{},
                Entities: {},
                Components: {},
                Blocs: {}
            };
            storage.DisplayState[applicationName] = displayState;
            this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
        }
        else if (storage.Version != version) {
            localStorage.removeItem(storage);
            storage = {
                Version: version,
                DisplayState:{},
                Entities: {},
                Components: {},
                Blocs: {}
            };
            storage.DisplayState[applicationName] = displayState;
            this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
        }
        else if(storage.DisplayState[applicationName] == null){
            localStorage.removeItem(storage);
            storage.DisplayState[applicationName] = displayState;
            this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
        }
            
    }
}
