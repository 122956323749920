import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError } from "rxjs";
import { shareReplay, catchError,  tap, retry } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class IDPService {
    private idpUrl = './api/v1/idp';

    constructor(private http: HttpClient, private localStorageService: LocalStorageService)
    { }

    sentEmailAccess(space:string, request: any) {
       var url = this.idpUrl+ '/'+space + '/sent_access.json';
       return this.http.put<any>(url, request)
        .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    sentGlobalEmailAccess(space:string, request: any) {
        var url = this.idpUrl+ '/'+space + '/sent_global_access.json';
        return this.http.put<any>(url, request)
         .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
     }

    resetPassEmailAccess(space:string, request: any) {
        var url = this.idpUrl+ '/'+space + '/reset_password.json';
        return this.http.put<any>(url, request)
         .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
     }

     sentOnboarding(space:string, request: any) {
        var url = this.idpUrl+ '/'+space + '/sent_first_access.json';
        return this.http.put<any>(url, request)
         .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
     }

     disableUser(space:string, request: any) {
        var url = this.idpUrl+ '/'+space + '/disable_user.json';
        return this.http.put<any>(url, request)
         .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
     }

     enableUser(space:string, request: any) {
        var url = this.idpUrl+ '/'+space + '/enable_user.json';
        return this.http.put<any>(url, request)
         .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
     }

     getAvailableProviders(): Observable<any> {
        var url = this.idpUrl + '/providers.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    getProfilUrl(): Observable<any> {
        var url = this.idpUrl + '/profil_url.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}