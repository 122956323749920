import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import * as $ from 'jquery';
import { EntityService } from "app/shared/entity.service";
import { ActionTypeButton, IActionAPI, IActionButton, IEntity, RenderTypeButton, TargetButton } from "app/shared/entity";
import { Subscription, lastValueFrom } from "rxjs";
import { ActionLegacyService } from "../../shared/actions-legacy.service";
import { SessionStorageService } from "app/shared/session-storage.service";
import CustomStore from "devextreme/data/custom_store";
import { AuthService } from "app/shared/auth.service";
import { ActionLegacyRefreshService } from "../../shared/actions-legacy-refresh.service";
import { LogLevel } from "@microsoft/signalr";
import { LocalStorageService } from "app/shared/local-storage.service";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'panel-hierarchical-view',
    templateUrl: './panel-hierarchical-view.component.html',
    styleUrls: ['./panel-hierarchical-view.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelHierarchicalViewComponent implements OnInit, OnDestroy, OnChanges {
    @Input() space: string = '';
    @Input() componentName: string = '';
    @Input() componentType: string = '';
    @Input() entityType: string = '';
    @Input() entityAnchor: IEntity;
    @Input() updateAttribut: IEntity;
    @Input() refreshAction: IActionAPI;
    @Input() userEmail: string = '';
    selectionMsg: any = [];
    groups: any;
    menuItems: any;
    sub2!: Subscription;
    @Input() idEntity: number;
    @Input() specificType: string;
    @Input() version: string = '';
    @Input() fullLoadedSpace: boolean = false;
    fullyLoaded: boolean = false;
    firstLoadTree: boolean = false;
    firstLoadData: boolean = false;
    entityAnchorUse : IEntity;
    entities : any[] = [];
    expandRowIds : any[] = [];
    idEntityUse: number;
    
    private cutedSelectionKey = "currentCutedSelection";
    constructor(
      private actionLegacyService: ActionLegacyService,
      private actionLegacyRefreshService: ActionLegacyRefreshService,
      private sessionStorageService: SessionStorageService,
      private localStorageService: LocalStorageService,
      private viewContainerRef: ViewContainerRef,
      private entityService: EntityService)
    {

    }

  
  

    async ngOnChanges(changes: any) {
        if (changes.fullLoadedSpace && this.fullLoadedSpace) {
            this.fullyLoaded = false;
            this.expandRowIds =[];
            await this.loadPanel();
          }
            else if (this.fullLoadedSpace == true && changes.entityAnchor && this.entityAnchor != null && this.entityAnchorUse != null  && this.entityAnchor.IdObject != this.entityAnchorUse.IdObject && this.fullyLoaded) {
                await this.loadPanel();
            }
            var entitiesT: any[] = [];
            if (changes.refreshAction && this.refreshAction != null) {
               var ctrl = this;
               var isUse = false;
               if(this.refreshAction.Uri.indexOf('states.json')> -1 && this.refreshAction.Parameters.ExtraParameters.indexOf('TakeOwnership')> -1)
               {
                    isUse = true;
                    var entities = this.entities.filter((th: any)  => (this.refreshAction.Parameters.EntititiesIds.includes(th.IdObject)));
                    entities.forEach(function (entity: IEntity) {                 
                        entity.Owner = ctrl.userEmail
                    });
               }
               else if(this.refreshAction.Uri.indexOf('states.json')> -1 && (this.refreshAction.Parameters.ExtraParameters.indexOf('PutMainStatut')> -1 || this.refreshAction.Parameters.ExtraParameters.indexOf('SyAsReference')> -1))
               {
                    isUse = true;
                    var entities = this.entities.filter((th: any)  => (this.refreshAction.Parameters.EntititiesIds.includes(th.IdObject)));
                    entities.forEach(function (entity: IEntity) {                 
                        entity.IsMain = true
                    });
               }
               else if(this.refreshAction.Uri.indexOf('states.json')> -1 && (this.refreshAction.Parameters.ExtraParameters.indexOf('PutPublishedStatut')> -1 || this.refreshAction.Parameters.ExtraParameters.indexOf('PutInWork')> -1))
               {
                    isUse = true;
                    var entities = this.entities.filter((th: any)  => (this.refreshAction.Parameters.EntititiesIds.includes(th.IdObject)));
                    entities.forEach(function (entity: IEntity) {    
                        if(entity.EntityType =='CY')
                        {
                            var othersCY = ctrl.entities.filter((th: any)  =>entity.Parent == th.Parent && th.IsPublished == true && th.IdObject != entity.IdObject)[0];
                                if(othersCY != null)
                                {
                                    othersCY.IsPublished = false;
                                }
                        }       
                        entity.IsPublished = true
                        if(entity.EntityType =='CY')
                        {
                            var parent = ctrl.entities.filter((th: any)  =>entity.Parent == th.IdObject)[0];
                            if(parent != null)
                            {
                                parent.IsMain = true;
                                var othersSY = ctrl.entities.filter((th: any)  =>parent.Parent == th.Parent && th.IsMain == true && th.IdObject != parent.IdObject)[0];
                                if(othersSY != null)
                                {
                                    othersSY.IsMain = false;
                                    othersSY.IsPublished = true;
                                }
                            }
                        }
                    });
               }
               else if(this.refreshAction.Uri.indexOf('states.json')> -1 && this.refreshAction.Parameters.ExtraParameters.indexOf('PutUnpublishedStatut')> -1)
               {
                    isUse = true;
                    var entities = this.entities.filter((th: any)  => (this.refreshAction.Parameters.EntititiesIds.includes(th.IdObject)));
                    entities.forEach(function (entity: IEntity) {                 
                        entity.IsPublished = false
                    });
               }               
               else if(this.refreshAction.Uri.indexOf('cut_and_paste.json')> -1)
               {
                    isUse = true;
                    if(this.refreshAction.Parameters.EntititiesIds[0] > 0 || this.refreshAction.Parameters.EntititiesIds[0] == -999)
                    {
                        if(this.space != "MDEL")
                            entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, this.refreshAction.Parameters.EntititiesIds[0], "d"))
                         else    
                         {
                            var temp = JSON.parse(this.refreshAction.Parameters.ExtraParameters);
                            var ids = temp.Selection;
  
                            if(this.refreshAction.Parameters.ExtraParameters.indexOf('RP') > -1 || this.refreshAction.Parameters.ExtraParameters.indexOf('CS') > -1)
                            {
                                var parent = ctrl.entities.filter((th: any)  => (th.IdObject == this.refreshAction.Parameters.EntititiesIds[0]))[0];
                                if(this.refreshAction.Parameters.ExtraParameters.indexOf('RP') > -1)
                                {
                                    parent = ctrl.entities.filter((th: any)  => (th.Parent == parent.IdObject && th.Name == 'RP'))[0];
                                    if(parent != null)
                                    {          
                                        ids.forEach(function (id: number) {
                                            var entity = ctrl.entities.filter((th: any)  => (th.IdObject == id))[0]
                                            entity.Parent = parent.IdObject;
                                        }); 
                                    }
                                }
                                if(this.refreshAction.Parameters.ExtraParameters.indexOf('CS') > -1)
                                {
                                    parent = ctrl.entities.filter((th: any)  => (th.Parent == parent.IdObject && th.Name == 'CS'))[0];
                                    if(parent != null)
                                    {                                    
                                        ids.forEach(function (id: number) {
                                            var entity = ctrl.entities.filter((th: any)  => (th.IdObject == id))[0]
                                            entity.Parent = parent.IdObject;
                                        }); 
                                    }
                                }
                               
                            }
                            if(this.refreshAction.Parameters.ExtraParameters.indexOf('LR') > -1 || this.refreshAction.Parameters.ExtraParameters.indexOf('BC') > -1)
                            {
                                var parent = ctrl.entities.filter((th: any)  => (th.IdObject == this.refreshAction.Parameters.EntititiesIds[0]))[0];
                                if(parent.EntityType != 'VA')
                                {
                                    entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, this.refreshAction.Parameters.EntititiesIds[0], "d"))
                                }
                            }
                         }
                    }
                    else
                    {
                        var entity = ctrl.entities.filter((th: any)  => (th.IdObject == this.refreshAction.Parameters.EntititiesIds[0]))[0];
                        if(entity.IdObject == -10000)
                            entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, entity.IdObject, entity.Name))
                        else
                            entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, (entity.Parent)*-1, entity.IdObject+'_'+entity.Name))
                    }
                    if(entitiesT.length > 0 && entitiesT[0].EntityType == 'CY' && ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Tr#') != -1)
                    {
                        var trStatus = ctrl.specificType.split('#')[1];
                        if(trStatus != 'All')
                        {
                            var now = new Date()
                            switch(trStatus)
                            {
                                case 'LastMonth':
                                    now = new Date(now.setMonth(now.getMonth()-1))
                                    entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                    break;
                                case 'LastYear':
                                    now = new Date(now.setFullYear(now.getFullYear()-1))
                                    entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                    break;
                                case 'Last':
                                    entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.IsMain) || th.EntityType != 'CY' || (th.Status == 'D' && th.EntityType == 'CY'));
                                    break;
                            }

                        }
                    }
                    entitiesT.forEach(function (entity: IEntity) {
                        ctrl.entities = ctrl.entities.filter((th: any)  => (th.IdObject != entity.IdObject))
                            entity.CreationTime = new Date(entity.CreationTime)
                            entity.ModificationTime = new Date(entity.ModificationTime)
                            ctrl.entities.push(entity)
                    }); 
               }
               else if(this.refreshAction.Uri.indexOf('states.json')> -1 && this.refreshAction.Parameters.ExtraParameters.indexOf('ReopenLastCycle')> -1)
               {
                    isUse = true;
                    var entities = this.entities.filter((th: any)  => (this.refreshAction.Parameters.EntititiesIds.includes(th.IdObject)));
                    var entitiesId: number[] = [];
                    entities.forEach(function (entity: IEntity) {                 
                        entity.Status = 'D'
                        entitiesId.push(entity.IdObject)
                    });
                   
                    do {
                        entities = this.entities.filter((th: any)  => (entitiesId.includes(th.Parent)));
                        entitiesId = [];
                        entities.forEach(function (entity: IEntity) {                 
                            entity.Status = 'D'
                            entitiesId.push(entity.IdObject)
                        });
                      } while (entities.length > 0);
               }
               if(isUse)
               {
                 var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                 instance.clearSelection()
                 instance.refresh();
               }
            }

            if (changes.updateAttribut && this.updateAttribut != null) {
                if (this.entities.filter((th: any)  => (th.IdObject == this.updateAttribut.IdObject)).length > 0) {
                    var attribut = this.entities.filter((th: any)  => (th.IdObject == this.updateAttribut.IdObject))[0];
                    if(this.updateAttribut.Name != null)
                    {
                        attribut.Name = this.updateAttribut.Name;
                        var params = attribut.LongName.split(':');
                        params[0] = attribut.Name;
                        attribut.LongName = params[0] + ' :'+params[1]
                    }
                    if(this.updateAttribut.LongName != null)
                    {
                        var params = attribut.LongName.split(':');
                        params[1] = this.updateAttribut.LongName;
                        attribut.LongName = params[0] + ': '+params[1];
                    }
                     var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                     instance.refresh();
                }
             }
    }
    

    async ngOnInit(): Promise<void> {
        this.ngOnDestroy(); 
        this.actionLegacyRefreshService.refreshActionFromToast.subscribe(async (data) => {
                var ctrl = this;
                var entitiesT = [];
                var action = JSON.parse(data.ActionData);
                if(data.Action == 'Delete' || data.Action == 'TransfoAvailableDelete')
                {
                 this.entities = this.entities.filter((th: any)  => (!action.EntititiesIds.includes(th.IdObject)));
                }
                else if(data.Action == 'Lock')
                {
                     var entities = this.entities.filter((th: any)  => (action.EntititiesIds.includes(th.IdObject)));
                     var entitiesId: number[] = [];
                     if(action.ExtraParameters && action.ExtraParameters.length > 0)
                     {
                        var previousEntity = ctrl.entities.filter((th: any)  => th.EntityType == 'CY' && th.IsMain && th.Parent == entities[0].Parent)[0];
                        previousEntity.isMain = false;
                     }
                     entities.forEach(function (entity: IEntity) {                 
                         entity.Status = 'V'
                         if(action.ExtraParameters && action.ExtraParameters.length > 0)
                         {                       
                             entity.IsMain = true;
                         }
                         entitiesId.push(entity.IdObject)
                     });
                     do {
                         entities = this.entities.filter((th: any)  => (entitiesId.includes(th.Parent)));
                         entitiesId = [];
                         entities.forEach(function (entity: IEntity) {                 
                             entity.Status = 'V'
                             entitiesId.push(entity.IdObject)
                         });
                       } while (entities.length > 0);
                       if(action.ExtraParameters && action.Parameters.ExtraParameters.length > 0)
                       {
                       if(this.entities.length > 0 && this.entities[0].EntityType == 'CY' && ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Tr#') != -1)
                       {
                           var trStatus = ctrl.specificType.split('#')[1];
                           if(trStatus != 'All')
                           {
                               var now = new Date()
                               switch(trStatus)
                               {
                                   case 'LastMonth':
                                       now = new Date(now.setMonth(now.getMonth()-1))
                                       this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                       break;
                                   case 'LastYear':
                                       now = new Date(now.setFullYear(now.getFullYear()-1))
                                       this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                       break;
                                   case 'Last':
                                     this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.IsMain) || th.EntityType != 'CY' || (th.Status == 'D' && th.EntityType == 'CY'));
                                       break;
                               }
   
                           }
                       }
                     }
 
                }
                if(data.Action == 'TransfoAvailable' || data.Action == 'TransfoAvailableDelete')
                {
                    var space = data.ShortName.replace(' est maintenant disponible', '');                    
                    this.localStorageService.remove(space)
                     var entities = this.entities.filter((th: any)  => (action.EntititiesIds.includes(th.IdObject)));
                         var entitiesId: number[] = [];
                         var previousEntity = ctrl.entities.filter((th: any)  => th.EntityType == 'CY' && th.IsMain && th.Parent == entities[0])[0];
                         if(previousEntity != null)
                            previousEntity.isMain = false;
                         entities.forEach(function (entity: IEntity) {                 
                             entity.IsMain = true;
                             entity.Status = 'V'
                             entitiesId.push(entity.IdObject)
                         });
                      if(this.entities.length > 0 && this.entities[0].EntityType == 'CY' && ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Tr#') != -1)
                       {
                           var trStatus = ctrl.specificType.split('#')[1];
                           if(trStatus != 'All')
                           {
                              var now = new Date()
                               switch(trStatus)
                               {
                                   case 'LastMonth':
                                       now = new Date(now.setMonth(now.getMonth()-1))
                                       this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                       break;
                                   case 'LastYear':
                                       now = new Date(now.setFullYear(now.getFullYear()-1))
                                       this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                       break;
                                   case 'Last':
                                     this.entities = this.entities.filter((th: any)  => (th.EntityType == 'CY' && th.IsMain) || th.EntityType != 'CY' || (th.Status == 'D' && th.EntityType == 'CY'));
                                       break;
                               }
   
                           }
                       }
 
                }                
                else if(data.Action == 'Clone')
                {
                     var currentClone = ctrl.entities.filter((th: any)  => (th.IdObject == action.EntititiesIds[0]))[0]
                    
                     if(currentClone == null)
                        return;
                     if(currentClone.Parent > 0 || currentClone.Parent == -999)
                         entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, currentClone.Parent, "d"))
                     else
                     {
                         var entity = ctrl.entities.filter((th: any)  => (th.IdObject == currentClone.Parent))[0];
                         if(entity.IdObject == -10000)
                             entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, entity.IdObject, entity.Name))
                         else
                             entitiesT = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, (entity.Parent)*-1, entity.IdObject+'_'+entity.Name))
                     }
                     if(entitiesT.length > 0 && entitiesT[0].EntityType == 'CY' && ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Tr#') != -1)
                     {
                         var trStatus = ctrl.specificType.split('#')[1];
                         if(trStatus != 'All')
                         {
                            var now = new Date()
                             switch(trStatus)
                             {
                                 case 'LastMonth':
                                     now = new Date(now.setMonth(now.getMonth()-1))
                                     entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                     break;
                                 case 'LastYear':
                                     now = new Date(now.setFullYear(now.getFullYear()-1))
                                     entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                     break;
                                 case 'Last':
                                     entitiesT = entitiesT.filter((th: any)  => (th.EntityType == 'CY' && th.IsMain) || th.EntityType != 'CY' || (th.Status == 'D' && th.EntityType == 'CY'));
                                     break;
                             }
 
                         }
                     }
                     
                     entitiesT.forEach(function (entity: IEntity) {
                         ctrl.entities = ctrl.entities.filter((th: any)  => (th.IdObject != entity.IdObject))
                             entity.CreationTime = new Date(entity.CreationTime)
                             entity.ModificationTime = new Date(entity.ModificationTime)
                             ctrl.entities.push(entity)
                     }); 
                }                
                var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                instance.clearSelection()
                instance.refresh();     
        });
    }

    async loadPanel()
    {
        var ctrl = this;
        var storageGlobal = this.localStorageService.getWithExpiration('UserPreferences');
        storageGlobal = JSON.parse(storageGlobal);
        ctrl.firstLoadTree = true;
        ctrl.firstLoadData = true;
        if(this.version == null || this.version.length == 0 || this.space == null || this.space.length == 0 || this.entityAnchor == null)
            return;
            ctrl.fullyLoaded = true;
            this.groups = [];
            let parentID = 0;
        if(this.entityAnchor != null)
        parentID = this.entityAnchor.IdObject;
           let componentActions = await lastValueFrom(this.entityService.getFullEntitiesAction(this.space));
           
            for (var i = 0; i < componentActions.length; i++) {
                var entityType = componentActions[i].EntityType;
                        if(StringUtils.isMainEntity(entityType))
                            entityType ='EP'
                if (this.IsPartOfMenu(componentActions[i])) {
                    if(this.groups[entityType] == null)
                        this.groups[entityType] = [];
                    let group = this.groups[entityType].find((obj: { Name: string; }) => {
                        return obj.Name === componentActions[i].GroupName
                    });
                    if (!group) {
                        group = {
                            Name: componentActions[i].GroupName,
                            ActionButtons: <IActionButton[]>[]
                        };
                        group.ActionButtons.push(componentActions[i]);
                        this.groups[entityType].push(group)
                    }
                    else if(group.ActionButtons.filter((tb: { Name: string; }) => tb.Name == componentActions[i].Name).length == 0) {
                        group.ActionButtons.push(componentActions[i]);
                    }
                }
            }
            await this.displayMenuAction();
 ctrl.entities = [];
 this.entityAnchorUse = this.entityAnchor;
        var ds = new CustomStore({  
            cacheRawData:false,
            async load(options) {
                ds.clearRawDataCache();
                let entities: IEntity[] = [];
                if(options.parentIds && ctrl.entities.length>0)
                {
                    if(9999999 == options.parentIds[options.parentIds.length-1])
                    {
                        ctrl.entityService.setFilterPreference(ctrl.space, null)
                        return ctrl.entities;
                    }
                    var entity = ctrl.expandRowIds.filter((th: any)  => (th == options.parentIds[options.parentIds.length-1]))[0];
                    if(entity == null)
                    {
                        ctrl.expandRowIds.push(options.parentIds[options.parentIds.length-1])
                        ctrl.entityService.setExpandPreference(ctrl.space, ctrl.expandRowIds)
                    }
                    if(options.parentIds[options.parentIds.length-1] > 0 || options.parentIds[options.parentIds.length-1] == -999)
                    {
                        entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, options.parentIds[options.parentIds.length-1], "d"))
                        if(entities.length == 0) 
                        {
                            var entity = ctrl.entities.filter((th: any)  => (th.IdObject == options.parentIds[options.parentIds.length-1]))[0];
                            if(entity != null)
                                entity.HasNextLevel = false;
                                ctrl.entityService.setFilterPreference(ctrl.space, null)
                            return [];
                        }
                    }
                    else
                    {
                        var entity = ctrl.entities.filter((th: any)  => (th.IdObject == options.parentIds[options.parentIds.length-1]))[0];
                        if(entity != null)
                        {
                            if(entity.IdObject == -10000)
                                entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, entity.IdObject, entity.Name))
                            else
                                entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, (entity.Parent)*-1, entity.IdObject+'_'+entity.Name))
                        }
                    }
                }
                else if(!options.filter || ctrl.entities.length == 0)
                {
                    ctrl.firstLoadData = false;
                    if(options.filter == null)
                      ds.push([])
                    if(options.filter != null && options.filter[0] == 'Parent')
                        options.filter = null;
                    if(ctrl.expandRowIds == null || ctrl.expandRowIds.length == 0)
                        options.parentIds = null;

                    if(!ctrl.specificType || ctrl.specificType.length == 0 || ctrl.specificType.indexOf('Fo#') == -1)
                    {
                        ctrl.entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, parentID, "d"))
                    }
                    else
                    {
                        ctrl.entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, -10000, ctrl.specificType.replace('#','-')))
                    }
                        ctrl.entities.forEach(function (entity: IEntity) {                 
                        entity.CreationTime = new Date(entity.CreationTime)
                        entity.ModificationTime = new Date(entity.ModificationTime)
                    });
                    for(var i = 0; i< ctrl.expandRowIds.length;i++)
                    {
                        if(ctrl.expandRowIds[i] > 0 || ctrl.expandRowIds[i] == -999)
                            entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, ctrl.expandRowIds[i], "d"))
                        else
                        {
                            var entity = ctrl.entities.filter((th: any)  => (th.IdObject == ctrl.expandRowIds[i]))[0];
                            if(entity != null)
                            {
                                if(entity.IdObject == -10000)
                                    entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, entity.IdObject, entity.Name))
                                else
                                    entities = await lastValueFrom(ctrl.entityService.getEntitiesTree(ctrl.space, (entity.Parent)*-1, entity.IdObject+'_'+entity.Name))
                            }
                        }
                        entities.forEach(function (entity: IEntity) {

                            if (ctrl.entities.filter((th: any)  => (th.IdObject == entity.IdObject)).length == 0) {
                                entity.CreationTime = new Date(entity.CreationTime)
                                entity.ModificationTime = new Date(entity.ModificationTime)
                                ctrl.entities.push(entity)
                            }
                        });  
                    }

                }
                var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                 var root =       instance.option("rootValue");
                 if(root == -999)
                {
                    var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                    instance.option("rootValue", 9999999);
                }
   
                
                if(entities.length > 0)
                {
                    entities.forEach(function (entity: IEntity) {

                        if (ctrl.entities.filter((th: any)  => (th.IdObject == entity.IdObject)).length == 0) {
                            entity.CreationTime = new Date(entity.CreationTime)
                            entity.ModificationTime = new Date(entity.ModificationTime)
                            ctrl.entities.push(entity)
                        }
                    });  
                }
                
                if(ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('#') == -1)
                ctrl.entities =  ctrl.entities.filter((th: {
                            Parent: number; EntityType: string; 
                })  => (th.EntityType == ctrl.specificType && th.Parent == parentID) ||  th.Parent != parentID);
                if(ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Bo#') != -1)
                {
                    var boStatus = ctrl.specificType.split('#')[1];
                    if(options.filter == null)
                    {
                        options.filter = ['Status', '=', boStatus];
                    }
                    else
                    {
                        options.filter.push('and')
                        options.filter.push(['Status', '=', boStatus])
                    }
                }
                let filterEntities =null;
                if(ctrl.specificType && ctrl.specificType.length > 0 && ctrl.specificType.indexOf('Tr#') != -1)
                {
                    var trStatus = ctrl.specificType.split('#')[1];
                    if(trStatus != 'All')
                    {
                        var now = new Date()
                        switch(trStatus)
                        {
                            case 'LastMonth':
                                now = new Date(now.setMonth(now.getMonth()-1))
                                filterEntities = ctrl.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                break;
                            case 'LastYear':
                                now = new Date(now.setFullYear(now.getFullYear()-1))
                                filterEntities = ctrl.entities.filter((th: any)  => (th.EntityType == 'CY' && th.CreationTime > now) || th.EntityType != 'CY');
                                break;
                            case 'Last':
                                filterEntities = ctrl.entities.filter((th: any)  => (th.EntityType == 'CY' && th.IsMain) || th.EntityType != 'CY' || (th.Status == 'D' && th.EntityType == 'CY'));
                                break;
                        }

                    }
                }

                if(options.filter)
                {
                    ctrl.entityService.setFilterPreference(ctrl.space, options.filter)
                    if(filterEntities == null)
                        filterEntities = ctrl.entities;
                    if(typeof options.filter[0]  === 'string')
                    {
                        switch(options.filter[1])
                        {
                            case 'contains':
                                filterEntities = filterEntities.filter((th: any)  => (th[options.filter[0].toString()].toUpperCase().indexOf(options.filter[2].toString().toUpperCase()) > -1));
                                break;
                            case 'notcontains':
                                filterEntities = filterEntities.filter((th: any)  => (th[options.filter[0].toString()].toUpperCase().indexOf(options.filter[2].toUpperCase()) > -1));
                                break;
                            case 'startswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[options.filter[0].toString()].toUpperCase().startsWith(options.filter[2].toUpperCase())));
                                break;
                            case 'endswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[options.filter[0].toString()].toUpperCase().endswith(options.filter[2].toUpperCase())));
                                break;
                            case '=':
                                filterEntities = filterEntities.filter((th: any)  => th[options.filter[0]].toString().toUpperCase() == options.filter[2].toString().toUpperCase());
                                break;
                            case '<>':
                                filterEntities = filterEntities.filter((th: any)  => th[options.filter[0]].toString().toUpperCase() != options.filter[2].toString().toUpperCase());
                                break;
                        }
                    }
                    else if(options.filter[1] =='and')
                    {
                        var filter = options.filter[0]
                        switch(filter[1])
                        {
                            case 'contains':
                                filterEntities = filterEntities.filter((th: any)  => (th[filter[0].toString()].toUpperCase().indexOf(filter[2].toString().toUpperCase()) > -1));
                                break;
                            case 'notcontains':
                                filterEntities = filterEntities.filter((th: any)  => (th[filter[0].toString()].toUpperCase().indexOf(filter[2].toUpperCase()) > -1));
                                break;
                            case 'startswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[filter[0].toString()].toUpperCase().startsWith(filter[2].toUpperCase())));
                                break;
                            case 'endswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[filter[0].toString()].toUpperCase().endswith(filter[2].toUpperCase())));
                                break;
                            case '=':
                                filterEntities = filterEntities.filter((th: any)  => th[filter[0]].toString().toUpperCase() == filter[2].toString().toUpperCase());
                                break;
                            case '<>':
                                filterEntities = filterEntities.filter((th: any)  => th[filter[0]].toString().toUpperCase() != filter[2].toString().toUpperCase());
                                break;
                        }
                        let filterT = options.filter[2]
                        switch(filter[1])
                        {
                            case 'contains':
                                filterEntities = filterEntities.filter((th: any)  => (th[filterT[0].toString()].toUpperCase().indexOf(filterT[2].toString().toUpperCase()) > -1));
                                break;
                            case 'notcontains':
                                filterEntities = filterEntities.filter((th: any)  => (th[filterT[0].toString()].toUpperCase().indexOf(filterT[2].toUpperCase()) > -1));
                                break;
                            case 'startswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[filterT[0].toString()].toUpperCase().startsWith(filterT[2].toUpperCase())));
                                break;
                            case 'endswith':
                                filterEntities = filterEntities.filter((th: any)  => (th[filterT[0].toString()].toUpperCase().endswith(filterT[2].toUpperCase())));
                                break;
                            case '=':
                                filterEntities = filterEntities.filter((th: any)  => th[filterT[0]].toString().toUpperCase() == filterT[2].toString().toUpperCase());
                                break;
                            case '<>':
                                filterEntities = filterEntities.filter((th: any)  => th[filter[0]].toString().toUpperCase() != filter[2].toString().toUpperCase());
                                break;
                        }
                    }
                    else{
                        return filterEntities;
                    }
                    return filterEntities;
                }
                if(filterEntities != null)
                    return filterEntities;
                ctrl.entityService.setFilterPreference(ctrl.space, null)
                return ctrl.entities;
              },  
            
        }); 

        var configTree = {
            dataSource: ds,
            width:'-webkit-fill-available',
            selection:
            {
                mode: 'multiple',
                recursive :false,
                allowSelectAll : true
            },
            parentIdExpr: 'Parent',
            hasItemsExpr: 'HasNextLevel',
              filterRow: { visible: true },
            keyExpr: 'IdObject',
            showColumnHeaders:true,
            filterValue : <any>[],
            rootValue:0,
            columnMinWidth:30,
            columnAutoWidth : true,
            autoExpandAll : false,
            remoteOperations: { filtering: true },
           columns: [{dataField: 'id', caption : '', width:100, allowFiltering : false},{dataField: 'LongName', caption : ' Entité', width:'calc(960 - 590px)'},{dataField: 'Status', width:60},{dataField: 'CreationTime', caption:'Date création', width:90, format: "dd.MM.yyyy"},{dataField: 'ModificationTime', caption:'Date modification', width:150, format: "dd.MM.yyyy HH:mm:ss"},{dataField: 'Owner', caption : 'Responsable', width:180},{ type: 'buttons',dataField: 'actions', with:60}],
            onRowPrepared: function (e: any) {
                if (e.data && e.data.IsMain === true) {
                    e.rowElement.addClass("isMain");
                } else if (e.data && e.data.IsPublished === true) {
                    e.rowElement.addClass("isPublished");
                } else if (e.data && e.data.IsSpecial === true) {
                    e.rowElement.addClass("isSpecial");
                }
                if (e.data && e.data.IdObject === ctrl.idEntity) {
                    e.rowElement.addClass("entity-selected");
                }
            },
            onCellPrepared: function (e: any) {
                if (e.column.dataField == 'actions' && e.rowType == "data") {
                    var entityType = e.data.EntityType;
                        if(StringUtils.isMainEntity(entityType))
                            entityType ='EP'
                    if (ctrl.groups[entityType] && ctrl.groups[entityType].length > 0) {
                        
                        var menuItemsLp = [{
                            icon: 'fa-solid fa-ellipsis',
                            items: ctrl.menuItems[entityType],
                        }];
                        let hasSpecialItem = false
                        for (let i = 0; i < ctrl.selectionMsg.length; i++) {
                            if (ctrl.selectionMsg[i].IdObject == -1) {
                                hasSpecialItem = true;
                            }

                        }
                        $(e.cellElement).dxMenu({
                            dataSource: menuItemsLp, onItemClick: async function (event: any): Promise<void> {
                                let element = event.itemData.Element;
                                if (!element || element.Disabled) return;
                                if (element.Childrens && element.Childrens.length > 0) return;
                                switch (element.ActionType) {
                                    case ActionTypeButton.API:
                                        await ctrl.actionLegacyService.parsingActionTypeApi(element, event.itemData.Control.selectionMsg, null, ctrl.space, event.itemData.Control.selectionMsg[0].Parent, event.itemData.Control.selectionMsg[0].EntityType, '', '');//config.subPerimeter);
                                        break;
                                    case ActionTypeButton.Javascript:
                                        await ctrl.actionLegacyService.parsingActionTypeJavascript(element, event.itemData.Control.selectionMsg, ctrl.space, null, event.itemData.Control.selectionMsg[0].Parent, event.itemData.Control.selectionMsg[0].EntityType, null, ctrl.version, event.itemData.Control.selectionMsg[0].CurrentProcedureAssociation);
                                        break;
                                }
                                if(element.Name == 'CutSelection')
                                {
                                    setTimeout(() => {                                        
                                        var instance = $('#navigation-hierachical-tree').dxTreeList('instance');
                                        instance.repaint();
                                    }, 200);
                                }
                            }
                        });
                        $(e.cellElement).removeClass('dx-menu');

                        if (ctrl.menuItems[entityType] && ctrl.menuItems[entityType].length > 0 && !hasSpecialItem)
                            $(e.cellElement).children('.dx-menu-horizontal').show();
                        else
                            $(e.cellElement).children('.dx-menu-horizontal').hide();
                    }
                }
                if (e.column.dataField === "LongName" && e.key && e.data.IdObject > 0) {

                    var url = '#/components/' + ctrl.space+'/'+ e.data.EntityType+'/' + e.data.IdObject+'/'+ctrl.componentName+'/'+ctrl.componentType;
                    if(e.data.EntityType != ctrl.entityType)
                        url = '#/components/' + ctrl.space+'/'+ e.data.EntityType+'/' + e.data.IdObject+'/general/CS';
                    $(e.cellElement).html("<a class='link_entities' href='" + url + "'+'>" + e.value + "</a>");
                }
                if (e.rowType === "data") {
                    if (ctrl.sessionStorageService.hasKey(ctrl.cutedSelectionKey)) {
                        var identifiersInCache = ctrl.sessionStorageService.get(ctrl.cutedSelectionKey);
                        var identifiers = identifiersInCache.split(',');
                        identifiers.forEach(function (id: string) {

                            if (e.data.IdObject.toString() === id) {
                                e.cellElement.css("opacity", "0.3");
                            }
                        });
                    }
                }
            },
            onRowCollapsed : function( e: any)
            {
                const index = ctrl.expandRowIds.indexOf(e.key);
                ctrl.expandRowIds.splice(index,1)
                ctrl.entityService.setExpandPreference(ctrl.space, ctrl.expandRowIds)
            },
            onSelectionChanged: async function (selectedItems: any) {
                ctrl.selectionMsg = selectedItems.selectedRowsData;
                await ctrl.displayMenuAction();
                var lastSelection = null;
                if(selectedItems.currentSelectedRowKeys.length > 0)
                    lastSelection = selectedItems.selectedRowsData.filter((tb: { IdObject: string; }) => tb.IdObject == selectedItems.currentSelectedRowKeys[0])[0];
                let correctSelectedItems = [];
                let shouldCleanSelect = false;
                var selectionEntity = [];
                for (let i = 0; i < selectedItems.selectedRowKeys.length; i++) {
                    if(selectionEntity.indexOf(selectedItems.selectedRowsData[i].EntityType) == -1)
                    {
                        selectionEntity.push(selectedItems.selectedRowsData[i].EntityType)
                    }
                }
                for (let i = 0; i < selectedItems.selectedRowKeys.length; i++) {
                    var rowIndex = selectedItems.component.getRowIndexByKey(selectedItems.selectedRowKeys[i])
                    var cell = selectedItems.component.getCellElement(rowIndex, "actions")
                    var entityType = selectedItems.selectedRowsData[i].EntityType;
                    if(lastSelection == null || selectedItems.selectedRowsData[i].Parent == lastSelection.Parent)
                    {
                        correctSelectedItems.push(selectedItems.selectedRowKeys[i])
                        if(StringUtils.isMainEntity(entityType))
                            entityType ='EP'
                        var menuItemsLp = [
                            {
                                icon: 'fa-solid fa-ellipsis',
                                items: ctrl.menuItems[entityType],

                            }
                        ];
                        let menu = $(cell).dxMenu('instance');
                        let hasSpecialItem = false
                        for (let i = 0; i < ctrl.selectionMsg.length; i++) {
                            if (ctrl.selectionMsg[i].IdObject == -1) {
                                hasSpecialItem = true;
                            }

                        }
                        if (!hasSpecialItem) {
                            if (menu && selectionEntity.length == 1) {
                                menu.option("dataSource", menuItemsLp);
                                $(cell).find('.dx-menu-item-popout-container').remove();
                                $(cell).find('.dx-menu-item-has-submenu').removeClass('dx-menu-item-has-submenu');
                                $(cell).children('.dx-menu-horizontal').show();
                            }
                            else {
                                $(cell).children('.dx-menu-horizontal').hide();
                            }
                        }
                        else {
                            $(cell).children('.dx-menu-horizontal').hide();
                        }
                    }
                    else
                    {
                        shouldCleanSelect = true;
                        var menuItemsLpEmpty: any = [
                            {
                                icon: 'fa-solid fa-ellipsis',
                                items: [],
                            }
                        ];
                        let menu = $(cell).dxMenu('instance');
                        if (menu && selectionEntity.length == 1) {
                            menu.option("dataSource", menuItemsLpEmpty);
                        }
                        $(cell).children('.dx-menu-horizontal').hide();
                    }
                }

                for (let i = 0; i < selectedItems.currentDeselectedRowKeys.length; i++) {
                    var rowIndex = selectedItems.component.getRowIndexByKey(selectedItems.currentDeselectedRowKeys[i])
                    var cell = selectedItems.component.getCellElement(rowIndex, "actions")
                    var menuItemsLpEmpty: any = [
                        {
                            icon: 'fa-solid fa-ellipsis',
                            items: [],
                        }
                    ];
                    let menu = $(cell).dxMenu('instance');
                    if (menu && selectionEntity.length == 1) {
                        menu.option("dataSource", menuItemsLpEmpty);
                    }
                    $(cell).children('.dx-menu-horizontal').hide();
                } 
                if(shouldCleanSelect)
                {
                    $('#navigation-hierachical-tree').dxTreeList('instance').selectRows(correctSelectedItems, false)
                }               
            }
        };
        if(this.entityAnchor != null)
            configTree.rootValue = this.entityAnchor.IdObject;
        setTimeout (  ()=> {
        var tree = $('#navigation-hierachical-tree').dxTreeList(<string><unknown>configTree).dxTreeList('instance');
        tree.clearSelection();    
        if (ctrl.firstLoadTree && storageGlobal != null && storageGlobal.Filters != null && storageGlobal.Filters[ctrl.space] != null)
        {
            var filters  = storageGlobal.Filters[ctrl.space];
            if(typeof filters[0]  === 'string')
            {
                var columns = tree.option('columns');
                var column = columns.filter((th: any)  => (th.dataField == filters[0].toString()))[0];
                if(column != null && column.filterValue == null)
                {   
                    tree.columnOption(column.dataField, {
                        selectedFilterOperation: filters[1],
                        filterValue :filters[2]});
                }
            }
            else if(filters[1] =='and')
            {
                var columns = tree.option('columns');
                var filter = filters[0]
                var column = columns.filter((th: any)  => (th.dataField == filter[0].toString()))[0];
                if(column != null && column.filterValue == null)
                {   
                    tree.columnOption(column.dataField, {
                        selectedFilterOperation: filter[1],
                        filterValue :filter[2]});
                }
                filter = filters[2]
                column = columns.filter((th: any)  => (th.dataField == filter[0].toString()))[0];
                if(column != null && column.filterValue == null)
                {   
                    tree.columnOption(column.dataField, {
                        selectedFilterOperation: filter[1],
                        filterValue :filter[2]});
                }
            }
        }
        if (ctrl.firstLoadTree && storageGlobal != null && storageGlobal.ExpandedRows != null && storageGlobal.ExpandedRows[ctrl.space] != null)
        {
            ctrl.expandRowIds  = storageGlobal.ExpandedRows[ctrl.space];
                var root = tree.option('rootValue');
                ctrl.expandRowIds.forEach(function (key: number) { 
                {
                    if(root != key)
                    {
                        setTimeout (  ()=> {
                            tree.expandRow(key)
                        }, 500);
                    }
                }});
            
        }
        ctrl.firstLoadTree = false;
                        }
                        , 500);
    }
    
    IsPartOfMenu(element: IActionButton) {
        return (element.Target == TargetButton.ContextMenu);
    }

    async displayMenuAction() {
        this.menuItems = [];
        var parent: any = null;
        var  currentProcedureAssociation = 'defaut';
        if(this.selectionMsg.length > 0){
        currentProcedureAssociation = await lastValueFrom(this.entityService.getProcedureAssociationfromEntity(this.space, this.selectionMsg[0].IdObject));
        this.selectionMsg[0].CurrentProcedureAssociation = currentProcedureAssociation;
        }
       for (let i = 0; i < this.selectionMsg.length; i++) {
        
            if (this.selectionMsg[i].IdObject == -1) {
                return;
            }
            if(this.selectionMsg[i].Parent > 0)
            parent = this.entities.filter(p=>p.IdObject == this.selectionMsg[i].Parent)[0]
            else
            parent = this.entities.filter(p=>p.IdObject == (this.selectionMsg[i].Parent*-1))[0]
        }
        if (this.selectionMsg.length > 0)
            $("#navigation-hierachical-tree").dxTreeList("columnOption", "actions", "visible", true);
            for(var propertyName in this.groups) {
                if(this.selectionMsg.filter((tb: { EntityType: string; }) => (tb.EntityType == propertyName && propertyName !='EP') || ((StringUtils.isMainEntity(tb.EntityType)) && propertyName =='EP')).length > 0)
                {
                    this.menuItems[propertyName] = [];
        for (var i = 0; i < this.groups[propertyName].length; i++) {
            if(this.selectionMsg.filter((tb: { EntityType: string; }) => (tb.EntityType == propertyName && propertyName !='EP') || ((StringUtils.isMainEntity(tb.EntityType)) && propertyName =='EP')).length > 0)
                {
                    let hasAddLineGroup = false;
          

            for (var j = 0; j < this.groups[propertyName][i].ActionButtons.length; j++) {

                var space = this.space;
                this.groups[propertyName][i].ActionButtons[j].Actions = this.groups[propertyName][i].ActionButtons[j].Actions.replace('(space)', this.space);
                this.groups[propertyName][i].ActionButtons[j].Actions = this.groups[propertyName][i].ActionButtons[j].Actions.replace('[    "(entitiesId)"  ]', '""');
                if (this.actionLegacyService.canDisplayAction(this.groups[propertyName][i].ActionButtons[j], this.selectionMsg,'','', parent, this.userEmail, currentProcedureAssociation, this.space)) {
                    if (this.menuItems[propertyName].length > 0 && !hasAddLineGroup) {
                        this.menuItems[propertyName].push(
                            {
                                Control: this,
                                template: this.actionLegacyService.menuItemTemplate,
                                Element: this.groups[propertyName][i].ActionButtons[0],
                                text: 'group'
                            }
                        )
                    }
                    hasAddLineGroup = true;
                    var subItems: any[] = [];
                    if (this.groups[propertyName][i].ActionButtons[j].Childrens.length > 0) {
                        var ctrl = this;
                        this.groups[propertyName][i].ActionButtons[j].Childrens.forEach(function (val: { Actions: string; RenderType: RenderTypeButton; LongName: any; }, index: any, tab: any) {
                            var subItem: any;
                            val.Actions = val.Actions.replace('[    "(entitiesId)"  ]', '""');
                            val.Actions = val.Actions.replace('(space)', space);
                            if (ctrl.actionLegacyService.canDisplayAction(<any>val, ctrl.selectionMsg,'','', parent, ctrl.userEmail, currentProcedureAssociation, ctrl.space)) {
                                if (val.RenderType == RenderTypeButton.Icone)
                                    subItem = {
                                        Element: val,
                                        Control: ctrl,
                                        template: ctrl.actionLegacyService.menuItemTemplate
                                    };
                                else
                                    subItem = {
                                        text: val.LongName,
                                        Element: val,
                                        Control: ctrl,
                                        template: ctrl.actionLegacyService.menuItemTemplate
                                    };

                                subItems.push(subItem);
                            }
                        });
                    }

                    if (this.groups[propertyName][i].ActionButtons[j].RenderType == RenderTypeButton.Icone) {
                        if (this.groups[propertyName][i].ActionButtons[j].Childrens.length > 0) {
                            this.groups[propertyName][i].ActionButtons[j].Disabled = true;
                            if(subItems.length > 0)
                                this.menuItems[propertyName].push({
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate,
                                    items: subItems
                                });
                        }
                        else
                            this.menuItems[propertyName].push(
                                {
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate
                                });
                    }
                    else if (this.groups[propertyName][i].ActionButtons[j].RenderType == RenderTypeButton.IconeAndText) {
                        if (this.groups[propertyName][i].ActionButtons[j].Childrens.length > 0) {
                            this.groups[propertyName][i].ActionButtons[j].Disabled = true;
                            if(subItems.length > 0)
                                this.menuItems[propertyName].push({
                                    text: this.groups[propertyName][i].ActionButtons[j].LongName,
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate,
                                    items: subItems
                                });
                        }
                        else
                            this.menuItems[propertyName].push(
                                {
                                    text: this.groups[propertyName][i].ActionButtons[j].LongName,
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate
                                });
                    }
                    else if (this.groups[propertyName][i].ActionButtons[j].RenderType == RenderTypeButton.Text) {
                        if (this.groups[propertyName][i].ActionButtons[j].Childrens.length > 0) {
                            this.groups[propertyName][i].ActionButtons[j].Disabled = true;
                            if(subItems.length > 0)
                                this.menuItems[propertyName].push({
                                    text: this.groups[propertyName][i].ActionButtons[j].LongName,
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate,
                                    items: subItems
                                });
                        }
                        else
                            this.menuItems[propertyName].push(
                                {
                                    text: this.groups[propertyName][i].ActionButtons[j].LongName,
                                    Element: this.groups[propertyName][i].ActionButtons[j],
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate
                                }
                            )
                    }
                }
                }
            }
        }
        }

    }
    }

    ngOnDestroy(): void {
    if (this.sub2)
    this.sub2.unsubscribe();
    }
}