import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import { Router } from "@angular/router";
import { EntityService } from "app/shared/entity.service";
import { IEntity } from "app/shared/entity";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'panel-liste-app',
    templateUrl: './panel-liste-app-mobile.component.html',
    styleUrls: ['./panel-liste-app-mobile.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelListAppMobileComponent implements OnInit, OnDestroy, OnChanges {
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() currentZone: any | undefined;
    @Input() identifier: string = '';
    @Input() variante: string = '';
    @Input() currentEntity: any | undefined;
    @Input() currentModule: any | undefined;
    @Input() version: string = '';
    @Input() tabName: string = '';
    @Input() moduleName: string = '';
    @Input() states : any;
    
    idEntityUse : string = '';
    tabs: any [] = [];
    selectedTab: any;
    currentProc: IEntity;
    fullyLoaded: boolean = false;
    availableModule : any;
    multiTab : boolean = false;
    baseUrl: string;
    availableApps : any = [];
    constructor(
      private router: Router,
      private entitiesService : EntityService,
      private applicationStructService: FrontOfficeStructService)
    {}

    async ngOnChanges(changes: any) {
        if (changes.currentEntity) {
            await this.loadPanel();
        }
    }
    
    async ngOnInit(): Promise<void> {
        this.ngOnDestroy(); 
    }

    async loadPanel() {
        this.baseUrl = this.router.url;
        
        if(this.version == null || this.version.length == 0) return;
        if (!this.identifier && this.identifier != '') return;
        this.ngOnDestroy();
        this.idEntityUse = this.identifier; 
        
        this.currentProc = await lastValueFrom(this.entitiesService.getProcOfEntity(this.currentModule.Space, this.identifier, this.applicationName));
        let availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, this.currentEntity.Name))
        setTimeout(()=>{
            $('#navigation-list-procedure').dxList({
                items: availableProcs,
                displayExpr:'LongName',
                width:'100%',
                itemTemplate(itemData, itemIndex, itemElement) {
                    if(itemData.IdObject == ctrl.currentProc.IdObject)
                        return $("<div />")
                            .css("padding", "5px")
                            .css("padding-left", "2.5rem !important")
                            .addClass("module-selected")
                            .append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                    else
                        return $("<div />")
                            .addClass("module-non-selected")
                            .css("padding", "5px")
                            .css("padding-left", "2.5rem !important")
                            .append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                },
                onItemClick(data)
                {
                    ctrl.returnToProc(data.itemData)
                }
            });
        $('#navigation-list-procedure').dxList('instance')}, 200)

        var ctrl = this; 
    }

    async returnToProc(proc?: IEntity): Promise<void> {
                if (!proc)
                {
                    let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(this.applicationName, this.version, this.zoneName));
                    var currentProcedureAssociation  = await lastValueFrom(this.entitiesService.getProcedureAssociation(this.zoneName, this.currentProc.IdObject));
                    var selectedModule: {ModuleName: any; Name: string; } = null;
                    var i =0;
                    do
                    {
                        var procsAvailable =   modules.Modules[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == this.variante) && tempProcAsso.length > 0){
                                selectedModule = modules.Modules[i];
                            }
                        });
                        i++;
                    }
                    while(selectedModule == null && i<modules.Modules.length);
                    let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                        let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName, this.version, this.zoneName, moduleName));
                    var selectedtab: { Name: string; } = null;
                    var i =0;
                    do
                    {
                        var procsAvailable =   tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == this.variante) && tempProcAsso.length > 0){
                            selectedtab = tabs.Tabs[i];
                            }
                        });
                        i++;
                    }
                    while(selectedtab == null && i<tabs.Tabs.length);
                    let entities = await lastValueFrom(this.entitiesService.getAvailableEntitiesFromProc(this.zoneName, this.currentProc.IdObject, this.currentModule.ParentEntityCible, this.applicationName));
                    this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);      
                }
                else {
                    let entities = await lastValueFrom(this.entitiesService.getAvailableEntitiesFromProc(this.zoneName, proc.IdObject, this.currentModule.ParentEntityCible, this.applicationName));
                    var currentProcedureAssociation  = await lastValueFrom(this.entitiesService.getProcedureAssociation(this.zoneName, proc.IdObject));
                    var tabName = this.tabName;
                    if(tabName.indexOf('/') > -1)
                        tabName = tabName.split('/')[1];
                    var selectEntities = entities.filter((tb: { IdObject: number; }) => tb.IdObject == this.currentEntity.IdObject);
                    if(this.currentEntity && selectEntities.length > 0)
                    {
                        this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ this.moduleName+'/'+ this.currentEntity.IdObject+'/'+ tabName]);
                    }
                    else if(this.currentEntity && selectEntities.length == 0)
                    {
                        let selectEntity = entities.filter((tb: { Name: string; }) => tb.Name == this.currentEntity.Name);
                        if(selectEntity.length > 0)
                            this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ this.moduleName+'/'+ selectEntity[0].IdObject+'/'+ tabName]);
                        else
                        {
                            let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(this.applicationName, this.version, this.zoneName));
                    let variante = await lastValueFrom(this.entitiesService.getVarianteFromEntity(modules.Space, modules.Version, entities[0].IdObject.toString(), modules.Perimeter));
                    var i =0;
                    do
                    {
                        var procsAvailable =   modules.Modules[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                                selectedModule = modules.Modules[i];
                            }
                        });
                        i++;
                    }
                    while(selectedModule == null && i<modules.Modules.length);
                    let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                    let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName, this.version, this.zoneName, moduleName));
                    var selectedtab: { Name: string; } = null;
                    var i =0;
                    do
                    {
                        var procsAvailable =   tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                        var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                            return procsAvailable.some((f : string) => {
                                return f === tb;
                            })
                        });
                        tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                            if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                                selectedtab = tabs.Tabs[i];
                            }
                        });
                        i++;
                    }  
                    while(selectedtab == null && i<tabs.Tabs.length);
                    this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);
                }
            }
            else
            {
                let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(this.applicationName, this.version, this.zoneName));
                let variante = await lastValueFrom(this.entitiesService.getVarianteFromEntity(modules.Space, modules.Version, entities[0].IdObject.toString(), modules.Perimeter));
                var i =0;
                do
                {
                    var procsAvailable =   modules.Modules[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                            selectedModule = modules.Modules[i];
                        }
                    });
                    i++;
                }
                while(selectedModule == null && i<modules.Modules.length);
                let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName, this.version, this.zoneName, moduleName));
                var selectedtab: { Name: string; } = null;
                var i =0;
                do
                {
                    var procsAvailable =   tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0) {
                            selectedtab = tabs.Tabs[i];
                        }
                    });
                    i++;
                }
                while(selectedtab == null && i<tabs.Tabs.length);
                this.router.navigate(['/app/' + this.applicationName+'/'+ this.zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);
            }
        }
    }


    ngOnDestroy(): void {}
}