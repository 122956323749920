import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardWithForcedLogin implements CanActivate {

    constructor(private authService: AuthService)
    {    }

    canActivate(): boolean{
        let hasIdToken =  this.authService.hasIdToken();
        let hasAccessToken = this.authService.hasAccessToken();
        return (hasAccessToken && hasIdToken);
    }
}