import { Component, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActionTypeButton, IEntity, RenderTypeButton, TargetButton } from '../../../../shared/entity';
import { AuthService } from 'app/shared/auth.service';
import { IActionAppButton } from 'app/front-office/front-office-struct';
import { FrontOfficeStructService } from 'app/front-office/front-office-struct.service';
import { EntityService } from 'app/shared/entity.service';
import * as $ from 'jquery';
import DataSource from 'devextreme/data/data_source';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionLegacyService } from "../../../../shared/actions-legacy.service";
import { lastValueFrom } from 'rxjs';
import { StringUtils } from 'app/utils/string-utils';
import { FrontOfficeToolbarFactory } from '../../shared-factory/front-office-toolbar-factory';

@Component({
    selector: 'front-office-toolbar-mobile',
    templateUrl: './front-office-toolbar-mobile.component.html',
    styleUrls: ['./front-office-toolbar-mobile.component.css']
})

export class FrontOfficeToolbarMobileComponent implements OnInit, OnDestroy, OnChanges {
    @Input() currentState : any;
    @Input() currentStateTab : any;
    @Input() formStates : any;
    @Input() states : any;
    @Input() version: string = '';
    @Input() currentEntity: any | undefined;
    @Input() identifier: string = '';
    @Input() currentZone: any | undefined;
    @Input() currentProcedureAssociation :string;
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() space: string = '';
    @Input() listTabState : any;
    @Input() variante: string = '';
    @Input() currentTab: any | undefined;
    @Input() currentModule: any | undefined;
    @Input() entityCible: string = '';
    @Input() tabStatus: number = 0;
    @Input() tabName: string = '';
    @Input() moduleName: string = '';
    @Input() currentProc: IEntity;
    name = 'Toolbar';
    errorMessage = '';
    applicationActions: IActionAppButton[] = [];
    displayApplicationActions: IActionAppButton[] = [];
    perimeter: string = '';
    actionBoutons: any;
    tabs: any [] = [];
    modulesConfig : any ={};
    tabConfig : any ={};
    selectedTab: any;
    reportsDef: any [] = [];
    exportsDef: any [] = [];
    tabRef: string = '';
    multiTab : boolean = false;
    fullyLoaded: boolean = false;
    idEntityUse : string = '';
    availableProcs:IEntity[] =[];
    calculAction : any;
    constructor(
        private router: Router,
        private authService: AuthService,
        public sanitizer: DomSanitizer,
        private entitiesService : EntityService,
        private applicationStructService: FrontOfficeStructService,
        private actionLegacyService: ActionLegacyService,
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.authService.hasIdToken()) {
            this.ngOnDestroy();
        }
    }

    async ngOnChanges(changes: any) {
        if (changes.zoneName) {
            this.modulesConfig={};
            this.tabConfig={};
            this.version = null;
            this.fullyLoaded = false;
        }

        if (changes.applicationName) {
            this.modulesConfig={};
            this.tabConfig={};
            this.version = null;
            this.fullyLoaded = false;
        }

        FrontOfficeToolbarFactory.handleCurrentStateChange(this, changes);

        if (changes.currentStateTab && this.fullyLoaded) {
            var instance = $('#listTabs').dxLookup('instance');
            if(this.currentStateTab)
                $('#listTabs').css('background-color', this.currentStateTab.Color)
            if(instance != null)
                instance.repaint();
        }

        FrontOfficeToolbarFactory.handleTabAndVarianteChanges(this, changes);
    }

    ngOnDestroy(): void {}

    async loadPanel() {
        if(this.version == null || this.version.length == 0 || this.currentZone == null || (!this.identifier && this.identifier != '')) return;
        this.ngOnDestroy();
        
        this.multiTab = this.currentModule.Tabs.length > 1 && this.tabName.substring(0, this.tabName.length-1) != this.currentModule.Name;
        this.space = this.currentModule.Space;
        this.perimeter = this.currentModule.Perimeter;
        this.tabs = [];
        var modules = this.currentZone.Modules;
        this.idEntityUse = this.identifier; 
        let hasMainEp = false;
        for (var i = 0; i < this.currentZone.Modules.length; i++) {
            if(StringUtils.isMainEntity(this.currentZone.Modules[i].EntityCible))
                hasMainEp = true;
        } 

        if(hasMainEp) {
            if(this.currentZone.SySelector != 'Masquer') {
                if(this.availableProcs.length == 0)             
                    this.availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, this.currentEntity.Name))
                setTimeout(()=>{
                    $('#list-proc-main').dxLookup({
                        items: ctrl.availableProcs,
                        displayExpr:'LongName',
                        width:'100%',
                        itemTemplate(itemData, itemIndex, itemElement) {
                            return $("<div />")
                                .css("padding", "5px")
                                .css("padding-left", "2.5rem !important")
                                .addClass(itemData.IdObject == ctrl.currentProc.IdObject ? "module-selected" : "module-non-selected")
                                .append($("<p />").html(itemData.LongName).css("display", "inline-block"));
                        },
                        dropDownOptions: {
                            hideOnOutsideClick: true,
                            showTitle: false,
                        },
                        value: ctrl.availableProcs.filter((tb: { Name: string; }) => tb.Name == ctrl.currentProc.Name)[0],
                        showClearButton: false,
                        onItemClick(data) {
                            FrontOfficeToolbarFactory.returnToProc(ctrl, data.itemData)
                        }
                      });
                    $('#list-proc-main').dxLookup('instance')
                }, 200)
            }        
            var i =0;
            modules = FrontOfficeToolbarFactory.getRetrieveModulesByProcedure(ctrl);
        }
        else
        {
            for (var j = 0; j < modules.length; j++) {
                for (var i = 0; i < modules[j].Tabs.length; i++) {
                    modules[j].Tabs[i].ModuleName = modules[j].Name;
                }
            }
        }
        
        this.idEntityUse = this.identifier; 
        
        FrontOfficeToolbarFactory.setTabs(ctrl);
        
        let fullTabs: any[] = FrontOfficeToolbarFactory.getFullTabs(ctrl, modules);

        var ctrl = this;
        ctrl.currentTab.Label = StringUtils.decodeSpecialEntitiesHtml(ctrl.currentTab.Label)
        ctrl.currentTab = fullTabs.filter((th: { Label: string; }) => th.Label == ctrl.currentTab.Label)[0];
        ctrl.selectedTab = ctrl.currentTab
        setTimeout (()=> {
        $('#listTabs').dxLookup({
            dataSource: new DataSource(FrontOfficeToolbarFactory.getListTabDatasourceObject(fullTabs)),
              dropDownOptions: {
                hideOnOutsideClick: true,
                showTitle: false,
              },
              width:'100vw',
              displayExpr: 'Label',
              grouped: true,
              searchEnabled: true,
              searchExpr: ['Label','ModuleParent'],
              value:ctrl.selectedTab,
              groupTemplate(data){ return FrontOfficeToolbarFactory.getListTabGroupTemplate(data) },
              itemTemplate(itemData, itemIndex, itemElement) { return FrontOfficeToolbarFactory.getListTabsItemTemplate(ctrl, itemData, itemIndex); },
                onItemClick: function (data:any) {
                    if(!data.itemData.IsGridEntity) {
                        if(data.itemData.ModuleName != null) {
                            ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+'/'+ ctrl.identifier+'/'+ data.itemData.Name]);
                        }
                        else {
                            let module = modules.filter((tb: { Tabs: []; }) => tb.Tabs.filter((th: { Label: string; }) => th.Label == data.itemData.Label).length > 0)[0];
                            ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ module.Name+'/'+ ctrl.identifier+'/'+ data.itemData.Name]);
                        }
                    }
                    else {
                        ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ data.itemData.ModuleName+ data.itemData.AllowFilterEntityT ? '/'+ctrl.identifier+'/' : '/0/'+ data.itemData.Name]);
                    }
                }
        }).dxLookup('instance')
        ctrl.fullyLoaded = true;
        }, 1000);

        this.tabRef  = this.zoneName+'_'+this.applicationName ;
    }

    async displayToolbarAction() { 
        if(!this.currentState || !StringUtils.isMainEntity(this.entityCible) || this.currentModule.Name != this.moduleName)
            return;
        let tempRapport = await lastValueFrom(this.applicationStructService.getReportConfig(this.applicationName, this.version, this.zoneName, this.currentState.Id, parseFloat(this.identifier)));
        let tempExport = await lastValueFrom(this.applicationStructService.getExportConfig(this.applicationName, this.version, this.zoneName));
        this.exportsDef = [];
        for(var i = 0;i<tempExport.length;i++) {
            var procsAvailable =  tempExport[i].AvailableProcedureAssociations.split('.');
            var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
                return procsAvailable.some((f : string) => {
                    return f === tb;
                })
            });

            tempExport[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                if((allowVariant == "RfcCommun" || allowVariant == this.variante) && tempProcAsso.length > 0)
                    this.exportsDef.push(tempExport[i]);
            });
            
        }
        this.reportsDef =[];

        FrontOfficeToolbarFactory.filterAndOrganizeReports(this, tempRapport);
               
        let applicationActions = await lastValueFrom(this.applicationStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.currentModule.Name, this.currentTab.Name, this.currentState.Id, this.currentStateTab.Id));
        if(this.multiTab)
            this.tabName = this.currentModule.Name+'/'+this.currentTab.Name;
        this.applicationActions = applicationActions.filter(FrontOfficeToolbarFactory.IsPartOfToolBar);
        this.displayApplicationActions = [];
        if(this.reportsDef.length == 0)
            this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name != 'Rapport');
        if(this.exportsDef.length == 0 || this.exportsDef[0].Label == 'Schéma initial')                  
            this.applicationActions = this.applicationActions.filter((tb: { Name: string; }) => tb.Name != 'Export' && tb.Name != 'Import');
            
        for (var i = 0; i < this.applicationActions.length; i++) {
            if(this.applicationActions[i].Name == 'Rapport' && this.reportsDef.length == 0) {
                this.applicationActions.splice(i,1);
                i--;
            }

            if(this.applicationActions[i].Name != 'Calcul' || !this.currentTab.IsGridEntity) {
                this.displayApplicationActions.push(this.applicationActions[i]);
            }
        }
        this.actionBoutons = [];
        let hasCalculAvailable = false;

        if(this.displayApplicationActions.length > 0) {
            let stateAction: { Actions: string; RenderType: RenderTypeButton; LongName: any;TargetState: any; }[] = [];
            if(this.currentZone.StateSelector != 'Masquer')
                stateAction = this.displayApplicationActions.filter((th: { Name: string; }) => !(th.Name == 'Calcul' || th.Name == 'Rapport' || th.Name == 'Export' || th.Name == 'Import'))
            this.displayApplicationActions = this.displayApplicationActions.filter((th: { Name: string; }) => th.Name == 'Calcul' || th.Name == 'Rapport' || th.Name == 'Export' || th.Name == 'Import')
            var ctrl = this;
            let menuItems : any[] = [];
            for (var i = 0; i < this.displayApplicationActions.length; i++) {
                this.actionBoutons.push(this.displayApplicationActions[i]);
            }

            for (var j = 0; j < this.actionBoutons.length; j++) {

                var space = this.space;
                this.actionBoutons[j].Actions = this.actionBoutons[j].Actions.replace('(space)', this.space);
                this.actionBoutons[j].Actions = this.actionBoutons[j].Actions.replace('[    "(entitiesId)"  ]', '""');

                if(this.actionBoutons[j].Name !='Calcul') {
                    this.actionBoutons[j].Force = true;
                    menuItems.push({
                        text: this.actionBoutons[j].LongName,
                        Element: this.actionBoutons[j],
                        Control: this,
                        template: this.actionLegacyService.menuItemTemplate
                    });
                }
                else{
                    this.calculAction = this.actionBoutons[j];
                    hasCalculAvailable = true;
                }
            }

            if(stateAction.length > 0) {
                var ctrl = this;
                
                stateAction.forEach(function (val: {
                    TargetState: any; Actions: string; RenderType: RenderTypeButton; LongName: any; }) {
                    var subItem: any;
                    val.Actions = val.Actions.replace('[    "(entitiesId)"  ]', '""');
                    val.Actions = val.Actions.replace('(space)', space);

                    subItem = {
                        Element: val,
                        Control: ctrl,
                        template: ctrl.actionLegacyService.menuItemTemplate
                    };

                    if (val.RenderType != RenderTypeButton.Icone) {
                        subItem.text = val.LongName;
                        if(val.TargetState != ctrl.currentState.Id)
                            menuItems.push(subItem);
                    }
                });
            }
            setTimeout( ()=> {
                const actionSheet = $('#action-sheet').dxActionSheet({
                    dataSource: menuItems,
                    title: 'Action disponibles',
                    async onItemClick(event) {
                        let element = event.itemData.Element;

                        if (!element || element.Disabled || element.Blocked) return;
                        element.FormStatus = ctrl.currentState.Id;
                        switch (element.ActionType) {
                            case ActionTypeButton.API:
                                ctrl.actionLegacyService.parsingActionTypeApiApp(element, ctrl.space, parseFloat(ctrl.identifier), ctrl.entityCible, ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
                                break;
                            case ActionTypeButton.Javascript:
                                    await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(element,ctrl.space, parseFloat(ctrl.identifier), ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.entityCible, ctrl.reportsDef, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.exportsDef, ctrl.currentZone.WorkflowId, null, ctrl.currentProcedureAssociation);
                                break;
                        }
                    },
                }).dxActionSheet('instance');
                $('#menu-buttons').dxButton({
                    icon :'more',
                    onClick : function (event: any) {
                        actionSheet.show();
                    }
                }).dxButton('instance');
            }, 1000);
        }
        else {
            $('#menu-buttons').children('.dx-menu-horizontal').hide();
        }

        if(hasCalculAvailable)
        {
            $('#button-cal').dxButton({
                text: 'Mettre à jour',
                icon: 'refresh',
                async onClick() {
                    var action = ctrl.calculAction;
                    action.FormStatus = ctrl.currentState.Id;
                    switch (action.ActionType) {
                        case ActionTypeButton.API:
                            ctrl.actionLegacyService.parsingActionTypeApiApp(action, ctrl.space, parseFloat(ctrl.identifier), ctrl.entityCible, ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.currentZone.WorkflowId);
                            break;
                        case ActionTypeButton.Javascript:
                            await ctrl.actionLegacyService.parsingActionTypeJavascriptApp(action,ctrl.space, parseFloat(ctrl.identifier), ctrl.tabName, ctrl.tabRef, ctrl.tabStatus, ctrl.tabs, ctrl.entityCible, ctrl.reportsDef, ctrl.applicationName, ctrl.zoneName, ctrl.version, ctrl.exportsDef, ctrl.currentZone.WorkflowId, null, ctrl.currentProcedureAssociation);
                            break;
                    }
                },
              }).dxButton('instance');
            $('#button-cal').show();
        }
        else
        {
            $('#button-cal').hide();
        }
        var ctrl = this;
       let allowFormState = [];
       for (var i = 0; i < this.formStates.length; i++) {
        if(this.formStates[i].Id  == ctrl.currentState.Id)
            allowFormState.push(this.formStates[i]); 
        else
        {
            var action = this.displayApplicationActions.filter((th: { TargetState: string; }) => th.TargetState == this.formStates[i].Id)[0];
            if(action != null)
            {
                allowFormState.push(this.formStates[i]);
            }
        }
        }
        this.displayApplicationActions = this.displayApplicationActions.filter((th: { TargetState: string; }) => th.TargetState != ctrl.currentState.Id);

        await FrontOfficeToolbarFactory.setListChangeState(ctrl, allowFormState);
        await FrontOfficeToolbarFactory.setListActionsSelectBox(ctrl);
    }
}
