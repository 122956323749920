export class ListUtils {
    static listHasElements(items:any[]):Boolean {
        return items != null && items.length > 0;
    }

    static getOnlyActiveItems(items:any[]):any{
        if (this.listHasElements(items) && items[0].hasOwnProperty('IsActive')) {
            return items.filter((tb: { IsActive: boolean; }) => tb.IsActive);
        }
        else {
            return items;
        }
    }

    static getListItemByIdEnumerationItem(items:any[], idEnumerationItem:any):any {
        if (this.listHasElements(items)) {
            var filteredList = items.filter((x: { IdEnumerationItem: string; }) => x.IdEnumerationItem == idEnumerationItem);
            return this.listHasElements(filteredList) ? filteredList[0] : '';
        }
        else {
            return '';
        }
    } 
}
