import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import * as $ from 'jquery';
import { FrontOfficeFormService } from "../../shared-service/front-office-form.service";
import * as numeral from 'numeral';
import * as moment from "moment";
import { FrontOfficeStructService } from "../../../front-office-struct.service";
import { LocalStorageService } from "app/shared/local-storage.service";
import { __await } from "tslib";
import { Subscription, lastValueFrom } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { FrontOfficeFormFactory } from "../../shared-factory/front-office-form-factory";
import { StringUtils } from "app/utils/string-utils";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'front-office-form-mobile',
  templateUrl: './front-office-form-mobile.component.html',
  styleUrls: ['./front-office-form-mobile.component.css']
})

export class FrontOfficeFormMobileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formName: string = '';
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() space: string = '';
  @Input() applicationName: string = '';
  @Input() zoneName: string = '';
  @Input() moduleName: string = '';
  @Input() tabName: string = '';
  @Input() variante: string = '';
  @Input() entityIdentifier: string = '';
  @Input() multiTab: boolean;
  @Input() order: number = 0;
  @Input() currentEntity: any;
  @Input() currentZone: any;
  @Input() contextInfo: any[];
  @Input() stateId: string = '';
  @Input() currentProcedureAssociation: string = '';
  @Input() entityCible: string = '';
  blocWidth: string;
  blocType: string;
  maxItems:number = 0;
  popupVisible :boolean = false;
  popupTitle : string ='';
  updateAvailable: boolean = true;
  fullyLoaded: boolean = false;
  dictSub: any;
  storage : any;
  currentLine : any;
  groups:any[] = [];
  labels: string[];
  selectedRowIndex : string = '';
  sub!: Subscription;
  constructor(
    private applicationFormService: FrontOfficeFormService,
    private appStructService:  FrontOfficeStructService,
    public sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient) 
    {this.dictSub = {}; }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextInfo && this.groups.length > 0) {
      var tabName = this.tabName;
      if(this.multiTab && this.tabName.indexOf('ø') == -1) {
        tabName = this.moduleName+'/'+this.tabName;
      }
      var module = this.moduleName != undefined ? this.moduleName.length > 2 ? this.moduleName : '' : '';
      var onglet = tabName.includes("/") ? tabName.split("/").pop() : tabName.includes("ø") ? tabName.split("ø").pop() : tabName;
      for(var i = 0; i < this.groups.length; i++) {
        let contextInfo = this.contextInfo.filter((tb: { Module: String, Onglet: string, Bloc: string, Ligne: string; }) => tb.Module == module && tb.Onglet == onglet && tb.Bloc == this.order.toString() && tb.Ligne == this.groups[i].Numbering)[0];
        this.groups[i].ContextInfo = contextInfo;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.ngOnDestroy(); 
    this.storage = this.localStorageService.getWithExpiration(this.space);
    this.storage = JSON.parse(this.storage);
    if (this.storage != null){
        if(this.storage.Lists == null )
        this.storage.Lists = {};
    }
    var ctrl = this; 
    if(this.perimeter == "Space") {
      var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
      let formConfig = await lastValueFrom(ctrl.applicationFormService.getFormConfigByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName, tabName));
      ctrl.blocWidth = formConfig.Width;
      ctrl.blocType = formConfig.Template;

      await FrontOfficeFormFactory.setGroups(ctrl, formConfig);
      FrontOfficeFormFactory.setSortGroups(ctrl);
      formConfig.items = ctrl.groups;
      
      let indexNumber = 0;
      FrontOfficeFormFactory.setItemContextInfo(ctrl, true);

      for(var i = 0; i<formConfig.items.length;i++){
        for(var j = 0; j<formConfig.items[i].items.length;j++) {
          var item = formConfig.items[i].items[j];
          item.Reference = tabName.replace('/','ø')+':'+formConfig.items[i].Numbering;
          item.id = 'item_'+i+'_'+j;
          if(item.cssClass == 'title') {
            item.colSpan = 5;
          }

          if(item.cssClass.indexOf('numbering') != -1) {
            if(i!= 0) {
              formConfig.items[i].items[j-1].colSpan = formConfig.colCount-(i-indexNumber)+1;
              indexNumber = i;
            }
          }

          item.style ='width: max-content;'

          await FrontOfficeFormFactory.setDisableVisibleItem(ctrl, item);

          if(item.RenderType =='html') {
            FrontOfficeFormFactory.setItemHtml(item);
          }
        }
      }

      ctrl.localStorageService.setWithExpiration(ctrl.space, JSON.stringify(ctrl.storage), 'CallTimout');
      ctrl.fullyLoaded = true;          
      $('#loadpanel').dxLoadPanel('instance').hide();
      ctrl.sub = ctrl.appStructService.updateParent.subscribe((item:any) => {
        let group = ctrl.groups.filter((tb: { Numbering: string; }) => tb.Numbering == item.Ligne)[0];

        if (group.contextInfo == null) {
          group.ContextInfo = {
            HasComment : false,
            HasDocument : false
          };
        }

        if(item.Type == 'comments')
          group.ContextInfo.HasComment = item.Value;
        else
          group.ContextInfo.HasDocument = item.Value;

        ctrl.appStructService.updateFormData.emit('ok')
      });
    }
  }

  async addElement(item: any, formData:any, ctrl : any){
    var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);

    if(item.editorOptions.disabled)
    {
      if(formData[0][item.dataField] == null )
        return;

      $('#'+item.id).empty()
      if(item.editorType == 'dxNumberBox' && item.editorOptions.format != '')
        $('#'+item.id).append("<span>"+numeral(formData[0][item.dataField]).format(item.editorOptions.format)+"</span>");
      else if(item.editorType == 'dxDateBox' && item.editorOptions.displayformat != '') {
        item.editorOptions.displayFormat = item.editorOptions.displayFormat.replace('dd', 'DD')
        $('#'+item.id).append("<span>"+moment(formData[0][item.dataField]).format(item.editorOptions.displayFormat)+"</span>");
      }
      else if(item.editorType == 'dxSelectBox')
        $('#'+item.id).append("<span>"+formData[0][item.dataField.replace('.IdEnumerationItem','')][item.list.indexOf('#1') != -1 ? "Code" : "Label"]+"</span>");
      else
        $('#'+item.id).append("<span>"+formData[0][item.dataField]+"</span>");
    }
    else if (ctrl.currentModule.IsModuleUser && item.dataField =='Organisation') {
      FrontOfficeFormFactory.setOrganisationItem(ctrl, item, formData);
    }
    else if (ctrl.currentModule.IsModuleUser && item.dataField =='RolePrincipal') {
      FrontOfficeFormFactory.setRolePrincipalItem(ctrl, item, formData);
    }
    else {
      FrontOfficeFormFactory.setNonDisableItemEditorOptions(ctrl, item, formData, tabName);
    }
  }

  updateContext(group : any) {
    this.currentLine = group;
    this.labels = [];
    let found = false;
    for(var i = this.groups.length-1;i>=0;i--) {
      if(group.Name == this.groups[i].Name) {
        found = true;
      }

      if(found && this.groups[i].items.length > 0 && this.groups[i].items[0].dataField.indexOf("Const") > -1) {
        if(group.items.length == this.groups[i].items.length) {
          for(var j = 0; j<this.groups[i].items.length;j++) {
            this.labels.push(this.groups[i].items[j].dataField)
          }
        }
        else {
          i=0;
        }
      }
    }
    this.popupTitle = group.Numbering + ' : '+ StringUtils.decodeSpecialEntitiesHtml(group.Label)
    this.popupVisible = true
  }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }
}