
 import { AppService } from './app.service';
 import { LocalStorageService } from './shared/local-storage.service';
 import { AuthService } from './shared/auth.service';
 import { Component, OnInit } from '@angular/core';
 import * as $ from 'jquery';
 import { DeviceDetectorService } from 'ngx-device-detector';
 import { lastValueFrom } from 'rxjs';
 import { HomePageService } from './home-page/home-page.service';
 declare var require: (e: any) => any;
import "devextreme/localization/globalize/number";
import "devextreme/localization/globalize/date";
import "devextreme/localization/globalize/currency";
import "devextreme/localization/globalize/message";
 
// Dictionaries for the German language
import deMessages from "devextreme/localization/messages/fr.json";
 
import Globalize from "globalize";
import * as numeral from 'numeral';
@Component({
    selector: 'pm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    pageTitle = 'PHMS BO';
    version = ''; 
    footColor = '';
    public isCollapsed:boolean = true;
    public showFooter:boolean = true;
    isMobile: boolean = true;
    public hasValidationMode : boolean = false;
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private spaceService: HomePageService,
        private localStorageService: LocalStorageService,
        private deviceService: DeviceDetectorService
    ) {
        Globalize.load(  
            require('devextreme-cldr-data/fr.json')
          );  
        
          const json = <any>{ main: {} };  
          json['main']['fr'] = { 'numbers': { 'symbols-numberSystem-latn': { 'group': "'",'decimal': "." } } };   
          Globalize.load(json);  
        
          Globalize.locale('fr');  

          numeral.register('locale', 'fr', {
            delimiters: {
                thousands: '\'',
                decimal: '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal : function (number) {
                return number === 1 ? 'er' : 'ème';
            },
            currency: {
                symbol: '€'
            }
        });
        
        // switch between locales
        numeral.locale('fr');
     Globalize.loadMessages(deMessages);
        this.isMobile = this.deviceService.isMobile();
        this.showFooter = true;
    }
 
    onRowExpanded(e: { component: any; key: any; }) {
        let grid = e.component;
        let rowIndex = grid.getRowIndexByKey(e.key);
        let rowElement = grid.getRowElement(rowIndex);
        let scrollable = grid.getScrollable();
    
        scrollable.scrollTo({
          left: scrollable.scrollLeft(),
          top: rowElement[0].offsetTop
        });
      }

      async loadVersion(){
            let infos = await lastValueFrom(this.appService.getAssemblyVersion())
            this.version = infos.AssemblyVersion
      }

    async ngOnInit(): Promise<void> {
        try
        {
            await this.initFunction();
        }
        catch
        {
            setTimeout(async () => {
                await this.initFunction();
            }, 100);
        }
        
    }

   async initFunction()
   {
    let configApp = await lastValueFrom(this.appService.getConfiguration());
    await this.loadVersion();    
    this.localStorageService.remove("SPAConfiguration");
    this.localStorageService.set("SPAConfiguration", configApp)
    var ctrl = this;
    this.footColor = configApp.FootBackGroundColor;
    this.hasValidationMode = await lastValueFrom(this.spaceService.hasValidationMode());
    var modeValidation =  this.localStorageService.get("ModeTravail");
    if(modeValidation == null)
        modeValidation = false;
    var text = 'Mode Travail';
    if(modeValidation)
    text = 'Mode validation';
    $("#modeValidation").dxCheckBox({
        text: text,
        value: modeValidation,
        onValueChanged: function (e) {
            if(e.value)
                $("#modeValidation").dxCheckBox('instance').option('text', 'Mode validation')
            else
                $("#modeValidation").dxCheckBox('instance').option('text', 'Mode travail')
            ctrl.localStorageService.set("ModeTravail", e.value);
            ctrl.spaceService.changeModeAlert.emit(e.value)
        }
    });
   }

    logout() { this.clearStorage(); this.authService.logout(); }
    
    reload() { window.location.reload(); }
    clearStorage() { localStorage.clear();sessionStorage.clear(); }

    logoutExternally() {
        this.clearStorage(); 
        window.open(this.authService.logoutUrl);
    }

    get hasValidToken() { return this.authService.hasAccessToken(); }
    get accessToken() { return this.authService.accessToken; }
    get refreshToken() { return this.authService.refreshToken; }
    get identityClaims() { return this.authService.identityClaims; }
    get idToken() { return this.authService.idToken; }
}
