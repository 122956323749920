import { Component, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import * as moment from "moment";
import { LocalStorageService } from "app/shared/local-storage.service";
import { EntityService } from "app/shared/entity.service";
import { FrontOfficeFormService } from "app/front-office/desktop-mobile/shared-service/front-office-form.service";
import * as numeral from "numeral";
import { IEntity } from "app/shared/entity";
import DataSource from "devextreme/data/data_source";
import { FrontOfficeStructService } from "app/front-office/front-office-struct.service";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'panel-detail-etab',
    templateUrl: './panel-detail-etab.component.html',
    styleUrls: ['./panel-detail-etab.component.css', './panel-detail-etab-mobile.component.css']
})

@Injectable({ providedIn: 'root' })
export class PanelDetailEtabComponent implements OnInit, OnDestroy {
  @Input() headerBlocs: any[] = [];
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() isMobile: boolean = false;
  @Input() variante: string = '';
  @Input() tabName: string = '';
  @Input() entityIdentifier: string = '';
  @Input() moduleName: string = '';
  @Input() moduleMainName: string = '';
  @Input() multiTab: boolean;
  @Input() space: string = '';
  @Input() zoneName: string = '';
  @Input() fullyLoad: boolean;
  @Input() headerName: string = 'Header';
  @Input() entityCible: string;
  @Input() applicationName: string = '';
  @Input() cleanHeader: boolean = false;
  @Input() currentProcedureAssociation: string = '';
  displayBlocs: any[] = [];
  formName: string = '';
  blocWidth: string;
  blocType: string;
  maxItems:number = 0;
  fullyLoaded: boolean = false;
  storage : any;
  zoneNameUse: string = '';
  groups:any[] = [];
  currentProc: IEntity;
  idEntityUse : string = '';
  constructor(
    private applicationFormService: FrontOfficeFormService,
    private entityService: EntityService,
    private router: Router,
    private applicationStructService: FrontOfficeStructService,
    private localStorageService: LocalStorageService
  ) {}

  async ngOnChanges(changes: any) {

    var ctrl = this;

    if ((changes.headerBlocs && ctrl.headerBlocs != null && ctrl.headerBlocs.length > 0)) {
      await ctrl.loadPanel();
    }
    
    if (changes.entityIdentifier) {
      await ctrl.loadPanel();
    }
    
    if (changes.cleanHeader && ctrl.cleanHeader) {
      await ctrl.loadPanel();
    }

    if (changes.fullyLoad && ctrl.fullyLoad && ctrl.headerBlocs.length == 0) {
      ctrl.hideHeaderStructPanel();
    }
  }

  async ngOnInit(): Promise<void> {
      this.ngOnDestroy(); 
  }

  async loadPanel(): Promise<void> {
    this.storage = this.localStorageService.getWithExpiration(this.space);
    this.storage = JSON.parse(this.storage);
    if (this.storage != null)
      if(this.storage.Lists == null )
        this.storage.Lists = {};
    
    var ctrl = this;
    if(StringUtils.isMainEntity(this.entityCible)) {
      if(this.headerBlocs == null || this.headerBlocs.length == 0 || this.entityCible == null || this.variante.length == 0 ) {
        return;
      }

      this.displayBlocs = this.headerBlocs;
      this.idEntityUse = this.entityIdentifier;
      this.formName = this.headerBlocs[0].Name;

      if(this.perimeter == "Space") {
        let formData = await lastValueFrom(ctrl.applicationFormService.getFormDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName));
        let formConfig = await lastValueFrom(ctrl.applicationFormService.getFormConfigByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.formName, ctrl.tabName));

        if(this.moduleMainName != null && this.moduleMainName.length > 0 && this.moduleMainName != this.moduleName) {
          formData = await lastValueFrom(ctrl.applicationFormService.getFormDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleMainName, ctrl.tabName, ctrl.formName));
          formConfig = await lastValueFrom(ctrl.applicationFormService.getFormConfigByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, ctrl.moduleMainName, ctrl.tabName, ctrl.formName, ctrl.tabName));
        }

        if (formData == undefined || formData.length == 0) {
          ctrl.hideHeaderStructPanel();
          $('#navigation-list-etab').css('height', 'calc(98vh - 76px)')
          return;
        }
        
        ctrl.blocWidth = formConfig.Width;
        ctrl.blocType = formConfig.Template;
        ctrl.groups = [];
        if(!this.isMobile){
          ctrl.showHeaderStructPanel();
        }

        for(var i = 0; i<formConfig.items.length; i++) {
          let bloc = formConfig.items[i];
          var procsAvailable =  bloc.AvailableProcedureAssociations.split('|');
          var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
            return procsAvailable.some((f : string) => { return f === tb; })
          });

          if((bloc.Variante == "RfcCommun" || bloc.Variante == this.variante) && bloc.items.length > 0 && ctrl.groups.filter((tb: { Name: string; }) => tb.Name == bloc.Name).length == 0 && (tempProcAsso.length > 0)) {
            ctrl.groups.push(bloc);
          }
        }

        formConfig.items = ctrl.groups;
        let indexNumber = 0;
        for(var i = 0; i<ctrl.groups.length;i++) {
          if(ctrl.groups[i].items.length > this.maxItems)
            this.maxItems = ctrl.groups[i].items.length;
          ctrl.groups[i].Index = i;
        }

        for(var i = 0; i<formConfig.items.length;i++) {
          for(var j = 0; j<formConfig.items[i].items.length;j++) {
            var item = formConfig.items[i].items[j];
            item.id = 'item_header_'+i+'_'+j;
            if(item.cssClass == 'title') {
              item.colSpan = 5;
            }

            item.style +='display: inline-block;'
            if(item.cssClass.indexOf('numbering') != -1) {
              if(i!= 0) {
                formConfig.items[i].items[j-1].colSpan = formConfig.colCount-(i-indexNumber)+1;
                indexNumber = i;
              }
            }
            item.Visible = true;

            if(item.RenderType =='html') {
              item.editorOptions.imageUpload= {
                tabs: ['file', 'url'],
                fileUploadMode: 'base64',
              };

              item.editorOptions.toolbar = {
                items: [
                  'undo', 'redo', 'separator', 
                  {
                    name: 'size',
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
                  },
                  {
                    name: 'font',
                    acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
                  },
                  'separator', 'bold', 'italic', 'strike', 'underline', 'separator',
                  'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator',
                  'orderedList', 'bulletList', 'separator',
                  {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5],
                  }, 'separator',
                  'color', 'background', 'separator',
                  'link', 'image', 'separator',
                  'clear', 'codeBlock', 'blockquote', 'separator',
                  'insertTable', 'deleteTable',
                  'insertRowAbove', 'insertRowBelow', 'deleteRow',
                  'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
                ],
              };
              item.editorOptions.mediaResizing = {
                enabled: true,
              };
            }
          }
        }
        setTimeout(async () => {
          for(var i = 0; i<formConfig.items.length;i++) {
            for(var j = 0; j<formConfig.items[i].items.length;j++) {
              var item = formConfig.items[i].items[j];
              await ctrl.addElement(item, formData);
            }
          }
          ctrl.localStorageService.setWithExpiration(ctrl.space, JSON.stringify(ctrl.storage), 'CallTimout');
          if(!this.isMobile)
            ctrl.fullyLoaded = true;
        }, 50);
      }
    }
    else {
      this.displayBlocs = this.headerBlocs;
      if(!this.isMobile) {
        ctrl.hideHeaderStructPanel();
      }
    }

    if(this.isMobile) {
      let entities: IEntity[] =[];
      if(this.zoneNameUse != this.zoneName) {
        this.zoneNameUse = this.zoneName;
        if(StringUtils.isMainEntity(this.entityCible)) {
          this.currentProc  = await lastValueFrom(this.entityService.getProcOfEntity(this.space, this.entityIdentifier, this.applicationName));
          entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(this.space, this.currentProc.IdObject, this.entityCible, this.applicationName));
          entities = entities.sort((a, b) => parseInt(a['Name'].match(/^[0-9]+/)[0]) > parseInt(b['Name'].match(/^[0-9]+/)[0]) ? 1 : parseInt(a['Name'].match(/^[0-9]+/)[0]) === parseInt(b['Name'].match(/^[0-9]+/)[0]) ? 0 : -1)
        }
        else {
          entities = await lastValueFrom(this.entityService.getListEntityByEntityType(this.space, this.entityCible, this.applicationName));
          entities = entities.sort((a, b) => a['LongName'].split(':')[1] > b['LongName'].split(':')[1] ? 1 : a['LongName'].split(':')[1] === b['LongName'].split(':')[1] ? 0 : -1)
        }

        var ds = new DataSource({  
          store: entities,   
          paginate: false  
        }); 

        setTimeout (()=> {
          $('#navigation-list-etab-mobile').dxList({
            dataSource: ds,
            width:'400px',
            height:'40vh',
            displayExpr: 'LongName',
            searchEnabled: true,
            searchExpr: ['LongName'],
            itemTemplate(itemData) {
              return $("<div />").css("padding", "5px").addClass(itemData.IdObject == ctrl.entityIdentifier ? "module-selected" : "module-non-selected").append($("<p />").html(StringUtils.isMainEntity(ctrl.entityCible) ? itemData.LongName : itemData.LongName.split(':')[1]).css("display", "inline-block"));
            },
            onItemClick: async function (data:any) {
              let modules = await lastValueFrom(ctrl.applicationStructService.getStructOfZone(ctrl.applicationName, ctrl.version, ctrl.zoneName));
              var selectedModule: {
                ModuleName: any; Name: string; 
              } = null;
              var i =0;

              do {
                var procsAvailable =  modules.Modules[i].AvailableProcedureAssociations.split('.');
                var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                  return procsAvailable.some((f : string) => { return f === tb; })
                });
                modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                  if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && data.itemData.EntityType == modules.Modules[i].EntityCible && tempProcAsso.length > 0) {
                    selectedModule = modules.Modules[i];
                  }
                });
                i++;
              }

              while(selectedModule == null && i<modules.Modules.length);
                let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                let tabs = await lastValueFrom(ctrl.applicationStructService.getStructOfModule(ctrl.applicationName, ctrl.version, ctrl.zoneName, moduleName));
                var selectedtab: { Name: string; } = null;
                var i = 0;
                do
                {
                  var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                  var tempProcAsso = ctrl.currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => { return f === tb; })
                  });
                  tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if((allowVariant == "RfcCommun" || allowVariant == ctrl.variante) && !tabs.Tabs[i].IsGridEntity && tempProcAsso.length > 0) {
                      selectedtab = tabs.Tabs[i];
                    }
                  });
                  i++;
                }
              while(selectedtab == null && i<tabs.Tabs.length);
              ctrl.router.navigate(['/app/' + ctrl.applicationName+'/'+ ctrl.zoneName+'/'+ moduleName+'/'+ data.itemData.IdObject+'/'+ selectedtab.Name]);
            }
          }).dxList('instance')
          ctrl.fullyLoaded = true;
        },500);
      }
    }
  }

  async addElement(item: any, formData:any){
    $('#'+item.id).empty()
    var dataField = item.dataField.replace('.IdEnumerationItem','');
    if(formData == undefined || formData.length == 0 || formData[0][dataField] == undefined || typeof(formData[0][dataField]) === "undefined" || formData[0][dataField] == null || formData[0][dataField] == '') {
      return;
    }

    if(item.editorType == 'dxNumberBox' && item.editorOptions.format != '') {
      $('#'+item.id).append("<span>"+numeral(formData[0][dataField]).format(item.editorOptions.format)+"</span>");
    }
    else if(item.editorType == 'dxDateBox' && item.editorOptions.displayformat != '') {
      item.editorOptions.displayFormat = item.editorOptions.displayFormat.replace('dd', 'DD')
      $('#'+item.id).append("<span>"+moment(formData[0][dataField]).format(item.editorOptions.displayFormat)+"</span>");
    }
    else if(item.editorType == 'dxSelectBox') {
      let label = formData[0][dataField].Label;
      $('#'+item.id).append("<span>" + typeof(label) !== "undefined" && label !== null ? label : formData[0][dataField] + "</span>");
    }
    else if(formData[0][dataField] != null) {
      var text = formData[0][dataField].replace(':','');
      text = text.replace('https','')
      text = text.replace('http','')
      $('#'+item.id).append("<span>"+text+"</span>");
    }
    else {
      $('#'+item.id).append("<span></span>");
    }
  }

  ngOnDestroy(): void {}

  headerSortBy(prop: string) {
    return this.groups.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  showHeaderStructPanel() {
    $('#headerStructPanel').show();
    $('#panelListEtab').css('top', 'calc(70vh - 102px)')
    $('#panelListEtab').css('height', '34vh')
  }

  hideHeaderStructPanel() {
    $('#headerStructPanel').hide();
    $('#panelListEtab').css('top', '3px')
    $('#panelListEtab').css('height', 'calc(100vh - 0px)')
  }
}