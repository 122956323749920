import { HttpClient } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IEntity } from "app/shared/entity";
import * as $ from 'jquery';
import { Subscription, lastValueFrom } from "rxjs";
import { FrontOfficeGridService } from "../../../front-office-grid.service";
import { FrontOfficeStructService } from "../../../front-office-struct.service";
import { FrontOfficeGridFactory } from "../../shared-factory/front-office-grid-factory";
import { StringUtils } from "app/utils/string-utils";

@Component({
    selector: 'front-office-grid-mobile',
    templateUrl: './front-office-grid-mobile.component.html',
    styleUrls: ['./front-office-grid-mobile.component.css']
})
export class FrontOfficeGridMobileComponent implements OnInit, OnDestroy {
  @Input() gridName: string = '';
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() space: string = '';
  @Input() applicationName: string = '';
  @Input() zoneName: string = '';
  @Input() moduleName: string = '';
  @Input() tabName: string = '';
  @Input() entityIdentifier: string = '';
  @Input() information: string = '';
  @Input() showInformation: boolean = false;
  @Input() isGridEntity: boolean = false;
  @Input() showComment: boolean = false;
  @Input() showFile: boolean = false;
  @Input() currentProc: IEntity;
  @Input() extension: string = '';
  @Input() docCode: string = '';
  @Input() maxDoc: number = 0;
  @Input() currentEntity: any;
  @Input() maxSizePerDoc: number = 0;
  @Input() commentLevel: number = 0;
  @Input() documentLevel: number = 0;
  @Input() state: any;
  @Input() currentStateTab: any;
  @Input() subPerimeter: string = '';
  @Input() subPerimeterDoc: string = '';
  stateId: string = '';
  subPattern : string = '';
  filterErrorsRows: boolean = false;
  showLineOnError: boolean = false;
  sub!: Subscription;
  editiontabs: any[] = [];
  childtabs: any[] = [];
  allColumns : any[] = [];
  dictSub: any;
  popupVisible :boolean = false;
  popupTitle : string ='';
  currentLine : any;
  codeListName : string = '';
  refreshTab: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private appStructService:  FrontOfficeStructService,
    private applicationGridService: FrontOfficeGridService) 
    { this.dictSub = {}; }

  async ngOnInit(): Promise<void> {
    this.ngOnDestroy(); 
    FrontOfficeGridFactory.setPopupMouseLeave();

    FrontOfficeGridFactory.setStorage(this);

    var ctrl = this; 
    this.stateId = this.state != null ? this.state.Id : 'ready';

    if(this.perimeter == "Space")
    {
      if(!this.isGridEntity) {
        var applicationActions = await lastValueFrom(this.appStructService.getActionButton(this.applicationName,this.version, this.zoneName, this.moduleName, this.tabName.indexOf('/') == -1 ? this.tabName : this.tabName.split('/')[1], this.state.Id, this.currentStateTab.Id));
        var calc:any = applicationActions.filter((tb: { Name: string; }) => tb.Name == 'Calcul')[0];
        this.showLineOnError = calc == null ? false : calc.DisplayErrorTab;
      }

      if(this.showLineOnError && !this.isGridEntity) {
        $('.add-check-button-mobile').dxCheckBox({
          text: 'Lignes en erreur',
          value:ctrl.filterErrorsRows,
          onValueChanged(e :any) {
            const newValue = e.value;
            let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
            ctrl.filterErrorsRows = newValue == false ? false : true;
            grid.repaint();
          },
        })
      }
      this.sub = ctrl.appStructService.updateParent.subscribe((item:any) => {
        var grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
        var index = grid.getRowIndexByKey(ctrl.entityIdentifier+'_'+item.Ligne);
        let rows = grid.getVisibleRows();  
        let row = rows[index];

        if ((<any>row.data).ContextInfo == null) {
          (<any>row.data).ContextInfo = {
            HasComment : false,
            HasDocument : false
          };
        }

        if(item.Type == 'comments')
          (<any>row.data).ContextInfo.HasComment = item.Value;
        else
          (<any>row.data).ContextInfo.HasDocument = item.Value;

        ctrl.appStructService.updateFormData.emit('ok')
        grid.refresh();
      });
        
      let gridConfig = await lastValueFrom(this.applicationGridService.getGridConfigByEntityId(this.space,this.version, this.applicationName, this.entityIdentifier, this.zoneName, this.moduleName, this.tabName, this.gridName));
      let canAdd = gridConfig.editing.allowAdding;
      gridConfig.width='100%';
      gridConfig.editing.allowAdding = false;
      gridConfig.columnAutoWidth = false;
      this.allColumns = gridConfig.columns;
      gridConfig.columns = gridConfig.columns.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => a['visibleIndex'] > b['visibleIndex'] ? 1 : a['visibleIndex'] === b['visibleIndex'] ? 0 : -1);
      
      let masterColumns: any[] = gridConfig.columns.filter((tb: { isIncludedInMaster: boolean; }) => tb.isIncludedInMaster == true);
      let specificColumns: any[] = [];
      let gridColumns = [];
      ctrl.editiontabs = [];    

      for(var i = 0; i<gridConfig.columns.length;i++) {
        var column = gridConfig.columns[i];
        if(column.caption.indexOf('$$') != -1)
          specificColumns.push(column);
        if(column.isIncludedInDataGrid || column.type =='command')
          gridColumns.push(column);
        if(column.dataField.indexOf('Code')>-1)
          ctrl.codeListName = column.format;
        
        if(column.isIncludedInDataGrid) {
          FrontOfficeGridFactory.setColumnIsIncludedInDatagridOrMaster(ctrl, column);
        }
      }

      for(var i = 0; i<gridConfig.columns.length;i++) {
        let item = gridConfig.columns[i];
        item.dataType = item.dataType == "text" ? 'string' : item.dataType;

        if(item.dataField.indexOf('IdEnumerationItem') > 0) {
          FrontOfficeGridFactory.setCellEnumerationItem(ctrl, item);
        }

        else if (item.dataField == 'Organisation' && item.allowEditing) {
          var url = './api/v1/application/'+ctrl.space+'/availableOrganisation/vue.json';
          let param = { Parameters : '' }
          var items =  await lastValueFrom(ctrl.httpClient.post(url, param));
          item.allowSorting= false;
          item.allowEditing = false;
          
          item.cellTemplate= function (container: any, options: any) {
            container.dxAutocomplete({
              dataSource:items,
              showClearButton: false,
              searchExpr: ['Val', 'Type'],
              placeholder: 'Veuillez choisir une organisation',
              valueExpr: 'Key',
              value:options.value,
              itemTemplate(data: { Val: any; Type: any; }) { return $(`<div>${data.Val} (${data.Type})</div>`); },
              onItemClick: async function (data:any) { await FrontOfficeGridFactory.onItemClickOrganisationChoice(ctrl, data, options); }
            });
          }     
        }
        else {
          item.dataField = item.dataField.split('.')[item.dataField.split('.').length-1];
        }

        await FrontOfficeGridFactory.setDictSub(ctrl, item);

        if(this.isGridEntity && item.visible)
          item.allowEditing = true;
        if(item.link && item.link.length > 0)
          ctrl.subPattern = item.link;
        if(item.dataField == '' || item.dataField == 'Disabled')
          item.visible = false;
        if(item.isRequired && item.visible)
          item.validationRules= [{ type: "required" }];
      }
      
      var firstVisible = gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible == true)[0];
    
      gridConfig.columns = [];
      firstVisible.width = '85%'
      gridConfig.columns.push(firstVisible)

      if (this.isGridEntity) {
        canAdd = true;
        gridConfig.columns.push(
        {
          type: 'buttons',
          width: 110,
          allowEditing : false,
          buttons: [
            {
              hint: 'Context',
              template: '<i class="fa-solid fa-circle-info"></i>',
              onClick(e:any) {
                if(ctrl.subPattern != '')
                  ctrl.router.navigate(['/app/' + ctrl.applicationName + '/' + ctrl.zoneName+ '/' + ctrl.moduleName+ '/' + e.row.key + '/'+ctrl.subPattern]);
                e.event.preventDefault();
              },
            },{
            hint: 'Supprimer',
              template: '<i class="fa-solid fa-trash"></i>',
              onClick(e:any) {
                FrontOfficeGridFactory.deleteRow(ctrl, e.row.data.Id, e.row.data.Id, true, 'refresh');
                e.event.preventDefault();
              },
          },
          {
            hint: 'Activer',
              template: '<i class="fa-solid fa-unlock"></i>',
              visible(e:any) {
                return e.row.data.Disabled != null && e.row.data.Disabled == true;
              },
              onClick(e:any) {
                var tabName6 = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
                e.row.data.Disabled = false;
                ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName6, e.row.data).subscribe({
                  next: async config => {
                    setTimeout(async ()=>
                    await FrontOfficeGridFactory.refreshData(ctrl), 200);
                },
                error: err => console.log(err)
              });
                e.event.preventDefault();
              },
          },
          {
            hint: 'Désactiver',
              template: '<i class="fa-solid fa-ban"></i>',
              visible(e:any) {
                return e.row.data.Disabled != null && e.row.data.Disabled == false;
              },
              onClick(e:any) {
                var tabName6 = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
                e.row.data.Disabled = true;
                ctrl.applicationGridService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName,
                  ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName6, e.row.data).subscribe({
                  next: async config => {
                    setTimeout(async ()=>
                    await FrontOfficeGridFactory.refreshData(ctrl), 200);
                },
                error: err => console.log(err)
              });
                e.event.preventDefault();
              },
          }
        ],
        });
      }
      else {
        if(gridConfig.editing.canDelete && ctrl.subPerimeter != '' && (this.currentEntity == null || !this.currentEntity.IsSpecial))
          gridConfig.editing.canDelete = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'D');
        if(gridConfig.editing.allowAdding && ctrl.subPerimeter != ''&& (this.currentEntity == null || !this.currentEntity.IsSpecial))
          gridConfig.editing.allowAdding = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'C');
        if(gridConfig.editing.canDelete && ctrl.subPerimeter != '' && this.currentEntity != null && this.currentEntity.IsSpecial)
          gridConfig.editing.canDelete = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'DSub');
        if(gridConfig.editing.allowAdding && ctrl.subPerimeter != ''&& this.currentEntity != null && this.currentEntity.IsSpecial)
          gridConfig.editing.allowAdding = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, ctrl.subPerimeter, ctrl.stateId, 'CSub');
        
        if(gridConfig.editing.canDelete) {
          gridConfig.columns.push({
            type: 'buttons',
            width: 80,            
            allowEditing : false,
            buttons: [
              {
                hint: 'Supprimer',
                template: '<i class="fa-solid fa-trash"></i>',
                onClick(e:any) {
                  FrontOfficeGridFactory.deleteRow(ctrl, e.row.key, ctrl.entityIdentifier, true, 'ok');
                  e.event.preventDefault();
                },
              }, 
            ],
          });
        }
      }

      if (canAdd) {
        $('#action-add').dxButton({
          text: 'Ajouter une ligne',
          onClick() {
            let grid = $('#grid-'+ctrl.gridName).dxDataGrid('instance');
            grid.addRow();
            grid.deselectAll();
            setTimeout(() => {
              let rows = grid.getVisibleRows(); 
              var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
              let row = rows[0];

              if (ctrl.isGridEntity) {
                if(row.data.Disabled)
                row.data.Disabled = false;
                  ctrl.applicationGridService.insertAttributEntity(ctrl.space,ctrl.version, ctrl.applicationName,ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, row.data, ctrl.entityIdentifier, ctrl.isGridEntity).subscribe({
                    next: async config => {
                      await FrontOfficeGridFactory.refreshData(ctrl);
                      ctrl.appStructService.updateFormData.emit('refresh')
                    },
                  error: err => console.log(err)
                });
              }
              else {
                row.data.Id = FrontOfficeGridFactory.generateUUID();
                ctrl.applicationGridService.insertAttribut(ctrl.space,ctrl.version, ctrl.applicationName,
                  ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName, tabName, row.data, ctrl.entityIdentifier).subscribe({
                  next: () => {
                  ctrl.appStructService.updateFormData.emit('refresh')
                  setTimeout(async () => { await FrontOfficeGridFactory.refreshData(ctrl); }, 1000);
                },
                error: err => console.log(err)
                });
              }
            }, 100);
          },
        }).dxButton('instance')
      }
      gridConfig.onSaved = async (event : any)=> { await FrontOfficeGridFactory.gridSaved(ctrl, event, false); }
      var gridEvent = {
        onCellPrepared: function (e: any) {
          if (e.column.style && e.column.style !== "") {
              var styles = e.column.style.split(';')
              styles.forEach((style: string) => {
                var attributs = style.split(':');
                e.cellElement.css(attributs[0], attributs[1]);
              });
          }
        },
        onCellClick: function (e: any){
          if(e.rowType =="data" && !e.column.buttons) {
            ctrl.updateContext(e.data, StringUtils.getTabNameWithConsiderMultiTab(ctrl))
          }
        },
        onRowPrepared: function (e: any) {
          if(e.data && e.data.LectureSeul === true){
            e.rowElement.addClass("lecture-seul");
          }
          if(e.data && ((e.data.ContextInfo != null && e.data.ContextInfo.Level == 2) || (ctrl.commentLevel == 3 && (e.data.ContextInfo == null || (!e.data.ContextInfo.HasComment))) || (ctrl.documentLevel == 3 && (e.data.ContextInfo == null || (!e.data.ContextInfo.HasComment))))) {
            e.rowElement.addClass("error");
          }
          if(e.data && ((e.data.ContextInfo != null && e.data.ContextInfo.Level == 1 && ((ctrl.commentLevel == 2 && !e.data.ContextInfo.HasComment) || (ctrl.documentLevel == 2 && !e.data.ContextInfo.HasDocument))))) {
            e.rowElement.addClass("warning");
          }

          if(ctrl.filterErrorsRows && e.data && e.data.ContextInfo != null && (e.data.ContextInfo.Level == 1 || e.data.ContextInfo.Level == 2))
            e.rowElement.show();
          else  if(e.rowType =="data")
            e.rowElement.hide();
          if(!ctrl.filterErrorsRows)
            e.rowElement.show();
        },
        onInitNewRow: function(info : any) { FrontOfficeGridFactory.gridInitNewRow(ctrl, info); },
        onEditingStart: function (e: any) {  if (e.data && e.data.LectureSeul === true) { e.cancel = true; } },
        onFocusedCellChanging: function(e: any){ FrontOfficeGridFactory.gridFocusedCellChanging(e); },
      };
      if(specificColumns.length == 0) {
          if(masterColumns.length > 0) {
            ctrl.configMaster(masterColumns, ctrl);
          }
          var procID = this.isGridEntity && this.currentProc.IdObject != null ? this.currentProc.IdObject : 0;
          let gridData = await lastValueFrom(this.applicationGridService.getGridDataByEntityId(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName));
          FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);

          if((gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') == -1)) {
            var visibleColumns = gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible);
            for(var i = 0; i<visibleColumns.length;i++) {
              visibleColumns[i].width = 100/visibleColumns.length+'%';
            }
          }
          else if((gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfig.columns.filter((tb: { style: string; visible: boolean; }) => tb.visible && (tb.style == '' ||   tb.style.indexOf('width') == -1)).length > 0)) {
            var visibleColumns = gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible);
            for(var i = 0; i<visibleColumns.length;i++) {
              var style = visibleColumns[i].style;
              if(style) {
                style = style.substring(style.indexOf('width'))
                let width = style.split(';')[0];
                visibleColumns[i].style = style.replace(width+';','');
              }
              visibleColumns[i].width = 100/visibleColumns.length+'%';
            }
          }
          else if(gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].width == null && gridConfig.columns.filter((tb: { visible: boolean; }) => tb.visible)[0].style.indexOf('width') != -1) {
            FrontOfficeGridFactory.configureGridColumnsWidthFromGridConfigColumnWidth(gridConfig.columns);
          }

          let grid = $('#grid-'+ctrl.gridName).dxDataGrid(<string>gridConfig).dxDataGrid(gridEvent).dxDataGrid('instance');
          grid.option("dataSource", gridData);
          grid.option("editing.mode", "cell")
          grid.refresh();
          $('#loadpanel').dxLoadPanel('instance').hide();
      }
      else {
        if(masterColumns.length > 0) {
          ctrl.configMaster(masterColumns, ctrl);
        }

        await FrontOfficeGridFactory.setColumnsCaptions(ctrl, specificColumns);

        var procID = this.isGridEntity && this.currentProc.IdObject != null ? this.currentProc.IdObject : 0;
        let gridData = await lastValueFrom(this.applicationGridService.getGridDataByEntityId(ctrl.zoneName,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.stateId, procID, ctrl.zoneName, ctrl.moduleName, ctrl.tabName, ctrl.gridName))
        
        FrontOfficeGridFactory.setGridContextInfo(ctrl, gridData);
        FrontOfficeGridFactory.configureGridColumnsWidthWithSpecificColumns(gridConfig);

        let grid = $('#grid-'+ctrl.gridName).dxDataGrid(<string>gridConfig).dxDataGrid(gridEvent).dxDataGrid('instance');
        grid.option("dataSource", gridData);
        grid.option("editing.mode", "cell")
        grid.refresh();        
        $('#loadpanel').dxLoadPanel('instance').hide();
      }
    }
    
    $('#loadpanel').dxLoadPanel('instance').hide();
  }

  updateContext(row : any, tab: string) {
    row.Tooltip = this.information;
    row.TooltipEnabled = this.showInformation;
    row.AllowComment = this.showComment;
    row.AllowFile = this.showFile;
    row.MaxDoc = this.maxDoc;
    row.MaxSizePerDoc = this.maxSizePerDoc;
    row.Extension = this.extension;
    row.DocCode = this.docCode;
    row.SubPerimeterDoc=this.subPerimeterDoc;
    this.currentLine = row;
    this.popupTitle = row.Code ? row.Code.Label : 'Ajout de ligne';
    this.popupVisible = true;
    this.refreshTab = true;
    setTimeout(() => {
      this.refreshTab = false;
    }, 100);
  }

  async configMaster(masterColumns: any[], ctrl:any) {
    var tabs: any[]= [];
    FrontOfficeGridFactory.setTabsAndItemsConfigMaster(ctrl, masterColumns, tabs);   
    ctrl.childtabs = tabs;
  }

  ngOnDestroy(): void {
    if (this.sub)
    this.sub.unsubscribe();
  }
}