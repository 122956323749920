import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "app/shared/local-storage.service";
import { Observable, throwError, of } from "rxjs";
import { shareReplay, catchError, tap, retry } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class FrontOfficeFormService {
    private appUrl = './api/{version}/app/{appName}/{zone}';

    constructor(private http: HttpClient, private localStorageService: LocalStorageService)
    { }

    getFormConfigByEntityId(space: string,version: string, appName: string, idEntity: string, zone: string, module: string, tab:string, bloc:string, tabRight:string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        if (storage != null){
            if (storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc] != null) {
                return of(JSON.parse(storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc]));
            }
        }
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + idEntity + '/' + module+ '/' + tab+ '/' + tabRight+ '/' + bloc + '/settings/form.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                storage.Blocs[zone + '-' + module+ '-' + tab+ '-' + bloc] = JSON.stringify(data);
                this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    updateAttributProperty(space: string, version: string, appName: string, id: string, zone: string, tabName: string, attribut: any, reference:string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone)+ '/' + id+ '/' + tabName+ '/' + reference + '/update_form_attribut.json'
       return this.http.put(url,attribut)
       .pipe(shareReplay(1),retry(1),tap(data => JSON.stringify(data)));
    }

    getFormDataByEntityId(space: string,version: string,  appName: string, idEntity: string, zone: string, module: string, tab:string, bloc:string): Observable<any> {
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);
        var url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + idEntity + '/blabla/' + module+ '/' + tab+ '/' + bloc + '.json';
        if(tab !="Header")
        url = this.appUrl.replace('{version}', version).replace('{appName}', appName).replace('{zone}', zone) + '/' + idEntity + '/blabla/0/' + module+ '/' + tab+ '/' + bloc + '.json';
        return this.http.get<any>(url).pipe(
            retry(1),
            shareReplay(1),
            tap(data => {
                JSON.stringify(data)
            }),
            catchError(this.handleError)
        )
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}