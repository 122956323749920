import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable()

export class ApplicationGuardService implements CanActivate {

    constructor(
        private localStorageService: LocalStorageService) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot): Promise<boolean> {
       this.localStorageService.set('ActiveRoute', route.pathFromRoot
       .map(v => v.url.map(segment => segment.toString()).join('/'))
       .join('/'))
        return true;
    }
}