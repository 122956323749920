import { EventEmitter, Injectable, OnDestroy } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ActionLegacyRefreshService implements OnDestroy {
    public refreshActionFromToast = new EventEmitter()
    public refreshActionAppFromToast = new EventEmitter()
    public refreshCommAppFromToast = new EventEmitter()
    constructor(
    ) {
        this.ngOnDestroy();
    }

    
    ngOnDestroy(): void {

    }
}