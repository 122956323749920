import { dxMenuItem } from 'devextreme/ui/menu';
export interface IEntity {
    IdObject: number;
    Name: string;
    EntityType: string;
    Status: string;
    CreationTime: Date;
    ModificationTime: Date;
    Owner: string;
    CalculationTime: string;
    IsMain: boolean;
    IsSpecial: boolean;
    LongName: string;
    ValidationTime: string;
    IsPublished: boolean;
    HasNextLevel: boolean;
    Parent?: number;
}

export interface IBreadCrumbEntity {
    ID: number;
    Name: string;
    EntityType: string;
    DisplayOrder: string;
    LongName: string;
    Link: string;
    ParentID?: number;
    HasNextLevel: boolean;
}

export interface IActionButton {
    EntityType: any;
    Target: TargetButton;
    Name: string;
    LongName: string;
    VisibilityCriteria: string;
    GroupName: string;
    IconClass: string;
    Tooltip: string;
    Order: number;
    Childrens : IActionButton[];
    RenderType: RenderTypeButton;
    ActionType: ActionTypeButton;
    Actions: string;
    Value: string;
    Disabled: boolean;
    ActionRight:string;
    SubPerimeterImport: string;
}

export interface GroupAction {
    Name: string;
    ActionButtons : IActionButton[];
}

export enum TargetButton
{
    Toolbar = 1,
    ContextMenu = 2
}

export enum RenderTypeButton
{
    Icone = 1,
    IconeAndText = 2,
    Text = 3,
    Input = 4,
    Checkbox = 5,
    Dropdown = 6,
    CustomDropdown = 7
}

export enum ActionTypeButton
{
    API = 1,
    Javascript = 2
}

export interface IActionAPI {
    AppName: string;
    Type: string;
    Uri: string;
    Parameters: any;
    File?: File;
}

export interface MenuVdsashItem extends dxMenuItem
{
    Element : IActionButton;
    Control : any;
}