import { ApplicationRef, Component,  EventEmitter,  Input, Output, createComponent } from "@angular/core";
import {  FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import { PopupService } from "./popup.service";
import * as $ from 'jquery';

@Component({
  selector:'ngbd-modal-report-content',
  template: `
    <form [formGroup]="fg" novalidate>
      <div class="modal-body">
        <div *ngIf="showDocs==true" class="reportElementDiv forceFullWidth" id="selectFormIncludeDocuments">
          <input class="reportElementInput" type="checkbox" [checked]="includeDocs" (change)="includeDocs = !includeDocs" id="selectFormIncludeDocumentsInput"/>
          <span>Inclure les pièces jointes</span>
        </div>
        <br/>
        <div *ngFor="let report of reportsDefinitions">
          <div [hidden]="report.Hide" style="display:inline-block;" class="forceFullWidth">
            <div id="selectReportForm{{report.Name}}" class="reportElementDiv forceFullWidth">
              <input class="reportElementInput" type="checkbox" [disabled]="report.Hide" id="selectReportFormInput{{report.Name}}"/>
              <span  [innerHTML]="report.Label"></span>
            </div>
          </div>
          <div *ngFor="let tab  of report.Tabs" [hidden]="report.Hide || tab.Hide">
            <div style="display:inline-block;padding-left:50px" id="selectReportTab{{report.Name}}{{tab.NameId}}" class="reportElementDiv forceFullWidth">
              <input class="reportElementInput"  type="checkbox" [ngClass]="{disabledAction :report.Hide || tab.Hide}" id="selectReportTabInput{{report.Name}}{{tab.NameId}}"/>
              <span [innerHTML]="tab.Label"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
          Annuler
        </button>
        <button type="button" class="btn btn-primary modalOkButton" id="popupOkButton" [disabled]="!hasChecked">          
          Ok
        </button>
      </div>

    </form>
  `
})

export class NgbdModalReportContent {
  @Input() title: string = "default";

  @Output() messageToParent :EventEmitter<any> = new EventEmitter();

  public fg!: FormGroup;
  public reportsDefinitions : any[];
  public hasChecked : boolean = false;
  public selectAll : boolean = false;
  public includeDocs: boolean = false;
  public showDocs: boolean = false;
  constructor( public fb: FormBuilder, public sanitizer: DomSanitizer) {
    this.fg = this.fb.group({
      InputFile: ['',[Validators.required]]
    });
  }
}

@Component({
    selector: "ngbd-modal-report-component",
})

export class NgbdModalReportComponent{
  insidePopup: any;
  @Output() refreshDataGridChange: EventEmitter<any> = new EventEmitter();

  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }

  show(actionAPI: IActionAPI, reportsDefinitions : any[]) {
    console.log('Show called');

    this.popupService.showPopup('Rapports', this.getContentTemplate(actionAPI, reportsDefinitions));

    const selectFormIncludeDocumentsElement = document.getElementById('selectFormIncludeDocuments');
    if (selectFormIncludeDocumentsElement && typeof selectFormIncludeDocumentsElement.addEventListener === 'function') {
      selectFormIncludeDocumentsElement.addEventListener("click", () => { this.selectFormIncludeDocumentsClick(); });
    }
    
    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
    document.getElementById('popupOkButton').addEventListener("click", () => { this.validExport(); });

    this.insidePopup.instance.reportsDefinitions.forEach((report:any) => {
      document.getElementById('selectReportForm' + report.Name).addEventListener("click", () => { this.changeReport(report); });

      report.Tabs.forEach((tab:any) => {
        document.getElementById('selectReportTab' + report.Name + tab.NameId).addEventListener("click", () => { this.changeTab(tab, report); });

        $('#selectReportTabInput' + report.Name + tab.NameId).prop('checked', tab.IsSelectedInReport);  
      });

      $('#selectReportFormInput' + report.Name).prop('checked', report.IsSelectedInReport); 
    });
  }

  getContentTemplate(actionAPI : any, reportsDefinitions : any[]) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalReportContent, {
        environmentInjector,
    });
    
    reportsDefinitions.forEach((report: { Name: string;Tabs: any; IsSelectedInReport: any; }) => {
      report.IsSelectedInReport = true;
      report.Tabs = report.Tabs.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => this.naturalSort(a['Sorting'],b['Sorting']))
      report.Tabs = report.Tabs.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => this.naturalSort(a['Sorting'],b['Sorting']))
      report.Tabs.forEach((element: {
        NameId: string; IsSelectedInReport: any;Tab:string 
}) => { 
        element.IsSelectedInReport = true;
        element.NameId = element.Tab.replace('/','_');
       });
    });
    this.insidePopup.instance.hasChecked = true;
    this.insidePopup.instance.reportsDefinitions = reportsDefinitions;
    this.insidePopup.instance.showDocs = this.insidePopup.instance.reportsDefinitions[0].HasDoc;

    this.insidePopup.instance.messageToParent.subscribe((includeDocs:File)=>{
      var reportsSelected: any[] = [];
      this.insidePopup.instance.reportsDefinitions.forEach((report: { Name: string;Tabs: any; IsSelectedInReport: any; }) => {
        if(report.IsSelectedInReport)
        {
          let selectedReport = <any>{};
          selectedReport.ReportName = report.Name;
          selectedReport.TabIdentifiers = [];
          var i = 0;
          let tabs: boolean[] = [];
          report.Tabs.forEach((element: {Hide: boolean; IsSelectedInReport: boolean;Tab:string }) => {
            tabs[i] = (element.Hide) ? false : element.IsSelectedInReport ? true : false;
            i++;
          });
          selectedReport.NbTabs = report.Tabs.length;
          selectedReport.TabsActivationTemp = tabs;
          reportsSelected.push(selectedReport);
        }
      });
      var parameter = {
        Requests : reportsSelected,
        IncludeDocs : includeDocs
      }
      actionAPI.Parameters.ExtraParameters = JSON.stringify(parameter);
      this.refreshDataGridChange.emit(actionAPI);
    });

    this.insidePopup.hostView.detectChanges();
    
    return this.insidePopup.location.nativeElement;
  }

  naturalSort(a : string, b: string) {
    var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
    sre = /(^[ ]*|[ ]*|[_]*$)/g,
    dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,

    x: any = a.toString().replace(sre, '') || '',
    y: any = b.toString().replace(sre, '') || '',

    xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),
    yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),

    xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
    yD = parseInt(y.match(hre)) || xD && y.match(dre) && Date.parse(y) || null;

    if (yD)
    if (xD < yD) return -1;
    else if (xD > yD) return 1;

    for (var cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
      let oFxNcL = !(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc]) || xN[cLoc] || 0;
      let oFyNcL = !(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc]) || yN[cLoc] || 0;

      if (isNaN(oFxNcL) !== isNaN(oFyNcL)) return (isNaN(oFxNcL)) ? -1 : 1;
      else if (typeof oFxNcL !== typeof oFyNcL) {
        oFxNcL += '';
        oFyNcL += '';
      }

      if (oFxNcL <= oFyNcL) return -1;
      if (oFxNcL >= oFyNcL) return 1;
    }
    return 0;
  }

  changeReport(report : any) {
    report.IsSelectedInReport = !report.IsSelectedInReport;
    $('#selectReportFormInput' + report.Name).prop('checked', report.IsSelectedInReport); 

    report.Tabs.forEach((tab:any) => {
      tab.IsSelectedInReport = report.IsSelectedInReport;
      $('#selectReportTabInput' + report.Name + tab.NameId).prop('checked', tab.IsSelectedInReport);   
    });
    this.checkValid();
  }

  checkValid() {
    this.insidePopup.instance.hasChecked = false;
    this.insidePopup.instance.reportsDefinitions.forEach((report: { Tabs: any; IsSelectedInReport: any; }) => {
      if (report.IsSelectedInReport) {
        this.insidePopup.instance.hasChecked = true;
      }
      report.Tabs.forEach((tab: { IsSelectedInReport: any; }) => {
        if(tab.IsSelectedInReport) {
          this.insidePopup.instance.hasChecked = true;
        }
      });
    });

    $('#popupOkButton').prop('disabled', !this.insidePopup.instance.hasChecked);
  }

  validExport() {
    this.insidePopup.instance.messageToParent.emit(this.insidePopup.instance.includeDocs);
    this.popupService.closePopup();
  }
  
  changeTab(tab : any, report : any) {
    tab.IsSelectedInReport = !tab.IsSelectedInReport;
    $('#selectReportTabInput' + report.Name + tab.NameId).prop('checked', tab.IsSelectedInReport); 

    if(!tab.IsSelectedInReport)
    {
      let hasCheck = false;
      report.Tabs.forEach((element: { IsSelectedInReport: any; }) => {
        if(element.IsSelectedInReport)hasCheck = true;
      });
      if(!hasCheck) {
        report.IsSelectedInReport = false;
      }
    }
    else {
      report.IsSelectedInReport=true;
    }

    $('#selectReportFormInput' + report.Name).prop('checked', report.IsSelectedInReport); 

    this.checkValid();
  }

  selectFormIncludeDocumentsClick() {
    this.insidePopup.instance.includeDocs = !this.insidePopup.instance.includeDocs;
    $('#selectFormIncludeDocumentsInput').prop('checked', this.insidePopup.instance.includeDocs);  
  }
}

