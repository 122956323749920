// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*::marker{
  display: none;
}
.nav-buttons{
  display: flex;
  flex-direction: row;
  height: 100%;
}
.navbar-button{
  width: 25vw;
  border: none;
  background: transparent;
  color: rgba(255,255,255,1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: .75rem;
}
.navbar-button:active{
  background-color: rgba(255,255,255,.12);
}
.navbar-button i{
  display: block;
  font-size: 1.25rem;
  height: 2rem;
  padding: .375rem 1.5rem;
  border-radius: 1rem;
}
.navbar-button.current-nav i{
  background-color: rgba(51,122,183,.65);
}
.button-label{
  font-size: .75rem;
}
.name-etab{
  width: 65px;
    text-overflow: ellipsis;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./app/front-office/desktop-mobile/mobile/breadcrumb-mobile/breadcrumb-mobile.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,sCAAsC;AACxC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,WAAW;IACT,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["*::marker{\r\n  display: none;\r\n}\r\n.nav-buttons{\r\n  display: flex;\r\n  flex-direction: row;\r\n  height: 100%;\r\n}\r\n.navbar-button{\r\n  width: 25vw;\r\n  border: none;\r\n  background: transparent;\r\n  color: rgba(255,255,255,1);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding-bottom: 1rem;\r\n  padding-top: .75rem;\r\n}\r\n.navbar-button:active{\r\n  background-color: rgba(255,255,255,.12);\r\n}\r\n.navbar-button i{\r\n  display: block;\r\n  font-size: 1.25rem;\r\n  height: 2rem;\r\n  padding: .375rem 1.5rem;\r\n  border-radius: 1rem;\r\n}\r\n.navbar-button.current-nav i{\r\n  background-color: rgba(51,122,183,.65);\r\n}\r\n.button-label{\r\n  font-size: .75rem;\r\n}\r\n.name-etab{\r\n  width: 65px;\r\n    text-overflow: ellipsis;\r\n    white-space: nowrap;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
