import { Component, Input, OnDestroy} from "@angular/core";
import { IActionAppButton } from "app/front-office/front-office-struct";
import { ActionTypeButton, RenderTypeButton, TargetButton } from "app/shared/entity";
import { Subscription } from "rxjs";

@Component({
    selector: 'front-office-toolbar-item',
    templateUrl: './front-office-toolbar-item.component.html',
    styleUrls: ['./front-office-toolbar-item.component.css']
})

export class FrontOfficeToolbarItemComponent implements OnDestroy {
    @Input() action: IActionAppButton = {
        Target: TargetButton.Toolbar,
        Name: '',
        LongName: '',
        TargetState:'',
        FormStatus:'',
        VisibilityCriteria: '',
        IconClass: '',
        Order: - 1,
        Tooltip: '',
        RenderType: RenderTypeButton.Icone,
        ActionType: ActionTypeButton.Javascript,
        Actions: '',
        Value: '',
        Disabled: true,
        Blocked:false
    };
    sub2!: Subscription;
    errorMessage = '';

    constructor() { }

    IsPartOfToolBar(element: IActionAppButton) {
        return (element.Target == TargetButton.Toolbar);
    }

    ngOnDestroy(): void {
        if (this.sub2) this.sub2.unsubscribe();
    }
   
}