// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .card .accordion-body {
    background-color: #fff !important;
    border: none;
}

.box {
    overflow: hidden;
}

.box-inner {
    padding-bottom: 5px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.notif{
    background-color: #fff;
}
.link-details {
    cursor: pointer;
}

.closeToast{
    cursor: pointer;
}

[id="navigation-list-etab"] .dx-list-search{
    margin: .25rem .5rem .5rem;
    border-radius: .25rem !important;
}

.liste-etab{
    border-top-width: 0;
    padding-top: .5rem;
    background-color: rgb(230, 239, 243);
}`, "",{"version":3,"sources":["webpack://./app/front-office/panel-liste-etab/panel-liste-etab.component.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,gCAAgC;AACpC;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,oCAAoC;AACxC","sourcesContent":["::ng-deep .card .accordion-body {\r\n    background-color: #fff !important;\r\n    border: none;\r\n}\r\n\r\n.box {\r\n    overflow: hidden;\r\n}\r\n\r\n.box-inner {\r\n    padding-bottom: 5px;\r\n    max-height: 100%;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n.notif{\r\n    background-color: #fff;\r\n}\r\n.link-details {\r\n    cursor: pointer;\r\n}\r\n\r\n.closeToast{\r\n    cursor: pointer;\r\n}\r\n\r\n[id=\"navigation-list-etab\"] .dx-list-search{\r\n    margin: .25rem .5rem .5rem;\r\n    border-radius: .25rem !important;\r\n}\r\n\r\n.liste-etab{\r\n    border-top-width: 0;\r\n    padding-top: .5rem;\r\n    background-color: rgb(230, 239, 243);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
