import { ApplicationRef, Component, createComponent, EventEmitter, Injectable, Input, Output, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { IActionAPI } from "app/shared/entity";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from "rxjs";
import { PopupService } from "./popup.service";

@Component({
    standalone:true,
    template: `
        <form [formGroup]="fg" novalidate>
            <div class="modal-body">
                <table>
                    <tbody>
                        <tr [hidden]="!simulationVisible">
                            <td class="halfSize">
                                <span>Simulation</span>
                            </td>
                            <td class="chkbSimulation">
                                <span><input id="hasSimu" type="checkbox" [disabled]="enableEntity" [checked]="isSpecial" (change)="isSpecial = !isSpecial"></span>
                            </td>
                        </tr>
                        <tr *ngIf="!hasNamePattern">
                            <td style="width: 150px;">
                                <span>Id</span>
                            </td>
                            <td style="width: 350px;">
                                <input type="text" id="nameTxt" class="TextBoxes" formControlName="entityName" [value]="name">
                                <div style='display:none' id="nameTxtRequired" class="alert alert-danger">
                                    <div>L'id est obligatoire</div>
                                </div>
                                <div style='display:none' id="nameTxtIncorrect" class="alert alert-danger">
                                    <div>Le format de l'id est incorrect, il ne peut contenir que des lettres, chiffres, tiret ou point et 50 charactères maximum</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 150px;">
                                <span>Nom</span>
                            </td>
                            <td style="width: 350px;">
                                <input type="text" id="longNameTxt" class="TextBoxes" formControlName="longName" [value]="longName">
                                <div class="alert alert-danger" style='display:none' id="longNameTxtRequired">
                                    <div>Le nom est obligatoire</div>
                                </div>
                                <div class="alert alert-danger" style='display:none' id="longNameTxtShouldNotDepass">
                                    <div >Le nom ne doit pas dépasser 500 charactères</div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="showFieldLR">
                            <td style="width: 150px;">
                                <span>Type de bloc</span>
                            </td>
                            <td style="width: 350px;">
                                <div id="blocTypeSelect"></div>
                            </td>
                        </tr>
                        <tr *ngIf="showFieldLR">
                            <td style="width: 150px;">
                                <span>Onglet</span> 
                            </td>
                            <td style="width: 350px;">
                                <input type="text" id="tabTxt" class="TextBoxes" formControlName="tab" [value]="tab">
                                
                                <div style='display:none' id='tabTxtRequired' class="alert alert-danger">
                                    <div>Le nom est obligatoire</div>
                                </div>

                                <div style='display:none' id='tabTxtIncorrect' class="alert alert-danger">
                                    <div>Le format de l'onglet est incorrect, il ne doit pas peut contenir que des lettres, chiffres, slash ou point et 20 charactères maximum</div>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-outline-dark"
                    id="popupCloseButton"
                >
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary modalOkButton"
                    id="popupOkButton"
                    disabled
                >          
                    Ok
                </button>
            </div>
        </form>
    `
})

export class PopupCloneContent {
    @Input() title: string = "default";
    @Input() enableEntity: boolean = true;
    @Input() simulationVisible: boolean = true;
    @Input() isSpecial: boolean = true;
    @Input() hasNamePattern: boolean = true;
    @Input() showFieldLR: boolean = false;
    @Input() preName: string = "";
    @Output() messageToParent: EventEmitter<any> = new EventEmitter();

    name: string = "";
    longName: string = "";
    blocType: string = "";
    tab: string = "";

    public fg: FormGroup;

    constructor(public sanitizer: DomSanitizer, public fb: FormBuilder, private httpClient: HttpClient) {
        if(this.hasNamePattern) this.name = this.preName;
        this.fg = fb.group({
            entityName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.]{1,50}$')]],
            longName: ['', [Validators.required, Validators.maxLength(500)]]
        });
    }

    async loadBlocType(version : string) {
        this.fg = this.fb.group({
            entityName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.]{1,50}$')]],
            longName: ['', [Validators.required, Validators.maxLength(500)]],
            tab: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9\/.]{1,20}$')]]
        });

        var url = '/api/'+version+'/spaces/MDEL/Definitions/enum_list/bloc_type/0.json';
        var items = <any[]>await lastValueFrom(this.httpClient.get(url));
        var ctrl = this;

        $("#blocTypeSelect").dxSelectBox({
            dataSource: items,
            searchEnabled: true,
            displayExpr:  'Label',
            valueExpr: 'Label',
            onSelectionChanged: async function (e) {
                ctrl.blocType = e.selectedItem.IdEnumerationItem;
            }
        });
    }
}


@Component({})

export class PopupCloneComponent {
    public refreshDataGridChange = new EventEmitter();
    title: string = "default";
    componentRef: any;

    private renderer: Renderer2;
    constructor(   
        rendererFactory: RendererFactory2,
        private applicationRef: ApplicationRef, 
        public fb: FormBuilder,
        public popupService: PopupService
    ) { 
      this.renderer = rendererFactory.createRenderer(null, null);
    }

    showCloneAction(title: string, actionAPI: IActionAPI, showSimu: boolean, enableEntity: boolean, hasNamePattern: boolean, preName: string, isSpecial: boolean) {

        this.popupService.showPopup(title, this.getContentTemplateCloneAction(actionAPI, showSimu, enableEntity, hasNamePattern, preName, isSpecial));

        document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
        document.getElementById('popupOkButton').addEventListener("click", () => { this.validForm(); });
        if(!hasNamePattern)
            document.getElementById('nameTxt').addEventListener("input", (event:any) => { this.nameValueChanged(event);});  
        document.getElementById('longNameTxt').addEventListener("input", (event:any) => { this.longNameValueChanged(event);});    

        var hasSimuElement = document.getElementById('hasSimu');
        if (hasSimuElement! != undefined) {
            hasSimuElement.addEventListener('change', (event:any) => { this.hasSimuValueChanged(event.target.checked); });
        }
    }

    getContentTemplateCloneAction(actionAPI: IActionAPI, showSimu: boolean, enableEntity: boolean, hasNamePattern: boolean, preName: string, isSpecial: boolean) {
        const environmentInjector = this.applicationRef.injector;
        this.componentRef = createComponent(PopupCloneContent, {
          environmentInjector,
        });

        this.componentRef.instance.enableEntity = !enableEntity;
        this.componentRef.instance.simulationVisible = showSimu;
        this.componentRef.instance.isSpecial = isSpecial;
        this.componentRef.instance.hasNamePattern = hasNamePattern;
        this.componentRef.instance.preName = preName;

        this.componentRef.instance.messageToParent.subscribe((receivedEntry: ICloneModalObject) => {
            if(actionAPI.Uri.indexOf('_app') == -1)
              actionAPI.Parameters.ExtraParameters = receivedEntry.Name + '|' + receivedEntry.LongName + '|' + receivedEntry.IsSimulation;
            else
              actionAPI.Parameters.ExtraParameters = receivedEntry.LongName;
            this.refreshDataGridChange.emit(actionAPI)
          }
        )

        this.componentRef.hostView.detectChanges();

        return this.componentRef.location.nativeElement;
    }

    showCloneActionLR(title: string, actionAPI: IActionAPI, showSimu: boolean, enableEntity: boolean, hasNamePattern: boolean, preName: string, isSpecial: boolean, version: string) {
        
        this.popupService.showPopup(title, this.getContentTemplateCloneActionLR(actionAPI, showSimu, enableEntity, hasNamePattern, preName, isSpecial, version));

        document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
        document.getElementById('popupOkButton').addEventListener("click", () => { this.validForm(); });

        document.getElementById('nameTxt').addEventListener("input", (event:any) => { this.nameValueChanged(event);});  
        document.getElementById('longNameTxt').addEventListener("input", (event:any) => { this.longNameValueChanged(event);});  
        document.getElementById('tabTxt').addEventListener("input", (event:any) => { this.tabValueChanged(event);});  
    }

    getContentTemplateCloneActionLR(actionAPI: IActionAPI, showSimu: boolean, enableEntity: boolean, hasNamePattern: boolean, preName: string, isSpecial: boolean, version: string) {
        const environmentInjector = this.applicationRef.injector;
        this.componentRef = createComponent(PopupCloneContent, {
            environmentInjector,
        });
        
        this.componentRef.instance.enableEntity = !enableEntity;
        this.componentRef.instance.simulationVisible = showSimu;
        this.componentRef.instance.isSpecial = isSpecial;
        this.componentRef.instance.hasNamePattern = hasNamePattern;
        this.componentRef.instance.preName = preName;
        this.componentRef.instance.showFieldLR = true;
        this.componentRef.instance.loadBlocType(version);

        this.componentRef.instance.messageToParent.subscribe((receivedEntry: ICloneModalObject) => {
            actionAPI.Parameters.ExtraParameters = receivedEntry.Name + '|' + receivedEntry.LongName + '|' + receivedEntry.IsSimulation+'|'+receivedEntry.BlocType+'|'+receivedEntry.Tab;
            this.refreshDataGridChange.emit(actionAPI);
        })

        this.componentRef.hostView.detectChanges();
        return this.componentRef.location.nativeElement;
    }

    validForm() {
        this.componentRef.instance.messageToParent.emit({
            IsSimulation: this.componentRef.instance.isSpecial,
            Name: this.componentRef.instance.name,
            LongName: this.componentRef.instance.longName,
            BlocType : this.componentRef.instance.blocType,
            Tab: this.componentRef.instance.tab
        });

        this.popupService.closePopup();
    }

    longNameValueChanged(event:any) {
        this.componentRef.instance.longName = event.target.value;
        this.componentRef.instance.fg.controls['longName'].setValue(this.componentRef.instance.longName);
        
        document.getElementById('longNameTxtRequired').style.display = this.componentRef.instance.fg.controls.longName.invalid && this.componentRef.instance.fg.controls.longName.errors?.required ? 'block' : 'none';
        document.getElementById('longNameTxtShouldNotDepass').style.display = this.componentRef.instance.fg.controls.longName.invalid && this.componentRef.instance.fg.controls.longName.errors?.maxlength ? 'block' : 'none';
    
        this.actualiseOkButton();
    }

    hasSimuValueChanged(newValue:any) {
        this.componentRef.instance.isSpecial = newValue;
    }

    actualiseOkButton() {
        var disable1 = !this.componentRef.instance.showFieldLR && ((!this.componentRef.instance.hasNamePattern && !this.componentRef.instance.fg.valid) || (this.componentRef.instance.hasNamePattern && this.componentRef.instance.fg.controls.longName.invalid));
        var disable2 = (this.componentRef.instance.showFieldLR && ((!this.componentRef.instance.hasNamePattern && !this.componentRef.instance.fg.valid) || this.componentRef.instance.blocType == ''));
        if (disable1 || disable2) {
            document.getElementById('popupOkButton').setAttribute("disabled", "");
        }
        else {
            document.getElementById('popupOkButton').removeAttribute("disabled");
        }
    }

    nameValueChanged(event:any) {
        this.componentRef.instance.name = event.target.value;
        this.componentRef.instance.fg.controls['entityName'].setValue(this.componentRef.instance.name);

        document.getElementById('nameTxtRequired').style.display = this.componentRef.instance.fg.controls.entityName.invalid && this.componentRef.instance.fg.controls.entityName.errors?.required ? 'block' : 'none';
        document.getElementById('nameTxtIncorrect').style.display = this.componentRef.instance.fg.controls.entityName.invalid && this.componentRef.instance.fg.controls.entityName.errors?.pattern ? 'block' : 'none';    

        this.actualiseOkButton();
    }

    tabValueChanged(event:any) {
        this.componentRef.instance.tab = event.target.value;
        this.componentRef.instance.fg.controls['tab'].setValue(this.componentRef.instance.tab);
        document.getElementById('tabTxtRequired').style.display = this.componentRef.instance.fg.controls.tab.invalid && this.componentRef.instance.fg.controls.tab.errors?.required ? 'block' : 'none';
        document.getElementById('tabTxtIncorrect').style.display = this.componentRef.instance.fg.controls.tab.invalid && this.componentRef.instance.fg.controls.tab.errors?.pattern ? 'block' : 'none';    

        this.actualiseOkButton();
    }
}


export interface ICloneModalObject {
    IsSimulation: boolean;
    Name: string;
    LongName: string;
    BlocType: string;
    Tab: string;
}