import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { HomePageService } from 'app/home-page/home-page.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()

export class SpaceGuardService implements CanActivate {

    constructor(
        private spaceService: HomePageService,
        private localStorageService: LocalStorageService) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot): Promise<boolean> {
        const space = String(route.params.space);
        var storage = this.localStorageService.getWithExpiration(space);
        storage = JSON.parse(storage);

        if (storage == null) {
            return await this.spaceService.getSpaceVersion('BO', space, false).then(value => {
                this.localStorageService.set('ActiveRoute', route.pathFromRoot
                .map(v => v.url.map(segment => segment.toString()).join('/'))
                .join('/'))
                return true;
            });
        }
        else{
            this.localStorageService.set('ActiveRoute', route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/'))
             return true;
        }
    }
}