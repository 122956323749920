import { Component, Injectable, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/shared/local-storage.service';
import { IBreadCrumbEntity } from '../entity';
import { EntityService } from '../entity.service';
import {  faUser, faRightFromBracket, faGear, faHammer } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/shared/auth.service';
import { FrontOfficeStructService } from 'app/front-office/front-office-struct.service';
import { SessionStorageService } from 'app/shared/session-storage.service';
import { HomePageService } from '../../home-page/home-page.service';
import { IApp } from 'app/home-page/home-page';
import { PanelComponent } from '../panel/panel.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';
import { IDPService } from 'app/front-office/idp.service';
import { Subscription } from 'rxjs-compat';
import { StringUtils } from 'app/utils/string-utils';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
@Injectable({ providedIn: 'root' })
export class BreadCrumbComponent implements OnInit, OnDestroy, OnChanges {
    @Input() idParent: number = -1;
    @Input() identifier: string = '';
    @Input() applicationName: string = '';
    @Input() zoneName: string = '';
    @Input() space: string = '';
    @Input() version: string = '';
    @Input() entityType: string = '';
    @Input() onHome: boolean = false;
    @Input() refreshApp: boolean = false;
    availableApps: IApp[] = [];
    sub!: Subscription;
    availableZonesPerApp: any ={};
    breadCrumbEntitiesReference: IBreadCrumbEntity[] = [];
    faRightFromBracket= faRightFromBracket;
    faUser=faUser;
    faGear=faGear;
    faHammer=faHammer;
    zoneLabel: string = '';
    isToolbarDisplayed: boolean= false;
    modeValidation: boolean= false;
    errorMessage = '';
    displayState: boolean = false;
    idp: string = '';
    email: string = '';
    constructor(private router: Router,
        private applicationStructService: FrontOfficeStructService,
        private entityService : EntityService,
        private spaceService: HomePageService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private authService: AuthService,
        private idpService : IDPService,
        private panelCompo: PanelComponent,
        private deviceService: DeviceDetectorService
    ) {

    }
    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.refreshApp && this.refreshApp == true) {
            this.availableApps = await lastValueFrom(this.spaceService.getAvailableApplications());
            this.availableZonesPerApp = [];
        }
        if (changes.applicationName) {
            await this.loadBreadCrumb();
        }

    }

    async ngOnInit(): Promise<void> {
        this.ngOnDestroy();
        this.email = this.authService.getEmail();
        this.modeValidation = this.localStorageService.get("ModeTravail");
        this.idp = this.authService.getIdp();
        this.availableApps = await lastValueFrom(this.spaceService.getAvailableApplications());
        await this.loadBreadCrumb();
        this.sub = this.spaceService.changeModeAlert.subscribe(async (modeValidation: boolean) => {
            this.modeValidation = modeValidation;
            if(this.applicationName == '')
                return;
            var app = this.availableApps.filter((tb: { Name: string; }) => tb.Name == this.applicationName)[0];
            if (modeValidation && !this.zoneName.endsWith('V'))
            {
                this.availableZonesPerApp[this.applicationName] =  await lastValueFrom(this.spaceService.getAvailableZonePerApplications(this.applicationName));
                var zoneV = this.availableZonesPerApp[this.applicationName].filter((tb: { Name: string; }) => tb.Name == this.zoneName+'V')[0];
                if(zoneV)
                {
                    if(this.applicationName == 'BO')
                        this.onSpaceClicked(zoneV)
                    else
                        this.onZoneClicked(zoneV, app)
                }
            }
            else if (!modeValidation && this.zoneName.endsWith('V'))
            {
                this.availableZonesPerApp[this.applicationName] =  await lastValueFrom(this.spaceService.getAvailableZonePerApplications(this.applicationName));
                var zone = this.availableZonesPerApp[this.applicationName].filter((tb: { Name: string; }) => tb.Name == this.zoneName.substring(0,this.zoneName.length -1))[0];
                if(zone)
                {
                    if(this.applicationName == 'BO')
                        this.onSpaceClicked(zone)
                    else
                        this.onZoneClicked(zone, app)
                }
            }
        })
    }

    async loadBreadCrumb()
    {
        if (this.sessionStorageService.hasKey('currentCycleRef')) {
            this.sessionStorageService.remove('currentCycleRef');
        };
        var ctrl = this;
        if(ctrl.idParent > -1)
        {
            this.zoneLabel = '';
            this.applicationName='BO'
            this.displayState = false;

            if(this.zoneName)
            {
                if (!this.idParent && this.idParent != -1) return;
            }
        }
        else if(this.identifier != '')
        {
            if(this.availableApps.length == 0)
                return;
                if(this.availableZonesPerApp[this.applicationName] == null)
                {
                    this.availableZonesPerApp[this.applicationName] =  await lastValueFrom(this.spaceService.getAvailableZonePerApplications(this.applicationName));
                }
                this.zoneLabel = this.availableZonesPerApp[this.applicationName].filter((tb: { Name: string; }) => tb.Name == this.zoneName)[0].LongName;
                this.displayState = this.availableApps.filter((tb: { Name: string; }) => tb.Name == this.applicationName)[0].DisplayState;
        }
        else
        {
            this.zoneLabel = '';
            if(this.deviceService.isMobile() || this.availableApps.filter(p=>p.Name == 'BO').length == 0)
            {
                var firstApp = this.availableApps.filter(p=>p.Name != 'BO')[0];
                if(firstApp)
                {
                    this.returnToApp(firstApp)
                }
            }
            else
            {
                if (this.sessionStorageService.hasKey('currentCycleRef')) {
                    this.sessionStorageService.remove('currentCycleRef');
                };
            }
        }
    }

    async onAppHover(app: IApp): Promise<void> {
        this.availableApps.forEach(app=>{
            app.Zones = [];
        });
        app.DisplayZone = false;
        if(this.availableZonesPerApp[app.Name] != null)
        {
            app.Zones = this.availableZonesPerApp[app.Name];
        }
        else
        {
            this.availableZonesPerApp[app.Name] =  await lastValueFrom(this.spaceService.getAvailableZonePerApplications(app.Name));
            app.Zones = this.availableZonesPerApp[app.Name];
        }
        if(this.availableZonesPerApp[app.Name].length > 2)
        app.DisplayZone = true;
            if(this.availableZonesPerApp[app.Name].length == 2)
            {
                var first = this.availableZonesPerApp[app.Name][0];
                var second = this.availableZonesPerApp[app.Name][1];
                if(first.Name.length < second.Name.length && first.Name != second.Name.substring(0, second.Name.length-1))
                app.DisplayZone = true;
                else if(first.Name.length > second.Name.length && first.Name.substring(0, first.Name.length-1) != second.Name)
                {
                    app.DisplayZone = true;
                }
            }
        setTimeout(() => {
            app.Zones = [];
        }, 20000);
    }
    async onAppLeave(app: IApp): Promise<void> {
        this.availableApps.forEach(app=>{
            app.Zones = [];
        });
        
        setTimeout(() => {
            app.Zones = [];
        }, 100);
    }
    async onAppClicked(app:IApp): Promise<void> {
        this.sessionStorageService.remove('simuLine');
        if(app.Name == "BO")
        {
            if(app.Zones.length == 1)
                await this.onSpaceClicked(app.Zones[0]);
            else if(app.Zones.length == 2)
            {
                var first = app.Zones[0];
                var second = app.Zones[1];
                if(!this.modeValidation)
                {
                    if(first.Name == second.Name.substring(0, second.Name.length-1))
                        await this.onSpaceClicked(app.Zones[0]);
                }
                else
                {
                    if(first.Name == second.Name.substring(0, second.Name.length-1))
                        await this.onSpaceClicked(app.Zones[1]);
                }
            }
        }
        else
        {
            if(app.Zones.length == 1)
                await this.onZoneClicked(app.Zones[0], app);
            else if(app.Zones.length == 2)
            {
                var first = app.Zones[0];
                var second = app.Zones[1];
                if(!this.modeValidation)
                {
                    if(first.Name == second.Name.substring(0, second.Name.length-1))
                        await this.onZoneClicked(app.Zones[0], app);
                }
                else
                {
                    if(first.Name == second.Name.substring(0, second.Name.length-1))
                        await this.onZoneClicked(app.Zones[1], app);
                }
            }
        }
    }

    sendUserIntegration()
    {
        this.applicationStructService.sendUserIntegration().subscribe({
            next: comments => {
            }});
    }
    
    async onZoneClicked(data: any, app:IApp): Promise<void> {
        var currentSelectedUnitName:string = null;
        if (this.sessionStorageService.hasKey('currentSelectedUnitName')) {
            currentSelectedUnitName = this.sessionStorageService.get('currentSelectedUnitName');
        }

        this.sessionStorageService.remove('simuLine');
        this.availableApps.forEach(app=>{
            app.Zones = [];
        });
        var zone = data.Name;
        this.zoneLabel = this.availableZonesPerApp[app.Name].filter((tb: { Name: string; }) => tb.Name == data.Name)[0].LongName;
        let value = await this.spaceService.getSpaceVersion(app.Name, zone, this.displayState);
        let currentZone = this.availableZonesPerApp[app.Name].filter((tb: { Name: string; }) => tb.Name == zone)[0];
        if(currentZone.Link && value == currentZone.CurrentVersion)
        {
           if(currentZone.Link.indexOf('{0}')> -1)
           {
            let procs = await lastValueFrom(this.entityService.getAvailableProcFO(zone, value, app.Name, 'a'));
            let mainProc = procs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
            var tempZone = await lastValueFrom(this.applicationStructService.getStructOfZone(app.Name, currentZone.CurrentVersion, zone ));
            let entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(zone, mainProc.IdObject, tempZone.MainEntityCible, app.Name));

            var idObject = entities[0].IdObject;
            if (currentSelectedUnitName != null && currentSelectedUnitName != ''){
                const entity = entities.find((x) => x.Name === currentSelectedUnitName);
                if (entity) {
                    idObject = entity.IdObject
                }
                else{
                    this.sessionStorageService.set('currentSelectedUnitName', entities[0].Name);
                }
            }
            else{
                this.sessionStorageService.set('currentSelectedUnitName', entities[0].Name);
            }

            this.router.navigate([currentZone.Link.replace('{0}', idObject.toString())]);
           }
           else if(currentZone.Link.indexOf('{1}')> -1)
           {
                let entity = await lastValueFrom(this.entityService.getFirstEntityAppByEntityType(currentZone.Modules.Space,app.Name, currentZone.Modules.Modules[0].EntityCible));
                this.router.navigate([currentZone.Link.replace('{1}', entity.IdObject.toString())]);
           }
           else this.router.navigate([currentZone.Link]);
        }
        else
        {
            let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(app.Name, value, zone));
            if(modules.Modules[0].IsLinkToProcedure)
            {

                let procs = await lastValueFrom(this.entityService.getAvailableProcFO(zone, value, app.Name, 'a'));
                let mainProc = procs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
                var currentProcedureAssociation  = await lastValueFrom(this.entityService.getProcedureAssociation(zone, mainProc.IdObject));
                let entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(zone, mainProc.IdObject, modules.Modules[0].ParentEntityCible, app.Name));

                var idObject = entities[0].IdObject;
                if (currentSelectedUnitName != null && currentSelectedUnitName != ''){
                    const entity = entities.find((x) => x.Name === currentSelectedUnitName);
                    if (entity) {
                        idObject = entity.IdObject
                    }
                    else{
                        this.sessionStorageService.set('currentSelectedUnitName', entities[0].Name);
                    }
                }
                else{
                    this.sessionStorageService.set('currentSelectedUnitName', entities[0].Name);
                }

                let variante = await lastValueFrom(this.entityService.getVarianteFromEntity(modules.Space, modules.Version, idObject.toString(), modules.Perimeter));
                var selectedModule: {
                    EntityCible: any;
                    ParentEntityCible: any;ModuleName: any; Name: string;
} = null;
                var i =0;
                do
                {
                    var procsAvailable =  modules.Modules[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                    });
                    modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                            selectedModule = modules.Modules[i];
                        }
                    });
                    i++;
                }
                while(selectedModule == null && i<modules.Modules.length);
                let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(app.Name, value, zone, moduleName)) 
                var selectedtab: {
                    IsGridEntity: boolean; Name: string; 
} = null;
                var i =0;
                do
                {
                    var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                    });
                    tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                        selectedtab = tabs.Tabs[i];
                        }
                    });
                    i++;
                }
                while(selectedtab == null && i<tabs.Tabs.length);
                if(selectedtab.IsGridEntity)
                {
                    currentZone.Link = '/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/0/'+ selectedtab.Name;
                    this.router.navigate(['/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/0/'+ selectedtab.Name]);
                }
                else
                {
                    currentZone.Link = '/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/{0}/'+ selectedtab.Name;
                    this.router.navigate(['/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/'+ idObject.toString()+'/'+ selectedtab.Name]);
                }
            }
            else
            {
                let moduleName =  modules.Modules[0].ModuleName != null ? modules.Modules[0].ModuleName : modules.Modules[0].Name;
                currentZone.Modules = modules;
                let moduleConfig = await lastValueFrom(this.applicationStructService.getStructOfModule(app.Name, value, zone, moduleName));
                let entity = await lastValueFrom(this.entityService.getFirstEntityAppByEntityType(modules.Space,app.Name, modules.Modules[0].EntityCible));
                currentZone.Link = '/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/{1}/'+ moduleConfig.Tabs[0].Name
                if(moduleConfig.Tabs[0].IsGridEntity && !moduleConfig.Tabs[0].AllowFilterEntity)
                    this.router.navigate(['/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/0/'+ moduleConfig.Tabs[0].Name]);
                else
                {
                this.router.navigate(['/app/' + app.Name+'/'+ zone+'/'+ moduleName+'/'+ entity.IdObject+'/'+ moduleConfig.Tabs[0].Name]);
                }
            }
        }
    }

    ngOnDestroy(): void {
    }

    async goToProfile()
    {
        let url : any =  await lastValueFrom(this.idpService.getProfilUrl());
        window.open(url, '_blank');
    }

    logout($event: any): void {
        this.clearStorage(); 
        this.authService.logout();
    }
    clearStorage() { 
        this.localStorageService.remove('ActiveRoute')
        this.localStorageService.remove('CDOT')
        this.localStorageService.remove('CDOTV')
        this.localStorageService.remove('MDEL')
        this.localStorageService.remove('EREP')
        this.localStorageService.remove('EREPV')
        this.localStorageService.remove('HEBG')
        this.localStorageService.remove('HEBGV')
        this.localStorageService.remove('INFR')
        this.localStorageService.remove('INFRV')
        this.localStorageService.remove('DREF')
        this.localStorageService.remove('DREFV')
        this.localStorageService.remove('SOHO')
        this.localStorageService.remove('SOHOV')
        this.localStorageService.remove('PKCE_verifier')
        this.localStorageService.remove('nonce')
        this.localStorageService.remove('ModeTravail')
        sessionStorage.clear(); }

    async returnToApp(application?: IApp): Promise<void> {
        this.localStorageService.remove("ScrollBar")
        if (application.Name == 'BO')
            this.router.navigate(['/components/'+this.zoneName+'/OP/1/general/CS']);
        else {      
            let zones = await lastValueFrom(this.spaceService.getAvailableZonePerApplications(application.Name));
            let zoneName = this.zoneName;
            let version = '';
            if(zones.filter((tb: { Name: string; }) => tb.Name == this.zoneName).length == 0)
            {
                zoneName = zones[0].Name;
                version = zones[0].CurrentVersion;
            }
            else
                version = zones.filter((tb: { Name: string; }) => tb.Name == this.zoneName)[0].CurrentVersion;
            let modules = await lastValueFrom(this.applicationStructService.getStructOfZone(application.Name, version, zoneName));
            let storage = this.localStorageService.getWithExpiration(zoneName);
            storage = JSON.parse(storage);
            if(storage == null)
            {
                storage = {
                    Version: version,
                    DisplayState:{},
                    Entities: {},
                    Components: {},
                    Blocs: {},
                    Lists:[]
                };
                storage.DisplayState['BO'] = false;
                this.localStorageService.setWithExpiration(zoneName, JSON.stringify(storage), 'CallTimout');
            }
            if(modules.Modules[0].IsLinkToProcedure)
            {
                let procs = await lastValueFrom(this.entityService.getAvailableProcFO(zoneName, version, application.Name, 'a'));
                let mainProc = procs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
                var currentProcedureAssociation  = await lastValueFrom(this.entityService.getProcedureAssociation(zoneName, mainProc.IdObject));
                let entities = await lastValueFrom(this.entityService.getAvailableEntitiesFromProc(zoneName, mainProc.IdObject, modules.Modules[0].ParentEntityCible, application.Name));
                let variante = await lastValueFrom(this.entityService.getVarianteFromEntity(modules.Space, modules.Version, entities[0].IdObject.toString(), modules.Perimeter));
                let selectedModule:any = null;
                var i =0;
                do
                {
                    var procsAvailable =  modules.Modules[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    modules.Modules[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                            selectedModule = modules.Modules[i];
                        }
                    });
                    i++;
                }
                while(selectedModule == null && i<modules.Modules.length);
                let moduleName =  selectedModule.ModuleName != null ? selectedModule.ModuleName : selectedModule.Name;
                let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(application.Name, version, zoneName, moduleName));
                var selectedtab: {
                    IsGridEntity: any; Name: string; 
} = null;
                var i =0;
                do
                {
                    var procsAvailable =  tabs.Tabs[i].AvailableProcedureAssociations.split('.');
                    var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                        return procsAvailable.some((f : string) => {
                            return f === tb;
                        })
                    });
                    tabs.Tabs[i].AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                        if((allowVariant == "RfcCommun" || allowVariant == variante) && tempProcAsso.length > 0){
                        selectedtab = tabs.Tabs[i];
                        }
                    });
                    i++;
                }
                while(selectedtab == null && i<tabs.Tabs.length);
                if(selectedtab.IsGridEntity)
                    this.router.navigate(['/app/' + application.Name+'/'+ zoneName+'/'+ moduleName+'/0/'+ selectedtab.Name]);
                else
                    this.router.navigate(['/app/' + application.Name+'/'+ zoneName+'/'+ moduleName+'/'+ entities[0].IdObject+'/'+ selectedtab.Name]);
            }
            else
            {
                let moduleName =  modules.Modules[0].ModuleName != null ? modules.Modules[0].ModuleName : modules.Modules[0].Name;
                let tabs = await lastValueFrom(this.applicationStructService.getStructOfModule(application.Name, version, zoneName, moduleName));
                if(tabs.Tabs[0].IsGridEntity && !tabs.Tabs[0].AllowFilterEntity)
                    this.router.navigate(['/app/' + application.Name+'/'+ zoneName+'/'+ moduleName+'/0/'+ tabs.Tabs[0].Name]);
                else
                {
                    let entity = await lastValueFrom(this.entityService.getFirstEntityAppByEntityType(this.space, application.Name, tabs.EntityCible));
                    this.router.navigate(['/app/' + application.Name+'/'+ zoneName+'/'+ moduleName+'/'+ entity.IdObject+'/'+ tabs.Tabs[0].Name]);   
                }
            }
        }
    }

    onSpaceClicked(space: any): void {
        this.sessionStorageService.remove('simuLine');
        this.availableApps.forEach(app=>{
            app.Zones = [];
        });
        if (this.sessionStorageService.hasKey('currentCycleRef')) {
            this.sessionStorageService.remove('currentCycleRef');
        };
        this.sessionStorageService.updateCycleReference();
        var storage = this.localStorageService.getWithExpiration(space.Name);
        if (storage == null)
            storage = JSON.parse(storage);
        if (storage == null) {
            
            storage = {
                Version: space.CurrentVersion,
                DisplayState:{},
                Entities: {},
                Components: {},
                Blocs: {}
            };
            storage.DisplayState['BO'] = false;
            this.localStorageService.setWithExpiration(space.Name, JSON.stringify(storage), 'CallTimout');
        }
        else if (storage.Version != space.CurrentVersion) {
            
            localStorage.removeItem(storage);
            storage = {
                Version: space.CurrentVersion,
                DisplayState:{},
                Entities: {},
                Components: {},
                Blocs: {}
            };
            storage.DisplayState['BO'] = false;
            this.localStorageService.setWithExpiration(space.Name, JSON.stringify(storage), 'CallTimout');
        }
        else if(storage.DisplayState[this.applicationName] == null){
            
            storage.DisplayState[this.applicationName] = this.displayState;
            this.localStorageService.setWithExpiration(space.Name, JSON.stringify(storage), 'CallTimout');
        }
        this.router.navigate(['/components/'+space.Name+'/OP/1/general/CS']);
    }

    displayPanel(): void {
        this.panelCompo.openPanel()
    }
}