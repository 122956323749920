export class DateUtils {
    static getDateFromString(dateString: String): Date {
        var date = new Date();
        if (dateString.includes('-')) {
            const [year, month, day] = dateString.split('-');
            date = new Date(+year, +month - 1, +day);
        }
        else{
            const [day, month, year] = dateString.split('/');
            date = new Date(+year, +month - 1, +day);
        }

        return date;
    }

    static isValidDate(dateString: String): Boolean {
        const date = this.getDateFromString(dateString);
        return !isNaN(date.getTime());
    }

    static getDateFromStringOrToday(dateString: String): Date{
        return dateString != null && dateString.length > 0 && this.isValidDate(dateString) 
            ? this.getDateFromString(dateString) 
            : new Date();
    }
}