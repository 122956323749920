import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { NotificationMessage } from "./NotificationMessage";
import { v4 as uuid } from 'uuid';
import { HomePageService } from "app/home-page/home-page.service";

@Injectable()
export class NotificationService {
    private historyNotificationKey: string = "historicNotifications";
    private historic: BehaviorSubject<NotificationMessage[]> = new BehaviorSubject<NotificationMessage[]>([]);
    private historicObservable: Observable<NotificationMessage[]> = new Observable<NotificationMessage[]>();
    
    public onIsOpenedChange = new EventEmitter();

    constructor(private localStorageService: LocalStorageService, private spaceService: HomePageService) {
    }

    public async addNotification(notif: NotificationMessage) {
        if (notif) {
            if (notif.MessageType == 1) {
                console.debug('notif refresh cache detected: ', notif.CacheKey);
                if (notif.CacheKey) {
                    var space = notif.CacheKey.split('-')[0];
                    if (this.localStorageService.hasKey(space)) {
                        console.debug('key cache removed in localstorage: ', space);
                        this.localStorageService.remove(space);
                        let version = await this.spaceService.getSpaceVersion('BO', space, false);               
                        var storage = <any>{
                            Version: version,
                            DisplayState:{},
                            Entities: {},
                            Components: {},
                            Blocs: {},
                            Lists:[]
                        };
                        storage.DisplayState['BO'] = false;
                        this.localStorageService.setWithExpiration(space, JSON.stringify(storage), 'CallTimout');
                    }
                }
            }
            else {
                
                if (notif.LongName.includes("data-href")) {
                    //console.debug("find link");

                    var urlLinkMatches = notif.LongName.match("data-href='([0-9a-zA-Z_\/-]*)'");
                    if (urlLinkMatches && urlLinkMatches.length > 0) {
                        var labelLinkMatches = notif.LongName.match("<a (.*)>(.*)</a>");
                        if (labelLinkMatches && labelLinkMatches.length > 0) {
                            notif.docUrl = urlLinkMatches[1];
                            notif.docUrlLabel = labelLinkMatches[2];

                            //render color is different between toast and panel...
                            notif.LongName = notif.LongName.replace("class='text-light'", "");
                            var matches = notif.LongName.match("<a (.*)>(.*?)<\/a>");
                            if (matches && matches.length > 0) {
                                notif.LongName = notif.LongName.replace(matches[0], "");
                            }
                            //console.debug('notif without link:', matches);
                        }
                    }
                } 

                var notifications: NotificationMessage[] = this.localStorageService.get(this.historyNotificationKey);
                if (notifications) {
                    notif.reference = uuid();
                    var isFound = false;
                    notifications.forEach(function (item, index, tab) {
                        if (notif.Identifier && item.Identifier == notif.Identifier) {
                            //notification already provides, refresh it needs
                            tab[index] = notif;
                            isFound = true;
                        }
                    });

                    if (!isFound) {
                        notifications.push(notif);
                    }
                    this.localStorageService.set(this.historyNotificationKey, notifications);
                } else {
                    var notifications: NotificationMessage[] = [];
                    notif.reference = uuid();
                    notifications.push(notif);
                    this.localStorageService.set(this.historyNotificationKey, notifications);
                }

                this.historic.next(notifications);
            }
        }        
    }

    public watch(): Observable<NotificationMessage[]> {
        var item = this.localStorageService.get(this.historyNotificationKey);
        if (item === undefined) {
            this.historicObservable = new Observable<NotificationMessage[]>();
        }
        else {
            this.historicObservable = this.historic.asObservable();
            this.historic.next(item);
        }
        return this.historicObservable;
    }

    public getNotifications(): NotificationMessage[] {
        var notifications: NotificationMessage[] = this.localStorageService.get(this.historyNotificationKey);
        if (notifications) {
            return notifications;
        } else {
            var notifications: NotificationMessage[] = [];
            return notifications;
        }
    }

    public hasNotification(Identifier: string): boolean {
        var notifications: NotificationMessage[] = this.localStorageService.get(this.historyNotificationKey);
        if (notifications) {
            var isFound = false;
            notifications.forEach(function (item, index, tab) {
                if (item.Identifier == Identifier) {
                    isFound = true;
                }
            });
            return isFound;
        } else {
            return false;
        }
    }

    public removeNotification(notif: NotificationMessage): void {
        var notifications = this.localStorageService.get(this.historyNotificationKey);
        if (notifications) {
            var index = -1;
            notifications.forEach(function (item: NotificationMessage, pos: number, tab: NotificationMessage[]) {
                if (item.reference == notif.reference) {
                    index = pos;
                }
            });

            if (index != -1) {
                notifications.splice(index, 1);
                this.localStorageService.set(this.historyNotificationKey, notifications);
                this.historic.next(notifications);
            }
        }
    }

    private clearHistoric(): void {
        var ExpirationDt = new Date();
        var delayOfdayInSeconds = 86400;
        var notifications = this.localStorageService.get(this.historyNotificationKey);
        if (notifications) {
            notifications.forEach(function (item: NotificationMessage, index: number, object: NotificationMessage[]) {
                if (item.ExpirationDt) {
                    var expirationDtOfItem = new Date(item.ExpirationDt)
                    if (ExpirationDt.getTime() > expirationDtOfItem.getTime()) {
                        object.splice(index, 1);
                    }
                }
                else {
                    var CreationDt = new Date(item.CreationDt);
                    if (delayOfdayInSeconds > ExpirationDt.getTime() - CreationDt.getTime()) {
                        item.Category = "Archives";
                    }
                }
            });

            this.localStorageService.set(this.historyNotificationKey, notifications);
            this.historic.next(notifications);
        }
    }
}