// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDropdownItem {
    display: flex;
    margin: 3px;
    padding: 2px;
    color: #337ab7;
    font-size: 12px;
    max-width: 150px;
}

.customDropdownItem:hover{
    color:#ffffff;
}

.overrideIcon {
    font-size: 14px;
}

.item {
    display: flex;
    margin: 3px;
    padding: 2px;
    color: #337ab7;
    border-color: #337ab7;
    float: right;
}

    .item:hover {
        border-radius: 5px;
        color: #ffffff;
        border-color: #ffffff;
        background: #337ab7;
        cursor: pointer;
    }
`, "",{"version":3,"sources":["webpack://./app/back-office/back-office-toolbar-item/back-office-toolbar-item.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,cAAc;IACd,qBAAqB;IACrB,YAAY;AAChB;;IAEI;QACI,kBAAkB;QAClB,cAAc;QACd,qBAAqB;QACrB,mBAAmB;QACnB,eAAe;IACnB","sourcesContent":[".customDropdownItem {\r\n    display: flex;\r\n    margin: 3px;\r\n    padding: 2px;\r\n    color: #337ab7;\r\n    font-size: 12px;\r\n    max-width: 150px;\r\n}\r\n\r\n.customDropdownItem:hover{\r\n    color:#ffffff;\r\n}\r\n\r\n.overrideIcon {\r\n    font-size: 14px;\r\n}\r\n\r\n.item {\r\n    display: flex;\r\n    margin: 3px;\r\n    padding: 2px;\r\n    color: #337ab7;\r\n    border-color: #337ab7;\r\n    float: right;\r\n}\r\n\r\n    .item:hover {\r\n        border-radius: 5px;\r\n        color: #ffffff;\r\n        border-color: #ffffff;\r\n        background: #337ab7;\r\n        cursor: pointer;\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
