
export enum NotificationCategory {
    Infos,
    Progress,
    Docs,
}

export class NotificationMessage {
    public reference: string = "";
    public docUrl: string = "";
    public docUrlLabel: string = "";
    progress: number = 0;
    downloading: boolean = false;

    constructor(
        public Category: string,
        public CreationDt: Date,
        public Level: string,
        public ShortName: string,
        public LongName: string,
        public Orign?: string,
        public DelayToast?: number,
        public UserIdentifier?: string,
        public Description?: string,
        public LabelLinkForDescription?: string,
        public ExpirationDt?: Date,
        public Identifier?: string,
        public MessageType?: number,
        public CacheKey?: string,
        public Action?: string,
        public NameInModal?:string
    ) {
    }
}
