import { Component, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import * as $ from 'jquery';
import { Subscription } from "rxjs";
import { NotificationMessage } from "../NotificationMessage";
import { NotificationService } from "../notification.service";
import {  faClose } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from "@angular/platform-browser";
import { DownloadService } from "../download.service";
import { AuthService } from "app/shared/auth.service";
import { PopupService } from "../popup.service";

@Component({
    selector: 'panel-infos',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.css']
})
@Injectable({ providedIn: 'root' })
export class PanelComponent implements OnInit, OnDestroy {
    
    @Input() isMobile: boolean = false;
    informationalNotifs: NotificationMessage[] = [];
    archiveNotifs: NotificationMessage[] = [];
    userEmail: string = '';
    subscriptionOfNotifications?: Subscription;
    fileDls: any;
    faClose = faClose;
    
    constructor(
        private popupService: PopupService,
        private downloadService: DownloadService,
        private notificationService: NotificationService,
        private authService: AuthService,
        public sanitizer: DomSanitizer) {
        $("#panelExtraInfo").hide();    
        this.fileDls = {};    
        this.userEmail = this.authService.getEmail();
    }

    ngOnInit(): void {}

    GetTextStatus(notif:NotificationMessage) {
        switch (notif.Level) {
            case "Info":
                return "<i class='fa fa-spinner me-2 accordionIcon'></i> <label class='title-notif'>"+notif.ShortName+"</label>"
            case "Success":
                return "<i class='fa fa-circle-check me-2 accordionIcon'></i> <label class='title-notif'>"+notif.ShortName+"</label>"
            case "Danger":
                return "<i class='fa fa-bug me-2 accordionIcon'></i> <label class='title-notif'>"+notif.ShortName+"</label>"
            case "Warning":
                return "<i class='fa fa-triangle-exclamation me-2 accordionIcon'></i> <label class='title-notif'>"+notif.ShortName+"</label>"
        }

        return "";
    }

    GetClassCss(notif: NotificationMessage): string {
        return "text-" + notif.Level.toLowerCase();
    }

    removeNotif($event: any, notif: NotificationMessage): void {
        this.notificationService.removeNotification(notif);
    }

    download(notif: NotificationMessage): void {
        if(this.fileDls[notif.docUrl] != null)
            return;
        this.fileDls[notif.docUrl] = true;
        notif.downloading=true;
        notif.progress = 0;
        var progress = $("#notif"+notif.Identifier).dxProgressBar({
            min: 0,
            max: 100,
            value: 0,
            statusFormat(ratio) {
                return `Chargement : ${ratio * 100}%`;
              },
              onComplete(e) {
                e.element.addClass('complete');
              },
        }).dxProgressBar('instance');
        $("#notif"+notif.Identifier).show();
        this.downloadService.download(notif.docUrl).subscribe(response => {

            if (response.progress !== undefined) {
                progress.option('value', Math.ceil(response.progress));
            } else if (response.body) {
                progress.option('value', 100);
                var filename = "download-VDSASH";
                let f= response.headers.get('content-disposition')?.split(';')[1];
                if (f && f.includes('filename=')) {
                    filename = f.substring(10);
                    filename = filename.replace("\"", "");
                    filename = filename.replace("\"", "");
                        filename = decodeURIComponent(filename);
                }
                this.fileDls[notif.docUrl] = null;
                notif.downloading=false;
                
                $("#notif"+notif.Identifier).hide();
                notif.progress = 0;
                const a = document.createElement('a')
                a.href = window.URL.createObjectURL(response.body);
                a.download = filename;
                console.debug('filename: ' + filename);
                a.click();
            }
        },error=>{
            $("#notif"+notif.Identifier).hide();
            notif.downloading=false;
            notif.progress = 0;
            this.fileDls[notif.docUrl] = null;
            var createdDt=new Date();
            var expirationDt=new Date(createdDt.getFullYear(),createdDt.getMonth(),createdDt.getDate(),createdDt.getHours()+1,createdDt.getMinutes());
            this.notificationService.addNotification(new NotificationMessage("Downloads",new Date(),"Danger","Une erreur s'est produite","Impossible de récupérer le document","spa",5,undefined,undefined,undefined,expirationDt,undefined,undefined));
        });
    }

    closePanel(): void {
        $("#panelExtraInfo").hide();
        $("#panelActivity").hide();
        if(!$("#panel-selector-tree").is(':visible'))
        {
            $("#bodyApp").css('width','calc(100vw - 300px)');
            if($("#panelContextInfo").is(':visible'))
            {
                $("#panelContextInfo").css('right','0');
            }
        }
        else
        {
            $("#bodyApp").css('width','calc(100vw - 960px)');
        }
        this.notificationService.onIsOpenedChange.emit(false);
    }

    openPanel(): void {
        $("#panelExtraInfo").show();
        $("#panelActivity").show();
        if(!$("#panel-selector-tree").is(':visible'))
        {
            $("#bodyApp").css('width','calc(100vw - 540px)');
            if($("#panelContextInfo").is(':visible'))
            {
                $("#panelContextInfo").css('right','240px');
            }
        }
        else
        {
            $("#bodyApp").css('width','calc(100vw - 960px - 200px - 2rem)');
        }

        this.notificationService.onIsOpenedChange.emit(true);

        this.actualiseAccordion()
    }

    ngOnDestroy() {
        this.subscriptionOfNotifications?.unsubscribe();
    }

    actualiseNotificationsData(notifications : any[]) {
        if (notifications) {
            this.informationalNotifs = [];
            this.archiveNotifs = [];
            var todaysDate = new Date();
            for (var i = 0; i < notifications.length; i++) {
                var notif = notifications[i];
                if(notif.UserIdentifier == this.userEmail)
                {
                    switch (notif.Category) {
                        case "Infos":
                            if(new Date(notif.CreationDt).setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
                                this.informationalNotifs.push(notif);
                            }
                            else {
                                this.archiveNotifs.push(notif);
                            }
                            break;
                        case "Archives":
                            this.archiveNotifs.push(notif);
                            break;
                    }
                }
            }
        }

        this.notificationService.onIsOpenedChange.emit(false);
    }

    actualiseAccordion() {
        this.subscriptionOfNotifications = this.notificationService.watch().subscribe(notifications => {
            this.actualiseNotificationsData(notifications);

            var datasource = [{Title: "Du jour", Id: 'day'}, {Title: "Archives", Id: 'archives'}];

            if ($("#accordion").data("dxAccordion") != undefined) {
                var accordion = $("#accordion").dxAccordion('instance');  
                var selectedIndex = -1;  

                var selectedItems = accordion.option('selectedItems');  
                datasource.forEach(function (item, index) {  
                    if (selectedItems[0].Id == item.Id) {  
                        selectedIndex = index;
                    }  
                });  

                accordion.option("dataSource", datasource);

                if (selectedIndex > -1) {
                    accordion.option("selectedIndex", selectedIndex);
                }
            }
            else {
                $("#accordion").dxAccordion({
                    dataSource: datasource,
                    itemTitleTemplate: (data) => data.Title,
                    itemTemplate: (data) => this.getInnerHTMLAccordionItem(data.Id),
                    collapsible: true,
                });
            }
        });
    }

    getInnerHTMLAccordionItem(id:any) {
            
        var container = document.createElement('div');

        var datasource = id == 'archives' ? this.archiveNotifs : this.informationalNotifs;

        datasource.slice().reverse().forEach((notif, index) => {
            const notificationElement = document.createElement('div');
            notificationElement.className = 'row notif mb-2 accordionNotifDiv';
            if (index === datasource.length - 1) {
                notificationElement.classList.add('lastRow');
            }

            const closeButton = document.createElement('span');
            closeButton.className = 'accordionCloseButton';
            closeButton.title = 'Supprimer';
            closeButton.innerHTML = '<i class="fa fa-xmark"></i>';
            closeButton.addEventListener('click', (event) => this.removeNotif(event, notif));
    
            notificationElement.appendChild(closeButton);
    
            const statusSpan = document.createElement('span');
            statusSpan.className = this.GetClassCss(notif) + ' accordionStatusText';
            statusSpan.style.display = 'inline-block';
            statusSpan.innerHTML += this.GetTextStatus(notif);

            const dateSpan = document.createElement('span');
            dateSpan.className = 'text-muted text-date accordionDateText';
            dateSpan.textContent = new Date(notif.CreationDt).toLocaleString('fr-FR');
    
            const downloadButton = document.createElement('button');
            downloadButton.className = 'btn btn-outline-secondary download-button';
            downloadButton.innerHTML = '<i class="fa-solid fa-download"></i>';
            downloadButton.addEventListener('click', () => this.download(notif));
    
            const detailsLink = document.createElement('a');
            detailsLink.className = 'ms-2 h6 link-info link-details';
            detailsLink.innerHTML = notif.LabelLinkForDescription;
            detailsLink.addEventListener('click', () => this.detailLinkOnClick(notif));
    
            const progressDiv = document.createElement('div');
            progressDiv.className = 'progress';
            progressDiv.id = 'notif'+notif.Identifier;
            progressDiv.hidden = true;

            notificationElement.appendChild(statusSpan);
            if (notif.LabelLinkForDescription) {
                const p1 = document.createElement('p');
                p1.style.marginBottom = '0';
                p1.style.display = 'inline-block';
                p1.appendChild(dateSpan);
                if (notif.docUrl != null && notif.docUrl != '') {
                    p1.appendChild(downloadButton);
                }
                p1.appendChild(document.createElement('br'));
                p1.appendChild(detailsLink);
                notificationElement.appendChild(p1);
            } else {
                const p2 = document.createElement('p');
                p2.style.marginBottom = '0';
                p2.style.display = 'inline-block';
                p2.appendChild(dateSpan);
                if (notif.docUrl != null && notif.docUrl != '') {
                    p2.appendChild(downloadButton);
                }
                notificationElement.appendChild(p2);
            }
            if (notif.docUrl != null && notif.docUrl != '') {
                notificationElement.appendChild(progressDiv);
            }
    
            container.appendChild(notificationElement);
        });

        return container;
    }

    detailLinkOnClick(notification: NotificationMessage) {
        this.popupService.openDetailOfNotification(notification);
    }
}