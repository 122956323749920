import { HttpClient } from "@angular/common/http";
import { ApplicationRef, Component,  EventEmitter,  Input, Output, createComponent } from "@angular/core";
import {  FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import { PopupService } from "./popup.service";
import * as $ from 'jquery';

@Component({
  selector:'ngbd-modal-export-content',
  template: `
    <form [formGroup]="fg" novalidate>
      <div class="modal-body">
        <div *ngFor="let schema of exportDefinitions" class="form-check">
          <div id="selectExportForm{{schema.Name}}">
            <input mdbRadio class="form-check-input" type="radio" id="radio{{schema.Name}}" name="drone" />
            <label class="form-check-label" for="radio{{schema.Name}}">{{schema.Label}}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
          Annuler
        </button>
        <button type="button" class="btn btn-primary modalOkButton" id="popupOkButton" disabled >          
          Ok
        </button>
      </div>
    </form>
  `
})

export class NgbdModalExportContent {
  @Input() title: string = "default";

  @Output() messageToParent :EventEmitter<any> = new EventEmitter();

  public fg!: FormGroup;
  public exportDefinitions : any[];
  public selectedExport : any = '';
  constructor(
    public fb: FormBuilder,
    public activePopup: PopupService,
    public sanitizer: DomSanitizer){
      this.fg=this.fb.group({
      InputFile: ['',[Validators.required]]
    });
  }
}

@Component({
    selector: "ngbd-modal-export-component",
    template:''
})

export class NgbdModalExportComponent{
  @Output() refreshDataGridChange: EventEmitter<any> = new EventEmitter();
  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }
  insidePopup: any;

  show(actionAPI: IActionAPI, exportDefinitions : any[]){

    exportDefinitions = exportDefinitions.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => a['Sorting'] > b['Sorting'] ? 1 : a['Sorting'] === b['Sorting'] ? 0 : -1);

    this.popupService.showPopup('Exports', this.getContentTemplate(actionAPI, exportDefinitions));

    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
    document.getElementById('popupOkButton').addEventListener("click", () => { this.validExport(); });

    exportDefinitions.forEach(x => {
      document.getElementById('selectExportForm' + x.Name).addEventListener("click", () => { this.selectExportClick(x); });
    });


    if (exportDefinitions.length == 1) {
      this.insidePopup.selectedExport = exportDefinitions[0];
      this.insidePopup.instance.selectedExport = exportDefinitions[0];
      $('#radio' + exportDefinitions[0].Name).prop('checked', true);    
      this.actualiseOkButton();
    }
  }

  getContentTemplate(actionAPI: IActionAPI, exportDefinitions : any[]) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalExportContent, {
        environmentInjector,
    });
        
    this.insidePopup.instance.exportDefinitions = exportDefinitions;

    this.insidePopup.instance.messageToParent.subscribe((exportShema:any)=>{
      var parameter = {
        SchemaName : exportShema.Name,
        SchemaLongName : exportShema.Label
      }
      actionAPI.Parameters.ExtraParameters = JSON.stringify(parameter);
      this.refreshDataGridChange.emit(actionAPI);
    });

    this.insidePopup.hostView.detectChanges();
    
    return this.insidePopup.location.nativeElement;
  }

  selectExportClick(schema : any) {
    this.insidePopup.instance.selectedExport = schema;
    this.actualiseOkButton();
  }

  validExport() {
    this.insidePopup.instance.messageToParent.emit(this.insidePopup.instance.selectedExport);
    this.popupService.closePopup();
  }

  actualiseOkButton() {
    $('#popupOkButton').prop('disabled', this.insidePopup.instance.selectedExport == undefined || this.insidePopup.instance.selectedExport == '');
  }
  
}

