import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { AppService } from "app/app.service";
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'panel-mobile-footer',
    templateUrl: './panel-mobile-footer.component.html',
})
@Injectable({ providedIn: 'root' })
export class PanelMobileFooterComponent implements OnInit, OnDestroy {
    version = '';
    
    constructor(private appService: AppService){}

    async ngOnInit(): Promise<void> {
        let infos = await lastValueFrom(this.appService.getAssemblyVersion())
        this.version = infos.AssemblyVersion;
    }

    ngOnDestroy(): void {}
}
