import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import { custom } from "devextreme/ui/dialog";

export class NgbdModalConfirmationContent implements OnInit {
    @Input() title: string = "default";
    @Input() contentHtml: string = "";
    @Input() identifier: string = "";
    @Input() messageToParent = '';
    constructor(public activeModal: any, public sanitizer: DomSanitizer) { }

    ngOnInit() {
    }
}


export class NgbdModalConfirmationComponent {
  public refreshDataGridChange = new EventEmitter()
  public confirmDeleteChange = new EventEmitter()
  public confirmDeleteDocChange = new EventEmitter()
  public confirmAddingDocChange = new EventEmitter()
  public confirmAddingCommentChange = new EventEmitter()
  constructor(
  ) { }

  clean()
  {
    this.confirmDeleteChange = new EventEmitter();      
    this.confirmDeleteDocChange = new EventEmitter();
    this.confirmAddingDocChange = new EventEmitter();
    this.confirmAddingCommentChange = new EventEmitter()
  }

  showConfirmationAction(title: string, description: string, actionAPI: IActionAPI) {
    let customConfirmDialog = this.getDialog(title, description);

    customConfirmDialog.show().then((res: { buttonText: string; el: { element: { classList: { remove: () => void; }; }; }; }) => {
        if (res.buttonText === "Ok") {
          this.refreshDataGridChange.emit(actionAPI)
        }
      });
  }

  showConfirmationWindow(title: string, description: string, gridId: string, identifiant:string) {
    let customConfirmDialog = this.getDialog(title, description);

    if(gridId != 'BO')
    {
      customConfirmDialog.show().then((res: { buttonText: string; el: { element: { classList: { remove: () => void; }; }; }; }) => {
        if (res.buttonText === "Ok") {
          return this.confirmDeleteChange.emit({ Retour : true, GridId : gridId, RowId : identifiant})
        }
      });
    }
    else
    {
      customConfirmDialog.show().then((res: { buttonText: string; el: { element: { classList: { remove: () => void; }; }; }; }) => {
        if (res.buttonText === "Ok") {
          return this.confirmDeleteDocChange.emit({ Retour : true, GridId : gridId, DocId : identifiant})
        }
      });    
    }
  }

  showSimpleConfirmation(title: string, description: string, object: any, confirmationType: ConfirmationType) {
    let customConfirmDialog = this.getDialog(title, description);

    customConfirmDialog.show().then((res: { buttonText: string; el: { element: { classList: { remove: () => void; }; }; }; }) => {
      if (res.buttonText !== "Ok") {
        object = null;
      }

      switch(confirmationType) { 
        case ConfirmationType.DeleteDocument: { 
          return this.confirmDeleteDocChange.emit(object)
        } 

        case ConfirmationType.AddingDocument: { 
          return this.confirmAddingDocChange.emit(object)
        } 

        case ConfirmationType.AddingComment: { 
          return this.confirmAddingCommentChange.emit(object)
        } 
     }
    }); 
  }

  getDialog(title: any, description: any) {
    return custom({
      title: title,
      messageHtml: description,
      buttons: [
        {
          text: "Annuler",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          }
        },
        {
          text: "Ok",
          onClick: (e) => {
            return { el: e, buttonText: e.component.option("text") };
          }
        }
      ]
    });
  }
}

enum ConfirmationType {
  DeleteDocument = 0,
  AddingDocument = 1,
  AddingComment = 2
}