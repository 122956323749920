import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { IActionAppButton } from "app/front-office/front-office-struct";
import { IActionAPI, IActionButton, IEntity, RenderTypeButton } from "app/shared/entity";
import { LocalStorageService } from "app/shared/local-storage.service";
import { SessionStorageService } from "app/shared/session-storage.service";
import { Subscription, lastValueFrom } from "rxjs";
import { EntityService } from "./entity.service";
import { PopupCloneComponent } from "./popup-clone.component";
import { NgbdModalCommunicationComponent } from "../front-office/modal-communication/modal-communication.component";
import { NgbdModalConfirmationComponent } from "../front-office/modal-confirmation.component";
import { NgbdModalExportComponent } from "./modal-export.component";
import { NgbdModalImportComponent } from "./modal-import.component";
import { NgbdModalReportComponent } from "./modal-report.component";
import { StringUtils } from "app/utils/string-utils";
import { NgbdModalSeqErrorComponent } from "../front-office/modal-seq-error/modal-seq-error.component";

@Injectable({ providedIn: 'root' })
export class ActionLegacyService implements OnDestroy {
    public refreshDataGridChangeAlert = new EventEmitter()
    public refreshDataGridChangeAlertApp = new EventEmitter()
    public executeActionChangeAlert = new EventEmitter()
    public executeActionChangeAlertApp = new EventEmitter()
    public addNewLineDataGridChangeAlert = new EventEmitter()
    public changeProcEmitter = new EventEmitter()
    public changeSimuEmitter = new EventEmitter()
    sub!: Subscription;
    sub2!: Subscription;
    sub3!: Subscription;
    sub4!: Subscription;
    sub5!: Subscription;
    sub6!: Subscription;
    private cutedSelectionKey = "currentCutedSelection";
    private currentCycleRefKey = "currentCycleRef";
    private cutedFeatureKey = "FeatureCutAndPasteEnabled";

    constructor(
        private confirmationModalComponent: NgbdModalConfirmationComponent,
        private cloneModalComponent: PopupCloneComponent,
        private reportModalComponent: NgbdModalReportComponent,
        private communicationModalComponent: NgbdModalCommunicationComponent,
        private seqModalComponent: NgbdModalSeqErrorComponent,
        private exportModalComponent: NgbdModalExportComponent,
        private importModalComponent: NgbdModalImportComponent,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private entityService: EntityService
    ) {
        this.ngOnDestroy();
        this.sub = this.confirmationModalComponent.refreshDataGridChange.subscribe((actionAPI) => {
            if(actionAPI.Uri.indexOf('_app') == -1)
                this.refreshDataGridChangeAlert.emit(actionAPI);
            else 
                this.refreshDataGridChangeAlertApp.emit(actionAPI);
        });
        this.sub2 = this.cloneModalComponent.refreshDataGridChange.subscribe((actionAPI) => {
            if(actionAPI.Uri.indexOf('_app') == -1)
                this.refreshDataGridChangeAlert.emit(actionAPI);
            else 
                this.refreshDataGridChangeAlertApp.emit(actionAPI);
        });
        this.sub3 = this.importModalComponent.onSelectedFile.subscribe((actionAPI) => {
            if(actionAPI.Uri.indexOf('_app') == -1)
                this.refreshDataGridChangeAlert.emit(actionAPI);
            else 
                this.refreshDataGridChangeAlertApp.emit(actionAPI);
        })
        this.sub5 = this.reportModalComponent.refreshDataGridChange.subscribe((actionAPI) => {
            this.executeActionChangeAlertApp.emit(actionAPI);
        });
        this.sub5 = this.communicationModalComponent.refreshCommunicationChange.subscribe((actionAPI) => {
            this.executeActionChangeAlertApp.emit(actionAPI);
        });
        this.sub6 = this.exportModalComponent.refreshDataGridChange.subscribe((actionAPI) => {
            this.executeActionChangeAlertApp.emit(actionAPI);
        });
    }

    cleanEmitter()
    {
        this.refreshDataGridChangeAlert = new EventEmitter()
        this.refreshDataGridChangeAlertApp = new EventEmitter()
        this.executeActionChangeAlert = new EventEmitter()
        this.executeActionChangeAlertApp = new EventEmitter()
        this.addNewLineDataGridChangeAlert = new EventEmitter()
        this.changeProcEmitter = new EventEmitter();
        this.changeSimuEmitter = new EventEmitter();
    }

    ngOnDestroy(): void {
        if (this.sub != null)
            this.sub.unsubscribe();
        if (this.sub2 != null)
            this.sub2.unsubscribe();
        if (this.sub3 != null)
            this.sub3.unsubscribe();
        if (this.sub4 != null)
            this.sub4.unsubscribe();
        if (this.sub5 != null)
            this.sub5.unsubscribe();
        if (this.sub6 != null)
            this.sub6.unsubscribe();
    }

    async callJsAction(actionName: string, actionAPI: IActionAPI, selectedEntity: IEntity[], parentType: string, space:string, reportsDefinitions : any[], exportDefinitions : any[], applicationName:string, confirmMessage : string, version : string) {
        switch (actionName) {
            case 'deleteEntities':
                this.deleteEntities(actionAPI, space);
                break;
            case 'deleteEntity':
                this.deleteEntity(actionAPI, space);
                break;
            case 'lockEntities':
                this.lockEntities(actionAPI);
                break;
            case 'cloneEntity':
                await this.cloneEntity(actionAPI, selectedEntity, parentType, space);
                break;
            case 'cloneEntityLR':
                await this.cloneEntityLR(actionAPI, selectedEntity, parentType, space, version);
                break;
            case 'addEntity':
                await this.addEntity(actionAPI, parentType, space);
                break;
            case 'changeStatus':
                await this.changeStatus(actionAPI, confirmMessage);
                break;
            case 'transmit':
                this.transmitEntity(actionAPI);
                break;
            case 'reportApp':
                this.reportApp(actionAPI, reportsDefinitions);
                break;
            case 'communicationMangement':
                this.communicationManagement(actionAPI, actionAPI.Parameters.EntityId, space, applicationName);
                break;
            case 'seqManagement':
                this.seqErrorManagement(actionAPI, actionAPI.Parameters.EntityId, space, applicationName);
                break;
            case 'exportApp':
                this.exportApp(actionAPI, exportDefinitions);
                break;
            case 'CutSelection':
                this.refreshDataGridChangeAlert.emit(null);
                break;
            case 'TakeReference':
                this.executeActionChangeAlert.emit(null);
                break;
            case 'ImportApp':
                this.importApp(actionAPI);
                break;
            case 'ImportExcel':
                this.importExcel(actionAPI, selectedEntity, parentType);
                break;
            case 'addNewLine':
                this.addNewLineDataGridChangeAlert.emit(null);
                break;
            case 'reportEntities':
            case 'CompareFull':
            case 'ComparePartial':
                this.executeActionChangeAlert.emit(actionAPI);
                break;
            case 'PasteSelection':
            case 'takeOwnership':
                this.refreshDataGridChangeAlert.emit(actionAPI);
                break;
            default:
                if(actionAPI.Uri.indexOf('_app') == -1)
                    this.refreshDataGridChangeAlert.emit(actionAPI);
                else 
                    this.refreshDataGridChangeAlertApp.emit(actionAPI);
                break;
        }
    }

    deleteEntities(actionAPI: IActionAPI, space : string) {
        if(StringUtils.isMainEntity(actionAPI.Parameters.CurrentEntityType))
        {
            this.entityService.getProcOfEntity(space, actionAPI.Parameters.EntititiesIds[0], 'FO').subscribe({
                next: (proc: { IdObject: any; }) => {
                    actionAPI.Parameters.SYParentId = proc.IdObject.toString();
                    this.confirmationModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer ces entités ?', actionAPI)
                }});
        }
        else
            this.confirmationModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer ces entités ?', actionAPI)
    }

    deleteEntity(actionAPI: IActionAPI, space : string) {
        this.confirmationModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sûr(e) de vouloir supprimer cette simulation ?', actionAPI)
    }

    lockEntities(actionAPI: IActionAPI) {
        this.confirmationModalComponent.showConfirmationAction('Demande de confirmation', 'Etes-vous sur(e) de vouloir figer cette entité ?', actionAPI)
    }

    reportApp(actionAPI: IActionAPI, reportsDefinitions : any[]) {
       this.reportModalComponent.show(actionAPI, reportsDefinitions)
    }

    communicationManagement(actionAPI: IActionAPI,entityId: string, space: string, applicationName:string) {
        this.communicationModalComponent.show(actionAPI,entityId, space, applicationName)
    }

    seqErrorManagement(actionAPI: IActionAPI,entityId: string, space: string, applicationName:string) {
        this.seqModalComponent.show(actionAPI,entityId, space, applicationName)
    }

    exportApp(actionAPI: IActionAPI, exportDefinitions : any[]) {
        this.exportModalComponent.show(actionAPI, exportDefinitions)
    }

    changeStatus(actionAPI: IActionAPI, confirmation:string) {
       this.confirmationModalComponent.showConfirmationAction('Changement état', confirmation, actionAPI)
    }

    transmitEntity(actionAPI: IActionAPI) {
        this.confirmationModalComponent.showConfirmationAction('Validation du formulaire', 'Voulez-vous vraiment valider le formulaire ? Il ne pourra plus être modifié sans une demande de remise en travail auprès de la DGCS.', actionAPI)
    }

    async cloneEntity(actionAPI: IActionAPI, selectedEntity: IEntity[], parentType: string, space:string) {
        var showSimu = space != 'MDEL' && (parentType == 'CY' || StringUtils.isMainEntity(parentType))
        var enableEntity = ((parentType == 'CY' && selectedEntity[0].Status == 'V') || parentType != 'CY') && !selectedEntity[0].IsSpecial;
        var preName = await lastValueFrom(this.entityService.GetNamePattern(space, parentType));
            if(StringUtils.isMainEntity(actionAPI.Parameters.CurrentEntityType))
            {
            this.entityService.getProcOfEntity(space, actionAPI.Parameters.EntititiesIds[0], 'FO').subscribe({
                next: (proc: { IdObject: any; }) => {
                    actionAPI.Parameters.SYParentId = proc.IdObject.toString();
                    this.cloneModalComponent.showCloneAction('Nouvel élément par copie de l\'élément sélectionné', actionAPI, showSimu, enableEntity, preName != '', preName, false)
                }});
        }
        else
            this.cloneModalComponent.showCloneAction('Nouvel élément par copie de l\'élément sélectionné', actionAPI, showSimu, enableEntity, preName != '', preName, showSimu)
    }

    async cloneEntityLR(actionAPI: IActionAPI, selectedEntity: IEntity[], parentType: string, space:string, version: string) {
        var enableEntity = ((parentType == 'CY' && selectedEntity[0].Status == 'V') || parentType != 'CY') && !selectedEntity[0].IsSpecial;
        var preName = await lastValueFrom(this.entityService.GetNamePattern(space, parentType));
        this.cloneModalComponent.showCloneActionLR('Nouvel élément par copie de l\'élément sélectionné', actionAPI, false, enableEntity, preName != '', preName, false, version)
    }

    async addEntity(actionAPI: IActionAPI, parentType: string, space:string) {
        var showSimu = parentType == 'SY' || parentType == 'VA'
        var enableEntity = false;
        var preName = await lastValueFrom(this.entityService.GetNamePattern(space, parentType));
        this.cloneModalComponent.showCloneAction('Veuillez préciser un nom pour cette simulation', actionAPI, showSimu, enableEntity, preName != '', preName, false)
    }

    importExcel(actionAPI: IActionAPI, selectedEntities: IEntity[], parentType: string) {
        var identifiers: string = "";
        var currentEntityType: string = "UR";

        if (selectedEntities) {
            selectedEntities.forEach(function (val, index, tab) {
                identifiers = identifiers + val.IdObject;
                currentEntityType = val.EntityType;

                if (index < tab.length) {
                    identifiers = identifiers + ",";
                }
            });

            this.importModalComponent.show('Import fichier excel', actionAPI);
        }
    }

    importApp(actionAPI: IActionAPI) {
        this.importModalComponent.show('Import fichiers', actionAPI);
    }

    menuItemTemplate(data: any) {
        if (data.text == 'group') {
            return '<div class="bordergroupAction"></div>';
        }
        else if (data.Element.RenderType == RenderTypeButton.Icone  && !data.Element.Force) {
            if (data.Element.Disabled)
                return '<div class="item disabledAction" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><i class="fa-solid ' + data.Element.IconClass + '"></i></div>'
            else
                return '<div class="item" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><i class="fa-solid ' + data.Element.IconClass + '"></i></div>'
        }
        else if (data.Element.RenderType == RenderTypeButton.IconeAndText || data.Element.Force) {
            if (data.Element.Disabled)
                return '<div class="item disabledAction" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><i class="fa-solid ' + data.Element.IconClass + '"></i> <p>' + data.Element.LongName + '<p></div>'
            else
                return '<div class="item" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><i class="fa-solid ' + data.Element.IconClass + '"></i> <p>' + data.Element.LongName + '<p></div>'
        }
        else if (data.Element.RenderType == RenderTypeButton.Text && !data.Element.Force) {
            if (data.Element.Disabled)
                return '<div class="item disabledAction" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><p>' + data.Element.LongName + '<p></div>'
            else
                return '<div class="item" placement="right" [openDelay]="1000" ngbTooltip="' + data.Element.Tooltip + '" tooltipClass="custom-tooltip"><p>' + data.Element.LongName + '<p></div>'
        }

        return '';
    }

    canDisplayAction(action: IActionButton, selectedRows: any, componentName: string = '', componentType: string = '', parent:any = null, email : string = '', currentProcedureAssociation : string = '', space : string = '') {
        if (action.VisibilityCriteria == '')
            return true;
        var ctrl = this;
        for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i].IdObject == -1)
                return false;
        }
        const criterias = action.VisibilityCriteria.split('|');
        var result = true;
        for (var i = 0; i < criterias.length; i++) {
            if (criterias[i] == 'US')
                result = (selectedRows && selectedRows.length == 1) || (componentName != '' && componentName != 'undefined');
            else if (criterias[i] == 'MS')
                result = (selectedRows && selectedRows.length > 0) || (componentName != '' && componentName != 'undefined');
            else if (criterias[i] == 'CD')
                result = componentType == 'CD';
            else if (criterias[i].startsWith('Key')) {
                var parameters = criterias[i].split('#');
                var typeStorage = parameters[0].replace('Key:', '');
                var keyName = parameters[1];
                if (typeStorage == 'Session')
                    result = this.sessionStorageService.hasKey(keyName);
                else
                    result = this.localStorageService.hasKey(keyName);
            }
            else if (criterias[i].startsWith('Parent') && parent == null)
                continue;
            else if (criterias[i].startsWith('Parent') && parent != null)
            {
                var parameters = criterias[i].replace('Parent$','').split('#');
                var attribut = parameters[0];
                var value = parameters[1];
                if (parameters.length > 2) {
                    var op = parameters[2];
                    switch (op) {
                        case 'ne':
                            result = parent[attribut].toString() != value;
                            break;
                    }
                } else {
                    result = parent[attribut].toString() == value;
                }
            }
            else if (criterias[i].startsWith('ExprOnEntities'))
                continue;
            else if (criterias[i].startsWith('ProcAssoc'))
            {
                if(space == 'MDEL')
                    result = true;
                else
                {
                var parameters = criterias[i].split('#');
                var procsAvailable = parameters[1].split('|');
                var tempProcAsso = currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                    return f === tb;
                    })
                });
                result = tempProcAsso.length > 0;
                }
            }
            else if (criterias[i].indexOf('#') > -1) {
                var parameters = criterias[i].split('#');
                var attribut = parameters[0];
                var value = parameters[1];

                if (parameters.length > 2) {
                    var op = parameters[2];
                    switch (op) {
                        case 'ne':
                            //not eq
                            for (var j = 0; j < selectedRows.length; j++) {
                                var element = selectedRows[j];
                                if (!(attribut == 'Owner' && value == 'Me'))
                                    result = element[attribut].toString() != value;
                                else
                                    result = element[attribut] != email;
                                if (!result) return result;
                            }
                            break;
                    }
                } else {
                    for (var j = 0; j < selectedRows.length; j++) {
                        var element = selectedRows[j];
                        if (!(attribut == 'Owner' && value == 'Me')) {
                            result = element[attribut].toString() == value;
                        }
                        else {
                            result = element[attribut] == email;
                        }
                        if (!result)
                            return result;
                    }
                }
            } 
            if (!result)
                return result;
        }
        return result;
    }

    canDisplayAppAction(action: IActionAppButton, currentEntity:any = null, email : string = '', nbSimu:number) {
        if (action.VisibilityCriteria == '')
            return true;
            
        var ctrl = this;
        if(currentEntity == null)
            return false;
        const criterias = action.VisibilityCriteria.split('|');
        var result = true;
        for (var i = 0; i < criterias.length; i++) {
            if (criterias[i].startsWith('Features')) 
                continue;
            if (criterias[i].startsWith('Key')) {
                var parameters = criterias[i].split('#');
                var typeStorage = parameters[0].replace('Key:', '');
                var keyName = parameters[1];
                if (typeStorage == 'Session')
                    result = this.sessionStorageService.hasKey(keyName);
                else
                    result = this.localStorageService.hasKey(keyName);
            }
            else if (criterias[i].indexOf('#') > -1) {
                var parameters = criterias[i].split('#');
                var attribut = parameters[0];
                var value = parameters[1];
                if (parameters.length > 2) {
                    var op = parameters[2];
                    switch (op) {
                        case 'ne':
                            //not eq
                            if (!(attribut == 'Owner' && value == 'Me'))
                                result = currentEntity[attribut].toString() != value;
                            else
                                result = currentEntity[attribut] != email;
                            if (!result) return result;
                            break;
                    }
                } else {
                    if (!(attribut == 'Owner' && value == 'Me')) {
                        result = currentEntity[attribut].toString() == value;
                    }
                    else {
                        result = currentEntity[attribut] == email;
                    }
                    if (!result)
                        return result;
                }
            }
            else if (criterias[i].startsWith('simuCount') ) {
                var maxSimu = criterias[i].split('=')[1];
                result = parseInt(maxSimu) > nbSimu
            }

            if (!result)
                return result;
        }
        return result;
    }

    refreshCommunicationModal()
    {
       // this.communicationModalComponent.refresh();
    }
    async parsingActionTypeApi(element: IActionButton, msgFromGrid: any, componentName: string, space: string, idParent: number, entityType: string, entityStatus : string, subPerimeter : string) {
        let ids = '';
        for (var i = 0; i < msgFromGrid.length; i++) {
            ids += msgFromGrid[i].IdObject + ','
        }
        if (ids != '')
            ids = ids.substring(0, ids.length - 1);
        var actionUrl = element.Actions.replace('(space)', space);
        if (actionUrl.includes('EntititiesIds": ""') && ids.length > 0) {
            actionUrl = actionUrl.replace('EntititiesIds": ""', 'EntititiesIds": [' + ids + ']');
        }

        if (componentName == null || componentName == 'undefined' || typeof componentName === 'undefined' || componentName === '') {
            actionUrl = actionUrl.replace('"(entitiesId)"', ids);
            actionUrl = actionUrl.replace('(entityType)', msgFromGrid[0].EntityType);
        }
        else {
            actionUrl = actionUrl.replace('EntititiesIds": ""', 'EntititiesIds": [' + idParent.toString() + ']');
            actionUrl = actionUrl.replace('"(entitiesId)"', idParent.toString());
            actionUrl = actionUrl.replace('(entityType)', entityType);
        }
        actionUrl = actionUrl.replace('(parentId)', idParent.toString());
        const actionAPI = <IActionAPI>JSON.parse(actionUrl);

        if (componentName == null || componentName == 'undefined' || typeof componentName === 'undefined' || componentName === '') {
            actionAPI.Parameters.EntityStatus = msgFromGrid[0].Status;
        }
        else
            actionAPI.Parameters.EntityStatus = entityStatus;
        actionAPI.Parameters.Space = space;    
        actionAPI.Parameters.SubPerimeter = subPerimeter;
        await lastValueFrom(this.entityService.executeAction(actionAPI));
    }

    parsingActionTypeApiApp(element: IActionAppButton, space: string, identifier: number, entityType: string, tabName : string, tabRef : string, tabStatus : number, tabs : string[], applicationName: string, zoneName:string, version : string, workflowId: string) {
        var actionUrl = element.Actions.replace('(space)', space);
        actionUrl = actionUrl.replace('(entityId)', identifier.toString());
        let ids = '';
        for (var i = 0; i < tabs.length; i++) {
            ids += '"'+tabs[i]+'"' + ','
        }
        if (ids != '')
            ids = ids.substring(0, ids.length - 1);
        if (actionUrl.includes('TabName":')) {
            actionUrl = actionUrl.replace('(tabName)', tabName);
        }
        if (actionUrl.includes('TabRef":')) {
            actionUrl = actionUrl.replace('(tabRef)', tabRef);
        }
        if (actionUrl.includes('TabStatus":')) {
            actionUrl = actionUrl.replace('(tabStatus)', tabStatus.toString());
           }
        if (actionUrl.includes('Tabs":')) {
            actionUrl = actionUrl.replace('"(tabs)"', ids);
        }
        const actionAPI = <IActionAPI>JSON.parse(actionUrl);
        actionAPI.Parameters.ApplicationName = applicationName;
        actionAPI.Parameters.ZoneName = zoneName;
        actionAPI.Parameters.WorkflowId = workflowId;
        actionAPI.Parameters.Version = version;
        actionAPI.Parameters.EntityType = entityType;
        actionAPI.Parameters.FormStatus = element.FormStatus;
        this.sub4 = this.entityService.executeAction(actionAPI).subscribe({
            next: (retour: any) => {
            },
            error: (err: any) => console.log(err)
        });
    }

    async parsingActionTypeJavascript(element: IActionButton, msgFromGrid: any, space: string, componentName: string, idParent: number, entityType: string, displayToolbarAction: any, version: string, currentProcedureAssociation : string) {
        var actionAPI: IActionAPI;
        let ids = '';

        for (var i = 0; i < msgFromGrid.length; i++) {
            ids += msgFromGrid[i].IdObject + ','
        }
        if (ids != '') ids = ids.substring(0, ids.length - 1);
       
        var parameters = element.Actions.split('#');
        var actionUrl = '';
        if (parameters.length > 1)
            actionUrl = parameters[1].replace('(space)', space);
        else
            actionUrl = parameters[0].replace('(space)', space);


        if (componentName == null || componentName == 'undefined' || typeof componentName === 'undefined' || componentName === '') {
               if (actionUrl.includes('EntititiesIds": ""')) {
                actionUrl = actionUrl.replace('EntititiesIds": ""', 'EntititiesIds": [' + ids + ']');
            }
            actionUrl = actionUrl.replace('"(entitiesId)"', ids);
            actionUrl = actionUrl.replace('(entityType)', msgFromGrid[0].EntityType);
            if (msgFromGrid[0].Parent)
                actionUrl = actionUrl.replace('(parentID)', msgFromGrid[0].Parent.toString());
            else if (idParent)
                actionUrl = actionUrl.replace('(parentID)', idParent.toString()); 
            else
                actionUrl = actionUrl.replace('(parentID)', '1'); // op = 1
        }
        else {

            if (actionUrl.includes('EntititiesIds": ""')) {
                actionUrl = actionUrl.replace('EntititiesIds": ""', 'EntititiesIds": [' + idParent + ']');
            }
            actionUrl = actionUrl.replace('"(entitiesId)"', idParent.toString());
            actionUrl = actionUrl.replace('(entityType)', entityType);
            actionUrl = actionUrl.replace('(parentID)', idParent.toString());
        }
        switch (parameters[0]) {
            case "reportEntities":
                actionUrl = this.enrichAdvancedParameter(actionUrl);
                break;
            case "takeOwnership":
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"Action\\\": \\\"TakeOwnership\\\",\\\"ChildEntityType\\\":\\\"null\\\" }");
                break;
            case "PutInWork":
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"Action\\\": \\\"PutInWork\\\",\\\"ChildEntityType\\\":\\\"null\\\" }");
                break;
            case "PutMainStatut":
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"Action\\\": \\\"PutMainStatut\\\",\\\"ChildEntityType\\\":\\\"null\\\" }");
                break;
            case "ResetData":
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"Action\\\": \\\"Reset\\\",\\\"ChildEntityType\\\":\\\"null\\\" }");
                break;
            case "ReopenLastCycle":
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"Action\\\": \\\"ReopenLastCycle\\\",\\\"ChildEntityType\\\":\\\"null\\\" }");
                break;
            case "CutSelection":
                actionUrl = actionUrl.replace('(extraParameters)', "{}");
                var currentEntityType = msgFromGrid[0].EntityType;
                this.saveSelectionInSesssionStorage(ids, currentEntityType);
                break;
            case "TakeReference":
                actionUrl = actionUrl.replace('(extraParameters)', "{}");
                if(msgFromGrid && msgFromGrid.length > 0)
                {
                    if(!msgFromGrid[0].ComparaisonThreshold)
                    {
                        msgFromGrid[0].ComparaisonThreshold = 0;
                    }
                    this.saveReferenceCycle(msgFromGrid[0]);
                }
                else
                {
                    this.saveReferenceCycle({
                        ComparaisonThreshold : 0,
                        IdObject : idParent
                    });
                }
                
                break;
            case "ComparePartial":
            case "CompareFull":
                actionUrl = actionUrl.replace('(extraParameters)', '');
                if (displayToolbarAction != null) displayToolbarAction();
                break;
            case "PasteSelection":
                var selectionSource = this.restoreCutedSelection();
                actionUrl = actionUrl.replace('(extraParameters)', "{ \\\"EntityType\\\": \\\"" + selectionSource.type + "\\\",\\\"Selection\\\": [ " + selectionSource.sel + " ] }");
                if (displayToolbarAction != null) displayToolbarAction();
                break;
        }
       
        if(actionUrl != 'addNewLine')
        {
            actionAPI = <IActionAPI>JSON.parse(actionUrl);

            if (parameters[0] == 'ImportExcel') {
                var exportDefinitions =  await lastValueFrom(this.entityService.getListExport(version, space, entityType));
                actionAPI = this.enrichImportParameter(actionAPI, currentProcedureAssociation, exportDefinitions);
             }
             if(parameters[0] ==  "ComparePartial" || parameters[0] ==  "CompareFull")
             {
                 var simpleCompo =  await lastValueFrom(this.entityService.getSimpleComponents(space));
                 var listCompo =  await lastValueFrom(this.entityService.getListComponents(space));
                 var selectionSource = this.restoreCycleRef();
                 var params = 
                 {
                     CycleRefId : selectionSource.IdObject,
                     ComparaisonThreshold : selectionSource.ComparaisonThreshold,
                     ComparaisonIsComplete : parameters[0] == "CompareFull",
                     SimpleComponents : simpleCompo,
                     ListComponents : listCompo
                 }
                 actionAPI.Parameters.ExtraParameters =  JSON.stringify(params);
            }
             if(parameters[0] ==  "GenerateBIView" && element.Name == 'All')
             {
                 var biComponents =  await lastValueFrom(this.entityService.getBIComponents(space));
                 actionAPI.Parameters.ExtraParameters =  JSON.stringify(biComponents);
            }
            actionAPI.Parameters.Space = space;  
            if(element.ActionRight =='IMP')
            {
                actionAPI.Parameters.SubPerimeterImport = element.SubPerimeterImport;
            }
            if (parameters.length > 1)
                await this.callJsAction(parameters[0], actionAPI, msgFromGrid, entityType, space, null, null, null, null, version);
            else
                await this.callJsAction('defaultAction', actionAPI, msgFromGrid, entityType, space, null, null, null, null, version);
        }
        else 
            await this.callJsAction(parameters[0], null, msgFromGrid, entityType, space, null, null, null, null, version); 
    }

    async parsingActionTypeJavascriptApp(element: IActionAppButton, space: string, identifier: number, tabName : string, tabRef : string, tabStatus : number, tabs : string[], entityType: string,reportsDefinitions:any[], applicationName: string, zoneName :string, version : string, exportDefinitions : any[], workflowId: string, confirmMessage: string, currentProcedureAssociation: string) {
        var actionAPI: IActionAPI;
        var parameters = element.Actions.split('#');
        var actionUrl = '';
        if (parameters.length > 1)
            actionUrl = parameters[1].replace('(space)', space);
        else
            actionUrl = parameters[0].replace('(space)', space);
            actionUrl = actionUrl.replace('(entitiesId)', identifier.toString());
            actionUrl = actionUrl.replace('(entityId)', identifier.toString());
            actionUrl = actionUrl.replace('(entityType)', entityType);
            actionUrl = actionUrl.replace('(entityType)', entityType);
            actionUrl = actionUrl.replace('(parentID)', identifier.toString());
        let ids = '';
        for (var i = 0; i < tabs.length; i++) {
            ids += '"'+tabs[i]+'"' + ','
        }
        if (ids != '')
            ids = ids.substring(0, ids.length - 1);
        if (actionUrl.includes('TabName":')) {
            actionUrl = actionUrl.replace('(tabName)', tabName);
        }
        if (actionUrl.includes('TabRef":')) {
            actionUrl = actionUrl.replace('(tabRef)', tabRef);
        }
        if (actionUrl.includes('TabStatus":')) {
            actionUrl = actionUrl.replace('(tabStatus)', tabStatus.toString());
           }
        if (actionUrl.includes('Tabs":')) {
            actionUrl = actionUrl.replace('"(tabs)"', ids);
        }
        if (actionUrl.includes('ExtraParameters":')) {
            actionUrl = actionUrl.replace('"(extraParameters)"', '""');
        }
        actionAPI = <IActionAPI>JSON.parse(actionUrl);
        actionAPI.Parameters.ApplicationName = applicationName;
        actionAPI.Parameters.ZoneName = zoneName;
        actionAPI.Parameters.WorkflowId = workflowId;
        actionAPI.Parameters.Version = version;
        actionAPI.Parameters.FormStatus = element.FormStatus;
        actionAPI.Parameters.EntityType = entityType;
        actionAPI.Parameters.EntityId = identifier.toString();
        if (parameters[0] == 'ImportApp') {
            actionAPI = this.enrichImportParameter(actionAPI, currentProcedureAssociation, exportDefinitions);
         }
        if((<any>element).DisplayErrorTab == true)
        {
            actionAPI.Parameters.ExtraParameters= actionAPI.Parameters.ExtraParameters.replace('}',",\"DisplayErrorTab\":true}");
        }
        if (parameters.length > 1)
            await this.callJsAction(parameters[0], actionAPI, null, null, space, reportsDefinitions, exportDefinitions, applicationName, null, null);
        else if (confirmMessage != null && confirmMessage.length > 1)
        await this.callJsAction('changeStatus', actionAPI, null, null, space, reportsDefinitions, exportDefinitions, applicationName, confirmMessage, null);
        else
            await this.callJsAction('defaultAction', actionAPI, null, null, space,reportsDefinitions, exportDefinitions, applicationName, null, null); 
    }

    private enrichImportParameter(actionAPI: IActionAPI, currentProcedureAssociation : string, exportAssoc : any): IActionAPI {
        var object = {
            CurrentProcedureAssociation : currentProcedureAssociation,
            ExportsAvailable : exportAssoc
        }
        actionAPI.Parameters.ExtraParameters = JSON.stringify(object);
        return actionAPI;
    }

    private enrichAdvancedParameter(actionUrl: string): string {
        var parameter = "{ \\\"Requests\\\":[ {\\\"ReportName\\\":\\\"\\\", \\\"TabIdentifiers\\\":[], \\\"TabsActivationTemp\\\":[], \\\"NbTabs\\\":null, \\\"TabsActivations\\\": 0 } ] }";
        actionUrl = actionUrl.replace('(extraParameters)', parameter);
        return actionUrl;
    }

    private saveSelectionInSesssionStorage(identifiers: string, currentEntityType: string) {

        if (this.sessionStorageService.hasKey(this.cutedSelectionKey)) {
            this.sessionStorageService.remove(this.cutedSelectionKey);
        };

        this.sessionStorageService.set(this.cutedSelectionKey, identifiers);
        this.sessionStorageService.set(this.cutedFeatureKey, currentEntityType);
    }

    private saveReferenceCycle(identifiers: any) {

        if (this.sessionStorageService.hasKey(this.currentCycleRefKey)) {
            this.sessionStorageService.remove(this.currentCycleRefKey);
        };

        this.sessionStorageService.set(this.currentCycleRefKey, identifiers);
        this.sessionStorageService.updateCycleReference();
    }

    private restoreCutedSelection(): any {
        if (this.sessionStorageService.hasKey(this.cutedSelectionKey)) {
            var selection = this.sessionStorageService.get(this.cutedSelectionKey);
            var type = this.sessionStorageService.get(this.cutedFeatureKey);

            this.sessionStorageService.remove(this.cutedSelectionKey);
            this.sessionStorageService.remove(this.cutedFeatureKey);
            return {
                sel: selection,
                type: type
            };
        };

        return undefined;
    }

    private restoreCycleRef(): any {
        if (this.sessionStorageService.hasKey(this.currentCycleRefKey)) {
            var selection = this.sessionStorageService.get(this.currentCycleRefKey);
            return selection;
        };

        return undefined;
    }
}