import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { BoModule } from './back-office/back-office.module';
import { InterceptorService } from './app.interceptor.service';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { SharedModule } from './shared/shared.module';
import { FallbackComponent } from './fallback.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppToastsComponent } from './shared/toast.component';
import { LocalStorageService } from './shared/local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontOfficeModule } from './front-office/front-office.module';
import { CookieService } from 'ngx-cookie-service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    declarations: [
       
        AppComponent,
        FallbackComponent,
        AppToastsComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        BoModule,
        FrontOfficeModule,
        SharedModule.forRoot(),
        RouterModule.forRoot(APP_ROUTES, { useHash: true, initialNavigation: 'disabled' }),
    ],
    bootstrap: [AppComponent],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true, deps: [OAuthService,LocalStorageService,CookieService, AppToastsComponent] },
        {provide: OAuthStorage, useFactory: storageFactory },
    ],
    exports: [FormsModule,ReactiveFormsModule],
})
export class AppModule { }
