import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { IActionAPI, IEntity } from "app/shared/entity";
import { EntityService } from "app/shared/entity.service";
import { LocalStorageService } from "app/shared/local-storage.service";
import { ActionLegacyService } from "app/shared/actions-legacy.service";
import { PanelContextAppComponent } from "app/front-office/panel-context-app/panel-context-app.component";
import { HomePageService } from "app/home-page/home-page.service";
import { Subscription, lastValueFrom } from "rxjs";
import * as $ from 'jquery';
import { FrontOfficeStructService } from "../front-office-struct.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { SessionStorageService } from "app/shared/session-storage.service";
import { ActionLegacyRefreshService } from "app/shared/actions-legacy-refresh.service";
import { StringUtils } from "app/utils/string-utils";

@Component({
    templateUrl: './front-office-struct.component.html', 
    styleUrls: ['./front-office-struct.component.css', '../front-office-struct-mobile.component.css']
})
export class FrontOfficeStructComponent implements OnInit, OnDestroy {
    perimeter: string = '';
    space: string = '';
    version: string = '';
    applicationName: string = '';
    zoneName: string = '';
    moduleName: string = '';
    moduleMainName: string = '';
    variante: string = '';
    tabName: string = '';
    updateDate: string = '';
    updateUser: string = '';
    currentWindowMobile: string = '';
    headerName: string = 'Header';
    multiTab: boolean;
    fullyLoadHeader: boolean;
    currentProc :any;
    currentProcedureAssociation :any;
    currentEntity :any;
    parentEntity :any;
    entityIdentifier: string = '';
    tempEntityIdentifier: string = '';
    sub!: Subscription;
    sub2!: Subscription;
    sub3!: Subscription;
    sub4!: Subscription;
    sub5!: Subscription;
    sub6!: Subscription;
    sub7!: Subscription;
    tabsBlocs :any[] = [];
    headerBlocs :any[] = [];
    tabs: any [] = [];
    currentTab: any;
    detailTab: any;
    currentModule : any;
    states: any [] = [];
    formStates: any [] = [];
    contextInfo: any [] = [];
    currentState: any;
    listTabState: any[];
    currentFormState: any;
    displayState: boolean = true;
    blockedCalcul: boolean = false;
    forceReloadData: boolean = false;
    cleanHeader: boolean = false;
    forceReloadDataReady: boolean = false;
    parentContext: boolean = false;
    exportCurrentTab : boolean = false;
    isMobile: boolean = true;
    isDirector: boolean = false;
    refreshEntities : boolean = false;
    entityCible: string;
    parentEntityCible: string;
    currentformStatus: number;
    currentZone :any;
     constructor(
        private route: ActivatedRoute,
        private router: Router,
        private applicationStructService: FrontOfficeStructService,
        private actionLegacyService: ActionLegacyService,
        private actionLegacyRefreshService : ActionLegacyRefreshService,
        private entitiesService : EntityService,
        private spaceService : HomePageService,
        public sanitizer: DomSanitizer,
        private localStorageService: LocalStorageService,
        private panelContextComponent: PanelContextAppComponent,
        private deviceService: DeviceDetectorService,
        private sessionStorageService: SessionStorageService) 
    { this.isMobile = this.deviceService.isMobile(); }

    async ngOnInit(): Promise<void> {
        this.route.params.subscribe(async (params) => {
            this.actionLegacyService.cleanEmitter();
            this.moduleMainName = '';
            this.panelContextComponent.closePanel();
            this.currentWindowMobile = '';
            let oldApp = this.applicationName;

            this.applicationName = String(params.applicationName);
            let oldZone = this.zoneName;
            this.zoneName = String(params.zoneName);
            let oldModuleName = this.moduleName;
          
            this.moduleName = String(params.moduleName);
            let oldTabName = this.tabName;
            if (params.tabName) {
                this.tabName = String(params.tabName);
            }
            if (params.entityIdentifier) {
                this.entityIdentifier = String(params.entityIdentifier);
            }

            this.ngOnDestroy();
            this.tabsBlocs = [];
            this.tempEntityIdentifier = this.entityIdentifier;
            var ctrl = this; 
            this.sub = this.actionLegacyService.refreshDataGridChangeAlertApp.subscribe(async (actionAPI: IActionAPI) => {
                if (actionAPI) {
                    await ctrl.refreshDataWithCallWebApi(actionAPI);
                }
            })
            this.sub6 = this.actionLegacyService.changeProcEmitter.subscribe(async (newCurrentProc: IEntity) => {
                this.currentProc = newCurrentProc
            })
            this.sub6 = this.actionLegacyService.changeSimuEmitter.subscribe(async (simu: IEntity) => {
                if(simu != null)
                {
                    this.sessionStorageService.set('simuLine', simu.IdObject);
                    this.tempEntityIdentifier = simu.IdObject.toString();
                }
                else
                {
                    this.sessionStorageService.remove('simuLine');
                    this.tempEntityIdentifier = this.entityIdentifier;
                }
                this.currentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space, this.tempEntityIdentifier));
                this.forceReloadData = true;
                var ctrl = this; 
                var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
                setTimeout(async () => {
                    ctrl.contextInfo = await lastValueFrom(ctrl.applicationStructService.getContextInfoPerBloc(ctrl.zoneName, moduleName, ctrl.tempEntityIdentifier));
                }, 50);
                var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl, '_');
                ctrl.currentformStatus = await lastValueFrom(ctrl.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.tempEntityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                
                let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                if(currentState != '') {
                    ctrl.currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                }
                else {
                    ctrl.currentState = ctrl.states[0];
                }
                ctrl.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier));
                let currentTabState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));   
                ctrl.currentFormState = null;
                setTimeout(() => {
                    ctrl.currentFormState = currentTabState != '' 
                        ? ctrl.formStates.filter((tb: { Id: string; }) => tb.Id == currentTabState)[0] 
                        : ctrl.formStates[0];
                }, 50);
            })
            this.sub7 = this.actionLegacyRefreshService.refreshCommAppFromToast.subscribe(async (data) => {
                this.actionLegacyService.refreshCommunicationModal();
            });
            this.sub5 = this.actionLegacyRefreshService.refreshActionAppFromToast.subscribe(async (data) => {
                
                if(data.Action == 'App$ImportApp')
                {
                    this.forceReloadData = true;
                }
                var ctrl = this; 
                ctrl.cleanHeader = false;
                if(data.Action.indexOf("App$ChangeStatusApp") != -1 || (data.Action.indexOf('CalculApp') != -1 && data.Action.indexOf('CalculAppEnter') == -1))
                {
                    ctrl.cleanHeader = true;
                    setTimeout(() => {
                        ctrl.cleanHeader = false;
                    }, 50);
                }
                if(data.Action == 'App$CalculAppEnter')
                {
                    if(this.tempEntityIdentifier == data.EntityId)
                    {
                        this.blockedCalcul = true;
                    }
                    return;
                }
                if(data.Action.startsWith('App$AddSimulationApp'))
                {
                    var simuId = data.Action.split('|')[1];
                    this.sessionStorageService.set('simuLine', simuId);
                    this.tempEntityIdentifier = simuId;
                    this.currentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space, this.tempEntityIdentifier));
                    this.forceReloadData = true;
                    var ctrl = this; 
                    var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
                    setTimeout(async () => {
                        ctrl.contextInfo = await lastValueFrom(ctrl.applicationStructService.getContextInfoPerBloc(ctrl.zoneName, moduleName, ctrl.tempEntityIdentifier));
                    }, 50);
                    var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl, '_');
                    ctrl.currentformStatus = await lastValueFrom(ctrl.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.tempEntityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                    
                    let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                    if(currentState != '')
                        ctrl.currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                    else
                        ctrl.currentState = ctrl.states[0];

                    ctrl.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier));
                    
                    let currentTabState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));   
                    ctrl.currentFormState = null;
                    setTimeout(() => {
                        if(currentTabState != '')
                            ctrl.currentFormState = ctrl.formStates.filter((tb: { Id: string; }) => tb.Id == currentTabState)[0];
                        else
                            ctrl.currentFormState = ctrl.formStates[0];
                    }, 50);
                }
                else
                {
                    if(data.Action == 'App$CalculApp')
                    {
                        this.forceReloadData = true;
                        this.blockedCalcul = false;
                    }
                    
                    var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
                    setTimeout(async () => {
                        ctrl.contextInfo = await lastValueFrom(ctrl.applicationStructService.getContextInfoPerBloc(ctrl.zoneName, moduleName, ctrl.tempEntityIdentifier));
                    }, 50);
                    var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl, '_');
                    ctrl.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier));
                    ctrl.currentformStatus = await lastValueFrom(ctrl.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.entityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                 
                    let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                    if(currentState != '') {
                        ctrl.currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                    }
                    else {
                        ctrl.currentState = ctrl.states[0];
                    }
                 
                    if(data.Action != 'App$ValidApp')
                    {
                        if(data.Action != 'App$CalculApp')
                        {
                            let currentTabState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));
                    
                            ctrl.currentFormState = null;
                            setTimeout(() => {
                                ctrl.currentFormState = currentTabState != '' ? ctrl.formStates.filter((tb: { Id: string; }) => tb.Id == currentTabState)[0] : ctrl.formStates[0];
                            }, 50);
                        }
                        else
                        {
                            ctrl.forceReloadDataReady = true;
                            setTimeout(() => {
                                ctrl.forceReloadDataReady = false;
                                ctrl.forceReloadData = false;
                            }, 50);
                        }
                    }
                }
            });
            this.sub2 = this.actionLegacyService.executeActionChangeAlertApp.subscribe(async (actionAPI: IActionAPI) => {
                if (actionAPI)
                {
                    if(actionAPI.Parameters.ExtraParameters.indexOf('"SchemaName":"GCourApp"') > -1)
                    {
                        this.exportCurrentTab = true;
                        setTimeout(() => {
                            this.exportCurrentTab = false;
                        }, 50);
                    }
                    else {
                        await ctrl.executeAction(actionAPI);
                    }
                }
            })
            this.sub4 = this.applicationStructService.changeMenu.subscribe(async (menu: string) => {
                ctrl.currentWindowMobile = (ctrl.currentWindowMobile != '' && menu =='Content') ? '' : menu;
            })
            this.sub3 = ctrl.applicationStructService.updateFormData.subscribe((item:any) => {
                setTimeout(async () => {
                    this.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier));
                    let currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                    if(currentState != '') {
                        this.currentState = this.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                    }
                    else {
                        this.currentState = this.states[0];
                    }
                }, 200);
                var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
                setTimeout(async () => {
                    this.contextInfo = await lastValueFrom(this.applicationStructService.getContextInfoPerBloc(this.zoneName, moduleName, this.tempEntityIdentifier));
                }, 50);
                if(!StringUtils.isMainEntity(this.entityCible) && item =='refresh')
                {
                    this.refreshEntities = true;
                    setTimeout(async () => {
                        this.refreshEntities = false;
                    }, 200);
                }
            });
            this.variante = '';
       
            var parent = '#appStructPanel';
            if(this.isMobile) {
                parent = '#appStructPanelMobile'
            }
            const loadPanel = $('#loadpanel').dxLoadPanel({
                shadingColor: 'rgba(0,0,0,0.4)',
                visible: true,
                container : '#loadpanel',
                showIndicator: true,
                position: { of: parent },
                showPane: true,
                shading: true,
                hideOnOutsideClick: false,
            }).dxLoadPanel('instance');
            let zones = await lastValueFrom(this.spaceService.getAvailableZonePerApplications(this.applicationName));
            let olVersion = this.version;
            if(this.version != zones.filter((tb: { Name: string; }) => tb.Name == this.zoneName)[0].CurrentVersion || (oldApp != this.applicationName && oldZone == this.zoneName))
            {
                this.version = zones.filter((tb: { Name: string; }) => tb.Name == this.zoneName)[0].CurrentVersion;
                this.currentZone = await lastValueFrom(this.applicationStructService.getStructOfZone(this.applicationName, this.version, this.zoneName));
            }
            if(!(oldApp == this.applicationName && oldZone == this.zoneName && oldModuleName == this.moduleName) || olVersion != this.version)
            {
                this.currentModule = await lastValueFrom(this.applicationStructService.getStructOfModule(this.applicationName,this.version, this.zoneName, this.moduleName));
                this.currentTab = await lastValueFrom(this.applicationStructService.getStructOfTab(ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName));
            }
            else if(oldTabName != this.tabName)
            {
                this.currentTab = await lastValueFrom(this.applicationStructService.getStructOfTab(ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, ctrl.tabName));
            }
            ctrl.detailTab = (ctrl.tabName.indexOf('/') != -1) 
                ? ctrl.currentModule.Tabs.filter((tb: { Name: string; }) => tb.Name == ctrl.tabName.split('/')[1])[0]
                : ctrl.currentModule.Tabs.filter((tb: { Name: string; }) => tb.Name == ctrl.tabName)[0];

    
            if(!ctrl.detailTab.DetailEntity) {
                ctrl.sessionStorageService.remove('currentLine');
            }
            else {
                this.tempEntityIdentifier = ctrl.sessionStorageService.get('currentLine');
            } 
            var simu = ctrl.sessionStorageService.get('simuLine');
            if(simu != null) {
                this.tempEntityIdentifier = simu;
            } 
        
            this.isDirector = await lastValueFrom(this.applicationStructService.isDirector(this.applicationName, this.zoneName, this.version));  
            this.states = await lastValueFrom(this.applicationStructService.getListState(this.currentZone.WorkflowId));
            this.formStates = await lastValueFrom(this.applicationStructService.getListFormState(this.currentZone.WorkflowId));
            this.perimeter = this.currentModule.Perimeter;
            this.space = this.currentModule.Space;
            let storage = this.localStorageService.getWithExpiration(this.space);
            storage = JSON.parse(storage);
            if(storage == null)
            {
                storage = {
                    Version: this.version,
                    DisplayState:{},
                    Entities: {},
                    Components: {},
                    Blocs: {},
                    Lists:[]
                };
                storage.DisplayState['BO'] = false;
                this.localStorageService.setWithExpiration(this.space, JSON.stringify(storage), 'CallTimout');
            }
            storage = this.localStorageService.getWithExpiration(this.space);
            storage = JSON.parse(storage);
            this.displayState = (storage && storage.DisplayState && storage.DisplayState[this.applicationName]) ? storage.DisplayState[this.applicationName] : true;

            this.version = this.currentModule.Version;
            this.currentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space, this.tempEntityIdentifier)); 
            this.tabs = this.currentModule.Tabs;
            let entityCible = this.currentModule.EntityCible;
            this.parentEntityCible = this.currentModule.ParentEntityCible;
            this.multiTab = ctrl.tabName.substring(0, ctrl.tabName.length-1) != ctrl.moduleName;
            var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
            setTimeout(async () => {
                if (ctrl.detailTab.IsGridEntity) {
                    this.contextInfo = await lastValueFrom(this.applicationStructService.getContextInfoPerBlocEntityGrid(this.zoneName, moduleName, this.tempEntityIdentifier, this.parentEntityCible));
                }
                else {
                    this.contextInfo = await lastValueFrom(this.applicationStructService.getContextInfoPerBloc(this.zoneName, moduleName, this.tempEntityIdentifier));
                }
            }, 50);
        
            this.variante = await lastValueFrom(this.entitiesService.getVarianteFromEntity(ctrl.space, ctrl.version, ctrl.entityIdentifier, ctrl.perimeter));
            if(this.currentTab.EntityCible != this.entityCible && this.currentTab.EntityCible != null) {
                entityCible = this.currentTab.EntityCible;
            }

            var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
            if(this.currentModule.ParentEntityCible != this.currentModule.EntityCible && this.entityIdentifier != '0') {
                this.parentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space, this.currentEntity.Parent));
            }
            else {
                this.parentEntity = null;
            }
            if(this.entityCible == '') {
                this.entityCible  = this.currentEntity.EntityType;
            }
            this.tabsBlocs = [];
            let hasMainEp = false;
            if(this.currentZone != null)
                for (var i = 0; i < this.currentZone.Modules.length; i++) 
                    if(this.currentZone.Modules[i].IsLinkToProcedure)
                        hasMainEp = true;

            if(hasMainEp && this.entityIdentifier != '0')
            {
                entityCible = this.currentModule.ParentEntityCible;
                this.entityCible = entityCible;
                if(this.entityCible == '') {
                    this.entityCible  = this.currentEntity.EntityType;
                }
                this.currentProc  = await lastValueFrom(this.entitiesService.getProcOfEntity(this.space, this.entityIdentifier, this.applicationName));
                this.currentProcedureAssociation  = await lastValueFrom(this.entitiesService.getProcedureAssociation(this.space, this.currentProc.IdObject));
                let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                if(currentState != '')
                    this.currentState = this.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                else
                    this.currentState = this.states[0];
                this.currentformStatus = await lastValueFrom(this.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.entityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                currentState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));
                this.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier));
                this.currentFormState = null;
                setTimeout(() => {
                    if(this.currentEntity.Status == 'V') {
                        this.currentFormState = this.formStates[this.formStates.length - 1];
                    }
                    else
                    {
                        this.currentFormState = this.formStates.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                        if(this.currentFormState == null) {
                            this.currentFormState = this.formStates[0];
                        }
                        var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
                        ctrl.applicationStructService.checkDisplayError(ctrl.version, ctrl.applicationName, ctrl.zoneName, tab.replace('ø', '_'), ctrl.entityIdentifier, ctrl.entityCible, ctrl.currentFormState.Id).subscribe({
                            next: comments => {
                            }});
                    }

                        
                }, 50);
                
                this.loadHeaderBlock(currentState)
            
            }
            else
            {
                this.entityCible = entityCible;
                if(!ctrl.currentModule.IsLinkToProcedure){
                    this.currentProc = {};   
                    this.currentProcedureAssociation  = 'defaut';
                }
                else
                {  
                    let availableProcs = await lastValueFrom(this.entitiesService.getAvailableProcFO(this.zoneName, this.version, this.applicationName, 'a'))
                    this.currentProc  = availableProcs.filter((tb: { Name: string;IsMain:boolean }) => tb.IsMain == true)[0];
                    this.currentProcedureAssociation  = await lastValueFrom(this.entitiesService.getProcedureAssociation(this.space, this.currentProc.IdObject));
                }
                let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                if(this.entityIdentifier != '0') {
                    this.currentState = this.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                    this.currentformStatus = await lastValueFrom(this.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.entityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                    currentState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));
                    this.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.entityIdentifier));
                    this.currentFormState = null;
                    setTimeout(() => {
                        this.currentFormState = this.formStates.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                        if(this.currentFormState == null) {
                            this.currentFormState = this.formStates[0];
                        }
                        var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
                        ctrl.applicationStructService.checkDisplayError(ctrl.version, ctrl.applicationName, ctrl.zoneName, tab.replace('ø', '_'), ctrl.entityIdentifier, ctrl.entityCible, ctrl.currentFormState.Id).subscribe({
                            next: comments => {
                            }});
                    }, 50);
                }
                else
                {   
                    this.currentFormState = null;
                    this.currentFormState = this.formStates.filter((tb: { Id: string; }) => tb.Id == this.formStates[0].Id)[0];
                    this.currentState = this.states[0];
                    currentState = 'ready';
                    var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
                    ctrl.applicationStructService.checkDisplayError(ctrl.version, ctrl.applicationName, ctrl.zoneName, tab.replace('ø', '_'), ctrl.entityIdentifier, ctrl.entityCible, ctrl.currentFormState.Id).subscribe({
                        next: comments => {
                        }});
                }
                this.loadHeaderBlock(currentState)
            }
        });
    }

    async loadHeaderBlock(currentState:any)
    {
        var ctrl = this;
       
        let headerConfig = await lastValueFrom(this.applicationStructService.getStructOfTab(ctrl.version, ctrl.applicationName, ctrl.zoneName, ctrl.moduleName, 'Header'));
        if(this.currentZone.MainEntityCible != this.currentModule.EntityCible)
        {
            let modulesMain = this.currentZone.Modules.filter((tb: { EntityCible: string; }) => tb.EntityCible == this.currentZone.MainEntityCible);
            if(modulesMain.length)
            {
                this.moduleMainName = modulesMain[0].Name;
                headerConfig = await lastValueFrom(this.applicationStructService.getStructOfTab(ctrl.version, ctrl.applicationName, ctrl.zoneName, this.moduleMainName, 'Header'));
            }
        }
        this.fullyLoadHeader = false;
        this.tabsBlocs = [];
        this.headerBlocs = [];
        let tempHeaderBloc: any[] = [];
        let tempTabBloc: any[] = [];
        var action = 'R';
        if(this.currentEntity != null &&this.currentEntity.IsSpecial)
          action = 'RSub';
        this.currentTab.Blocs.forEach(async (bloc: {
            AvailableProcedureAssociations: any; Type:string, Variante: string, AvailableVariantes: string, SubPerimeter: string; 
        }) => { 
            var procsAvailable = bloc.AvailableProcedureAssociations.split('|');
            if(bloc.AvailableProcedureAssociations.indexOf('.') != -1)
                procsAvailable = bloc.AvailableProcedureAssociations.split('.');
            var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
            return procsAvailable.some((f : string) => {
                return f === tb;
            })
            });
            if(bloc.Type == 'Grid' && (bloc.Variante == "RfcCommun" || bloc.Variante == this.variante || this.variante == '' || bloc.Variante == null) && (tempProcAsso.length > 0))
            {
                if(bloc.SubPerimeter == '')
                    tempTabBloc.push(bloc);
                else if(await ctrl.applicationStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, bloc.SubPerimeter, currentState, action))    
                    tempTabBloc.push(bloc);
            } 
            if(bloc.Type == 'Form')
            {
                tempTabBloc.push(bloc);
            }
        });
        this.tabsBlocs = tempTabBloc;
        headerConfig.Blocs.forEach(async (bloc: {
            AvailableProcedureAssociations: any; Type:string, Variante: string, AvailableVariantes: string, SubPerimeter: string; 
        }) => {
            var procsAvailable = bloc.AvailableProcedureAssociations.split('.');
            var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
            return procsAvailable.some((f : string) => {
                return f === tb;
            })
            });
            if(bloc.Type == 'Grid' && (bloc.Variante == "RfcCommun" || bloc.Variante == this.variante || this.variante == '' || this.variante == null || bloc.Variante == null) && (tempProcAsso > 0))
            {
                if(bloc.SubPerimeter == '')
                    tempHeaderBloc.push(bloc);
                else if(await ctrl.applicationStructService.getSubPerimeter(ctrl.space, ctrl.version,ctrl.applicationName, ctrl.zoneName, bloc.SubPerimeter, currentState, action))    
                    tempHeaderBloc.push(bloc);
            } 
            if(bloc.Type == 'Form')
            {
                var canAdd = false;
                var procsAvailable = bloc.AvailableProcedureAssociations.split('.');
                var tempProcAsso = this.currentProcedureAssociation.split('|').filter((tb: string) => {
                    return procsAvailable.some((f : string) => {
                        return f === tb;
                    })
                });
                if(tempProcAsso.length > 0 && tempHeaderBloc.indexOf(bloc)== -1)
                {
                    canAdd = true;
                }
                bloc.AvailableVariantes.split('.').forEach(async (allowVariant: string) => {
                    if((allowVariant == "RfcCommun" || allowVariant == this.variante || this.variante == '' || this.variante == null || bloc.Variante == null)&& tempHeaderBloc.indexOf(bloc)== -1)
                    {
                        if(canAdd) {
                            tempHeaderBloc.push(bloc);
                        }
                    }
                });
            }
        });
        if(this.tabsBlocs.length == 0)
        {
            $('#loadpanel').dxLoadPanel('instance').hide();
        }
        this.headerBlocs = tempHeaderBloc;
        this.fullyLoadHeader = true;
    }
    async executeAction(actionAPI: IActionAPI)
    {
        this.entitiesService.executeAction(actionAPI).subscribe({
            next: async () => {}
        });
    }

    async refreshDataWithCallWebApi(actionAPI: IActionAPI) {
        await this.entitiesService.executeAction(actionAPI).subscribe({
            next: async () => {
                if(actionAPI.Uri.indexOf('simulation_app') > -1 && actionAPI.Type =='DELETE')
                {
                    this.sessionStorageService.remove('simuLine');
                    this.tempEntityIdentifier = this.entityIdentifier;
                    this.currentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space, this.tempEntityIdentifier));
                    this.forceReloadData = true;
                    var ctrl = this; 
                    var moduleName = ctrl.zoneName+'_'+ctrl.applicationName;
                    setTimeout(async () => {
                        ctrl.contextInfo = await lastValueFrom(ctrl.applicationStructService.getContextInfoPerBloc(ctrl.zoneName, moduleName, ctrl.tempEntityIdentifier));
                    }, 50);
                    
                    var tab = StringUtils.getTabNameWithConsiderMultiTab(ctrl, '_');

                    ctrl.currentformStatus = await lastValueFrom(ctrl.applicationStructService.getFormStatus(ctrl.version, ctrl.space, ctrl.tempEntityIdentifier, ctrl.applicationName+'_'+ctrl.zoneName));
                    
                    let  currentState = await lastValueFrom(ctrl.applicationStructService.getTabStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_')));
                    if(currentState != '')
                        ctrl.currentState = ctrl.states.filter((tb: { Id: string; }) => tb.Id == currentState)[0];
                    else
                        ctrl.currentState = ctrl.states[0];
        
                    ctrl.listTabState =  await lastValueFrom(ctrl.applicationStructService.getListAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier));
                    
                    let currentTabState = await lastValueFrom(ctrl.applicationStructService.getFormAppStatus(ctrl.version, ctrl.space, ctrl.zoneName, ctrl.applicationName, ctrl.tempEntityIdentifier, tab.replace('ø', '_'),ctrl.currentZone.WorkflowId));   
                    ctrl.currentFormState = null;
                    setTimeout(() => {
                        if(currentTabState != '')
                            ctrl.currentFormState = ctrl.formStates.filter((tb: { Id: string; }) => tb.Id == currentTabState)[0];
                        else
                            ctrl.currentFormState = ctrl.formStates[0];
                    }, 50);
                }
            },
            error: (err: any) => console.log(err)
        });
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
        if (this.sub2)
            this.sub2.unsubscribe();
        if (this.sub3)
            this.sub3.unsubscribe();
        if (this.sub4)
            this.sub4.unsubscribe();
        if (this.sub5)
            this.sub5.unsubscribe();
        if (this.sub6)
            this.sub6.unsubscribe();
        if (this.sub7)
            this.sub7.unsubscribe();
    }
}