import { NgModule } from '@angular/core';
import { DxDataGridModule, DxTextAreaModule, DxHtmlEditorModule } from 'devextreme-angular';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BoDetailComponent } from './back-office-detail/back-office-detail.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SpaceGuardService } from 'app/shared/space-guard-service';
import { BoToolbarComponent } from '../back-office/back-office-toolbar/back-office-toolbar.component';
import { BoToolbarItemComponent } from '../back-office/back-office-toolbar-item/back-office-toolbar-item.component';
import { PanelHierarchicalViewComponent } from './panel-hierarchical-view/panel-hierarchical-view.component';
import { PanelSelecteurViewComponent } from '../back-office/panel-selecteur-view/panel-selecteur-view.component';
import { DocumentService } from '../back-office/document.service';

@NgModule({
    declarations: [
        BoDetailComponent,
        BoToolbarComponent,
        BoToolbarItemComponent,
        PanelHierarchicalViewComponent,
        PanelSelecteurViewComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild([
            {
                path: 'components/:space/:entityType/:idParent/:componentName/:componentType',
                children: [
                    {
                        path: '',
                        component: BoDetailComponent,
                        canActivate: [SpaceGuardService]
                    }
                ]
            }
        ]),
        RouterModule,
        DxDataGridModule,
        DxTextAreaModule,
        DxHtmlEditorModule
    ],
    exports: [
        BoDetailComponent,
        BoToolbarComponent,
        BoToolbarItemComponent,
        PanelHierarchicalViewComponent,
        PanelSelecteurViewComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy }, 
        SpaceGuardService, 
        DocumentService
    ],
    bootstrap: [BoDetailComponent]
})
export class BoModule { }
