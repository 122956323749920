import { ApplicationRef, Component,  EventEmitter,  Input, OnInit, Output, createComponent } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";
import * as $ from 'jquery';
import { LocalStorageService } from "app/shared/local-storage.service";
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import * as numeral from "numeral";
import { AuthService } from "app/shared/auth.service";
import { DownloadService } from "../../shared/download.service";
import { PopupService } from "../../shared/popup.service";
import { DateUtils } from "../../utils/date-utils";
import { ListUtils } from 'app/utils/list-utils';
import { FrontOfficeStructService } from "../front-office-struct.service";
import { IActionAPI } from "app/shared/entity";
import { BoService } from "app/back-office/back-office.service";
import { StringUtils } from "app/utils/string-utils";

@Component({
  selector:'ngbd-modal-seq-error-content',
  template: `
    <div class="modal-body">
      <div class="crud-seq-error">
          <div id="gridSeqError"></div>
      </div>
    </div>
    <div id="loadpanelSeq"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
        Fermer
      </button>
    </div>
  `,
  styleUrls: ['./modal-seq-error.component.css']
})

export class NgbdModalSeqErrorDetailContent implements OnInit {
  @Input() title: string = "default";

  @Output() messageToParent :EventEmitter<any> = new EventEmitter();

  public entityIdentifier : string;
  public applicationName : string;
  public seqError : any;
  public version :string;
  public space : string;
  public storage : any;
  public entityType : string;
  public userMail : string;
  userEmail : string = '';
  newLinetabs: any[] = [];
  seqErrors: any[] = [];
  constructor(
    public httpClient: HttpClient,
    private authService: AuthService,   
    private downloadService: DownloadService,
    private localStorageService: LocalStorageService,    
    private appStructService:  FrontOfficeStructService,
    private componentService: BoService,
    public sanitizer: DomSanitizer){}

    async ngOnInit(): Promise<void> {
      const loadPanel = $('#loadpanel').dxLoadPanel({
        shadingColor: 'rgba(0,0,0,0.4)',
        visible: true,
        container : '#loadpanel',
        showIndicator: true,
        position: { of: parent },
        showPane: true,
        shading: true,
        hideOnOutsideClick: false,
    }).dxLoadPanel('instance');
      var storage = this.localStorageService.getWithExpiration(this.space);
      storage = JSON.parse(storage);
      this.storage = storage
      this.version = storage.Version;
      if(!storage.Lists) {
        storage.Lists = [];
      }
      let masterColumns: any[] = [];
      this.userEmail = this.authService.getEmail();
      var configGrid = <any>{
        "paging": { "pageSize": 20 },
        "remoteOperations": true,
        "columnAutoWidth": true,
        "noDataText": "Aucune données présente pour ce composant",
        "width":"100%",
        "keyExpr":"Id",
        "showBorders": true,
        "columnMinWidth": 20,
        "allowColumnResizing": true,
        "allowColumnReordering": true,
        "sorting": { "mode": "multiple" },
        "rowAlternationEnabled": true,
        "filterRow": { "visible": true },
        "filterPanel": { "visible": false },
        "headerFilter": { "visible": false },
        "selection": {
          "mode": "multiple",
          "showCheckBoxesMode":"always"
        },
        "scrolling": { "mode": "virtual" },
        "columnChooser": { "enabled": false },
        "columnFixing": { "enabled": true },
        "columns": [
          {
            "dataField": "Id",
            "caption": "Id log",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 1,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": false,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Modèle",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Id"
          }, 
          {
            "dataField": "Message",
            "caption": "Message",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": true,
            "visibleIndex": 4,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": false,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Modèle",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Message"
          },
          {
            "dataField": "Level",
            "caption": "Niveau",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": true,
            "visibleIndex": 2,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": false,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Modèle",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Level"
          },
          {
            "dataField": "StatusCode",
            "caption": "Status",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": true,
            "visibleIndex": 3,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": false,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Modèle",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "StatusCode"
          }, 
          {
            "dataField": "Application",
            "caption": "App",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 1,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Métier",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Application"
          }, 
          {
            "dataField": "Path",
            "caption": "Chemin API",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 2,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Métier",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Path"
          },
          {
            "dataField": "ApplicationName",
            "caption": "FO",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 3,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Métier",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "ApplicationName"
          },
          {
            "dataField": "Zone",
            "caption": "Espace",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 4,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Métier",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "Zone"
          },
          {
            "dataField": "ExceptionStack",
            "caption": "Stack",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 1,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "textarea",
            "tabName": "Technique",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "ExceptionStack"
          },
          {
            "dataField": "ExceptionMessage",
            "caption": "Exceptions",
            "dataType": "string",
            "cellTemplate": null,
            "format": null,
            "width": null,
            "subPerimeter": "ECO:R/D|V#BASE:R/D|V#ADM:R/D|V#",
            "visible": false,
            "visibleIndex": 2,
            "allowSorting": true,
            "isRequired": false,
            "defaultValue": "",
            "tooltip": null,
            "allowEditing": true,
            "sortOrder": null,
            "type": null,
            "isIncludedInMaster": true,
            "isIncludedInDataGrid": false,
            "renderType": "default",
            "tabName": "Technique",
            "style": null,
            "cssClass": null,
            "link": null,
            "calculSummary": null,
            "name": "ExceptionMessage"
          }
        ],
        "pager": null,
        "editing": {
            "allowUpdating": false,
            "allowDeleting": false,
            "canDelete": false,
            "allowAdding": false,
            "useIcons": true,
            "mode": "cell"
        },
        "subPerimeter": "ECO:C|R|U|D/D#ECO:R/V#BASE:R/D#BASE:R/V#ADM:C|R|U|D/D#ADM:R/V#",
        "loadPanel": {
            "enabled": true,
            "showIndicator": true,
            "shading": true
        },
        "masterDetail": { "enabled": true },
        "dataSource": []
    }
    var ctrl = this;
    
    ctrl.newLinetabs = [];
    for (var i = 0; i < configGrid.columns.length; i++) {
      var column = configGrid.columns[i];
      if(column.isIncludedInMaster) {
        masterColumns.push(column);
      }
      if(column.isIncludedInMaster || column.isIncludedInDataGrid) {
        let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, true)
        var currentTab:any = null;
        var currentTabNew:any = null;
        for(var j=0; j<  ctrl.newLinetabs.length; j++) {
          if( ctrl.newLinetabs[j].title == column.tabName)
            currentTabNew =  ctrl.newLinetabs[j];
        }
        if(currentTab == null) {
          currentTab = {};
          currentTab.items = [];
          currentTab.items.push(item);
          currentTab.colCount= 1;
          currentTab.title = column.tabName;
        }
        else {
          if(item.colSpan && item.colSpan== 2) {
            let previousItem = currentTab.items[currentTab.items.length-1];
            previousItem.colSpan = 2;
          }
          currentTab.items.push(item);
        }
        if(currentTabNew == null) {
          currentTabNew = {};
          currentTabNew.items = [];
          if(!item.editorOptions.disabled)
            currentTabNew.items.push(item);
          currentTabNew.colCount= 1;
          currentTabNew.title = column.tabName;
          ctrl.newLinetabs.push(currentTabNew);

        }
        else {
          if(item.colSpan== 2 && currentTabNew.items.length > 0) {
            let previousItem = currentTabNew.items[currentTabNew.items.length-1];
            previousItem.colSpan = 2;
          }
          if(!item.editorOptions.disabled)
            currentTabNew.items.push(item);
        }
      }
    }
    
    
    ctrl.newLinetabs = ctrl.newLinetabs.filter((tb: { items: any; }) => tb.items.length > 0);
    var ctrl = this;
    var gridEvent = {
      onCellPrepared: function (e: any) {
        if (e.column.style && e.column.style !== "") {
          var styles = e.column.style.split(';')
          styles.forEach((style: string) => {
            var attributs = style.split(':');
          e.cellElement.css(attributs[0], attributs[1]);
          });
        }
      },
    };
    if(masterColumns.length > 0) {
      await ctrl.configMaster(configGrid, masterColumns, storage, ctrl, true, true, 'seqError');
    }
    this.seqErrors = await lastValueFrom(this.appStructService.getSeqLog(this.entityIdentifier));
    configGrid.dataSource = this.seqErrors;
    $('#gridSeqError').dxDataGrid(<string>configGrid).dxDataGrid(gridEvent).dxDataGrid('instance')
    $('#loadpanel').dxLoadPanel('instance').hide();
  }

  async configMaster(configGrid : any, masterColumns: any[], storage: any, ctrl:NgbdModalSeqErrorDetailContent, addMessage : boolean, addDocument : boolean, nomcomposant : string) {
    var tabs: any[]= [];
    for (var i = 0; i < masterColumns.length; i++) {
      var column = masterColumns[i];
      if(column.dataField == 'FkObject' || column.dataField == 'Id' || column.dataField == 'Code')
        continue;
      let item = await ctrl.getItemFormPerColumn(column, storage, ctrl, false)
      var currentTab:any = null;
      for(var j=0; j<  tabs.length; j++) {
        if( tabs[j].Name == StringUtils.decodeSpecialEntitiesHtml(column.tabName))
          currentTab =  tabs[j];
      }
      if(currentTab == null) {
        currentTab = {};
        currentTab.items = [];
        currentTab.items.push(item);
        currentTab.Name = StringUtils.decodeSpecialEntitiesHtml(column.tabName);
        tabs.push(currentTab);
      }
      else {
        if(item.colSpan== 2) {
          let previousItem = currentTab.items[currentTab.items.length-1];
          previousItem.colSpan = 2;
        }
        currentTab.items.push(item);
      }
    }

    if(this.space !="MDEL") {
      configGrid.masterDetail = {
        enabled: true,
        template: async function(element : any, masterDetailOptions :any) {
          let grid = $('#gridSeqError').dxDataGrid('instance');
          grid.option("editing.mode", "cell")
          let detailEntity = ctrl.seqErrors.filter((tb: { Id: string; }) => tb.Id == masterDetailOptions.data.Id)[0];
          var items = [];
          for(var i=0; i<  tabs.length; i++) {
            if( tabs[i].Name == '')
            tabs[i].Name ='Infos'
            items.push({
              title:  tabs[i].Name,
              data: { item : detailEntity, items :tabs[i].items, index:i},
              template: function(itemData:any, itemIndex:any, itemElement:any) {
                var formName = 'formMaster'+itemData.data.index;
                var info = $('<div id="'+formName+'">').addClass('component-master-form').addClass('master-tab-form').dxForm({
                  formData: itemData.data.item,                    
                  labelMode: 'floating',
                  scrollingEnabled:true,
                  showColonAfterLabel: true,
                  colCount: 2,
                  items: itemData.data.items
                });
                itemElement.append(info);
              }
            })
          }
          if(items.length > 1) {
            element.append($('<div>').dxTabPanel(({ items: items })));
          }
          else {
            element.append($('<div id="formMaster">').addClass('component-master-form').dxForm({
              formData: detailEntity,
              labelMode: 'floating',
              showColonAfterLabel: true,
              showValidationSummary: true,
              validationGroup: 'validateForm',
              colCount: 2,
              items:  tabs[0].items
            }));
          }
          grid.updateDimensions();
        }
      };
    }
  }

  getItems(tabModel:any) {
    var items = tabModel.items;
    var groupsItems = 
    [
      {
        itemType: 'group',
        items: items.filter((tb: { dataField: string; }) => tb.dataField == 'Title' || tb.dataField == 'Message'),
      },
      {
        itemType: 'group',
        items: items.filter((tb: { dataField: string; }) => tb.dataField != 'Title' && tb.dataField != 'Message'),
      }
    ];
    return groupsItems;
  }

  async getItemFormPerColumn(column:any, storage: any, ctrl:NgbdModalSeqErrorDetailContent, withdefault:boolean) {
    let item : any = {
      dataField: column.dataField,
      label:{
        text: column.caption,
      },
      editorOptions: {
        
        disabled: column.allowEditing == false,
        displayFormat : '' ,       
      }
    };
    if(column.dataType == 'bool')
      column.isRequired = false;
    if(column.isRequired)
    {
      item.validationRules= [{ type: "required" }]
    }
    if(column.dataType == "decimal" || column.dataType == "number"){
      item.editorType = 'dxNumberBox';
      item.editorOptions = {
        showSpinButtons: false,
        displayFormat : '' , 
        dataField : column.dataField,
        disabled: column.allowEditing == false,
        format: column.format,
        showClearButton: false,
      }
      item.editorOptions.onKeyDown = function(e:any) {
        var preventedKeys = [38, 40];
        var keyCode = e.event.keyCode;
        var shouldPreventEvent = !e.component.option("opened") &&
            preventedKeys.indexOf(keyCode) !== -1 &&
            !e.event.altKey;
        if(shouldPreventEvent) {
            e.event.preventDefault();
            e.event.stopImmediatePropagation();
        }
      }
      item.formatNumber = item.editorOptions.format
      item.editorOptions.format = function (value:any) {
        return numeral(value).format(item.formatNumber)
      }
    }
    else if(column.dataType == "DynamicList" && typeof(column.format) !== "undefined" && column.format !== null) {
      let itemsT: any[] = [];
      if (ctrl.storage.Lists[column.format.replace('#1', '')] != null) {
        itemsT = JSON.parse(ctrl.storage.Lists[column.format.replace('#1', '')]);
      }
      else {
        var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+column.format.replace('#1', '')+'/0.json';
        itemsT = await lastValueFrom(<any>ctrl.httpClient.get(url));
        ctrl.storage.Lists[column.format.replace('#1', '')] = JSON.stringify(itemsT);
      }
      
      this.setListItem(itemsT, item, column);

      if(column.format.indexOf('#1') != -1) {
        item.editorOptions.displayExpr = 'Code';
      }
    }
    else if(column.dataType == "DateTime" || column.dataType == "date") {
      item.editorType ='dxDateBox'
      item.editorOptions.displayFormat= "dd.MM.yyyy";
      item.formatDate = item.editorOptions.format
      item.editorOptions.format = function (value:any) {
        return moment(value).format(item.formatDate)
      }
    }
    else if(column.dataType == "bool") {
      column.dataType ='boolean';
      item.editorType = "dxCheckBox";      
    }
    else if(column.dataType == "string" && column.renderType =="textarea") {
      item.editorType = "dxTextArea";
      item.colSpan= 2;
      item.editorOptions= {
        height: 90,
        displayFormat : '' , 
        disabled: column.allowEditing == false,
      }
    }
    else if(column.dataField == "OpIdentifiant") {
      let itemsT: any[] = await this.getOpCampagneItems();
      this.setListItem(itemsT, item, column);
    }
    else if(column.dataType == "string" && column.renderType =="html") {
      item.editorType = 'dxHtmlEditor';
      item.colSpan= 1;
      item.editorOptions = {
        height: 300,
        disabled: column.allowEditing == false,
        imageUpload: {
          tabs: ['file', 'url'],
          fileUploadMode: 'base64',
        },
        toolbar: {
          multiline:false,
          items: [
            'undo', 'redo', 'separator',
            {
              name: 'size',
              acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
            },
            {
              name: 'font',
              acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
            },
            'separator', 'bold', 'italic', 'strike', 'underline', 'separator', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator', 'orderedList', 'bulletList', 'separator',
            {
              name: 'header',
              acceptedValues: [false, 1, 2, 3, 4, 5],
            }, 
            'separator', 'color', 'background', 'separator', 'link', 'image', 'separator', 'clear', 'codeBlock', 'blockquote', 'separator', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft', 'insertColumnRight', 'deleteColumn',
          ],
        },
        mediaResizing: {
          enabled: true,
        },
        allowSoftLineBreak :true,
        onFocusOut : async function (e:any) {
          var attribut = {
            Name:'Message',
            Value :e.component.option('value'),
          }
          let grid = $('#gridSeqError').dxDataGrid('instance');
          var selectRows = grid.getSelectedRowsData();
          await lastValueFrom(ctrl.componentService.updateAttributProperty(ctrl.space, ctrl.entityType, selectRows[0].Id, 'CD', 'seqError', attribut));
        }
      }
      item.label.location= 'top';
      item.label.showColon=false;
    }
    
    if(withdefault) {
      if(column.defaultValue && column.defaultValue.length > 0) {
        if(column.dataType == 'DynamicList' && column.dataField !='Code') {
          var list = JSON.parse(ctrl.storage.Lists[column.format]);
          item.editorOptions.value = ListUtils.getListItemByIdEnumerationItem(list, column.defaultValue);
        }
        else if(column.dataType == 'boolean') {
          item.editorOptions.value = column.defaultValue.toLowerCase() == 'true'
          column.defaultValue = item.editorOptions.value
        }
        else if (column.dataType == 'date' || column.dataType == 'datetime'){
          item.editorOptions.value = DateUtils.getDateFromStringOrToday(column.defaultValue);
        }
        else {
          item.editorOptions.value=column.defaultValue;
        }
      }
      else if(column.dataType == 'number') {
        item.editorOptions.value= 0
      }
      else if(column.dataType == 'date' || column.dataType == 'datetime') {
        item.editorOptions.value = new Date();
      }
      else if(column.dataType == 'boolean') {
        item.editorOptions.value= false
      }
      else if(column.dataType == 'string') {
        item.editorOptions.value= ''
      }
      else if(column.dataType == 'DynamicList' && column.dataField !='Code') {
        var list = JSON.parse(ctrl.storage.Lists[column.format]);
        item.editorOptions.value = list[0]
      }
      if(column.dataField =='DisplayEnumCode'  || column.dataField =='Id') {
        item.editorOptions.value= ' .'
      }
      column.defaultValue = item.editorOptions.value
    }

    return item
  }

  async getOpCampagneItems() {
    var ctrl = this;
    let itemsT: any[] = [];
          
    if (ctrl.storage.Lists['opcampagne'] != null) {
      itemsT = JSON.parse(ctrl.storage.Lists['opcampagne']);
    }
    else {
      var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/opcampagne/0.json';
      itemsT = <any[]>await lastValueFrom((<any>ctrl.httpClient).get(url));
      ctrl.storage.Lists['opcampagne'] = JSON.stringify(itemsT);
    }

    return itemsT;
  }

  setListItem(itemsT : any, item : any, column : any) {
    itemsT = ListUtils.getOnlyActiveItems(itemsT);
    item.editorType = 'dxSelectBox';
    item.editorOptions = {
      dataSource: itemsT,
      displayFormat : '' , 
      searchEnabled: true,
      disabled: column.allowEditing == false,
      displayExpr: 'Label',
      valueExpr: 'IdEnumerationItem',
      deferRendering : true
    }
  }


}

@Component({
  selector: "ngbd-modal-seqError-component",
})

export class NgbdModalSeqErrorComponent{
  @Output() refreshSeqErrorChange: EventEmitter<any> = new EventEmitter();
  modalRef : any;
  insidePopup: any;
  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }
  
  show(actionAPI: IActionAPI, entityId: string, space: string, applicationName: string){
    this.popupService.showPopup('Details et parcours de l\'erreur', this.getContentTemplate(actionAPI, entityId, space, applicationName), '70vw');

    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
  }

  getContentTemplate(actionAPI: IActionAPI, entityId: string, space: string, applicationName: string) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalSeqErrorDetailContent, {
        environmentInjector,
    });

    this.insidePopup.instance.entityIdentifier = entityId;
    this.insidePopup.instance.space = space;
    this.insidePopup.instance.applicationName = applicationName;
    this.insidePopup.instance.entityType = actionAPI.Parameters.EntityType;
    this.insidePopup.instance.messageToParent.subscribe((seqError:any)=>{
      actionAPI.Parameters.ExtraParameters = JSON.stringify(this.getParameterFromSeqError(seqError, seqError.Action=='SendMe'));
    })

    this.insidePopup.hostView.detectChanges();
    return this.insidePopup.location.nativeElement;
  }

  getParameterFromSeqError(seqError: any, demoMode: any) {
    return {
      Identifier : seqError.OpIdentifiant,
      Body : seqError.Message,
      Subject : seqError.Title,
      SendToEP : seqError.SentToEpUser,
      Excel : seqError.Excel,
      DocumentsId : seqError.Documents,
      DemoMode : demoMode
    }
  }

  sendSeqError(sendType:string) {
    this.insidePopup.instance.sendForm(sendType)
  }
}

