import { StringUtils } from "app/utils/string-utils";
import * as moment from "moment";
import * as numeral from "numeral";
import { lastValueFrom } from "rxjs";

export class FrontOfficeMobileDetailFactory {
    static createFileUploader(ctrl:any, row:any) {
        $('#file-uploader').dxFileUploader({
            multiple: false,
            accept: row.Extension,
            maxFileSize: row.MaxSizePerDoc,
            value: [],
            uploadMode: 'useForm',
            onValueChanged(e) {
                ctrl.saveDocument(e.value)
            },
        }).dxFileUploader('instance');
    }

    static deleteComment(ctrl:any, row:any, lastComment:any): void {
        if(lastComment.Id)
        {
            ctrl.appStructService.deleteComment(ctrl.applicationName, ctrl.version, ctrl.zoneName, lastComment.Id, ctrl.longTabName).subscribe({
            next: () => {
                var blocref = ctrl.zoneName+'_'+ctrl.applicationName;
                ctrl.appStructService.saveCommentContext(ctrl.zoneName, blocref, ctrl.entityIdentifier, row.module, row.onglet, row.bloc, row.ligne, ctrl.comments.length > 2, ctrl.comments.length-2, ctrl.currentZone.WorkflowId).subscribe({
                next: () => {
                    var temp = 
                    {
                        Module: row.module,
                        Onglet: row.Onglet,
                        Bloc: row.Bloc,
                        Ligne: row.Ligne,
                        Reference : ctrl.longTabName+':'+row.Numbering,
                        Type:'comments',
                        Value:false
                    }
                    ctrl.appStructService.updateParent.emit(temp)
                }});
                ctrl.reloadComment();
            }
            });
        }
        else {
            ctrl.comments.pop();
        }
    }

    static saveComment(ctrl:any, row:any, lastComment:any): void {
        if(lastComment.Id)
        {
            ctrl.appStructService.updateComment(ctrl.applicationName, ctrl.version, ctrl.zoneName, lastComment.Id, lastComment.Commentaire, ctrl.longTabName, row.SubPerimeterDoc).subscribe({
                next: () => {
                    ctrl.reloadComment();
                }
            });
        }
        else{
            ctrl.appStructService.addComment(ctrl.applicationName, ctrl.version, ctrl.zoneName, ctrl.entityIdentifier, lastComment, ctrl.longTabName, row.SubPerimeterDoc).subscribe({
            next: () => {
                var blocref = ctrl.zoneName+'_'+ctrl.applicationName;
                            ctrl.appStructService.saveCommentContext(ctrl.zoneName, blocref, ctrl.entityIdentifier, row.module, row.onglet, row.bloc, row.ligne, true, ctrl.comments.length, ctrl.currentZone.WorkflowId).subscribe({
                            next: () => {
                                var temp = 
                                {
                                    Module: row.module,
                                    Onglet: row.Onglet,
                                    Bloc: row.Bloc,
                                    Ligne: row.Ligne,
                                    Type:'comments',
                                    Value:true
                                }
                                ctrl.appStructService.updateParent.emit(temp)
                            }});
                    ctrl.reloadComment();
                }
            });
        }
    }

    static addNewComment(ctrl:any, row:any): void {
        ctrl.comments.push(
            {
                DateCreation : new Date(),
                DateCreationString : moment(new Date()).format('DD.MM.yyyy'),
                Owner:ctrl.me,
                ErrorMessage:'',
                Module: row.module,
                Onglet: row.Onglet,
                Bloc: row.Bloc,
                Ligne: row.Ligne,
            }
        )
    }

    static refreshControleStatus(ctrl:any, row:any)
    {
        if(row.ContextInfo != null) {
            if(ctrl.documents.length == 0 && ctrl.comments.length == 0)
            {
                if(row.ContextInfo.Level == 2 || row.DocumentLevel == 3 || row.CommentLevel == 3)
                    ctrl.controleType = 'danger';
                else if(row.ContextInfo.Level == 1)
                    ctrl.controleType = 'warning';
            }
            else if(ctrl.documents.length == 0 && row.DocumentLevel == 3)
                ctrl.controleType = 'danger';
            else if(ctrl.comments.length == 0 && row.CommentLevel == 3)
                ctrl.controleType = 'danger';
            else if(row.ContextInfo.Level != 0 && ctrl.documents.length > 0 && row.DocumentLevel > 1 && row.CommentLevel < 2)
                ctrl.controleType = '';   
            else if(row.ContextInfo.Level != 0 && ctrl.comments.length > 0 && row.CommentLevel > 1 && row.DocumentLevel < 2)
                ctrl.controleType = ''; 
            else if(row.ContextInfo.Level != 0 && ctrl.documents.length > 0 && row.DocumentLevel > 1 && ctrl.comments.length > 0 && row.CommentLevel > 1)
                ctrl.controleType = '';
            else if(row.ContextInfo.Level == 2)
                ctrl.controleType = 'danger';
            else if(row.ContextInfo.Level == 1)
                ctrl.controleType = 'warning';
            
        }
    }

    static openTabDoc(ctrl:any, row:any) {
        if(row.MaxDoc == 0 || ctrl.documents.length < row.MaxDoc) {
            setTimeout(() => {
                if(row.MaxSizePerDoc == 0)
                    row.MaxSizePerDoc = 10000000;
                if(row.Extension == '')
                    row.Extension = 'pdf';

                ctrl.formats = row.Extension.split(',');
                for(var i =0; i<ctrl.formats.length;i++){
                    if(ctrl.formats[i][0] != '.')
                        ctrl.formats[i] = '.'+ctrl.formats[i];
                }
                row.Extension = ctrl.formats.join(',');
                FrontOfficeMobileDetailFactory.createFileUploader(ctrl, row);
            }, 800);
        }
    }

    static downloadDocument(ctrl:any, documentA: any): void {
        var url = ''
        if(documentA.DocumentGuid == ''){
            url = './api/'+ctrl.version+'/app/'+ctrl.applicationName+'/'+ctrl.zoneName+'/'+ctrl.entityIdentifier+'/old_documents.json';
            
            ctrl.downloadService.downloadWithObject(url, documentA).subscribe((response:any) => {
                var filename = documentA.SourceFileName;
                if (response.body) {
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(response.body);
                    a.download = filename;
                    a.click();
                }
            });
        }
        else
        {
            url = './api/v1/spaces/'+ctrl.zoneName+'/documents/'+documentA.DocumentGuid;
            
            ctrl.downloadService.download(url).subscribe((response:any) => {
                var filename = documentA.SourceFileName;
                if (response.body) {
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(response.body);
                    a.download = filename;
                    a.click();
                }
            });
        }
    }

    static async addElement(ctrl:any, item:any, formData:any){

        if(item.editorOptions.disabled)
        {
            if(formData[0][item.dataField] == null )
            return;
            $('#'+item.id).empty()
            let label ='';
            if(item.caption != null)
            label = '<label>'+item.caption+' : &nbsp;</label>';
            if(item.editorType == 'dxNumberBox' && item.editorOptions.format != '')
            {
            $('#'+item.id).append("<span>"+label+numeral(formData[0][item.dataField]).format(item.editorOptions.format)+"</span>");
            }
            else if(item.editorType == 'dxDateBox' && item.editorOptions.displayformat != '')
            {
            item.editorOptions.displayFormat = item.editorOptions.displayFormat.replace('dd', 'DD')
            $('#'+item.id).append("<span>"+label+moment(formData[0][item.dataField]).format(item.editorOptions.displayFormat)+"</span>");
            }
            else if(item.editorType == 'dxSelectBox')
            {
            if(item.list.indexOf('#1') != -1)
            {
            $('#'+item.id).append("<span>"+label+formData[0][item.dataField.replace('.IdEnumerationItem','')].Code+"</span>");
            }
            else
            $('#'+item.id).append("<span>"+label+formData[0][item.dataField.replace('.IdEnumerationItem','')].Label+"</span>");
            }
            else
            $('#'+item.id).append("<span>"+label+formData[0][item.dataField]+"</span>");
        }
        else if (ctrl.currentModule.IsModuleUser && item.dataField =='Organisation')
        {
            var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
            var url = './api/v1/application/'+ctrl.space+'/availableOrganisation/vue.json';
            let param = {
            Parameters : ''
            }
            var items = await lastValueFrom(ctrl.httpClient.post(url, param));
            $('#'+item.id).dxAutocomplete(
            {
            dataSource: items,
            placeholder: 'Veuillez choisir une organisation',
            valueExpr: 'Key',
            value: formData[0][item.dataField],
            itemTemplate(data) {
            return $(`<div>${data.Val} (${data.Type})</div>`);
            },
            showClearButton: false,
            async onItemClick(data) {
            if(data.itemData.Key == item.editorOptions.value)
            return;
            var attribut =
            {
            Name:item.fullDataField,
            Value :data.itemData.Key,
            Format :item.SubPerimeter
            }
            await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
            ctrl.appStructService.updateFormData.emit('ok')
            },
            });
        }
        else if (ctrl.currentModule.IsModuleUser && item.dataField =='RolePrincipal')
        {
            var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
            var url = './api/v1/application/'+ctrl.space+'/availableRole/vue.json';
            let param = {
            Parameters : ''
            }
            var items = await lastValueFrom(ctrl.httpClient.post(url, param));   
            $('#'+item.id).dxAutocomplete(
            {
            dataSource: items,
            placeholder: 'Veuillez choisir un rôle',
            valueExpr: 'Label',
            value: formData[0][item.dataField],
            itemTemplate(data) {
            return $(`<div>${data.Label}</div>`);
            },
            showClearButton: false,
            async onItemClick(data) {
            if(data.itemData.Role == item.editorOptions.value)
            return;
            var attribut =
            {
            Name:item.fullDataField,
            Value :data.itemData.Role,
            Format :item.SubPerimeter
            }
            await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
            ctrl.appStructService.updateFormData.emit('ok')
            },
            });
        }
        else{
            var tabName = StringUtils.getTabNameWithConsiderMultiTab(ctrl);
            if(item.caption != null)
            {
                item.editorOptions.label = item.caption;
                item.editorOptions.labelMode= 'static';
                item.editorOptions.labelLocation= 'left';
            }
            if(item.editorType == 'dxNumberBox')
            {
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                var attribut =
                {
                    Name:item.fullDataField.replace('.IdEnumerationItem',''),
                    Value :e.value.toString(),
                    Format :item.SubPerimeter
                }
                await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                ctrl.appStructService.updateFormData.emit('ok')
                };
                item.editorOptions.onKeyDown = function(e:any) {
                    var preventedKeys = [38, 40];
                    var keyCode = e.event.keyCode;
                    var shouldPreventEvent = !e.component.option("opened") &&
                    preventedKeys.indexOf(keyCode) !== -1 &&
                    !e.event.altKey;
                    if(shouldPreventEvent) {
                        e.event.preventDefault();
                        e.event.stopImmediatePropagation();
                    }
                }

                item.formatNumber = item.editorOptions.format
                item.editorOptions.format = function (value:any) {
                    return numeral(value).format(item.formatNumber)
                }
                $('#'+item.id).dxNumberBox(item.editorOptions);
            }
            else if(item.editorType == 'dxSelectBox')
            {
                let items: any[] = [];

                if (ctrl.storage.Lists[item.list.replace('#1', '')] != null) {
                    items = JSON.parse(ctrl.storage.Lists[item.list.replace('#1', '')]);
                }
                else
                {
                    var url = './api/'+ctrl.version+'/spaces/'+ctrl.space+'/Definitions/enum_list/'+item.list.replace('#1', '')+'/0.json';
                    items = await lastValueFrom(ctrl.httpClient.get(url));
                    ctrl.storage.Lists[item.list.replace('#1', '')] = JSON.stringify(items);
                }
                item.editorOptions.dataSource = items;
                if(item.list.indexOf('#1') != -1)
                {
                    item.editorOptions.displayExpr = 'Code';
                }
                item.editorOptions.value = formData[0][item.dataField.replace('.IdEnumerationItem','')].Label;
                item.editorOptions.onSelectionChanged = async function (e:any) {
                    const newValue = e.selectedItem.IdEnumerationItem;
                    if(e.selectedItem.Label == item.editorOptions.value)
                    return;
                    var attribut =
                    {
                        Name:item.fullDataField.replace('.IdEnumerationItem',''),
                        Value :newValue,
                        Format :item.SubPerimeter
                    }
                    await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                    ctrl.appStructService.updateFormData.emit('ok')
                }
                $('#'+item.id).dxSelectBox(item.editorOptions);
            }
            else if(item.editorType == 'dxTextBox')
            {
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                var attribut =
                {
                Name:item.fullDataField.replace('.IdEnumerationItem',''),
                Value :e.value.toString(),
                Format :item.SubPerimeter
                }
                await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                ctrl.appStructService.updateFormData.emit('ok')
                };
                $('#'+item.id).dxTextBox(item.editorOptions);
            }
            else if(item.editorType == 'dxTextArea')
            {
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.height= 90;
                item.editorOptions.onValueChanged = async function (e:any) {
                    var attribut =
                    {
                        Name:item.fullDataField.replace('.IdEnumerationItem',''),
                        Value :e.value.toString(),
                        Format :item.SubPerimeter
                    }
                    await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                    ctrl.appStructService.updateFormData.emit('ok')
                };
                $('#'+item.id).dxTextArea(item.editorOptions);
            }
            else if(item.editorType == 'dxDateBox')
            {
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                    var attribut =
                    {
                        Name:item.fullDataField.replace('.IdEnumerationItem',''),
                        Value :moment(e.value.toString()).format('DD.MM.yyyy'),
                        Format :item.SubPerimeter
                    }
                    await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                    ctrl.appStructService.updateFormData.emit('ok')
                };
                item.editorOptions.displayFormat= "dd.MM.yyyy";
                item.formatDate = item.editorOptions.format
                item.editorOptions.format = function (value:any) {
                    return moment(value).format(item.formatDate)
                }
                $('#'+item.id).dxDateBox(item.editorOptions);
            }
            else if(item.editorType == 'dxCheckBox')
            {
                item.editorOptions.value = formData[0][item.dataField];
                item.editorOptions.onValueChanged = async function (e:any) {
                    var attribut =
                    {
                        Name:item.fullDataField.replace('.IdEnumerationItem',''),
                        Value :e.value.toString(),
                        Format :item.SubPerimeter
                    }
                    await lastValueFrom(ctrl.applicationFormService.updateAttributProperty(ctrl.space,ctrl.version, ctrl.applicationName, ctrl.entityIdentifier, ctrl.zoneName, tabName, attribut));
                    ctrl.appStructService.updateFormData.emit('ok')
                };
                $('#'+item.id).dxCheckBox(item.editorOptions);
            }
        }
    }

    static setTabs(ctrl:any, row:any) {
        $('#tabs').dxTabs({
            dataSource: ctrl.tabs,
            selectedItem: ctrl.tabs.filter((tb: any) => tb.id == ctrl.activePanel)[0],
            onItemClick(e) {
                if(e.itemData.id == 'panelData')
                    ctrl.dataActiv = true;
                else
                    ctrl.dataActiv = false;

                if(e.itemData.id == 'panelInfos')
                    ctrl.infoActiv = true;
                else
                    ctrl.infoActiv = false;

                if(e.itemData.id == 'panelComments')
                    ctrl.commentActiv = true;
                else
                    ctrl.commentActiv = false;

                if(e.itemData.id == 'panelDocs') {
                    ctrl.docActiv = true;
                    FrontOfficeMobileDetailFactory.openTabDoc(ctrl, row);
                }
                else
                    ctrl.docActiv = false;

                if(e.itemData.id == 'panelControle')
                    ctrl.controlActiv = true;
                else
                    ctrl.controlActiv = false;
            },
        }).dxTabs('instance');
    }

    static setPanelControl(ctrl:any) {
        ctrl.tabs.push({
            id: 'panelControle',
            icon: 'clear',
        })

        if(ctrl.activePanel == '') {
            ctrl.activePanel = 'panelControle';
            ctrl.controlActiv = true;
        }
    }

    static async setCanAddOrDeleteFile(ctrl:any, row:any) {
        ctrl.deleteFile = true;
        ctrl.addFile = true;
        if(row.SubPerimeterDoc != '') {
            ctrl.deleteFile =  await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, row.SubPerimeterDoc, ctrl.stateId, ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'DSub' : 'D');  
            ctrl.addFile =  await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, row.SubPerimeterDoc, ctrl.stateId, ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'CSub' : 'C');
        }
    }

    static setPanelDoc(ctrl:any, row:any) {
        var nbDoc = row.ContextInfo && row.ContextInfo.NbDocument ? row.ContextInfo.NbDocument : 0

        ctrl.tabs.push({
        id: 'panelDocs',
        icon: 'textdocument',
        text: nbDoc
        })

        if(ctrl.activePanel == '') {
            ctrl.activePanel = 'panelDocs';
            ctrl.docActiv = true;
        }
    }

    static setCommentDateCreationModification(ctrl:any) {
        for(var i = 0; i < ctrl.comments.length; i++)
        {
            ctrl.comments[i].DateCreationString = moment(ctrl.comments[i].DateCreation).format('DD.MM.yyyy')
            ctrl.comments[i].DateCreation = new Date(ctrl.comments[i].DateCreation)
            if(ctrl.comments[i].DateModification)
            {
                ctrl.comments[i].DateModificationString = moment(ctrl.comments[i].DateModification).format('DD.MM.yyyy')
            }
        }
    }

    static setPanelComments(ctrl:any, row:any) {
        if(ctrl.activePanel == '')
        {
            ctrl.activePanel = 'panelComments';
            ctrl.commentActiv = true;
        }

        var nbComment = row.ContextInfo && row.ContextInfo.NbComment ? row.ContextInfo.NbComment : 0;

        ctrl.tabs.push({
            id: 'panelComments',
            icon: 'comment',
            text: nbComment
        })
    }

    static setPanelInfo(ctrl:any) {
        ctrl.tabs.push({
            id: 'panelInfos',
            icon: 'info',
        })
    }

    static setPanelData(ctrl:any) {
        ctrl.tabs.push({
            id: 'panelData',
            icon: 'edit',
        })
        ctrl.activePanel = 'panelData';
    }

    static async getCanShowFile(ctrl:any, row:any) {
        var action = ctrl.currentEntity != null && ctrl.currentEntity.IsSpecial ? 'RSub' : 'R';
        var canShowFile = await ctrl.appStructService.getSubPerimeter(ctrl.space, ctrl.version, ctrl.applicationName, ctrl.zoneName, row.SubPerimeterDoc, ctrl.stateId,action); 
        return canShowFile;  
    }
}