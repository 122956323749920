import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import * as $ from 'jquery';
import { FrontOfficeFormService } from "../../shared-service/front-office-form.service";
import { FrontOfficeStructService } from "../../../front-office-struct.service";
import { LocalStorageService } from "app/shared/local-storage.service";
import { __await } from "tslib";
import { DownloadService } from "app/shared/download.service";
import { AuthService } from "app/shared/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { lastValueFrom } from 'rxjs';
import { StringUtils } from "app/utils/string-utils";
import { FrontOfficeMobileDetailFactory } from "../../shared-factory/front-office-mobile-detail-factory";
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'front-office-detail-form-mobile',
    templateUrl: './front-office-detail-form-mobile.component.html',
    styleUrls: ['./front-office-detail-form-mobile.component.css']
})

export class FrontOfficeDetailFormMobileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() formName: string = '';
  @Input() perimeter: string = '';
  @Input() version: string = '';
  @Input() space: string = '';
  @Input() applicationName: string = '';
  @Input() zoneName: string = '';
  @Input() moduleName: string = '';
  @Input() tabName: string = '';
  @Input() variante: string = '';
  @Input() entityIdentifier: string = '';
  @Input() multiTab: boolean;
  @Input() contextInfo: any[];
  @Input() labels: string[];
  @Input() currentZone: any;
  @Input() currentEntity: any;
  @Input() stateId: string = '';
  @Input() group:any;
  @Input() entityCible: string = '';
  information: string = '';
  formData:any;
  me: string = '';
  activePanel: string = '';
  showInformation: boolean = false;
  dataActiv: boolean = true;
  infoActiv: boolean = false;
  showComment: boolean = false;
  commentActiv: boolean = false;
  docActiv: boolean = false;
  controlActiv: boolean = false;
  showFile: boolean = false;
  deleteFile: boolean = false;
  addFile: boolean = false;
  comments: any[] = [];
  documents: any[] = [];
  fullyLoaded: boolean = false;
  storage : any;
  longTabName:string = '';
  formats: string[] = [];
  tabs: any[] =[];
  showControl: boolean = false;
  controleType: string = '';
  constructor(
    private appStructService:  FrontOfficeStructService,
    private downloadService: DownloadService,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    private applicationFormService: FrontOfficeFormService,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient) 
  {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.group)
      this.updateContext(this.group)
  }

  async ngOnInit(): Promise<void> {
    this.formData = await lastValueFrom(this.applicationFormService.getFormDataByEntityId(this.space,this.version, this.applicationName, this.entityIdentifier, this.zoneName, this.moduleName, this.tabName, this.formName))
    this.storage = this.localStorageService.getWithExpiration(this.space);
    this.storage = JSON.parse(this.storage);
    if (this.storage != null){
        if(this.storage.Lists == null )
        this.storage.Lists = {};
    }
  }

  async updateContext(group : any) {
    this.tabs =[];
    this.documents = [];
    this.comments = [];
    this.formats = [];
    this.activePanel = '';
    this.information = $("<p/>").html(group.Tooltip).text();
    this.showInformation = group.TooltipEnabled;
    this.showComment = group.AllowComment;
    this.showFile = group.AllowFile;
    var tabName = StringUtils.getTabNameWithConsiderMultiTab(this);
    this.longTabName = tabName;

    if(this.showFile && group.SubPerimeterDoc != '') {
      this.showFile = await FrontOfficeMobileDetailFactory.getCanShowFile(ctrl, group);
    }

    var ctrl = this;
    if(group.ContextInfo != null) {
      this.showControl = group.ContextInfo.Description != '';
    }

    FrontOfficeMobileDetailFactory.setPanelData(ctrl);
    
    if(ctrl.showInformation) {
      FrontOfficeMobileDetailFactory.setPanelInfo(ctrl);
    }

    ctrl.me = ctrl.authService.getEmail();

    if(ctrl.showComment) {
      FrontOfficeMobileDetailFactory.setPanelComments(ctrl, group);
      ctrl.comments = await lastValueFrom(ctrl.appStructService.getAllComments(this.applicationName,this.version,this.zoneName, this.entityIdentifier, group.module, group.onglet, group.bloc, group.ligne, tabName,group.SubPerimeterDoc));
      FrontOfficeMobileDetailFactory.setCommentDateCreationModification(ctrl);
      FrontOfficeMobileDetailFactory.refreshControleStatus(ctrl, ctrl.group);
      FrontOfficeMobileDetailFactory.addNewComment(ctrl, ctrl.group);
      this.comments = this.comments.sort((a, b) => a['DateCreation'] > b['DateCreation'] ? -1 : a['DateCreation'] === b['DateCreation'] ? 0 : 1);
    }

    if(ctrl.showFile) {
      FrontOfficeMobileDetailFactory.setPanelDoc(ctrl, group);
      FrontOfficeMobileDetailFactory.setCanAddOrDeleteFile(ctrl, group);
      ctrl.documents = await lastValueFrom(ctrl.appStructService.getAllDocuments(this.applicationName,this.version,this.zoneName, this.entityIdentifier, group.module, group.onglet, group.bloc, group.ligne, tabName,group.SubPerimeterDoc));
      FrontOfficeMobileDetailFactory.refreshControleStatus(ctrl, ctrl.group);
      FrontOfficeMobileDetailFactory.openTabDoc(ctrl, ctrl.group);
    }

    if(ctrl.showControl) {
      FrontOfficeMobileDetailFactory.setPanelControl(ctrl);
    }

    FrontOfficeMobileDetailFactory.setTabs(ctrl, ctrl.group);

    setTimeout(async () => {i
      for(var i = 0; i<this.group.items.length;i++)
      {
        var itemf = this.group.items[i];
        if(this.labels.length > 0)
          itemf.caption = this.formData[0][this.labels[i]];

        await FrontOfficeMobileDetailFactory.addElement(this, itemf, this.formData);
      }
      this.fullyLoaded = true;
    }, 50);
  }

  ngOnDestroy(): void {  }

  downloadDocument(documentA: any): void {
    FrontOfficeMobileDetailFactory.downloadDocument(this, documentA);
  }

  saveComment(lastComment:any): void {
    FrontOfficeMobileDetailFactory.saveComment(this, this.group, lastComment);
  }

  deleteComment(lastComment:any): void {
    FrontOfficeMobileDetailFactory.deleteComment(this, this.group, lastComment);
  }

  deleteDocument(lastDocument:any): void {
    if(!lastDocument.Id) { 
      this.documents.pop(); 
    }
  }
}
