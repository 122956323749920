import { ApplicationRef, Component, EventEmitter, Input, OnInit, createComponent } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IActionAPI } from "app/shared/entity";
import { PopupService } from "../shared/popup.service";
import * as $ from 'jquery';

@Component({
  selector: "ngbd-modal-confirmation-idp-content",
  template: `
    <div class="modal-body">
      <div>{{ description }}</div>
      <textarea id="remark" [hidden]="!showRemarks" style="padding: .5rem 1rem;width: -webkit-fill-available;width:-moz-available;margin: 1.5rem 0.5rem;" rows="5" class="comment-body" placeholder="Remarques"></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" id="popupCloseButton">
        Annuler
      </button>
      <button type="button" class="btn btn-primary modalOkButton" id="popupOkButton">          
        Ok
      </button>
    </div>
  `
})

export class NgbdModalConfirmationIdpContent implements OnInit {
    showRemarks: boolean = true;
    description: string = '';
    constructor() { }

    ngOnInit() {}
}

@Component({
    selector: "ngbd-modal-confirm-idp-component",
    template: ""
})

export class NgbdModalConfirmationIdpComponent {
  public insidePopup: any;
  public refreshIdpRequestChange = new EventEmitter()
  constructor(public popupService: PopupService, public applicationRef: ApplicationRef) { }

  showConfirmationAction(title: string, description: string, idpRequest: any, showRemarks : boolean) {
    var popup = this.popupService.showPopup(title, this.getContentTemplate(description, idpRequest, showRemarks));

    document.getElementById('popupCloseButton').addEventListener('click', () => { this.popupService.closePopup(); });
    document.getElementById('popupOkButton').addEventListener("click", () => { this.validClick(); });
  }

  getContentTemplate(description: string, idpRequest: any, showRemarks : boolean) {
    const environmentInjector = this.applicationRef.injector;
    this.insidePopup =  createComponent(NgbdModalConfirmationIdpContent, {
        environmentInjector,
    });

    this.insidePopup.instance.description = description;
    this.insidePopup.instance.idpRequest = idpRequest;
    this.insidePopup.instance.showRemarks = showRemarks;
    this.insidePopup.hostView.detectChanges();
    
    return this.insidePopup.location.nativeElement;
  }

  validClick() {
    var remark = $('#remark').val();
    this.insidePopup.instance.idpRequest.Remarks = remark;
    this.refreshIdpRequestChange.emit(this.insidePopup.instance.idpRequest);
    this.popupService.closePopup();
  }
}