import { Component, Input, OnDestroy, ViewContainerRef} from "@angular/core";
import { ActionTypeButton, IActionButton, RenderTypeButton, TargetButton } from "app/shared/entity";
import { Subscription } from "rxjs";
import { ActionLegacyService } from "../../shared/actions-legacy.service";

@Component({
    selector: 'toolbar-bo-item',
    templateUrl: './back-office-toolbar-item.component.html',
    styleUrls: ['./back-office-toolbar-item.component.css']
})

export class BoToolbarItemComponent implements OnDestroy {
    @Input() action: IActionButton = {
        Target: TargetButton.Toolbar,
        Name: '',
        LongName: '',
        ActionRight:'',
        EntityType:'',
        Childrens: null,
        GroupName:'',
        SubPerimeterImport:'',
        VisibilityCriteria: '',
        IconClass: '',
        Order: - 1,
        Tooltip: '',
        RenderType: RenderTypeButton.Icone,
        ActionType: ActionTypeButton.Javascript,
        Actions: '',
        Value: '',
        Disabled: true
    };
    @Input() entityId: number = 0;
    @Input() entityStatus: string = '';
    @Input() space: string = '';
    @Input() componentName: string = '';
    @Input() currentProcedureAssociation: string = ''; 
    @Input() currentEntity: any;
    @Input() subPerimeter: any;
    @Input() version: any;
    sub2!: Subscription;
    errorMessage = '';

    constructor(
        private actionLegacyService: ActionLegacyService,
        private viewContainerRef: ViewContainerRef
    ) { 

    }


    IsPartOfToolBar(element: IActionButton) {
        return (element.Target == TargetButton.Toolbar);
    }

    async onElementClicked(event: any, element: IActionButton): Promise<void> {
        var  list = []
        list.push(this.currentEntity)
        switch (element.ActionType) {
            case ActionTypeButton.API:
                await this.actionLegacyService.parsingActionTypeApi(element, list, this.componentName, this.space, this.entityId, this.currentEntity.EntityType, this.entityStatus, '');//config.subPerimeter);
                break;
            case ActionTypeButton.Javascript:
                await this.actionLegacyService.parsingActionTypeJavascript(element, list, this.space, this.componentName, this.entityId, this.currentEntity.EntityType, null, this.version, this.currentProcedureAssociation);
                break;
        }
    }


    ngOnDestroy(): void {
        if (this.sub2) this.sub2.unsubscribe();
    }
   
}