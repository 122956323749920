import { Component, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActionTypeButton, IActionButton, IBreadCrumbEntity, RenderTypeButton, TargetButton } from '../../shared/entity';
import { IActionAppButton, IComponentSelector } from 'app/front-office/front-office-struct';
import { EntityService } from 'app/shared/entity.service';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionLegacyService } from "../../shared/actions-legacy.service";
import { BoService } from 'app/back-office/back-office.service';
import { NavigationService } from '../navigation.service';
import { lastValueFrom } from 'rxjs';
import { StringUtils } from 'app/utils/string-utils';

@Component({
    selector: 'toolbar-bo',
    templateUrl: './back-office-toolbar.component.html',
    styleUrls: ['./back-office-toolbar.component.css']
})

export class BoToolbarComponent implements OnInit, OnDestroy, OnChanges {
    name = 'Toolbar';
    errorMessage = '';
    baseUrl: string;
    @Input() version: string = '';
    @Input() entityType: string = '';
    @Input() componentName: string = '';
    @Input() componentType: string = '';
    @Input() userEmail: string = '';
    @Input() status: string = '';   
    @Input() currentBlocType: string = ''; 
    @Input() currentProcedureAssociation: string = '';    
     blocTypeModule: boolean = false;
    components: IComponentSelector[] = [];
    selectedComponent: any = null;
    groups: any;
    currentEntity:any;
    boActions: IActionAppButton[] = [];
    breadCrumbEntities: IBreadCrumbEntity[] = [];
    @Input() idEntity: number = 0;
    @Input() space: string = '';
    perimeter: string = '';
    entitesActions: IActionButton[] = [];
    entitesMainActions: IActionButton[] = [];
    fullyLoaded: boolean = false;
    idEntityUse : number = 0;
    constructor(private router: Router,
        private entitiesService : EntityService,
        private navigationService: NavigationService,
        public sanitizer: DomSanitizer,
        private componentService: BoService,
        private viewContainerRef: ViewContainerRef,
        private actionLegacyService: ActionLegacyService
    ) {
        this.baseUrl = router.url;
    }

    async ngOnInit(): Promise<void> {
        this.ngOnDestroy();
        await this.loadPanel();
    }

    async ngOnChanges(changes: any) {
        if (changes.space) {
            await this.loadPanel();
        }
        if (changes.currentBlocType) {
            await this.loadPanel();
        }
        if (changes.currentProcedureAssociation) {
            await this.loadPanel();
        }
        if (changes.idEntity && this.idEntity != this.idEntityUse && this.fullyLoaded) {
            await this.loadPanel();
        }
        else if (changes.componentName && changes.componentName != null && (typeof changes.componentName !== 'undefined') && this.selectedComponent != null && this.selectedComponent.Name != this.componentName) {
            this.selectedComponent.Name = this.componentName;
           $('#list-component').dxLookup('instance').repaint();
        }
    }

    async loadPanel() {
            this.baseUrl = this.router.url;
            if(this.version == null || this.version.length == 0)
            return;
            
            if(this.currentBlocType != null && this.currentBlocType == 'M')
            this.blocTypeModule = true;
            else
            this.blocTypeModule = false;
            this.currentEntity = await lastValueFrom(this.entitiesService.getCurrentEntity(this.space,this.idEntity.toString()));
            var ctrl = this;
            await this.loadComponent();
            await this.displayToolbarAction();
            this.breadCrumbEntities = await lastValueFrom(this.navigationService.getBreadCrumbEntity(this.space, this.idEntity, this.entityType));
            setTimeout(async ()=>{
             $('#list-component').dxLookup({
                items: ctrl.components,
                placeholder: 'Sélectionner un composant',
                width:'350px',
                dropDownOptions: {
                    hideOnOutsideClick: true,
                    container: '#gridComponents',
                    showTitle: false,
                    maxHeight:'85vh',
                    position: {
                        collision: 'fit',
                        at:'left bottom',
                        my:'left top',
                        offset : '3 0',
                      },
                  },
                displayExpr:'Label',
                itemTemplate(itemData, itemIndex, itemElement) {
                    var className = '';
                    if(itemData.Type == 'CS')
                        className = 'fa-list'
                    else if(itemData.Type == 'CD' || itemData.Type == 'CM')
                        className = 'fa-th'
                    else if(itemData.Type == 'ET')
                        className = 'fa-puzzle-piece'
                    var temp = $('<div><i class="fas '+className+'"></i>'+itemData.Label+'</div>');
                    if(itemIndex % 2 == 1)
                    temp.addClass("alternative-row")
                    return temp;
                },
                showClearButton: false,
                value: ctrl.components.filter((tb: { Name: string; }) => tb.Name == ctrl.selectedComponent.Name)[0],
                onItemClick(data)
                {
                    ctrl.router.navigate([data.itemData.Link]);
                }
                });
                $('#list-component').dxLookup('instance')
                this.idEntityUse = this.idEntity;
                this.fullyLoaded = true;
        }, 200)
    }

    async loadComponent(): Promise<void> {
        if (this.entityType != null && this.entityType != undefined && this.entityType != '' && typeof this.entityType !== "undefined" && this.entityType != 'undefined') {
            this.components = await lastValueFrom(this.componentService.getListComponent(this.space, this.entityType, this.idEntity));
            if(this.entityType == 'LR')
            {
                if(this.blocTypeModule)
                this.components = this.components.filter((tb: { Name: string; }) => tb.Name == 'LRGeneral' ||  tb.Name == 'LRFeature');
                else
                this.components = this.components.filter((tb: { Name: string; }) => tb.Name != 'LRFeature');
            }
            for (var i = 0; i < this.components.length; i++) {
                this.components[i].Label = StringUtils.decodeSpecialEntitiesHtml(this.components[i].Label);
                var subLink = this.components[i].Name.substring(2).toLocaleLowerCase();
                if (this.components[i].Type == 'ET') {
                    this.components[i].Link = '/entities/' + this.space + '/' + this.entityType + '/' + this.idEntity + '/' + this.components[i].Name;
                    if (this.components[i].Name.toLocaleLowerCase() == this.componentName.toLocaleLowerCase()) {
                        this.selectedComponent = this.components[i];
                    }
                }
                else {
                    this.components[i].Link = '/components/' + this.space + '/' + this.entityType + '/' + this.idEntity + '/' + subLink + '/' + this.components[i].Type;
                    if (this.components[i].Name.substring(2).toLocaleLowerCase() == this.componentName.toLocaleLowerCase()) {
                        this.selectedComponent = this.components[i];
                    }
                }
            }

            if (this.componentName == '') {
                this.selectedComponent = this.components[0];
            }
            if(this.selectedComponent && this.selectedComponent.Name.startsWith(this.entityType))
                this.selectedComponent.Name = this.selectedComponent.Name.substring(2)
        }
    }

    ngOnDestroy(): void {
    }

    IsPartOfToolBar(element: IActionButton) {
        return (element.Target == TargetButton.Toolbar);
    }
    IsPartOfMenu(element: IActionButton) {
        return (element.Target == TargetButton.ContextMenu);
    }

    async displayToolbarAction() {
        this.entitesActions = [];
        this.entitesMainActions = [];
        let componentActions = await lastValueFrom(this.entitiesService.getEntitiesAction(this.space, this.idEntity, this.entityType, this.entityType));
        var  list = []
        list.push(this.currentEntity)
        for (var i = 0; i < componentActions.length; i++) {
            if ((this.IsPartOfMenu(componentActions[i]) && (componentActions[i].GroupName != 'Main' || componentActions[i].Name == 'Rapport')) || (this.IsPartOfToolBar(componentActions[i]) && componentActions[i].GroupName == 'Main'&& componentActions[i].Name != 'Rapport')) {
                if (this.actionLegacyService.canDisplayAction(componentActions[i], [], this.componentName, this.componentType,null, this.userEmail, this.currentProcedureAssociation, this.space) && this.entitesActions.indexOf(componentActions[i]) == -1&& this.entitesMainActions.indexOf(componentActions[i]) == -1)
                {
                    if(componentActions[i].Name != 'PasteSelection' && componentActions[i].Name != 'Delete')
                    if(componentActions[i].GroupName == 'Main' && componentActions[i].Name != 'Rapport')
                        this.entitesMainActions.push(componentActions[i]);
                    else 
                        this.entitesActions.push(componentActions[i]);
                }    
            }
        }
        this.groups = [];
        if(this.entitesActions.length > 0)
        {
            var ctrl = this;
            let menuItems = [];
            for (var i = 0; i < this.entitesActions.length; i++) {
                    let group = this.groups.find((obj: { Name: string; }) => {
                        return obj.Name === this.entitesActions[i].GroupName
                    });
                    if (!group) {
                        group = {
                            Name: this.entitesActions[i].GroupName,
                            ActionButtons: <IActionButton[]>[]
                        };
                        group.ActionButtons.push(this.entitesActions[i]);
                        this.groups.push(group)
                    }
                    else if(group.ActionButtons.filter((tb: { Name: string; }) => tb.Name == this.entitesActions[i].Name).length == 0) {
                        group.ActionButtons.push(this.entitesActions[i]);
                    }
                }
                for (var i = 0; i < this.groups.length; i++) {
                let hasAddLineGroup = false;
                for (var j = 0; j < this.groups[i].ActionButtons.length; j++) {

                    var space = this.space;
                    this.groups[i].ActionButtons[j].Actions = this.groups[i].ActionButtons[j].Actions.replace('(space)', this.space);
                    this.groups[i].ActionButtons[j].Actions = this.groups[i].ActionButtons[j].Actions.replace('[    "(entitiesId)"  ]', '""');

                        var last = menuItems[menuItems.length-1]
                        if (menuItems.length > 0 && !hasAddLineGroup && (last != null && last.text != 'group')) {
                            menuItems.push(
                                {
                                    Control: this,
                                    template: this.actionLegacyService.menuItemTemplate,
                                    Element: this.groups[i].ActionButtons[0],
                                    text: 'group'
                                }
                            )
                        }
                        hasAddLineGroup = true;
                        var subItems: any[] = [];
                        if (this.groups[i].ActionButtons[j].Childrens.length > 0) {
                            var ctrl = this;
                            this.groups[i].ActionButtons[j].Childrens.forEach(function (val: { Actions: string; RenderType: RenderTypeButton; LongName: any; }, index: any, tab: any) {
                                if (ctrl.actionLegacyService.canDisplayAction(<any>val, [], ctrl.componentName, ctrl.componentType,null, ctrl.userEmail, ctrl.currentProcedureAssociation, ctrl.space))
                                {
                                    var subItem: any;
                                    val.Actions = val.Actions.replace('[    "(entitiesId)"  ]', '""');
                                    val.Actions = val.Actions.replace('(space)', space);
        
                                    if (val.RenderType == RenderTypeButton.Icone)
                                        subItem = {
                                            Element: val,
                                            Control: ctrl,
                                            template: ctrl.actionLegacyService.menuItemTemplate
                                        };
                                    else
                                        subItem = {
                                            text: val.LongName,
                                            Element: val,
                                            Control: ctrl,
                                            template: ctrl.actionLegacyService.menuItemTemplate
                                        };
        
                                    subItems.push(subItem);
                                }
                            });
                        }
    
                        if (this.groups[i].ActionButtons[j].RenderType == RenderTypeButton.Icone) {
                            if (this.groups[i].ActionButtons[j].Childrens.length > 0) {
                                this.groups[i].ActionButtons[j].Disabled = true;
                                if(subItems.length > 0)
                                    menuItems.push({
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate,
                                        items: subItems
                                    });
                                    
                            }
                            else
                                menuItems.push(
                                    {
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate
                                    });
                        }
                        else if (this.groups[i].ActionButtons[j].RenderType == RenderTypeButton.IconeAndText) {
                            if (this.groups[i].ActionButtons[j].Childrens.length > 0) {
                                this.groups[i].ActionButtons[j].Disabled = true;
                                if(subItems.length > 0)
                                    menuItems.push({
                                        text: this.groups[i].ActionButtons[j].LongName,
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate,
                                        items: subItems
                                    });
                            }
                            else
                                menuItems.push(
                                    {
                                        text: this.groups[i].ActionButtons[j].LongName,
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate
                                    });
                        }
                        else if (this.groups[i].ActionButtons[j].RenderType == RenderTypeButton.Text) {
                            if (this.groups[i].ActionButtons[j].Childrens.length > 0) {
                                this.groups[i].ActionButtons[j].Disabled = true;
                                if(subItems.length > 0)
                                    menuItems.push({
                                        text: this.groups[i].ActionButtons[j].LongName,
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate,
                                        items: subItems
                                    });
                            }
                            else
                                menuItems.push(
                                    {
                                        text: this.groups[i].ActionButtons[j].LongName,
                                        Element: this.groups[i].ActionButtons[j],
                                        Control: this,
                                        template: this.actionLegacyService.menuItemTemplate
                                    }
                                )
                        }
                    }
                }
            var menuItemsLp = [{
                icon: 'fa-solid fa-ellipsis',
                items: menuItems,
            }];
            setTimeout( ()=>
            {
            var menu = $('#menu-buttons').dxMenu({
                dataSource: menuItemsLp, 
                onItemClick: async function (event: any): Promise<void> {
                    let element = event.itemData.Element;

                    if (!element || element.Disabled) return;
                    if (element.Childrens && element.Childrens.length > 0) return;
                    switch (element.ActionType) {
                        case ActionTypeButton.API:
                            await ctrl.actionLegacyService.parsingActionTypeApi(element, [], event.itemData.Control.componentName, ctrl.space, event.itemData.Control.idEntityUse, event.itemData.Control.entityType, event.itemData.Control.status, '');//config.subPerimeter);
                            break;
                        case ActionTypeButton.Javascript:
                            await ctrl.actionLegacyService.parsingActionTypeJavascript(element, [], ctrl.space, event.itemData.Control.componentName, event.itemData.Control.idEntityUse, event.itemData.Control.entityType, null, ctrl.version, ctrl.currentProcedureAssociation);
                            break;
                    }
                }
            }).dxMenu('instance');
             $('#menu-buttons').removeClass('dx-menu');
             $('#menu-buttons').children('.dx-menu-horizontal').show()
        }, 1000);
        }
        else $('#menu-buttons').children('.dx-menu-horizontal').hide()
        this.idEntityUse = this.idEntity;
    }
}
